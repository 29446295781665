import { Yup } from "csv-package";

const incidentTypes = ["assult", "threat", "damage", "stalked", "other"].reduce(
  (children, incidentKey) => {
    return {
      ...children,

      [`incident_${incidentKey}`]: Yup.array()
        .ensure()
        .when("incident_types", {
          is: (incident_types: string[]) =>
            incident_types && incident_types.includes(incidentKey),
          then: Yup.array().ensure().min(1, "Please select recent or previous"),
        }),

      [`incident_${incidentKey}_recent`]: Yup.string().when(
        `incident_${incidentKey}`,
        {
          is: (v: string[]) => v && v.includes("recent"),
          then: Yup.string().required("Please enter detail or example"),
        }
      ),

      [`incident_${incidentKey}_previous`]: Yup.string().when(
        `incident_${incidentKey}`,
        {
          is: (v: string[]) => v && v.includes("previous"),
          then: Yup.string().required("Please enter detail or example"),
        }
      ),
    };
  },
  {}
);

const incidentHistory = {
  // @ts-ignore
  incident_date: Yup.string().csvDate().required("Please enter a date"),
  violence_duration: Yup.string().required("Please enter a timespan"),
  incident_types: Yup.array()
    .ensure()
    .min(1, "Please select at least 1 incident category"),
  ...incidentTypes,
  child_affected: Yup.string().required("We require this information"),
  child_affected_detail: Yup.string().when("child_affected", {
    is: "yes",
    then: Yup.string().required("Please provide detail"),
  }),
  resp_charged: Yup.string().required("We require this information"),
  police_contacted: Yup.string().required("We require this information"),
};

export default incidentHistory;
