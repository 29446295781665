import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { ApplicationStatusType } from "csv-package";

import spacing from "../theme/spacing";
import { ApplicationContext } from "../reducers/applicationFrame";
import Button from "./Button";

type Props = {
  steps: object[];
  status: ApplicationStatusType;
};

const NextButton: React.FC<Props> = ({ steps, status }) => {
  const {
    goToStep,
    state: { currentStep },
  } = React.useContext(ApplicationContext);

  const lastIndex = React.useMemo(() => steps.length - 1, [steps.length]);

  const disabled = React.useMemo(() => {
    if (status === "initiated") {
      return false;
    }
    if (status === "submitted" && currentStep < lastIndex) {
      return false;
    }

    return true;
  }, [status, currentStep, lastIndex]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    goToStep(currentStep + 1);
  };

  // hide button on re-declare screen
  if (status === "re-declare-requested" && currentStep === lastIndex) {
    return null;
  }

  // hide button if on complete screen
  if (currentStep > lastIndex) {
    return null;
  }

  return (
    <ButtonContainer disabled={disabled}>
      <Button onClick={handleClick}>
        {currentStep < lastIndex ? "Next: " : "Submit"}
        {_.get(steps, `[${currentStep + 1}].name`, "")}
      </Button>
    </ButtonContainer>
  );
};

export default NextButton;

const ButtonContainer = styled.fieldset`
  padding: 0;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding: 0 ${spacing.gutters}px;
  button {
    margin: 0 10px;
  }

  button:last-child {
    margin-right: 0;
  }

  button:first-child {
    margin-left: 0;
  }
`;
