import { NarrativeTemplateType } from "../../helpers/narrativeGenerator";

const template: NarrativeTemplateType[] = [
  {
    title: "Background",
    template: "Please supply tempate.",
  },
];

export default template;
