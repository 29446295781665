import React from "react";
import Cookies from "js-cookie";
import { useHistory, useParams } from "react-router-dom";

// This page is when a Applicant has a URL with a base64 endcoded data that will allow then to re-authinicate and complete thier re-declartion

const ReEntry: React.FC = () => {
  const { push } = useHistory();
  const { base64key } = useParams<{ base64key: string }>();
  const identityID = window.atob(base64key);

  // build local storage key
  const localStorageKey = `CognitoIdentityId-${process.env.REACT_APP_APPLICANT_IDENTITY_POOL_ID}`;
  const localStorageValue = identityID;

  localStorage.setItem(localStorageKey, localStorageValue);

  Cookies.set("anonymousIdenttiyId", identityID, {
    expires: 0.08,
  });

  push("/applications");

  return null;
};

export default ReEntry;
