import { courts } from "csv-package";

const getValidPostcodes = (): string[] => {
  const postcodes: string[] = [];

  courts.forEach((court) => {
    if (court.enabled) {
      postcodes.push(...court.court_postcodes);
    }
  });

  return postcodes;
};

export default getValidPostcodes;
