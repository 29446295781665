import { useFormikContext } from "formik";
import { rgba } from "polished";
import React from "react";
import { useParams, withRouter } from "react-router-dom";
import styled from "styled-components/macro";
import get from "lodash/get";

import { ReactComponent as OkIcon } from "../../../assets/icons/PostcodeOk.svg";
import media from "../../../theme/media";

import ExitContentEvr from "../../../components/ExitContentEvr";
import LoadingIndicator from "../../../components/LoadingIndicator";
import SupportServices from "../../../components/SupportServices";
import { FlatApplciationType } from "../../../types";
import { protectedPersonRoles } from "../constants";
import narrativeGenerator from "../../../helpers/narrativeGenerator";
import template from "../narrativeTemplate";
import ApplicationContext from "../../../reducers/applicationFrame";

const Complete: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { values, setFieldValue } = useFormikContext<FlatApplciationType>();
  const [saving, setSaving] = React.useState(true);

  const { state: { schema } } = React.useContext(ApplicationContext);

  // on mount
  React.useEffect(() => {
    if (
      get(values, "appl_pregnant_baby") === "yes" ||
      get(values, "prp_pregnant_baby") === "yes" ||
      get(values, "prp_addr_secret") === "yes" ||
      get(values, "appl_det_addr_secret") === "yes" ||
      get(values, "incident_pys_detail") ||
      get(values, "incident_fsw_detail") ||
      get(values, "incident_htc_detail") ||
      get(values, "incident_sty_detail")
    ) {
      setFieldValue("Flags.high_risk", true);
    } else {
      setFieldValue("Flags.high_risk", false);
    }

    setFieldValue("Status", "submitted");

    // work out what type the application is
    const typeMap = {
      AE: "E",
      AV: "V",
      AC: "V",
      AR: "R",
      RV: "V",
      RC: "V",
      RR: "R",
      ACAV: "V",
      ACAE: "EV",
      ACAEAV: "EV",
      AEAV: "EV",
      RCRV: "V",
    };
    if (!Array.isArray(values.app_types)) {
      throw new Error("app_types should be an array!");
    }
    const typeKey = values.app_types.sort().join("") as keyof typeof typeMap;
    let Type = typeMap[typeKey];

    setFieldValue("Type", Type);
    let generatedNarative = narrativeGenerator(
      values,
      template,
      schema,
      false
    );
    generatedNarative = generatedNarative.toUpperCase();
    setFieldValue("Narrative", generatedNarative);

    // eslint-disable-next-line
  }, []);

  // turn off loading screen when <AutoSaver is a reported a confirmed submit
  const form_is_submitted = values.form_is_submitted;
  React.useEffect(() => {
    if (form_is_submitted === "yes") {
      setSaving(false);
    }
  }, [form_is_submitted]);

  if (saving) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Inner>
        <Main>
          <SuccessBox>
            <OkIcon />
            <div>
              <h2>
                Your application has been submitted but no changes have been made at this stage.
              </h2>
              <p>
                Your reference number is {id}. You will not need to quote this
                number when talking to staff, however it may be useful as a
                reference so keep it somewhere safe.
              </p>
            </div>
          </SuccessBox>
          <h2>What happens next?</h2>
          <ExitContentEvr
            isProtectedPerson={values.seeking === "appl" && protectedPersonRoles.includes(String(values.seeking_appl_role))}
          />
          <h2>Family violence support services</h2>
          <p>
            For support and advice about family violence, you may wish to
            contact a family violence service. To find local, available support
            in your location, visit the
            <a
              href="https://www.dvrcv.org.au/"
              target="_blank"
              rel="noreferrer"
            >
              Domestic Violence Resource Centre directory.
            </a>
          </p>
          <SupportServices
            services={[
              "safeSteps",
              "noToViolence",
              "_1800Respect",
              "djirra",
              "inTouch",
              "rainbowDoor",
            ]}
          />
          <h2>Legal advice</h2>
          <SupportServices
            services={[
              "victoriaLegalAid",
              "federationOfCommunityLegalCentres",
              "victoriaAboriginalLegalService",
            ]}
          />
        </Main>
      </Inner>
    </>
  );
};

export default withRouter(Complete);

const Inner = styled.div`
  width: 100%;
  max-width: 1310px;
  position: relative;
  z-index: 1;
  padding: 0;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${media.greaterThan("desktop")`
    flex-direction: row;
  `}
`;

const Main = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: 646px;
`;

const SuccessBox = styled.div`
  padding: 20px 15px;
  background: ${rgba(`#1ba4b2`, 0.15)};
  width: 100%;
  font-size: 18px;
  line-height: 1.44;
  position: relative;
  margin-bottom: 40px;

  ${media.greaterThan("tablet")`
    padding: 33px 64px;
  `}

  h2 {
    font-size: 22px;
    margin-top: 0;
  }

  > svg {
    height: 25px;
    width: 25px;
    margin-bottom: 5px;
    ${media.greaterThan("tablet")`
        position: absolute;
        top: 33px;
        left: 32px;
        transform: translateX(-50%);

    `};
  }
`;
