import React from "react";

import Button from "./Button";
import Modal from "./Modal";

type Props = {
  onCloseClick: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
  onOkClick: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
  visible: boolean;
  displayText: string;
};

const ConfirmationPopup: React.FunctionComponent<Props> = ({ onCloseClick, onOkClick, visible, displayText }) => {
  if (visible) {
    return (
      <Modal onClose={onCloseClick}>
        <p>
            {displayText}
        </p>
        <div className="buttons">
          <Button
            onClick={(e) => {
              e.preventDefault();
              onOkClick(e)
            }}
          >
            Ok
          </Button>
          <Button 
            variant="secondary" 
            onClick={onCloseClick}
          >
            Cancel 
          </Button>
        </div>
      </Modal>
    );
  }

  return null;
};

export default ConfirmationPopup;
