import React from "react";
import styled from "styled-components/macro";

import media from "../theme/media";

import Info from "./Info";
import mobileTimeline from "../assets/images/mobile-timeline.svg";
import desktopTimeline from "../assets/images/desktop-timeline.svg";
import { rem } from "polished";

const ExitContent = () => (
  <div>
    <p>
      A{" "}
      <Info
        displayText="registrar"
        headingText="Registrar"
        bodyText="A court officer responsible for maintaining a register or record of court proceedings."
        inherit
      />{" "}
      will contact you to discuss your application. If you have chosen phone
      contact, the{" "}
      <Info
        displayText="registrar"
        headingText="Registrar"
        bodyText="A court officer responsible for maintaining a register or record of court proceedings."
        inherit
      />{" "}
      will ring you from a private number and will not leave a message unless
      you have said it is safe to do so. Please{" "}
      <Info
        displayText="contact your local court"
        headingText="your local court"
        bodyText='<a href="https://www.mcv.vic.gov.au/going-court/find-court" title="_blank" rel="noopener noreferrer" >You can find your nearest court location here.</a>'
        inherit
      />{" "}
      if you have said it is not safe for the court to ring or email you.
    </p>
    <MobileTimeline src={mobileTimeline} />
    <DesktopTimeline src={desktopTimeline} />
    <div>
      <h2>What you’ll discuss with the registrar</h2>
      <p>
        The{" "}
        <Info
          displayText="registrar"
          headingText="Registrar"
          bodyText="A court officer responsible for maintaining a register or record of court proceedings."
          inherit
        />{" "}
        will talk to you about:
      </p>
      <ul>
        <li>the details of your application</li>
        <li>the intervention order process and what to expect</li>
        <li>a time and date for the possible court hearing</li>
        <li>the court FVIO application process and what to expect</li>
        <li>any safety concerns you might have</li>
      </ul>
      <p>
        Please note that the Magistrates' Court is open during business hours
        only, Monday to Friday and is not open on public holidays.
      </p>
    </div>
  </div>
);

export default ExitContent;

const MobileTimeline = styled.img`
  display: block;
  margin: ${rem(35)} 0 ${rem(80)};
  ${media.greaterThan("tablet")`
    display: none;
  `}
`;

const DesktopTimeline = styled.img`
  display: none;
  margin: ${rem(35)} 0 ${rem(80)};
  width: 100%;
  ${media.greaterThan("tablet")`display: block;`}
`;
