import React from "react";
import styled from "styled-components/macro";
import { useField } from "formik";
import { rem } from "polished";
import MaskedInput from "react-text-mask";

import inputStyles from "../theme/inputStyles";

import ErrorMessage from "./ErrorMessage";
import FormLabel from "./FormLabel";
import FormNote from "./FormNote";
import useQuestionConfig from "../hooks/useQuestionConfig";

type Props = {
  name: string;
  label?: string;
  note?: string;
};

const QuestionDatepicker: React.FC<Props> = ({ name, ...rest }) => {
  const [field, meta] = useField(name);
  const { label, note } = useQuestionConfig(name, rest);

  return React.useMemo(() => {
    const hasError = meta.touched && meta.error;
    return (
      <Wrapper>
        {label && <FormLabel name={name} text={label} />}
        {note && <FormNote text={note} />}
        {hasError && <StyledErrorMessage>{meta.error}</StyledErrorMessage>}
        <Input
          name={name}
          mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
          value={typeof field.value !== "string" ? "" : field.value}
          placeholder="DD/MM/YYYY"
          onChange={field.onChange}
          onBlur={field.onBlur}
        />
      </Wrapper>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value, meta]);
};

export default QuestionDatepicker;

export const Wrapper = styled.div`
  margin-bottom: ${rem(32)};
  & + & {
    margin-left: 13px;
  }
`;

const Input = styled(MaskedInput)`
  ${inputStyles}
  width: 160px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-bottom: 13px;
`;
