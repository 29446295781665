import Yup from '../yup';

const review = {
  appl_info_share: Yup.string().when('Status', {
    is: 'initiated',
    then: Yup.string().required('Please select a information sharing option'),
  }),
  appl_info_share_limited: Yup.array()
    .ensure()
    .when('appl_info_share', {
      is: 'limited',
      then: Yup.array()
        .ensure()
        .min(1, 'Please select at least one Information Sharing Entities'),
    }),
  declaration_of_truth: Yup.boolean()
    .oneOf([true], 'You must agree with the declaration of truth')
    .required('You must agree with the declaration of truth'),
};

export default review;
