import React from "react";

import CheckboxAdvanced from "../../../components/CheckboxAdvanced";
import LongFootnote from "../../../components/LongFootnote";
import PageTitle from "../../../components/PageTitle";
import QuestionsFrame from "../../../components/QuestionsFrame";
import SubHeading from "../../../components/SubHeading";
import ConditionalFieldsWrapper from "../../../components/ConditionalFieldsWrapper";
import ConditionsAdjuster from "./ConditionsAdjuster";

const Conditions = () => (
  <>
    <ConditionsAdjuster />
    <PageTitle heading="Conditions requested on the order" />
    <SubHeading
      heading="I want the respondent to be prevented from:"
      description="You may choose as many as you like from the list, but the
                magistrate may not include all the conditions you choose on the
                order. If there is something you do not want the respondent to
                do which is not covered in this list, you should discuss this
                with the Court Registrar, and note it in the box provided"
    />
    <QuestionsFrame>
      <CheckboxAdvanced name="coo_con_old_a" />
      <CheckboxAdvanced name="coo_con_old_b" />
      <CheckboxAdvanced name="coo_con_old_c" />
      <CheckboxAdvanced name="coo_con_old_d" />
      <CheckboxAdvanced name="coo_con_old_e" />
      <CheckboxAdvanced name="coo_con_old_f" />
      <CheckboxAdvanced name="coo_con_old_g" />
      <CheckboxAdvanced name="coo_con_old_h" />
    </QuestionsFrame>
    <SubHeading heading="I would like exceptions included in this order. The respondent may:" />
    <QuestionsFrame>
      <CheckboxAdvanced name="coo_exc_old_a" />
      <CheckboxAdvanced name="coo_exc_old_b" />
      <CheckboxAdvanced name="coo_exc_old_c" />
      <CheckboxAdvanced name="coo_exc_old_d" />
      <CheckboxAdvanced name="coo_exc_old_e" />
      <p>
        BUT ONLY if the respondent does not commit family violence while doing
        so
      </p>
    </QuestionsFrame>
    <SubHeading heading="I want the Court to order:" />
    <QuestionsFrame>
      <CheckboxAdvanced name="coo_con_old_i" />
      <CheckboxAdvanced name="coo_con_old_j" />
      <CheckboxAdvanced name="coo_con_old_k" />
      <CheckboxAdvanced name="coo_con_old_l" />
    </QuestionsFrame>
    <SubHeading heading="I want the Court to encourage:" />
    <QuestionsFrame>
      <CheckboxAdvanced name="coo_con_old_m" />
    </QuestionsFrame>
    <ConditionalFieldsWrapper conditions={{ prp_add_order: "yes" }}>
      <SubHeading heading="Children’s arrangements:" />
      <QuestionsFrame>
        <CheckboxAdvanced name="coo_con_old_n" />
        <CheckboxAdvanced name="coo_con_old_o" />
      </QuestionsFrame>
      <SubHeading heading="I believe that it may jeopardise my safety and/or the safety of my child/ren for my child/ren:" />
      <QuestionsFrame>
        <CheckboxAdvanced name="coo_con_old_p" />
        <CheckboxAdvanced name="coo_con_old_q" />
        <CheckboxAdvanced name="coo_con_old_r" />
      </QuestionsFrame>
    </ConditionalFieldsWrapper>

    <QuestionsFrame>
      <LongFootnote name="coo_con_other" charLimit={1500} />
    </QuestionsFrame>
  </>
);

export default Conditions;
