import React from "react";
import styled from "styled-components/macro";
import ReactLoading from "react-loading";

type Props = {
  color?: string;
};

const LoadingIndicator: React.FC<Props> = ({ color }) => (
  <Wrapper color={color}>
    <ReactLoading type="spin" />
  </Wrapper>
);

export default LoadingIndicator;

const Wrapper = styled.div`
  padding: 50px 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  ${(props) => props.color && `color: ${props.color}`}
`;
