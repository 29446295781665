const getAge = (dobString: string): number => {
  // convert dd/mm/yyyy to js date
  const dobDate = new Date();
  const dobParts = dobString.split("/");
  dobDate.setDate(parseInt(dobParts[0], 10));
  dobDate.setMonth(parseInt(dobParts[1], 10) - 1);
  dobDate.setFullYear(parseInt(dobParts[2], 10));
  dobDate.setHours(0);
  dobDate.setMinutes(0);
  dobDate.setSeconds(0);
  dobDate.setMilliseconds(0);

  const nowDate = new Date();

  // @ts-ignore
  const diff = nowDate - dobDate;

  return new Date(diff).getUTCFullYear() - 1970;
};

export default getAge;
