import { useFormikContext } from "formik";
import React from "react";
import styled from "styled-components";
import ConditionalFieldsWrapper from "../../../components/ConditionalFieldsWrapper";
import IconButton from "../../../components/IconButton";
import Question from "../../../components/Question";
import QuestionAddress from "../../../components/QuestionAddress";
import QuestionDOB from "../../../components/QuestionDOB";
import QuestionsFrame from "../../../components/QuestionsFrame";
import QuestionsSection from "../../../components/QuestionsSection";
import RadioGroup from "../../../components/RadioGroup";
import { FlatApplciationType } from "../../../types";

type Props = {
  childIndex: number;
  addChild: () => void;
  removeChild: (childIndex: number) => void;
  showAddButton: boolean;
};

const Child: React.FC<Props> = ({
  childIndex,
  addChild,
  removeChild,
  showAddButton,
}) => {
  const { isValid } = useFormikContext<FlatApplciationType>();
  return (
    <ChildContainer>
      <QuestionsSection
        heading={`Child ${childIndex + 1}`}
        onRemoveClick={() => removeChild(childIndex)}
      >
        <QuestionsFrame>
          <Question name={`children[${childIndex}].prp_giv_nam`} />
          <Question name={`children[${childIndex}].prp_giv_nam2`} />
          <Question name={`children[${childIndex}].prp_giv_nam3`} />
          <Question name={`children[${childIndex}].prp_fam_nam`} />
          <Question name={`children[${childIndex}].prp_other_nam`} />
          <Question
            type="select"
            name={`children[${childIndex}].prp_rel_to_resp`}
          />
          <QuestionDOB
            dobName={`children[${childIndex}].prp_dob`}
            ageName={`children[${childIndex}].prp_age`}
          />
          <RadioGroup name={`children[${childIndex}].prp_sex`} />
          <ConditionalFieldsWrapper
            conditions={{
              [`children[${childIndex.toString()}].prp_sex`]: "U",
            }}
          >
            <Question name={`children[${childIndex}].prp_sex_other`} />
          </ConditionalFieldsWrapper>
          <RadioGroup name={`children[${childIndex}].prp_lgbt`} />
          <RadioGroup name={`children[${childIndex}].prp_abor_code`} />
          <RadioGroup name={`children[${childIndex}].prp_disabl`} />
          <RadioGroup name={`children[${childIndex}].prp_live`} />
          <ConditionalFieldsWrapper
            conditions={{ [`children[${childIndex}].prp_live`]: "no" }}
          >
            <QuestionAddress
              namePrefix={`children[${childIndex}].prp_addr`}
              label="What address is the child currently living at?"
            />
          </ConditionalFieldsWrapper>

          {showAddButton && (
            <div>
              <IconButton
                icon="plus"
                disabled={!isValid}
                onClick={(e) => {
                  e.preventDefault();
                  addChild();
                }}
                label="Add another child"
              />
            </div>
          )}
        </QuestionsFrame>
      </QuestionsSection>
    </ChildContainer>
  );
};

export default Child;

const ChildContainer = styled.div`
  padding-top: 30px;
  border-top: 1px solid #d4e1ed;
`;
