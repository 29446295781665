import React from "react";
import { useFormikContext } from "formik";
import { flatten, get, pick } from "lodash";
import { rem } from "polished";
import styled from "styled-components/macro";
import useDeepCompareEffect from "use-deep-compare-effect";
import { ConditionsType, conditionsChecker } from "csv-package";

import { FlatApplciationType } from "../types";

// build names for address question
const addressQuestionNames = (namePrefix: string): string[] => {
  return ["_strnr", "_strname", "_strtype", "_sub", "_state", "_postcode"].map(
    (suffix) => `${namePrefix}${suffix}`
  );
};
const detailQuestionAccordionNames = (namePrefix: string): string[] => {
  return ["_detail", "_date"].map(
    (suffix) => `${namePrefix}${suffix}`
  );
};

const recursiveGetFieldNameWithin = (children: React.ReactNode): string[] => {
  const names = [];

  // array of compnents
  if (Array.isArray(children)) {
    const tempStore = children.map((c) => recursiveGetFieldNameWithin(c));
    names.push(...tempStore);
    // @ts-ignore
  } else if (!children.props) {
    // do nothing
    // @ts-ignore
  } else if (!children.props.name && children.props.children) {
    // @ts-ignore
    names.push(...recursiveGetFieldNameWithin(children.props.children));
    // @ts-ignore
  } else if (children.props.namePrefix) {
    // @ts-ignore
    names.push(addressQuestionNames(children.props.namePrefix));
    // @ts-ignore
    names.push(detailQuestionAccordionNames(children.props.namePrefix));
    // @ts-ignore
  } else if (children.props.name) {
    // @ts-ignore
    names.push(children.props.name);
  }

  return flatten(names);
};

type Props = {
  conditions: ConditionsType;
  indent?: boolean;
  embeded?: boolean;
};

const ConditionalFieldsWrapper: React.FC<Props> = ({
  conditions,
  indent,
  children,
  embeded,
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const [hasPassed, setHasPassed] = React.useState<boolean | null>(null);

  const names = React.useMemo(() => {
    return recursiveGetFieldNameWithin(children);
  }, [children]);

  const pickedConditionsValues = pick(
    values,
    Object.keys(conditions)
  ) as FlatApplciationType;

  // check conditions
  useDeepCompareEffect(() => {
    const result = conditionsChecker(conditions, pickedConditionsValues);
    setHasPassed(result);
  }, [pickedConditionsValues]);

  React.useEffect(() => {
    if (hasPassed === null) {
      return;
    }
    if (!hasPassed) {
      names.forEach((name) => {
        const resetValue = Array.isArray(pickedConditionsValues[name])
          ? []
          : "";
        if (get(values, name) !== resetValue) {
          setFieldValue(name, resetValue);
          setFieldTouched(name, false);
        }
      });
    }
    // eslint-disable-next-line
  }, [hasPassed]);
  return React.useMemo(() => {
    if (!hasPassed) {
      return null;
    }

    return (
      <Wrapper indent={indent} embeded={embeded}>
        {children}
      </Wrapper>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPassed]);
};

export default ConditionalFieldsWrapper;

export const Wrapper = styled.div<{
  indent?: boolean;
  embeded?: boolean;
}>`
  padding-top: ${rem(16)};
  padding-left: ${({ indent }) => (indent ? rem(45) : "0")};
`;
