import React from "react";
import styled, { css } from "styled-components/macro";
import _ from "lodash";
import { rem } from "polished";
import { useField, useFormikContext } from "formik";
import { SchemaOptionType } from "csv-package";

import Checkbox from "./Checkbox";
import FormLabel, { Label } from "./FormLabel";
import FormNote, { Note } from "./FormNote";
import ErrorMessage from "./ErrorMessage";
import ConditionalFieldsWrapper from "./ConditionalFieldsWrapper";
import ConfirmationPopup from './ConfirmationPopup';
import optionsCalculator from "../helpers/optionsCalculator";
import { FlatApplciationType } from "../types";
import useQuestionConfig from "../hooks/useQuestionConfig";

type Props = {
  name: string;
  label?: string;
  note?: string;
  options?: SchemaOptionType[];
  disableOthers?: string | string[];
  selectAllText?: string;
  indentChildren?: boolean;
  compact?: boolean;
  optionChildren?: {
    [key: string]: React.ReactNode;
  };
  confirmationMsg?: boolean;
};

const CheckboxGroup: React.FC<Props> = ({
  name,
  disableOthers,
  selectAllText,
  indentChildren = true,
  optionChildren,
  compact,
  confirmationMsg=false,
  ...rest
}) => {
  const { values } = useFormikContext<FlatApplciationType>();
  const [field, meta, helpers] = useField(name);
  const { default: configDefault, label, note, options } = useQuestionConfig(
    name,
    rest
  );

  const [confirmationPopup, setConfirmationPopup] = React.useState(false);
  const [checkboxValue, setCheckboxValue] = React.useState('');

  //confirmation modal functions
  const onOkConfirmation = () => {
    helpers.setValue([checkboxValue]);
    setConfirmationPopup(false);
  }

  // Set defaults from config if field hasn't been touched and value is empty
  React.useEffect(() => {
    if (!meta.touched && _.isEmpty(field.value)) {
      helpers.setValue(configDefault);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // adjust checkbox options if there are any conditions
  const calculatedOptions = React.useMemo(() => {
    if (options === undefined) {
      return [];
    }
    return optionsCalculator(rest.options || options, values);
  }, [rest.options, options, values]);

  // clear any values from array if they are not showing because of conditions
  React.useEffect(() => {
    if (!Array.isArray(meta.value) || _.isEqual(options, calculatedOptions)) {
      return;
    }

    // get any values that are no longer valid
    const optionsValuesToBeCleared = _.xorWith(
      calculatedOptions,
      options,
      _.isEqual
    ).map((o) => o.value);

    if (_.intersection(optionsValuesToBeCleared, meta.value).length > 0) {
      helpers.setValue(_.difference(meta.value, optionsValuesToBeCleared));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, calculatedOptions]);

  const hasError = meta.touched && meta.error;
  const currentValues = React.useMemo(() => field.value || [], [
    field.value,
  ]) as string[];

  const handleCheckboxChange = React.useCallback(
    (e) => {
      const value = e.target.value;

      if (typeof currentValues === "string") {
        helpers.setValue([]);
      }
      if (!currentValues.includes(value)) {
        if((Array.isArray(currentValues) && currentValues.length > 0) && ((Array.isArray(disableOthers) && disableOthers.includes(value)) ||
        value === disableOthers) && confirmationMsg
        ) {
          setCheckboxValue(value);
          setConfirmationPopup(true);
        }else {
          helpers.setValue([...currentValues, value]);
        }
      } else {
        helpers.setValue(currentValues.filter((v) => v !== value));
      }

      if (!confirmationMsg && 
        ((Array.isArray(disableOthers) && disableOthers.includes(value)) ||
          value === disableOthers) &&
        !currentValues.includes(value)
      ) {
        helpers.setValue([value]);
      }
    },
    // eslint-disable-next-line
    [currentValues]
  );

    return (
      <div>
        {
          <ConfirmationPopup 
            visible={confirmationPopup} 
            onCloseClick={()=>setConfirmationPopup(false)} 
            onOkClick={onOkConfirmation}
            displayText="Selecting this option will remove any previously selected option(s) and the information you have entered in your application."
            />
        }
        <Wrapper compact={compact}>
          {label && <FormLabel name={name} text={label} />}
          {note && <FormNote text={note} />}
          {hasError && <StyledErrorMessage>{hasError}</StyledErrorMessage>}
          {selectAllText && (
            <Checkbox
              name="xxx"
              value="xxx"
              label={selectAllText}
              onCheckboxChange={(e) => {
                // if all options selected onChange de-select all else select all
                if (_.isEqual(currentValues, Object.keys(calculatedOptions))) {
                  helpers.setValue([]);
                } else {
                  helpers.setValue(Object.keys(calculatedOptions));
                }
              }}
              checked={_.isEqual(currentValues, Object.keys(calculatedOptions))}
            />
          )}
          {calculatedOptions.map((o, i) => {
            if (o.value === "hidden") {
              return null;
            }

            const optionChild = _.get(optionChildren, o.value, null);
            const checked = currentValues.includes(o.value);

            // easy check if disable Other is a string
            // first check if the disableOther has been checked
            // then check that this option is the disable others option
            let disabled =
              typeof disableOthers === "string" &&
              currentValues.includes(disableOthers) &&
              o.value !== disableOthers;

            // When array
            // Check diabledOthers is an array
            // interestion => check that if a disable other has been selected
            // make sure this option is one of the disable others
            if (
              Array.isArray(disableOthers) &&
              _.intersection(currentValues, disableOthers).length > 0 &&
              !_.includes(disableOthers, o.value)
            ) {
              disabled = true;
            }

            return (
              <CheckboxWrapper key={`${i} ${o.value}`}>
                <Checkbox
                  label={o.text}
                  name={name}
                  value={o.value}
                  onCheckboxBlur={field.onBlur}
                  disabled={disabled}
                  onCheckboxChange={handleCheckboxChange}
                  checked={checked}
                  description={o.description}
                />
                {optionChild && (
                  <ConditionalFieldsWrapper
                    conditions={{ [name]: o.value }}
                    indent={indentChildren}
                    embeded
                  >
                    {optionChild}
                  </ConditionalFieldsWrapper>
                )}
              </CheckboxWrapper>
            );
          })}
        </Wrapper>
      </div>
    );
};

export default CheckboxGroup;

const Wrapper = styled.div<{ compact?: boolean }>`
  margin-bottom: ${rem(35)};
  ${({ compact }) =>
    compact &&
    css`
      margin-top: -${rem(16)};
      > ${Note} {
        display: none;
      }
      > ${Label} {
        display: none;
      }
    `}
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-bottom: 13px;
`;

const CheckboxWrapper = styled.div`
  margin-top: ${rem(4)};
`;
