import React from "react";
import styled from "styled-components/macro";
import { rem } from "polished";

import media from "../theme/media";

import { Note } from "./FormNote";
import Info from "./Info";
import processHelpText from "../helpers/processHelpText";

const FormLabel: React.FunctionComponent<{
  text: string;
  name?: string;
}> = React.memo(
  ({ text, name, children }) => {
    const helpTextLabel = processHelpText(text);

    if (helpTextLabel) {
      let { after, before } = helpTextLabel;

      return (
        <Label htmlFor={name} name={name}>
          {before && before}
          <Info inherit string="" {...helpTextLabel} />
          {after && after}
          {children}
        </Label>
      );
    }
    return (
      <Label htmlFor={name}>
        {text}
        {children}
      </Label>
    );
  },
  (p, n) => {
    return true;
  }
);

export default FormLabel;

export const Label = styled.label<{ name?: string }>`
  display: block;
  font-size: 17px;
  font-weight: bold;
  color: #3c3c3c;
  margin-bottom: ${rem(8)};
  font-weight: 600;
  position: relative;

  ${Note} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }

  ${media.greaterThan("tablet")`
    font-size: 18px;
  `}
`;
