import styled, {
  css,
  FlattenSimpleInterpolation,
} from "styled-components/macro";
import { darken, lighten, rem } from "polished";
import colors from "../theme/colors";
import media from "../theme/media";

type ButtonVariantType = "primary" | "secondary" | "alert";

const buttonStyles: { [K in ButtonVariantType]: FlattenSimpleInterpolation } = {
  primary: css`
    background-color: ${colors.blue};
    color: white;
    &:hover {
      background-color: ${darken(0.1, colors.blue)};
    }

    &:active {
      background-color: ${lighten(0.1, colors.blue)};
    }
  `,
  secondary: css``,
  alert: css`
    background: ${colors.error};
    color: white;
  `,
};

const Button = styled.button<{
  auto?: boolean;
  variant?: ButtonVariantType;
  small?: boolean;
}>`
  border: none;
  outline: none;
  border-radius: 3px;
  min-width: ${rem(161)};
  min-height: ${rem(48)};
  font-size: ${rem(16)};
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  padding: 0 1em;
  ${({ auto }) =>
    auto &&
    `
    min-width: unset;
    width: auto;
  `};

  &:hover:disabled,
  &:disabled {
    background: #b3b3b3;
    cursor: not-allowed;
  }
  ${({ variant }) => (variant ? buttonStyles[variant] : buttonStyles.primary)}

  ${({ small }) => css`
    min-width: ${rem(120)};
    min-height: ${rem(36)};
    font-size: ${rem(14)};
  `}

  ${media.lessThan("tablet")`
    min-width: 5rem;
  `}
`;

export default Button;
