import { generateAddressValidation, Yup } from "csv-package";

const child = Yup.object().shape({
  prp_giv_nam: Yup.string().csvGivenName().required("Given name is required"),
  prp_giv_nam2: Yup.string().csvGivenName(),
  prp_giv_nam3: Yup.string().csvGivenName(),
  prp_fam_nam: Yup.string().trim().required(`Family name is required`),
  prp_rel_to_resp: Yup.string().trim().required("We require this information"),
  prp_sex: Yup.string().trim().required("We require this information"),
  prp_sex_other: Yup.string().when("prp_sex", {
    is: "U",
    then: Yup.string().trim().required("We require the gender of the child"),
  }),
  prp_dob: Yup.string().when("prp_age", {
    is: (prp_age: string) => !prp_age,
    then: Yup.string()
      .csvDate()
      .csvDobUnder18()
      .required("Please enter a DOB or age"),
  }),
  prp_age: Yup.string()
    .matches(/[0-9]{1,2}/, "The child aproximate must be a number (0-18).")
    .csvDobUnder18(),
  prp_abor_code: Yup.string().trim().required("We require this information"),
  prp_disabl: Yup.string().trim().required("We require this information"),
  prp_live: Yup.string().trim().required("We require this information"),
  ...generateAddressValidation("prp_addr", "prp_live", "no"),
});

export default child;
