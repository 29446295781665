import React from "react";
import { fvioValidation } from "csv-package";

import { ApplicationFrameStepType } from "../../types";

import Applicant from "./components/Applicant";
import Children from "./components/Children";
import Respondent from "./components/Respondent";
import IncidentDetails from "./components/IncidentDetails";
import PreviousOrders from "./components/PreviousOrders";
import Conditions from "./components/Conditions";
import Confirm from "./components/Confirm";
import Review from "./components/Review";

const steps: ApplicationFrameStepType[] = [
  {
    name: "Applicant",
    validation: fvioValidation.applicant,
    component: <Applicant />,
  },
  {
    name: "Children",
    validation: fvioValidation.children,
    component: <Children />,
  },
  {
    name: "Respondent",
    validation: fvioValidation.respondent,
    component: <Respondent />,
  },
  {
    name: "Incident details",
    validation: fvioValidation.incidentDetails,
    component: <IncidentDetails />,
  },
  {
    name: "Other orders",
    validation: fvioValidation.previousOrders,
    component: <PreviousOrders />,
  },
  {
    name: "Conditions on the order",
    validation: fvioValidation.conditions,
    component: <Conditions />,
  },
  {
    name: "Confirm details",
    validation: {},
    component: <Confirm />,
  },
  {
    name: "Review and submit",
    validation: fvioValidation.review,
    component: <Review />,
  },
];

export default steps;
