import React from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import styled from "styled-components/macro";
import { useParams } from "react-router-dom";
import {
  SchemaType,
  ApplicationType,
  Yup,
  ApplicationStatusType,
} from "csv-package";

import media from "../theme/media";
import goToStepCurried from "../helpers/goToStepCurried";
import { hasAnonymousIdentity } from "../helpers/auth";
import api from "../helpers/api";

import ErrorSummary from "./ErrorSummary";
import ProgressBar from "./ProgressBar";
import MenuBar from "./MenuBar";
import AutoSaver from "./AutoSaver";
import LoadingIndicator from "./LoadingIndicator";
import DisableFormFieldset from "./DisableFormFieldset";
import NextButton from "./NextButton";
import ApplicationContext, { reducer } from "../reducers/applicationFrame";
import {
  ApplicationFrameStepType,
  CrumbType,
  FlatApplciationType,
} from "../types";
import NarrativeCharsCalculator from "./NarrativeCharsCalculator";
import InactivityCheck from "./InactivityCheck";
import ReDeclareStatusBar from "./ReDeclareBar";
import { NarrativeTemplateType } from "../helpers/narrativeGenerator";
import NarrativeComplier from "./NarrativeComplier";

export type NarrativeLimitConfigType = {
  keys: string[];
  limit: number;
  step: number;
};

type Props = {
  schema: SchemaType;
  steps: ApplicationFrameStepType[];
  complete: React.ReactNode;
  compatibleTypes: string[];
  reDeclare: React.ReactNode;
  reDeclareComplete: React.ReactNode;
  narrativeTemplate?: NarrativeTemplateType[];
  narrativeLimitConfig?: NarrativeLimitConfigType;
};

const ApplicationFrame: React.FC<Props> = ({
  schema,
  steps,
  complete,
  compatibleTypes,
  reDeclare,
  reDeclareComplete,
  narrativeTemplate,
  narrativeLimitConfig,
}) => {
  const { id: applicationID } = useParams<{ id: string }>();
  const [initialValues, setInitialValues] =
    React.useState<FlatApplciationType | null>(null);
  const [state, dispatch] = React.useReducer(reducer, {
    schema,
    currentStep: 0,
    showErrors: false,
    savingInProgress: false,
    narrativeCharactersLeft: 0,
    narrativeKeys: narrativeLimitConfig ? narrativeLimitConfig.keys : [],
    narrativeLimit: narrativeLimitConfig ? narrativeLimitConfig.limit : 0,
  });

  React.useEffect(() => {
    const fetchApplication = async () => {
      try {
        const result = (await api(
          "get",
          `/application/${applicationID}`
        )) as ApplicationType;
        if (result.Type === undefined) {
          result.Type = "N";
        }

        console.log(result.Type, compatibleTypes);

        if (!compatibleTypes.includes(result.Type)) {
          throw new Error(
            `Application ${applicationID} invalid with this application frame`
          );
        }

        // flatten data
        const flattenedData = {
          ...result,
          ...result.Data,
        } as FlatApplciationType;

        // filter out null vaules
        const initialValues = Object.keys(flattenedData).reduce((acc, key) => {
          if (flattenedData[key] === null) {
            return acc;
          } else {
            return {
              ...acc,
              [key]: flattenedData[key],
            };
          }
        }, {}) as FlatApplciationType;
        setInitialValues(initialValues);

        // go to last step if status is re-declare
        if (initialValues.Status === "re-declare-requested") {
          dispatch({ type: "setStep", step: steps.length - 1 });
        }
      } catch (error: any) {
        if (error.response && error.response.status === 404) {
          alert("Application not found");
          return;
        }
        alert(error);
        return;
      }
    };
    fetchApplication();
  }, [applicationID, compatibleTypes, steps.length]);

  const getVaildationSchema = () => {
    const { currentStep } = state;
    let validation =
      (steps[currentStep] && steps[currentStep].validation) || null;

    // add narrative chars left validation on last step
    if (
      narrativeLimitConfig !== undefined &&
      currentStep === narrativeLimitConfig.step
    ) {
      const { limit } = narrativeLimitConfig;
      _.set(
        validation,
        "NarrativeCharsLeft",
        Yup.number()
          .moreThan(
            -1,
            `Incident details when combined are over ${limit} characters. Please review and reduce the size of these descriptions.`
          )
          .required()
      );
    }

    if (currentStep < steps.length && typeof validation === "object") {
      // @ts-ignore
      return Yup.object().shape(validation);
    }
    return Yup.object().shape({});
  };

  const crumbs: CrumbType[] = [
    {
      text: applicationID,
    },
  ];

  if (!hasAnonymousIdentity()) {
    crumbs.unshift({ url: "/applications/", text: "Applications" });
  }

  const renderStepComponent = (Status: ApplicationStatusType) => {
    if (
      Status === "re-declare-requested" &&
      steps.length - 1 === state.currentStep
    ) {
      // show re-declare step if last step and status
      return reDeclare;
    } else if (
      steps.length === state.currentStep &&
      Status === "re-declare-accepted"
    ) {
      // Show complete step when we are past the last step
      return reDeclareComplete;
    } else if (steps.length === state.currentStep) {
      return complete;
    }

    return steps[state.currentStep].component;
  };

  if (initialValues === null) {
    return (
      <BlankScreen>
        <LoadingIndicator />
      </BlankScreen>
    );
  }
  return (
    <Wrapper>
      <MenuBar crumbs={crumbs} saving={state.savingInProgress} />
      <Formik
        onSubmit={(values) => console.log(values)}
        validationSchema={getVaildationSchema()}
        initialValues={initialValues}
      >
        {(props) => {
          const goToStep = goToStepCurried(dispatch)(state)(props);

          return (
            <Form
              onKeyPress={(e) => {
                const element = e.target as HTMLElement;
                if (e.key === "Enter" && element.tagName !== "TEXTAREA") {
                  e.preventDefault();
                  goToStep(state.currentStep + 1);
                }
              }}
            >
              <ReDeclareStatusBar />
              <ApplicationContext.Provider value={{ state, goToStep }}>
                <InactivityCheck />
                {["submitted", "initiated"].includes(props.values.Status) && (
                  <AutoSaver dispatch={dispatch} />
                )}
                {narrativeTemplate && props.values.Status === "initiated" && (
                  <NarrativeComplier
                    narrativeTemplate={narrativeTemplate}
                    formikProps={props}
                    schema={schema}
                  />
                )}
                {narrativeLimitConfig !== undefined && (
                  <NarrativeCharsCalculator config={narrativeLimitConfig} />
                )}

                <ProgressBar
                  steps={steps}
                  isReDeclared={props.values.Status === "re-declare-requested"}
                />

                <TopSpacer />

                <StepsContainer>
                  <Steps>
                    {state.showErrors && !_.isEmpty(props.errors) && (
                      <ErrorSummary />
                    )}
                    <DisableFormFieldset
                      steps={steps}
                      currentStep={state.currentStep}
                      status={props.values.Status}
                    >
                      {renderStepComponent(props.values.Status)}
                    </DisableFormFieldset>
                    <NextButton steps={steps} status={props.values.Status} />
                  </Steps>
                </StepsContainer>
              </ApplicationContext.Provider>
            </Form>
          );
        }}
      </Formik>
    </Wrapper>
  );
};

export default ApplicationFrame;

const StepsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
`;
const Steps = styled.div`
  width: 100%;
  max-width: 680px;
`;

const Wrapper = styled.div`
  position: relative;
  padding-top: 50px;
`;

const TopSpacer = styled.div`
  height: 0;
  ${media.greaterThan("phone")`
    height: 50px;
  `}
`;

const BlankScreen = styled.div`
  height: calc(100vh - 197px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
