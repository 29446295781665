import React from "react";
import { courts } from "csv-package";

import { Note } from "../../../components/FormNote";
import PageTitle from "../../../components/PageTitle";
import Question from "../../../components/Question";
import QuestionDatepicker from "../../../components/QuestionDatepicker";
import QuestionsFrame from "../../../components/QuestionsFrame";
import QuestionSpacer from "../../../components/QuestionSpacer";
import RadioGroup from "../../../components/RadioGroup";

const ExistingOrder: React.FC = () => (
  <>
    <PageTitle
      heading="Help us find your existing order"
      description="We use this information to help our court registrars to find your existing order. If it is not safe for you to access these details, you can leave the date and case number blank."
    />
    <QuestionsFrame>
      <QuestionDatepicker name="eo_aprox_date" />
      <Question name="eo_case_no" />
      <Question
        type="select"
        name="granted_court_id"
        options={courts
          .filter((court) => court.enabled)
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((court) => ({
            value: court.court_id,
            text: court.name,
          }))}
      />
      <RadioGroup
        name="eo_pc"
        indentChildren={false}
        optionChildren={{
          yes: (
            <>
              <Note>
                As the police were involved, the court is required to notify them of your application and the court listing date. 
              </Note>
              <QuestionSpacer size={6} />
            </>
          ),
        }}
      />
    </QuestionsFrame>
  </>
);

export default ExistingOrder;
