import React from "react";
import styled from "styled-components/macro";
import { Yup } from "csv-package";

import media from "../theme/media";
import { ApplicationContext } from "../reducers/applicationFrame";
import { useFormikContext } from "formik";
import { ApplicationFrameStepType, FlatApplciationType } from "../types";
import colors from "../theme/colors";

const useDebounce = (value: FlatApplciationType, delay: number): any => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

type Props = {
  steps: ApplicationFrameStepType[];
  isReDeclared: boolean;
};

const ProgressBar: React.FC<Props> = ({ steps, isReDeclared }) => {
  const { values } = useFormikContext<FlatApplciationType>();
  const { state, goToStep } = React.useContext(ApplicationContext);
  const { currentStep } = state;

  const debouncedValues = useDebounce(values, 1000);

  const highestAllowedStep = React.useMemo(() => {
    const stepsValid = steps.map((s) => {
      // @ts-ignore
      const schema = Yup.object().shape(s.validation);

      // change false to true to debug
      if (false && !schema.isValidSync(values)) {
        schema.validate(values).catch((err) => {
          console.log({ err });
        });
      }

      return schema.isValidSync(debouncedValues);
    });
    if (stepsValid.every((b) => b)) {
      return stepsValid.length;
    }
    return stepsValid.indexOf(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValues]);

  return (
    <Wrapper>
      <Inner>
        {steps.map((s, i, a) => {
          let disabled = false;
          if (i !== 0 && i > highestAllowedStep) {
            disabled = true;
          }

          const name =
            a.length - 1 === i && isReDeclared
              ? "Redeclare amendments"
              : s.name;
          return (
            <Step
              key={s.name}
              current={currentStep === i}
              future={currentStep < i}
            >
              <CircleNumber
                disabled={disabled}
                onClick={(e) => {
                  e.preventDefault();
                  goToStep(i);
                }}
              >
                <StepNumber>{i + 1}</StepNumber>
                <StepLabel>{name}</StepLabel>
              </CircleNumber>
            </Step>
          );
        })}
      </Inner>
    </Wrapper>
  );
};

export default ProgressBar;

const Wrapper = styled.div`
  width: 100%;
  height: 100px;
  background: ${colors.blue};
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1310px;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CircleNumber = styled.button`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${colors.blue};
  color: ${colors.blue};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  position: relative;
  border: none;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  ${media.greaterThan("tablet")`
    width: 26px;
    height: 26px;
    color: white;
  `};
`;
const StepLabel = styled.span`
  display: none;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  color: ${colors.blue};
  /* white-space: nowrap; */
  text-align: center;
  font-size: 12px;
  border: none;
  background: transparent;
  width: 7.5em;
  ${media.greaterThan("tablet")`
    display:block;
    font-size: 12px;
  `}
  ${media.greaterThan("desktop")`
    font-size: 15px;
  `}
`;

const StepNumber = styled.span`
  display: none;
  transform: translateY(0.05em);
  font-weight: 300;
  ${media.greaterThan("tablet")`display:block;`};
`;

const Step = styled.div<{ future?: boolean; current?: boolean }>`
  border: none;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 15px;
  &:before {
    content: "";
    display: block;
    height: 0;
    width: 20px;
    border: 1px ${({ future }) => (future ? `dashed` : `solid`)} #d4e1ed;
    ${media.greaterThan("phone")`width: 35px;`}
    ${media.greaterThan("tablet")`width: 70px;`}
    ${media.greaterThan("desktop")`width: 100px;`}
  }

  &:first-child:before {
    display: none;
  }

  ${({ future }) =>
    future &&
    `
        ${CircleNumber} {
            background: #D4E1ED;
            color: #6D6D6D;
        }

        ${StepLabel} {
          color: #6D6D6D;
        }
  `}

  ${({ current }) =>
    current &&
    `
        ${CircleNumber} {
            background: #3C3C3C;
            color: white;
        }

        ${StepLabel} {
          color: #3C3C3C;
        }
  `}
`;
