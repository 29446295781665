/* eslint-disable react-hooks/exhaustive-deps */
import { useFormikContext } from "formik";
import React from "react";
import styled from "styled-components";
import _ from "lodash";

import Question, { Wrapper as QuestionWrapper } from "./Question";
import QuestionDatepicker, {
  Wrapper as QuestionDatepickerWrapper,
} from "./QuestionDatepicker";
import { FlatApplciationType } from "../types";

type Props = {
  dobName: string;
  ageName: string;
};

const QuestionDOB: React.FC<Props> = ({ dobName, ageName }) => {
  const { values, setFieldValue } = useFormikContext<FlatApplciationType>();

  const dobValue = _.get(values, dobName);
  const ageValue = _.get(values, ageName);
  const prevDobValueRef = React.useRef<string | null>(null);
  const prevAgeValueRef = React.useRef<string | null>(null);

  // reset age if dob is adjusted
  React.useEffect(() => {
    if (
      dobValue !== prevDobValueRef.current &&
      typeof dobValue === "string" &&
      dobValue.length > 0
    ) {
      setFieldValue(ageName, "");
    }
    if (typeof dobValue === "string") {
      prevDobValueRef.current = dobValue;
    }
  }, [dobValue]);

  // reset dob if age is adjusted
  React.useEffect(() => {
    if (
      ageValue !== prevAgeValueRef.current &&
      typeof ageValue === "string" &&
      ageValue.length > 0
    ) {
      setFieldValue(dobName, "");
    }
    if (typeof ageValue === "string") {
      prevAgeValueRef.current = ageValue;
    }
  }, [ageValue]);

  return (
    <Wrapper>
      <QuestionDatepicker name={dobName} />
      <Question name={ageName} />
    </Wrapper>
  );
};

export default QuestionDOB;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  > ${QuestionWrapper} {
    width: 10rem;
  }

  > ${QuestionDatepickerWrapper} {
    width: 10rem;
    margin-right: 1rem;
  }
`;
