import React from "react";
import { useFormikContext } from "formik";
import { DeclarationOfTruth } from "csv-package";

import Button from "../../../components/Button";
import ButtonContainer from "../../../components/ButtonContainer";
import PageTitle from "../../../components/PageTitle";
import QuestionsFrame from "../../../components/QuestionsFrame";
import ReDeclareSection from "../../../components/ReDeclareSection";

import { FlatApplciationType } from "../../../types";
import useReDeclare, { ReDeclareModals } from "../../../hooks/useReDeclare";
import ReDeclareNarrative from "../../../components/ReDeclareNarrative";
import ReDeclareSectionChild from "../../../components/ReDeclareSectionChild";
import _ from "lodash";
import { unProtectedPersonRoles } from "../constants";

const ReDeclare: React.FC = () => {
  const { values } = useFormikContext<FlatApplciationType>();

  const {
    handleAcceptReDeclare,
    handleCallBack,
    reDeclareObject,
    reDeclareState,
    handleDeclarationOfTruth,
    handleErrorModalClose,
  } = useReDeclare();

  if (reDeclareObject.Status === "canceled") {
    return null;
  }

  const reDeclareCreateDate = new Date(reDeclareObject.Created);
  return (
    <>
      <PageTitle
        heading="Application amendments"
        description={`The following sections have been amended by the Magistrates’ Court based on your telephone conversation that occurred on the ${reDeclareCreateDate.toLocaleDateString(
          "en-AU"
        )}. Please review these amendments to ensure they’re accurate before re-submitting.`}
      />

      <ReDeclareSection
        title="Existing order"
        names={["eo_aprox_date", "eo_case_no", "granted_court_id", "eo_pc"]}
        reDeclareObject={reDeclareObject}
        values={values}
      />
      <ReDeclareSection
        title="Your role"
        names={[
          "seeking",
          "seeking_appl_role",
          "incident_phy",
          "incident_phy_detail",
          "incident_phy_date",
          "incident_pys",
          "incident_pys_detail",
          "incident_pys_date",
          "incident_aep",
          "incident_aep_detail",
          "incident_aep_date",
          "incident_cym",
          "incident_cym_detail",
          "incident_cym_date",
          "incident_thy",
          "incident_thy_detail",
          "incident_thy_date",
          "incident_cbp",
          "incident_cbp_detail",
          "incident_cbp_date",
          "incident_fsw",
          "incident_fsw_detail",
          "incident_fsw_date",
          "incident_dyp",
          "incident_dyp_detail",
          "incident_dyp_date",
          "incident_ryf",
          "incident_ryf_detail",
          "incident_ryf_date",
          "incident_htc",
          "incident_htc_detail",
          "incident_htc_date",
          "incident_kha",
          "incident_kha_detail",
          "incident_kha_date",
          "incident_sty",
          "incident_sty_detail",
          "incident_sty_date",
          "incident_fmc",
          "incident_fmc_detail",
          "incident_fmc_date",
          "incident_cdw",
          "incident_cdw_detail",
          "incident_cdw_date",
        ]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <ReDeclareNarrative
        title="Your statement"
        reDeclareObject={reDeclareObject}
        values={values}
      />


      <ReDeclareSection
        title="Contact details"
        names={[
          "appl_det_giv_nam",
          "appl_det_giv_nam2",
          "appl_det_giv_nam3",
          "appl_det_fam_nam",
          "appl_det_other_nam",
          "appl_det_dob",
          "appl_det_addr",
          "appl_det_addr_strnr",
          "appl_det_addr_strname",
          "appl_det_addr_strtype",
          "appl_det_addr_sub",
          "appl_det_addr_state",
          "appl_det_addr_postcode",
          "appl_det_addr_secret",
          "appl_sex",
          "appl_det_sex_other",
          "appl_inter",
          "appl_lang",
          "appl_lang_other",
          {
            name: "appl_safe_cont",
            label: unProtectedPersonRoles.includes(String(values.Data.seeking_appl_role)) ? "What is the best way to contact the protected person?" : "",
            options: unProtectedPersonRoles.includes(String(values.Data.seeking_appl_role)) ? [
              {
                "value": "phone",
                "text": "Phone number"
              },
              {
                "value": "email",
                "text": "Email"
              },
              {
                "value": "trust",
                "text": "Through a trusted person"
              },
              {
                "value": "unsafe",
                "text": "It is not safe to contact the protected person, she/he will contact the court"
              }
            ]:undefined
          },
          "appl_cont_phone",
          "appl_cont_phone_2",
          "appl_cont_phone_3",
          {
            name: "appl_cont_phone_vm",
            label: unProtectedPersonRoles.includes(String(values.Data.seeking_appl_role)) ? "Is it safe to leave the protected person a voicemail message?" : ""
          },
          {
            name: "appl_cont_safe_times",
            label: unProtectedPersonRoles.includes(String(values.Data.seeking_appl_role)) ? "When is it safest for us to ring the protected person?" : ""
          },
          "appl_cont_email",
          "appl_cont_email_2",
          "appl_cont_trst_per",
          "appl_cont_trst_per_rel",
          "appl_cont_trst_per_rel_other",
          "appl_cont_trst_per_phone",
          "appl_cont_trst_per_email",
          "appl_cont",
          "appl_phone",
          "appl_phone_2",
          "appl_phone_3",
          "appl_email",
          "appl_email_2",
        ]}
        reDeclareObject={reDeclareObject}
        values={values}
      />
      <ReDeclareSection
        title="Protected Person"
        names={[
          "prp_det_giv_nam",
          "prp_det_giv_nam2",
          "prp_det_giv_nam3",
          "prp_det_fam_nam",
          "prp_other_names",
          "prp_det_dob",
          "prp_sex",
          "prp_sex_other",
          "prp_abor_code1",
          "prp_lgbt",
          "prp_disabl1",
          "prp_disabl_req",
          "prp_disabl_req_other",
          "prp_inter",
          "prp_lang",
          "prp_lang_other",
          "prp_pregnant_baby",
          "appl_abor_code1",
          "appl_lgbt",
          "appl_disabl1",
          "appl_disabl_req",
          "appl_disabl_req_other",
          "appl_pregnant_baby",
          "prp_addr_flat",
          "prp_addr_strnr",
          "prp_addr_strname",
          "prp_addr_strtype",
          "prp_addr_sub",
          "prp_addr_state",
          "prp_addr_postcode",
          "prp_addr_secret",
        ]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <ReDeclareSection
        title="Respondent"
        names={[
          "resp_det_giv_nam",
          "resp_det_giv_nam2",
          "resp_det_giv_nam3",
          "resp_det_fam_nam",
          "resp_det_dob",
          "resp_addr_known",
          "resp_addr_known_pc",
          "resp_addr_known_pc_stn",
          "resp_addr_known_pc_ofc",
          "resp_det_addr_flat",
          "resp_det_addr_strnr",
          "resp_det_addr_strname",
          "resp_det_addr_strtype",
          "resp_det_addr_sub",
          "resp_det_addr_state",
          "resp_det_addr_postcode",
        ]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <ReDeclareSection
        title="Application type"
        names={[
          "app_types",
          "app_type_extend_why",
          "app_type_extend_why_still_fearful",
          "app_type_extend_why_breached",
          "app_type_extend_why_violence_stopped",
          "app_type_extend_further_violence",
          "app_type_extend_add",
          "coo_con_prevent",
          "coo_con_can",
          "coo_con_other",
          "child_action",
          "vary_resp_why",
          "vary_resp_change",
          "vary_resp_able",
          "vary_resp_other",
          "revoke_resp_why",
          "revoke_appl_why",
        ]}
        reDeclareObject={reDeclareObject}
        values={values}
      />
      <ReDeclareSection
        title="Vary"
        names={[
          "coo_con_old_a",
          "coo_con_old_a_detail",
          "coo_con_old_b",
          "coo_con_old_b_detail",
          "coo_con_old_c",
          "coo_con_old_c_detail",
          "coo_con_old_d",
          "coo_con_old_d_detail",
          "coo_con_old_e",
          "coo_con_old_e_detail",
          "coo_con_old_f",
          "coo_con_old_f_detail",
          "coo_con_old_g",
          "coo_con_old_g_detail",
          "coo_con_old_h",
          "coo_con_old_h_detail",
          "coo_con_old_i",
          "coo_con_old_i_detail",
          "coo_con_old_j",
          "coo_con_old_j_detail",
          "coo_con_old_k",
          "coo_con_old_k_detail",
          "coo_con_old_l",
          "coo_con_old_l_detail",
          "coo_con_old_m",
          "coo_con_old_m_detail",
          "coo_con_old_n",
          "coo_con_old_n_detail",
          "coo_con_old_o",
          "coo_con_old_o_detail",
          "coo_con_old_p",
          "coo_con_old_p_detail",
          "coo_con_old_q",
          "coo_con_old_q_detail",
          "coo_con_old_r",
          "coo_con_old_r_detail",
          "coo_exc_old_a",
          "coo_exc_old_a_detail",
          "coo_exc_old_b",
          "coo_exc_old_b_detail",
          "coo_exc_old_c",
          "coo_exc_old_c_detail",
          "coo_exc_old_d",
          "coo_exc_old_d_detail",
          "coo_exc_old_e",
          "coo_exc_old_e_detail",
        ]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <ReDeclareSection
        title="Add or remove a child/children"
        names={["child_action_add_why"]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      {Array.isArray(reDeclareObject.Data.children) &&
        reDeclareObject.Data.children
          .sort((a, b) => {
            if (typeof a.index === "number" && typeof b.index === "number") {
              return a.index - b.index;
            }
            return 0;
          })
          .map((data, index) => (
            <ReDeclareSectionChild
              key={index}
              index={index}
              values={values}
              titlePrefix="Child "
              data={data}
              names={[
                "child_action",
                "prp_giv_nam",
                "prp_giv_nam2",
                "prp_giv_nam3",
                "prp_fam_nam",
                "prp_dob",
                "prp_sex1",
                "prp_sex_other",
                "prp_abor_code",
                "prp_disabl",
                "prp_rel_to_appl",
                "prp_rel_to_resp",
                "prp_live",
                "prp_addr_flat",
                "prp_addr_strnr",
                "prp_addr_strname",
                "prp_addr_strtype",
                "prp_addr_sub",
                "prp_addr_state",
                "prp_addr_postcode",
                "prp_ord_exist",
                "prp_ord_exist_det",
                "prp_ord_cpo_dlo",
                "prp_ord_cpo_ncd",
                "prp_ord_io_dlo",
                "prp_ord_io_ncd",
                "prp_ord_oo_dlo",
                "prp_ord_oo_ncd",
                "prp_ord_fc",
                "prp_flc_hear",
                "prp_flc_other_po",
                "prp_flc_other",
              ]}
            />
          ))}

      <ReDeclareSection
        title="Other orders"
        names={[
          "order_exist_cm",
          "order_exist_cm_yes",
          "order_exist_changed_other_terr",
          "order_exist_made_other_terr",
          "order_exist_curr_app_chng_other_terr"
        ]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <ReDeclareSection
        title="Information sharing"
        names={[
          "appl_info_share",
          {
            name: "appl_info_share_limited",
            label: "Please review the information sharing entities you consent to",
          },
        ]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <ReDeclareSection
        title="SMS reminder consent"
        names={[
          "appl_cont_sms_consent",
          "appl_reminder_cont_phone"
        ]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <QuestionsFrame>
        <DeclarationOfTruth
          applicantName={`${_.get(
            reDeclareObject,
            "Data.appl_det_giv_nam",
            values.appl_det_giv_nam
          )}  ${_.get(
            reDeclareObject,
            "Data.appl_det_fam_nam",
            values.appl_det_fam_nam
          )}`}
          applicantDOB={
            _.get(reDeclareObject, "Data.appl_det_dob", values.appl_det_dob) +
            ""
          }
          checked={reDeclareState.declaration_of_truth}
          onCheckboxChange={handleDeclarationOfTruth}
        />
      </QuestionsFrame>
      <ButtonContainer>
        <Button variant="secondary" onClick={handleCallBack}>
          {
            {
              uninitialised: "Reject and request a call",
              loading: "Requesting...",
              successful: "Request successful",
              failed: "Request failed",
            }[reDeclareState.call_back_status]
          }
        </Button>
        <Button onClick={handleAcceptReDeclare}>
          {
            {
              uninitialised: "Accept changes and redeclare",
              loading: "Submitting...",
              successful: "Request successful",
              failed: "Request failed",
            }[reDeclareState.submit_status]
          }
        </Button>
      </ButtonContainer>

      <ReDeclareModals
        reDeclareState={reDeclareState}
        handleErrorModalClose={handleErrorModalClose}
      />
    </>
  );
};

export default ReDeclare;
