import Yup, { generateAddressValidation } from "../yup";
import { unProtectedPersonRoles, protectedPersonRoles } from "./constants";

const contactDetails = {
  appl_det_giv_nam: Yup.string()
    .csvGivenName()
    .required("Given name is required"),
  appl_det_giv_nam2: Yup.string().csvGivenName(),
  appl_det_giv_nam3: Yup.string().csvGivenName(),
  appl_det_fam_nam: Yup.string().trim().required("Family name is required"),
  court_address_is_valid: Yup.mixed().oneOf(["yes"], "Invalid Address"),
  appl_det_dob: Yup.string()
    .trim()
    .when(["seeking_appl_role"],{
      is:"prp_14",
      then: Yup.string().trim()
      .csvDate()
      .csvDobOver14()
      .csvDobUnder18()
      .required("DOB is required"),
      otherwise: Yup.string().trim()
      .csvDate()
      .csvDobOver18()
      .required("DOB is required")}),

  ...generateAddressValidation(
    "prp_addr",
    "seeking",
    (seeking) => seeking === "appl"
  ),

  ...generateAddressValidation(
    "appl_det_addr",
    ["seeking", "seeking_appl_role"],
    (seeking, seeking_appl_role) => {
      if (seeking === "appl" && unProtectedPersonRoles.includes(String(seeking_appl_role))) {
        return true;
      } else if (seeking === "resp") {
        return true;
      }
      return false;
    }
  ),
  appl_det_addr_secret: Yup.string().when(["seeking", "seeking_appl_role"], {
    is: (seeking: string, seeking_appl_role: string) => (seeking === "appl" && unProtectedPersonRoles.includes(String(seeking_appl_role))) || seeking === "resp",
    then: Yup.string().required("We require this information"),
  }),

  prp_addr_secret: Yup.string().when("seeking", {
    is: (seeking: string) => seeking === "appl",
    then: Yup.string().required("We require this information"),
  }),

  appl_cont: Yup.array()
    .ensure()
    .when(["seeking", "seeking_appl_role"], {
      is: (seeking: string, seeking_appl_role: string) => (seeking === "resp" || (seeking === "appl" && unProtectedPersonRoles.includes(String(seeking_appl_role)))),
    then: Yup.array().min(1, "You must select at least one option").required("We require this information"),
    }),

  appl_phone_3: Yup.string().when("appl_cont", {
    is: (appl_cont: string[]) =>
      Array.isArray(appl_cont) && appl_cont.includes("phone"),
    then: Yup.string()
      .ausMobilePhone()
      .required("Mobile phone number required"),
  }),
  appl_phone: Yup.string().trim().ausPhone(),
  appl_phone_2: Yup.string().trim().ausPhone(),
  appl_email: Yup.string().when("appl_cont", {
    is: (appl_cont: string[]) =>
      Array.isArray(appl_cont) && appl_cont.includes("email"),
    then: Yup.string()
      .trim()
      .email("Please enter a valid email address")
      .required("We require this information"),
  }),
  appl_email_2: Yup.string().email("Please enter a valid email address"),

  appl_safe_cont: Yup.array()
    .ensure().when(["seeking"], {
      is: (seeking: string) => (seeking === "appl"),
    then: 
    Yup.array().min(1, "You must select at least one option")
    .required("We require this information")}),

  appl_cont_phone_3: Yup.string().when("appl_safe_cont", {
    is: (appl_safe_cont: string[]) =>
      Array.isArray(appl_safe_cont) && appl_safe_cont.includes("phone"),
    then: Yup.string()
      .ausMobilePhone()
      .required("Mobile phone number required"),
  }),
  appl_cont_phone: Yup.string().trim().ausPhone(),
  appl_cont_phone_2: Yup.string().trim().ausPhone(),
  appl_cont_phone_vm: Yup.string().when(["seeking", "appl_safe_cont"], {
    is: (seeking: string, appl_safe_cont: string[]) =>
      seeking === 'appl' && Array.isArray(appl_safe_cont) && appl_safe_cont.includes("phone"),
    then: Yup.string()
      .required("We require this information"),
  }),
  appl_cont_safe_times: Yup.array().ensure()
    .when(["seeking", "appl_safe_cont"], {
      is: (seeking: string, appl_safe_cont: string[]) =>
        seeking === 'appl' && Array.isArray(appl_safe_cont) && appl_safe_cont.includes("phone"),
      then: Yup.array().ensure()
      .min(1, "You must select at least one option")
    }),
  appl_cont_email: Yup.string().when("appl_safe_cont", {
    is: (appl_safe_cont: string[]) =>
      Array.isArray(appl_safe_cont) && appl_safe_cont.includes("email"),
    then: Yup.string()
      .trim()
      .email("Please enter a valid email address")
      .required("We require this information"),
  }),
  appl_cont_email_2: Yup.string().email("Please enter a valid email address"),

  appl_cont_trst_per: Yup.string().when(["appl_cont", "appl_safe_cont"], {
    is: (appl_cont: string[],appl_safe_cont: string[]) =>
      Array.isArray(appl_safe_cont) && appl_safe_cont.includes("trust") ||
      Array.isArray(appl_cont) && appl_cont.includes("trust"),
    then: Yup.string().required("We require this information"),
  }),
  appl_cont_trst_per_rel: Yup.string().when(["appl_cont", "appl_safe_cont"], {
    is: (appl_cont: string[],appl_safe_cont: string[]) =>
      Array.isArray(appl_safe_cont) && appl_safe_cont.includes("trust") ||
      Array.isArray(appl_cont) && appl_cont.includes("trust"),
    then: Yup.string().required("We require this information"),
  }),
  appl_cont_trst_per_rel_other: Yup.string().when(
    ["appl_safe_cont", "appl_cont_trst_per_rel"],
    {
      is: (appl_safe_cont: string[], appl_cont_trst_per_rel: string) =>
        Array.isArray(appl_safe_cont) &&
        appl_safe_cont.includes("trust") &&
        appl_cont_trst_per_rel === "other",
      then: Yup.string().required("We require this information"),
    }
  ),
  appl_cont_trst_per_phone: Yup.string().when(
    [`appl_cont`,`appl_safe_cont`, `appl_cont_trst_per_email`],
    {
      is: (appl_cont: string[], appl_safe_cont: string[], appl_cont_trst_per_email: string) =>
        (appl_safe_cont && appl_safe_cont.includes("trust") && !appl_cont_trst_per_email) 
        || (appl_cont &&  appl_cont.includes("trust") && !appl_cont_trst_per_email),
      then: Yup.string()
        .required("You must enter a trusted person email or phone number")
        .ausPhone(),
    }
  ),
  appl_cont_trst_per_email: Yup.string().email(
    "Trusted person's email must be a valid email address."
  ),
  resp_det_giv_nam: Yup.string()
      .trim()
      .when(["seeking"],{
        is: "appl",
        then: Yup.string()
      .csvGivenName()
      .required("Given name is required")}),
  resp_det_fam_nam: Yup.string()
      .trim()
      .when(["seeking"],{
        is: "appl",
        then: Yup.string().trim().required("Family name is required")}),
  resp_det_dob: Yup.string()
      .trim()
      .transform((value?: string)=> value ? value : undefined)
      .nullable()
      .notRequired()
      .csvDate()
      .birthdate(),
  resp_addr_known: Yup.string().when("seeking", {
    is: (seeking: string) => seeking === "appl",
    then: Yup.string().required("We require this information"),
  }),
  resp_addr_known_pc: Yup.string().when(["seeking", "resp_addr_known"], {
    is: (seeking: string, resp_addr_known: string) =>
      seeking === "appl" && resp_addr_known === "unknown",
    then: Yup.string().required("We require this information"),
  }),
  ...generateAddressValidation("resp_det_addr", "resp_addr_known", "other"),

  appl_sex: Yup.string().required("We require your gender"),
  appl_det_sex_other: Yup.string().when("appl_sex", {
    is: "U",
    then: Yup.string().trim().required("We require the gender"),
  }),
  appl_pregnant_baby: Yup.string().when(["seeking", "seeking_appl_role"], {
    is: (seeking: string, seeking_appl_role: string) => seeking === "appl" && protectedPersonRoles.includes(seeking_appl_role) || seeking === "resp",
    then: Yup.string().trim().required("We require this information"),
  }),
  appl_abor_code1: Yup.string().when(["seeking", "seeking_appl_role"], {
    is: (seeking: string, seeking_appl_role: string) => seeking === "appl" && protectedPersonRoles.includes(seeking_appl_role)|| seeking === "resp",
    then: Yup.string().trim().required("We require this information"),
  }),
  appl_lgbt: Yup.string().when(["seeking", "seeking_appl_role"], {
    is: (seeking: string, seeking_appl_role: string) => seeking === "appl" && protectedPersonRoles.includes(seeking_appl_role)|| seeking === "resp",
    then: Yup.string().trim().required("We require this information"),
  }),
  appl_disabl1: Yup.string().when(["seeking", "seeking_appl_role"], {
    is: (seeking: string, seeking_appl_role: string) => seeking === "appl" && protectedPersonRoles.includes(seeking_appl_role)|| seeking === "resp",
    then: Yup.string().trim().required("We require this information"),
  }),
  appl_disabl_req: Yup.array()
    .ensure()
    .when('appl_disabl1', {
      is: 'yes',
      then: Yup.array()
        .ensure()
        .min(1, 'Please select your requirements'),
    }),
  appl_disabl_req_other: Yup.string().when(["appl_disabl1", "appl_disabl_req"], {
    is: (appl_disabl1: string, appl_disabl_req: Array<string>) => appl_disabl1 === "yes" && appl_disabl_req.includes('Other'),
    then: Yup.string().trim().required("We require this information"),
  }),
  appl_inter: Yup.string().required("Sharing this will help us with your application"),
  appl_lang: Yup.string().when("appl_inter", {
    is: "yes",
    then: Yup.string().required("Please select a language"),
  }),
  appl_lang_other: Yup.string().when("appl_lang", {
    is: "Other",
    then: Yup.string().trim().required("We require this information"),
  }),
  prp_det_giv_nam: Yup.string().when(["seeking", "seeking_appl_role"], {
    is: (seeking: string, seeking_appl_role: string) => seeking === "appl" && unProtectedPersonRoles.includes(seeking_appl_role),
    then: Yup.string().csvGivenName().required("Given name is required"),
  }).when(["seeking"], {
    is: "resp",
    then: Yup.string().csvGivenName().required("Given name is required"),
  }),
  prp_det_fam_nam: Yup.string().when(["seeking", "seeking_appl_role"], {
    is: (seeking: string, seeking_appl_role: string) => seeking === "resp" || (seeking === "appl" && unProtectedPersonRoles.includes(seeking_appl_role)),
    then: Yup.string().trim().required("Family name is required"),
  }),
  prp_det_dob: Yup.string().when(["seeking", "seeking_appl_role"], {
      is: (seeking: string, seeking_appl_role: string) => seeking === "appl" && (seeking_appl_role === 'pg' || seeking_appl_role === 'pwc'),
      then: Yup.string().trim().csvDate().csvDobUnder18().required("DOB is required"),
    })
    .when(["seeking", "seeking_appl_role"], {
      is: (seeking: string, seeking_appl_role: string) => seeking === "appl" && seeking_appl_role !== 'pg' && seeking_appl_role !== 'pwc' && unProtectedPersonRoles.includes(seeking_appl_role),
      then: Yup.string().trim().csvDate().csvDobOver18().required("DOB is required"),
    })
    .when(["seeking"], {
      is: "resp",
      then: Yup.string()
          .transform((value?: string)=> value ? value : undefined)
          .nullable()
          .notRequired()
          .csvDate()
          .birthdate(),
    }),
  prp_sex: Yup.string().when(["seeking", "seeking_appl_role"], {
    is: (seeking: string, seeking_appl_role: string) => seeking === "appl" && unProtectedPersonRoles.includes(seeking_appl_role),
    then: Yup.string().trim().required("We require the gender"),
  }),
  prp_sex_other: Yup.string().when("prp_sex", {
    is: "U",
    then: Yup.string().trim().required("We require the gender"),
  }),
  prp_pregnant_baby: Yup.string().when(["seeking", "seeking_appl_role"], {
    is: (seeking: string, seeking_appl_role: string) => seeking === "appl" && unProtectedPersonRoles.includes(seeking_appl_role),
    then: Yup.string().trim().required("We require this information"),
  }),
  prp_abor_code1: Yup.string().when(["seeking", "seeking_appl_role"], {
    is: (seeking: string, seeking_appl_role: string) => seeking === "appl" && unProtectedPersonRoles.includes(seeking_appl_role),
    then: Yup.string().trim().required("We require this information"),
  }),
  prp_lgbt: Yup.string().when(["seeking", "seeking_appl_role"], {
    is: (seeking: string, seeking_appl_role: string) => seeking === "appl" && unProtectedPersonRoles.includes(seeking_appl_role),
    then: Yup.string().trim().required("We require this information"),
  }),
  prp_disabl1: Yup.string().when(["seeking", "seeking_appl_role"], {
    is: (seeking: string, seeking_appl_role: string) => seeking === "appl" && unProtectedPersonRoles.includes(seeking_appl_role),
    then: Yup.string().trim().required("We require this information"),
  }),
  prp_disabl_req: Yup.array()
    .ensure()
    .when('prp_disabl1', {
      is: 'yes',
      then: Yup.array()
        .ensure()
        .min(1, 'Please select your requirements'),
    }),
  prp_disabl_req_other: Yup.string().when(["prp_disabl1", "prp_disabl_req"], {
    is: (prp_disabl1: string, prp_disabl_req: Array<string>) => prp_disabl1 === "yes" && prp_disabl_req.includes('Other'),
    then: Yup.string().trim().required("We require this information"),
  }),
  prp_inter: Yup.string().when(["seeking", "seeking_appl_role"], {
    is: (seeking: string, seeking_appl_role: string) => seeking === "appl" && unProtectedPersonRoles.includes(seeking_appl_role),
    then: Yup.string().trim().required("Sharing this will help us with your application"),
  }),
  prp_lang: Yup.string().when("prp_inter", {
    is: "yes",
    then: Yup.string().required("We require this information"),
  }),
  prp_lang_other: Yup.string().when("prp_lang", {
    is: "Other",
    then: Yup.string().trim().required("We require this information"),
  }),
};

export default contactDetails;
