import { FormikProps } from "formik";
import React from "react";
import _ from "lodash";

import narrativeGenerator, {
  NarrativeTemplateType,
} from "../helpers/narrativeGenerator";
import { FlatApplciationType } from "../types";
import { SchemaType } from "csv-package";

type Props = {
  narrativeTemplate: NarrativeTemplateType[];
  formikProps: FormikProps<FlatApplciationType>;
  schema: SchemaType;
};

const NarrativeComplier: React.FC<Props> = ({
  narrativeTemplate,
  formikProps,
  schema,
}) => {
  const { values, setFieldValue } = formikProps;
  React.useEffect(() => {
    try {
      const narrative = narrativeGenerator(
        values,
        narrativeTemplate,
        schema,
        false
      );
      const uppercaseNarrative = narrative.toUpperCase();
      if (uppercaseNarrative !== values.Narrative) {
        setFieldValue("Narrative", uppercaseNarrative);
      }
    } catch (e: any) {
      console.log(e.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return null;
};

export default React.memo(NarrativeComplier, (prev, next) =>
  _.isEqual(prev.formikProps.values, next.formikProps.values)
);
