import { createGlobalStyle } from "styled-components/macro";
import { em, fontFace, normalize, rem } from "polished";
import media from "./media";
import colors from "./colors";

const GlobalStyles = createGlobalStyle`
    ${fontFace({
      fontFamily: "HK Grotesk",
      fontFilePath: "/fonts/HKGrotesk-Regular",
      fontWeight: "300",
    })}


    ${fontFace({
      fontFamily: "HK Grotesk",
      fontFilePath: "/fonts/HKGrotesk-Medium",
      fontWeight: "400",
    })}

    ${fontFace({
      fontFamily: "HK Grotesk",
      fontFilePath: "/fonts/HKGrotesk-SemiBold",
      fontWeight: "600",
    })}

    ${fontFace({
      fontFamily: "HK Grotesk",
      fontFilePath: "/fonts/HKGrotesk-Bold",
      fontWeight: "700",
    })}

    ${normalize()}
    body {
        color: ${colors.black};
        font-family: 'HK Grotesk';
    }

    svg {
        fill: currentColor;
    }

    * {
        box-sizing: border-box;
    }

    #root {
      padding-top: 58px;
    }

    a {
      color: inherit;
    }

    #__ba_launchpad {
      position: fixed;
      right: 20px;
      left: auto;
      top: 220px;
    }
    
    ul, ol {
      padding-left: 1.2em;
    }
    li {
      margin-bottom: 0.3em;
    }

    h1 {
      font-size: ${rem(26)};
      color: #3c3c3c;
      line-height: 1.12;
      font-weight: bold;
      &:first-child {
        margin-top: 0;
      }

      ${media.greaterThan("tablet")`
          font-size: 34px;
      `}
    }

    h2 {
      color: #3c3c3c;
      font-size: ${rem(26)};
      line-height: 1.3;
      font-weight: bold;
      margin: ${em(30, 26)} 0;
      &:first-child {
        margin-top: 0;
      }

      ${media.greaterThan("tablet")`
      font-size: ${rem(26)};
      `}
    }
    
    h3 {
      font-size: 1.25em;
    }

    h4 {
      font-size: ${rem(22)};
      line-height: ${rem(30, 22)};
    }
`;

export default GlobalStyles;
