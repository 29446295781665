import React from "react";
import PageTitle from "../../../components/PageTitle";
import QuestionsFrame from "../../../components/QuestionsFrame";
import RadioGroup from "../../../components/RadioGroup";

const YourRole: React.FC = () => (
  <>
    <PageTitle heading="Who is making the application today?" />
    <QuestionsFrame>
      <RadioGroup
        name="appl_type"
        compact
        optionChildren={{
          abap: <RadioGroup name="abap_type" compact />,
        }}
      />
    </QuestionsFrame>
  </>
);

export default YourRole;
