import React from "react";

import { FlatApplciationType } from "../types";
import { useFormikContext } from "formik";
import Modal from "./Modal";
import Button from "./Button";
import { useHistory } from "react-router-dom";

const INACTIVITY_MINS = process.env.REACT_APP_INACTIVITY_MINS || 7;
const intervalMins =
  typeof INACTIVITY_MINS === "string"
    ? parseInt(INACTIVITY_MINS, 10)
    : INACTIVITY_MINS;

type Props = {};

const InactivityCheck: React.FC<Props> = () => {
  const { values } = useFormikContext<FlatApplciationType>();
  const { push } = useHistory();

  const lastActivityTimestamp = React.useRef<number>(0);
  const [checkInterval, setCheckInterval] = React.useState<number>(0);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [timerInterval, setTimerInterval] = React.useState<number>(0);
  const [timer, setTimer] = React.useState<number>(60);

  // on mount start interval
  // clean up destorys intervals
  React.useEffect(() => {
    return () => {
      clearInterval(checkInterval);
      clearInterval(timerInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // every time values is updates update our timestamp
  // and restart interval
  React.useEffect(() => {
    startChecker();

    const now = new Date();
    lastActivityTimestamp.current = now.valueOf();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  // when timer hits 0 quick exit
  React.useEffect(() => {
    if (timer < 0) {
      clearInterval(timerInterval);
      push("/quick");
    }
  }, [push, timer, timerInterval]);

  // start timer whenever the modal shows
  React.useEffect(() => {
    if (showModal) {
      setTimer(60);
      clearInterval(timerInterval);
      const interval = setInterval(() => setTimer((t) => t - 1), 1000);
      // @ts-ignore
      setTimerInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  // do the check
  const actionCheck = () => {
    const now = new Date().valueOf();
    const checkTimestamp = now - intervalMins * 60 * 1000;
    if (lastActivityTimestamp.current < checkTimestamp) {
      clearInterval(checkInterval);
      setShowModal(true);
    }
  };

  const startChecker = React.useCallback(() => {
    clearInterval(checkInterval);
    const interval = setInterval(actionCheck, intervalMins * 60 * 1000);
    // @ts-ignore
    setCheckInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    lastActivityTimestamp.current = new Date().valueOf();
    e.preventDefault();
    clearInterval(timerInterval);
    startChecker();
    setShowModal(false);
  };

  if (showModal) {
    return (
      <Modal onClose={handleModalClose}>
        <p>
          <strong>Your session is about to expire.</strong>
        </p>
        <p>You'll be exited from this session after {timer} seconds</p>
        <div className="buttons">
          <Button
            variant="secondary"
            onClick={(e) => {
              e.preventDefault();
              clearInterval(timerInterval);
              push("/quick");
            }}
          >
            Exit session
          </Button>
          <Button onClick={handleModalClose}>Continue</Button>
        </div>
      </Modal>
    );
  }

  return null;
};

export default InactivityCheck;
