import React from "react";
import styled from "styled-components/macro";
import { useFormikContext } from "formik";

import QuestionDatepicker from "../../../components/QuestionDatepicker";
import Question from "../../../components/Question";
import RadioGroup from "../../../components/RadioGroup";
import CheckboxGroup from "../../../components/CheckboxGroup";
import QuestionSpacer from "../../../components/QuestionSpacer";
import QuestionAddress from "../../../components/QuestionAddress";
import QuestionsSection from "../../../components/QuestionsSection";
import QuestionsFrame from "../../../components/QuestionsFrame";
import SubHeading from "../../../components/SubHeading";
import ConditionalFieldsWrapper from "../../../components/ConditionalFieldsWrapper";
import IconButton from "../../../components/IconButton";
import { FlatApplciationType } from "../../../types";

type Props = {
  childIndex: number;
  showAddButton: boolean;
  removeChild: (childIndex: number) => void;
  addChild: () => void;
};

const Child: React.FC<Props> = ({
  childIndex,
  removeChild,
  addChild,
  showAddButton,
}) => {
  const { isValid, values, setFieldValue } =
    useFormikContext<FlatApplciationType>();
  const numberAsWord = [
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
  ];

  // set the index on mount
  React.useEffect(() => {
    // @ts-ignore
    if (values.children[childIndex].index !== childIndex) {
      setFieldValue(`children[${childIndex}].index`, childIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChildContainer>
      <QuestionsSection
        heading={`Child ${numberAsWord[childIndex]}'s personal details`}
        onRemoveClick={() => removeChild(childIndex)}
      >
        <QuestionsFrame>
          <Question name={`children[${childIndex}].prp_giv_nam`} />
          <Question name={`children[${childIndex}].prp_giv_nam2`} />
          <Question name={`children[${childIndex}].prp_giv_nam3`} />
          <Question name={`children[${childIndex}].prp_fam_nam`} />
          <RadioGroup name={`children[${childIndex}].prp_sex1`} />
          <ConditionalFieldsWrapper
            conditions={{
              [`children[${childIndex.toString()}].prp_sex1`]: "U",
            }}
          >
            <Question name={`children[${childIndex}].prp_sex_other`} />
          </ConditionalFieldsWrapper>
          <QuestionDatepicker name={`children[${childIndex}].prp_dob`} />
          <RadioGroup name={`children[${childIndex}].prp_abor_code`} />
          <RadioGroup name={`children[${childIndex}].prp_disabl`} />
          <RadioGroup name={`children[${childIndex}].prp_live`} />
          <ConditionalFieldsWrapper
            conditions={{ [`children[${childIndex}].prp_live`]: "no" }}
          >
            <QuestionAddress
              namePrefix={`children[${childIndex}].prp_addr`}
              label="What address is the child currently living at?"
            />
          </ConditionalFieldsWrapper>
        </QuestionsFrame>
      </QuestionsSection>
      <QuestionsSection heading="Child’s relationship to you and the respondent">
        <QuestionsFrame>
          <Question
            type="select"
            name={`children[${childIndex}].prp_rel_to_appl`}
          />
          <Question
            type="select"
            name={`children[${childIndex}].prp_rel_to_resp`}
          />
        </QuestionsFrame>
        <SubHeading heading="Existing court applications" />
        <QuestionsFrame>
          <RadioGroup name={`children[${childIndex}].prp_ord_exist`} />
          <ConditionalFieldsWrapper
            conditions={{
              [`children[${childIndex}].prp_ord_exist`]: "yes",
            }}
          >
            <CheckboxGroup
              name={`children[${childIndex}].prp_ord_exist_det`}
              optionChildren={{
                cpo: (
                  <>
                    <QuestionSpacer />
                    <DatesContainer>
                      <QuestionDatepicker
                        label="Date of last order"
                        name={`children[${childIndex}].prp_ord_cpo_dlo`}
                      />
                      <QuestionDatepicker
                        label="Next court date"
                        name={`children[${childIndex}].prp_ord_cpo_ncd`}
                      />
                    </DatesContainer>
                  </>
                ),
                io: (
                  <>
                    <QuestionSpacer />
                    <DatesContainer>
                      <QuestionDatepicker
                        label="Date of last order"
                        name={`children[${childIndex}].prp_ord_io_dlo`}
                      />
                      <QuestionDatepicker
                        label="Next court date"
                        name={`children[${childIndex}].prp_ord_io_ncd`}
                      />
                    </DatesContainer>
                  </>
                ),
                oo: (
                  <>
                    <QuestionSpacer />
                    <DatesContainer>
                      <QuestionDatepicker
                        label="Date of last order"
                        name={`children[${childIndex}].prp_ord_oo_dlo`}
                      />
                      <QuestionDatepicker
                        label="Next court date"
                        name={`children[${childIndex}].prp_ord_oo_ncd`}
                      />
                    </DatesContainer>
                  </>
                ),
              }}
            />
          </ConditionalFieldsWrapper>
          <RadioGroup name={`children[${childIndex}].prp_ord_fc`} />
          <ConditionalFieldsWrapper
            conditions={{ [`children[${childIndex}].prp_ord_fc`]: "yes" }}
          >
            <CheckboxGroup name={`children[${childIndex}].prp_flc_hear`} />

            <ConditionalFieldsWrapper
              conditions={{
                [`children[${childIndex}].prp_flc_hear`]: "po",
              }}
            >
              <Question name={`children[${childIndex}].prp_flc_other_po`} />
            </ConditionalFieldsWrapper>
            <ConditionalFieldsWrapper
              conditions={{
                [`children[${childIndex}].prp_flc_hear`]: "other",
              }}
            >
              <Question name={`children[${childIndex}].prp_flc_other`} />
            </ConditionalFieldsWrapper>
          </ConditionalFieldsWrapper>
          {showAddButton && (
            <div>
              <IconButton
                icon="plus"
                disabled={!isValid}
                onClick={(e) => {
                  e.preventDefault();
                  addChild();
                }}
                label="Add another child"
              />
            </div>
          )}
        </QuestionsFrame>
      </QuestionsSection>
    </ChildContainer>
  );
};

export default Child;

const DatesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ChildContainer = styled.div`
  padding-top: 30px;
  border-top: 1px solid #d4e1ed;
`;
