import { useFormikContext } from "formik";
import React from "react";
import { FlatApplciationType } from "../types";

const ClearValues: React.FC<{ names: string[] }> = ({ names }) => {
  const {
    setFieldValue,
    setFieldTouched,
    values,
  } = useFormikContext<FlatApplciationType>();

  React.useEffect(() => {
    names.forEach((name) => {
      const resetValue = Array.isArray(values[name]) ? [] : "";
      setFieldValue(name, resetValue);
      setFieldTouched(name, false);
    });

    // eslint-disable-next-line
  }, []);

  return null;
};

export default ClearValues;
