import React from "react";
import { ApplicationType } from "csv-package";

import api from "../helpers/api";
import { isAuthenticated, getUsername } from "../helpers/auth";

import LoadingIndicator from "../components/LoadingIndicator";
import { useHistory } from "react-router-dom";
import {
  configApplicationType,
  configJurisdiction,
} from "../config/systemConfig";

const ApplicationCreate = () => {
  const { replace } = useHistory();

  React.useEffect(() => {
    const createApplication = async () => {
      try {
        const isAuth = await isAuthenticated();
        if (!isAuth) {
          replace(`/signup`);
        }
        const Username = await getUsername();
        const Type = configApplicationType;
        const Jurisdiction = configJurisdiction;

        const result = (await api("post", "/application", {
          Username,
          Type,
          Jurisdiction,
        })) as ApplicationType;

        if (typeof result.ApplicationID !== "string") {
          throw new Error("Application failed to create");
        }

        let url = `/application/${result.ApplicationID}`;
        replace(url);
      } catch (e) {
        console.error(e);
        console.log("error creating new application");
      }
    };
    createApplication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingIndicator />;
};

export default ApplicationCreate;
