import React from "react";
import styled, { css, createGlobalStyle } from "styled-components/macro";

import { ReactComponent as Cross } from "../assets/icons/X.svg";

import colors from "../theme/colors";
import media from "../theme/media";

const NoBodyScroll = createGlobalStyle`
  body, html {
    overflow: hidden;
  }
`;

type Props = {
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  large?: boolean;
};

const Modal: React.FC<Props> = ({ onClose, children, large }) => {
  return (
    <ModalOverlay>
      <NoBodyScroll />
      <ModalOuterContainer large={large}>
        <ModalHeader>
          {onClose && (
            <ModalCloseButton onClick={onClose}>
              <span>Close</span> <Cross />
            </ModalCloseButton>
          )}
        </ModalHeader>
        <ModalInnerContainer large={large}>
          <ModalContent large={large}>{children}</ModalContent>
        </ModalInnerContainer>
      </ModalOuterContainer>
    </ModalOverlay>
  );
};

export default Modal;

const ModalOverlay = styled.div`
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  display: flex;
  align-items: center;
  justify-content: center;
  .buttons {
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
    button + button {
      margin-left: 10px;
    }
  }
`;

const ModalOuterContainer = styled.div<{ large?: boolean }>`
  position: absolute;
  width: 100%;
  max-width: 553px;
  box-sizing: border-box;
  border-radius: 6px;
  background: white;
  padding: 60px 25px 60px 42px;
  height: auto;
  ${({ large }) =>
    large &&
    css`
      height: 100%;
      border-radius: 0;
      ${media.greaterThan("phone")`
        height: calc(100vh - 90px);
        border-radius: 6px;
      `};
    `}
`;

const ModalInnerContainer = styled.div<{ large?: boolean }>`
  width: 100%;
  height: auto;
  ${({ large }) =>
    large &&
    css`
      height: 100%;
      overflow: scroll;
    `}
`;

const ModalContent = styled.div<{ large?: boolean }>`
  padding-right: 40px;
  width: 100%;
  & > :first-child {
    margin-top: 0;
  }
  h2 {
    font-size: 1.5em;
  }

  footer {
    text-align: center;
  }
`;

const ModalHeader = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 25px 0 42px;
`;

const ModalCloseButton = styled.button`
  color: ${colors.blue};
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  span {
    text-decoration: underline;
  }
  &:hover {
    cursor: pointer;
    span {
      text-decoration: none;
    }
  }
`;
