import React from "react";
import styled, { css } from "styled-components/macro";
import axios, { CancelTokenSource } from "axios";
import _ from "lodash";
import { rem } from "polished";
import { useFormikContext } from "formik";

import media from "../theme/media";
import spacing from "../theme/spacing";
import colors from "../theme/colors";
import inputStyles from "../theme/inputStyles";
import createAddressTerm from "../helpers/createAddressTerm";

import { ReactComponent as ClearIcon } from "../assets/icons/Clear.svg";
import { ReactComponent as EditIcon } from "../assets/icons/Edit.svg";

import FormLabel from "./FormLabel";
import ErrorMessage from "./ErrorMessage";
import { FlatApplciationType } from "../types";
import QuestionAddressValidator from "./QuestionAddressValidator";

const useDebounce = (value: string, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

type Props = {
  namePrefix: string;
  label: string;
  useToDetermineCourt?: boolean;
};

const QuestionAddress: React.FC<Props> = ({
  namePrefix,
  label,
  useToDetermineCourt,
}) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleChange,
  } = useFormikContext<FlatApplciationType>();

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const [term, setTerm] = React.useState<string>(
    createAddressTerm(values, namePrefix)
  );
  const [autoCompleteList, setAutoCompleteList] = React.useState<string[]>([]);
  const [selectedAutoCompleteIndex, setSelectedAutoCompleteIndex] =
    React.useState<number>(-1);
  const [selectionComplete, setSelectionComplete] =
    React.useState<boolean>(false);
  const [manualEdit, setManualEdit] = React.useState<boolean>(false);
  const [suburbs, setSuburbs] = React.useState<string[]>([]);
  const [cancelToken, setCancelToken] = React.useState<CancelTokenSource>(
    axios.CancelToken.source()
  );

  const transformAutoCompleteAddress = (addresses: string[]): string[] => {
    return addresses.map((address) =>
      address.replace(/^(UNIT|FLAT) ([0-9a-b]{1,4})( |\/)(.+)$/gim, "$2/$4")
    );
  };

  // search on debound term update
  const debouncedTerm = useDebounce(term, 400);
  React.useEffect(() => {
    const fetchAddresses = async () => {
      if (debouncedTerm.length < 4) {
        return;
      }
      setCancelToken(axios.CancelToken.source());
      await axios
        .get(
          `${
            process.env.REACT_APP_ADDRESSIFY_API_URL
          }/addresspro/autocomplete?api_key=${
            process.env.REACT_APP_ADDRESSIFY_API_KEY
          }&term=${encodeURI(debouncedTerm)}&state=VIC`,
          {
            cancelToken: cancelToken.token,
          }
        )
        .then((res) => {
          const list = transformAutoCompleteAddress(res.data);
          setAutoCompleteList(list);
          setSelectedAutoCompleteIndex(-1);
        })
        .catch((thrown) => {
          if (axios.isCancel(thrown)) {
            console.log("Request canceled", thrown.message);
          } else {
            // do nothing
          }
        });

      await axios
        .get(
          `${
            process.env.REACT_APP_ADDRESSIFY_API_URL
          }/addresspro/autocomplete?api_key=${
            process.env.REACT_APP_ADDRESSIFY_API_KEY
          }&term=${encodeURI(debouncedTerm)}&state=SA&max_results=5`,
          {
            cancelToken: cancelToken.token,
          }
        )
        .then((res) => {
          const list = transformAutoCompleteAddress(res.data);
          setAutoCompleteList((l) => [...l, ...list]);
          setSelectedAutoCompleteIndex(-1);
        })
        .catch((thrown) => {
          if (axios.isCancel(thrown)) {
            console.log("Request canceled", thrown.message);
          } else {
            // do nothing
          }
        });

      await axios
        .get(
          `${
            process.env.REACT_APP_ADDRESSIFY_API_URL
          }/addresspro/autocomplete?api_key=${
            process.env.REACT_APP_ADDRESSIFY_API_KEY
          }&term=${encodeURI(debouncedTerm)}&state=NSW&max_results=5`,
          {
            cancelToken: cancelToken.token,
          }
        )
        .then((res) => {
          const list = transformAutoCompleteAddress(res.data);
          setAutoCompleteList((l) => [...l, ...list]);
          setSelectedAutoCompleteIndex(-1);
        })
        .catch((thrown) => {
          if (axios.isCancel(thrown)) {
            console.log("Request canceled", thrown.message);
          } else {
            // do nothing
          }
        });
    };

    if (debouncedTerm !== createAddressTerm(values, namePrefix)) {
      fetchAddresses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTerm]);

  // when postcode is updated grab suburbs and state
  const postcodeValue = _.get(values, `${namePrefix}_postcode`, "") as string;
  React.useEffect(() => {
    const fetchSuburbs = async () => {
      const result = await axios.get(
        `${
          process.env.REACT_APP_ADDRESSIFY_API_URL
        }/address/getSuburbsforPostcode?api_key=${
          process.env.REACT_APP_ADDRESSIFY_API_KEY
        }&term=${encodeURI(postcodeValue)}`
      );
      const suburbs = _.uniq(
        result.data.map((s: string) => s.split(",")[0])
      ).sort() as string[];
      setSuburbs(suburbs);
    };

    if (postcodeValue && postcodeValue.match(/^[0-9]{4}$/) && manualEdit) {
      fetchSuburbs();
    }
  }, [postcodeValue, manualEdit]);

  // when postcode is updated grab suburbs and state
  const suburbValue = _.get(values, `${namePrefix}_sub`, "") as string;
  React.useEffect(() => {
    const fetchStates = async () => {
      const { data } = await axios.get(
        `${
          process.env.REACT_APP_ADDRESSIFY_API_URL
        }/address/suburbStatePostcodeAutoComplete?api_key=${
          process.env.REACT_APP_ADDRESSIFY_API_KEY
        }&term=${encodeURI(suburbValue)}&postcode=${encodeURI(postcodeValue)}`
      );
      const foundString = data.find(
        (o: string) => o.split(", ")[0] === suburbValue
      );

      const stateResult = foundString
        ? foundString.split(", ")[1].split(" ")[0]
        : "";

      setFieldValue(`${namePrefix}_state`, stateResult);
    };

    if (
      manualEdit &&
      postcodeValue &&
      postcodeValue.match(/^[0-9]{4}$/) &&
      suburbValue &&
      suburbValue !== ""
    ) {
      fetchStates();
    }
  }, [postcodeValue, suburbValue, manualEdit, setFieldValue, namePrefix]);

  const generateFieldName = React.useCallback(
    (fieldName: string): string => {
      return `${namePrefix}_${fieldName}`;
    },
    [namePrefix]
  );

  const parseAddress = () => {
    axios
      .get(
        `${process.env.REACT_APP_ADDRESSIFY_API_URL}/address/parse?api_key=${
          process.env.REACT_APP_ADDRESSIFY_API_KEY
        }&term=${encodeURI(autoCompleteList[selectedAutoCompleteIndex])}`
      )
      .then((res) => {
        setFieldValue(
          generateFieldName("flat"),
          _.get(res.data, "UnitNumber") || _.get(res.data, "LevelNumber", "")
        );
        setFieldValue(generateFieldName("strnr"), res.data.Number);
        setFieldValue(generateFieldName("strname"), res.data.Street);
        setFieldValue(generateFieldName("strtype"), res.data.StreetType);
        setFieldValue(generateFieldName("sub"), res.data.Suburb);
        setFieldValue(generateFieldName("state"), res.data.State);
        setFieldValue(generateFieldName("postcode"), res.data.Postcode);
        setFieldTouched(generateFieldName("strnr"), true);
        setFieldTouched(generateFieldName("strname"), true);
        setFieldTouched(generateFieldName("strtype"), true);
        setFieldTouched(generateFieldName("sub"), true);
        setFieldTouched(generateFieldName("state"), true);
        setFieldTouched(generateFieldName("postcode"), true);
      })
      .catch((e) => {
        console.log("parse error", e);
        parseAddress();
      });
  };

  const handleTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTerm(e.target.value);
  };

  const selectAutoCompleteItem = (index: number) => {
    // cancel axios requests
    cancelToken.cancel("Operation canceled by the user.");
    setTerm(autoCompleteList[index]);
    setAutoCompleteList([]);
    setSelectionComplete(true);
    parseAddress();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { keyCode } = e;
    if (keyCode === 38 && selectedAutoCompleteIndex >= -1) {
      // up arrow
      e.preventDefault();
      setSelectedAutoCompleteIndex((v) => v - 1);
    } else if (
      keyCode === 40 &&
      selectedAutoCompleteIndex < autoCompleteList.length
    ) {
      // down arrow
      e.preventDefault();
      setSelectedAutoCompleteIndex((v) => v + 1);
    } else if (keyCode === 13 && selectedAutoCompleteIndex > -1) {
      // enter key
      e.preventDefault();
      selectAutoCompleteItem(selectedAutoCompleteIndex);
    }
  };

  const handleReset = (e: React.MouseEvent<HTMLButtonElement>) => {
    setTerm("");
    setAutoCompleteList([]);
    setSelectedAutoCompleteIndex(-1);
    setSelectionComplete(false);
    setManualEdit(false);
    setFieldValue(generateFieldName("flat"), "");
    setFieldValue(generateFieldName("strnr"), "");
    setFieldValue(generateFieldName("strname"), "");
    setFieldValue(generateFieldName("strtype"), "");
    setFieldValue(generateFieldName("sub"), "");
    setFieldValue(generateFieldName("state"), "");
    setFieldValue(generateFieldName("postcode"), "");

    inputRef.current && inputRef.current.focus();
  };

  const addressErrors = React.useMemo(() => {
    return ["strname", "strtype", "sub", "state", "postcode"]
      .filter(
        (suffix) =>
          _.get(errors, generateFieldName(suffix)) &&
          _.get(touched, generateFieldName(suffix))
      )
      .map((suffix) => _.get(errors, generateFieldName(suffix)));
  }, [errors, generateFieldName, touched]);

  return (
    <>
      <Wrapper>
        <FormLabel text={label} />
        {addressErrors.length > 0 && (
          <StyledErrorMessage>
            <ul>
              {addressErrors.map((ae) => (
                <li key={ae}>{ae}</li>
              ))}
            </ul>
          </StyledErrorMessage>
        )}
        <div style={{ position: "relative" }}>
          {!manualEdit &&
            ["strname", "strtype", "sub", "state", "postcode"].map((suffix) => (
              <VisuallyHiddenInput
                name={generateFieldName(suffix)}
                type="text"
                key={suffix}
              />
            ))}
        </div>
        <Search
          ref={inputRef}
          type="text"
          onChange={handleTermChange}
          onKeyDown={handleKeyDown}
          value={term}
          disabled={selectionComplete}
        />
        {selectionComplete && !manualEdit && (
          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              setManualEdit(true);
            }}
          >
            <EditIcon /> <span>Edit</span>
          </ActionButton>
        )}
        {selectionComplete && manualEdit && (
          <ActionButton onClick={handleReset}>
            <ClearIcon /> <span>Clear address</span>
          </ActionButton>
        )}
        {autoCompleteList && (
          <AutoCompleteList>
            {autoCompleteList.map((a, i) => (
              <AutoCompleteItem
                key={i}
                highlighted={selectedAutoCompleteIndex === i}
                onClick={() => selectAutoCompleteItem(i)}
                onMouseEnter={() => setSelectedAutoCompleteIndex(i)}
              >
                {a}
              </AutoCompleteItem>
            ))}
          </AutoCompleteList>
        )}
      </Wrapper>
      {manualEdit && (
        <ParsedAddressContent>
          <UnitLevelLotNumber>
            <FormLabel text="Unit/Flat number" />
            <input
              type="text"
              value={
                (_.get(values, generateFieldName("flat"), "") as string) || ""
              }
              name={generateFieldName("flat")}
              onChange={handleChange}
            />
          </UnitLevelLotNumber>
          <StreetNumber>
            <FormLabel text="Street number" />
            <input
              type="text"
              value={
                (_.get(values, generateFieldName("strnr"), "") as string) || ""
              }
              name={generateFieldName("strnr")}
              onChange={handleChange}
            />
          </StreetNumber>
          <StreetName>
            <FormLabel text="Street name" />
            <input
              type="text"
              value={
                (_.get(values, generateFieldName("strname"), "") as string) ||
                ""
              }
              name={generateFieldName("strname")}
              onChange={handleChange}
            />
          </StreetName>
          <StreetType>
            <FormLabel text="Street type" />
            <input
              type="text"
              value={
                (_.get(values, generateFieldName("strtype"), "") as string) ||
                ""
              }
              name={generateFieldName("strtype")}
              onChange={handleChange}
              maxLength={4}
            />
          </StreetType>
          <Postcode>
            <FormLabel text="Postcode" />
            <input
              type="text"
              value={
                (_.get(values, generateFieldName("postcode"), "") as string) ||
                ""
              }
              name={generateFieldName("postcode")}
              onChange={handleChange}
            />
          </Postcode>
          <Suburb>
            <FormLabel text="Suburb" />
            <select
              value={
                (_.get(values, generateFieldName("sub"), "") as string) || ""
              }
              name={generateFieldName("sub")}
              onChange={handleChange}
            >
              {suburbs.length > 0 ? (
                <>
                  <option value="">Please select a suburb</option>
                  {suburbs.map((s) => (
                    <option key={s} value={s}>
                      {s}
                    </option>
                  ))}
                </>
              ) : (
                <option>Please enter a valid postcode</option>
              )}
            </select>
          </Suburb>
          <State>
            <FormLabel text="State" />
            <input
              type="text"
              value={
                (_.get(values, generateFieldName("state"), "") as string) || ""
              }
              disabled
            />
          </State>
        </ParsedAddressContent>
      )}
      {useToDetermineCourt && (
        <QuestionAddressValidator namePrefix={namePrefix} />
      )}
    </>
  );
};

export default QuestionAddress;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 32px;
`;

const Search = styled.input`
  ${inputStyles}
`;

const ActionButton = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  bottom: 15px;
  right: 15px;
  padding: 0;
  border: none;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  color: ${colors.blue};
  svg {
    height: 1em;
    width: 1em;
    margin-right: 0.4em;
  }
  span {
    display: none;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
    ${media.greaterThan("tablet")`
      display: inline;
    `}
  }
`;

const AutoCompleteList = styled.ul`
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 2;
  padding: 0;
  margin: 0;
  width: 100%;
`;

const AutoCompleteItem = styled.li<{ highlighted?: boolean }>`
  height: 48px;
  background: #f3f7fb;
  border: 1px solid #979797;
  padding: 0 0 0 15px;
  width: 100%;
  display: flex;
  list-style-type: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #3c3c3c;
  font-size: 18px;
  margin: 0;

  & + & {
    border-top: none;
  }

  ${({ highlighted }) =>
    highlighted &&
    css`
      background: #d4e1ed;
    `}
`;

const ParsedAddressContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const AddressFieldMaster = styled.div`
  width: 100%;
  margin-bottom: 15px;
  input {
    ${inputStyles}
  }

  select {
    ${inputStyles}
  }

  ${media.greaterThan("tablet")`
    width: calc(50% - 5px);
  `}
`;

const UnitLevelLotNumber = styled(AddressFieldMaster)`
  width: calc(50% - 5px);
`;

const StreetNumber = styled(AddressFieldMaster)`
  width: calc(50% - 5px);
`;

const StreetName = styled(AddressFieldMaster)``;

const StreetType = styled(AddressFieldMaster)``;

const Suburb = styled(AddressFieldMaster)`
  width: calc(50% - 5px);
  ${media.greaterThan("tablet")`
    width: calc(50% - 5px);
  `}
`;

const Postcode = styled(AddressFieldMaster)`
  width: calc(50% - 5px);
  ${media.greaterThan("tablet")`
    width: calc(25% - 5px);
  `}
`;

const State = styled(AddressFieldMaster)`
  width: calc(50% - 5px);
  ${media.greaterThan("tablet")`
    width: calc(25% - 5px);
  `}
`;

const StyledErrorMessage = styled(ErrorMessage)`
  padding: ${rem(spacing.gutters)};
  margin-bottom: 13px;
  ul li {
    color: ${colors.error};
  }
`;

const VisuallyHiddenInput = styled.input`
  display: block;
  height: 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
`;
