import Yup from '../yup';
import child from './child';

const children = {
  prp_witness: Yup.string()
    .trim()
    .required('We require this information'),
  prp_add_order: Yup.string()
    .trim()
    .required('We require this information'),
  children: Yup.array().of(child),
};

export default children;
