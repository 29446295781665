import { get } from "lodash";

import { FlatApplciationType } from "../types";

const createAddressTerm = (
  values: FlatApplciationType,
  prefix: string = "appl_det_addr"
): string => {
  const addressTerms: string[] = [
    "flat",
    "strnr",
    "strname",
    "strtype",
    "sub",
    "state",
    "postcode",
  ];
  return addressTerms.reduce((addressString, suffix) => {
    const value = get(values, `${prefix}_${suffix}`);
    if (!value) {
      return addressString;
    } else if (suffix === "flat") {
      return `${value}/`;
    } else if (suffix === "strnr") {
      return `${addressString}${value}`;
    } else if (suffix === "strtype") {
      return `${addressString} ${value},`;
    }
    return `${addressString} ${value}`;
  }, "");
};

export default createAddressTerm;
