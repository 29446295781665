import { Yup } from "csv-package";
import child from "./child";

const children = {
  ap_children: Yup.string().required("We require this information"),
  ap_children_exposed: Yup.string().required("We require this information"),
  children: Yup.array().of(child),
};

export default children;
