import _ from "lodash";
import {
  ApplicationDataType,
  ChildDataType,
  getMappedOptionValue,
  SchemaType,
} from "csv-package";

import { FlatApplciationType } from "../../types";
import isStrings from "../isStrings";

const calculateTempateValues = (
  values: FlatApplciationType,
  schema: SchemaType
): ApplicationDataType => {
  const templateValues: ApplicationDataType = Object.keys(values).reduce(
    (accumulator, key) => {
      // allow for 2 levels e.g. children[1].prp_sex
      const rawValue = values[key];
      let val = rawValue;

      // skip any key staring with a capital;
      if (key.charAt(0) === key.charAt(0).toUpperCase()) {
        return accumulator;
      }

      // skip
      // check for array of strings and not a children
      if (
        typeof rawValue === "string" ||
        (Array.isArray(rawValue) && isStrings(rawValue))
      ) {
        try {
          val = getMappedOptionValue(schema, key, rawValue);
        } catch (e) {
          //do nothing
        }

        // check if child
      } else if (Array.isArray(rawValue) && !isStrings(rawValue)) {
        val = rawValue.map((child) => {
          return _(child)
            .keys()
            .filter((f) => schema.fields.map((s) => s.name).includes(f))
            .reduce((accumulator, key) => {
              try {
                const rawVal = child[key];
                if (typeof rawVal === "number") {
                  return accumulator;
                }
                const val = getMappedOptionValue(schema, key, rawVal);
                return {
                  ...accumulator,
                  [key]: val,
                };
              } catch (e) {
                return accumulator;
              }
            }, {});
        }) as ChildDataType[];
      }
      return {
        ...accumulator,
        [key]: val,
      };
    },
    {
      children: [],
    }
  );

  return templateValues;
};

export default calculateTempateValues;
