import React from "react";
import styled, { css } from "styled-components/macro";
import { lighten, rem } from "polished";
import { useField } from "formik";
import { SchemaOptionType } from "csv-package";

import inputStyles from "../theme/inputStyles";

import FormLabel from "./FormLabel";
import FormNote from "./FormNote";
import ErrorMessage from "./ErrorMessage";

type AutoCompleteOption =
  | "name"
  | "honorific-prefix"
  | "given-name"
  | "additional-name"
  | "family-name"
  | "honorific-suffix"
  | "nickname"
  | "username"
  | "new-password"
  | "current-password"
  | "one-time-code"
  | "organization-title"
  | "organization"
  | "street-address"
  | "address-line1"
  | "address-line2"
  | "address-line3"
  | "address-level4"
  | "address-level3"
  | "address-level2"
  | "address-level1"
  | "country"
  | "country-name"
  | "postal-code"
  | "cc-name"
  | "cc-given-name"
  | "cc-additional-name"
  | "cc-family-name"
  | "cc-number"
  | "cc-exp"
  | "cc-exp-month"
  | "cc-exp-year"
  | "cc-csc"
  | "cc-type"
  | "transaction-currency"
  | "transaction-amount"
  | "language"
  | "bday"
  | "bday-day"
  | "bday-month"
  | "bday-year"
  | "sex"
  | "url"
  | "photo";

type Props = {
  name: string;
  label?: string;
  type?: string;
  note?: string;
  notePosition?: "below" | "right";
  options?: SchemaOptionType[];
  autoComplete?: AutoCompleteOption;
  maxlength?: number;
};

const StandaloneQuestion: React.FC<Props> = React.memo(
  ({
    name,
    label,
    type,
    note,
    notePosition,
    options,
    autoComplete,
    maxlength,
  }) => {
    const [field, meta] = useField(name);
    const error = meta.touched && meta.error;

    return (
      <Wrapper>
        {label && (
          <FormLabel name={name} text={label}>
            {note && notePosition === "right" && <FormNote text={note} />}
          </FormLabel>
        )}
        {note && notePosition === "below" && <FormNote text={note} />}
        {error && <StyledErrorMessage>{meta.error}</StyledErrorMessage>}
        {type === "select" ? (
          <Select
            name={name}
            onChange={field.onChange}
            onBlur={field.onBlur}
            error={Boolean(error)}
            value={field.value || ""}
          >
            <option value="">Please select an option</option>
            {options &&
              options.map((o, i) => (
                <option value={o.value} key={(o.value, i)}>
                  {o.text}
                </option>
              ))}
          </Select>
        ) : (
          <Input
            name={name}
            type={type}
            onChange={field.onChange}
            onBlur={field.onBlur}
            error={Boolean(error)}
            value={field.value || ""}
            autoComplete={autoComplete}
            maxLength={maxlength}
          />
        )}
      </Wrapper>
    );
  }
);

export default StandaloneQuestion;

export const Wrapper = styled.div`
  margin-bottom: ${rem(35)};
`;

const Input = styled.input`
  ${inputStyles}
`;

export const Select = styled.select`
  ${inputStyles}
  ${({ value }) =>
    !value &&
    css`
      color: ${lighten(0.5, `#000`)};
    `}
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-bottom: 13px;
`;
