import _ from "lodash";
import { ApplicationDataType } from "csv-package";

import getAge from "../../helpers/getAge";
import { NarrativeTemplateType } from "../../helpers/narrativeGenerator";
import { hasAddAndRemoveChildren, hasOnlyAddChildren, hasOnlyRemoveChildren } from "../../helpers/childHelpers";
import { protectedPersonRoles } from "./constants";


const template: NarrativeTemplateType[] = [
  {
    template: 'I seek to:'
  },
  {
    conditions: {
      seeking: "resp",
    },
    variables: {
      appTypes: values => getAppTypes(values)
    },
    template: "have leave to apply to <%= _.toLower(appTypes) %>.",
  },
  {
    conditions: {
      seeking: "appl",
    },
    variables: {
      appTypes: values => getAppTypes(values)
    },
    template: "apply to <%= _.toLower(appTypes) %>.",
  },
  {
    template: 'The reasons/grounds for the application are:'
  },
  {
    conditions: {
      app_types: "AE"
    },
    template: "I am seeking to extend the order because <%= app_type_extend_why %>",
  },
  {
    conditions: {
      app_types: "AE",
      app_type_extend_why: "still_fearful"
    },
    template: "<%= app_type_extend_why_still_fearful %>",
  },
  {
    conditions: {
      app_types: "AE",
      app_type_extend_why: "further_violence"
    },
    template: `Allegations of further violence that have occurred since the order was made are:`,
  },
  ...(["incident_phy", "incident_pys", "incident_aep", "incident_thy", "incident_cbp", "incident_fsw", "incident_ryf", "incident_kha", "incident_sty",
    "incident_htc", "incident_cym", "incident_dyp", "incident_fmc", "incident_cdw"].map(inc => ({
      conditions: {
        show: (values: any) => (values.app_types as string[]).includes("AE") && (values["app_type_extend_why"] as string[]).includes("further_violence") && values[`${inc}_detail`]
      },
      template: `<%= ${inc}_date\n %>\n<%= ${inc}_detail %>`,
    }))),
  {
    conditions: {
      app_types: "AE",
      app_type_extend_why: "breached"
    },
    template: `<%= app_type_extend_why_breached %>`,
  },
  {
    conditions: {
      app_types: "AE",
      app_type_extend_why: "violence_stopped"
    },
    template: `<%= app_type_extend_why_violence_stopped %>`,
  },
  {
    conditions: {
      app_types: "AE",
      show: (values: any) => !_.isEmpty(values.app_type_extend_add)
    },
    template: `<%= app_type_extend_add %>`,
  },
  {
    conditions: {
      app_types: ["AV"]
    },
    template: `<%= vary_appl_why %>`,
  },
  ...(["con_old_a", "con_old_b", "con_old_c", "con_old_d", "con_old_e", "con_old_f", "con_old_g", "con_old_h", "exc_old_a", "exc_old_b", "exc_old_c", "exc_old_d", "exc_old_e",
    "con_old_i", "con_old_j", "con_old_k", "con_old_l", "con_old_m", "con_old_n", "con_old_o", "con_old_p", "con_old_q", "con_old_r",].map(vo => ({
      conditions: {
        show: (values: any) => ((values.app_types as string[]).includes("AV") || (values.app_types as string[]).includes("RV")) && values[`coo_${vo}`] === 'yes'
      },
      template: `<%= coo_${vo}_detail %>`,
    }))),
  {
    conditions: {
      app_types: ["RV"],
      show: (values: any) => !_.isEmpty(values.vary_resp_children_arr)
    },
    template: `<%= vary_resp_children_arr %>`,
  },
  {
    conditions: {
      app_types: ["RV"]
    },
    template: `<%= vary_resp_why %>`,
  },
  {
    conditions: {
      seeking: "resp",
      app_types: ["AV", "RV"],
    },
    template: `<%= vary_resp_why_2 %>`,
  },
  {
    conditions: {
      app_types: ["AC"],
      show: (values: any) => values.children && hasOnlyAddChildren(values)
    },
    template:
      "<%= child_action_add_why %>",
  },
  {
    conditions: {
      app_types: ["AC"],
      show: (values: any) => values.children && hasOnlyRemoveChildren(values)
    },
    template:
      "<%= child_action_remove_why %>",
  },
  {
    conditions: {
      app_types: ["RC"],
    },
    template:
      "<%= child_action_remove_why %>",
  },
  {
    conditions: {
      app_types: ["AC"],
      show: (values: any) => values.children && hasAddAndRemoveChildren(values)
    },
    template:
      "<%= child_action_add_remove_why %>",
  },
  {
    conditions: {
      app_types: ["AC"],
      show: (values: any) => values.children && hasAddAndRemoveChildren(values)
    },
    template:
      "I am seeking to add:",
  },
  {
    conditions: {
      app_types: ["AC"],
      show: (values: any) => values.children && _.some(values.children, { child_action: 'add' })
    },
    variables: {
      chilren_adding: values => getChildrenTexts(values, true)
    },
    template:
      "<% _.forEach(chilren_adding, (c) => { %><%- c %> <% }) %>",
  },
  {
    conditions: {
      app_types: ["AC"],
      show: (values: any) => values.children && hasAddAndRemoveChildren(values)
    },
    template:
      "I am seeking to remove:",
  },
  {
    conditions: {
      app_types: ["AC"],
      show: (values: any) => values.children && _.some(values.children, { child_action: 'remove' })
    },
    variables: {
      chilren_removing: values => getChildrenTexts(values, false)
    },
    template:
      "<% _.forEach(chilren_removing, (c) => { %><%- c %> <% }) %>",
  },
  {
    conditions: {
      app_types: ["RC"]
    },
    variables: {
      chilren: values => getChildrenTexts(values)
    },
    template:
      "<% _.forEach(chilren, (c) => { %><%- c %> <% }) %>",
  },
  {
    conditions: {
      app_types: "AR"
    },
    template: `<%= revoke_appl_why %>`,
  },
  {
    conditions: {
      app_types: "RR"
    },
    template: `<%= revoke_resp_why %>`,
  },
  {
    conditions: {
      app_types: "RR"
    },
    template: `<%= revoke_resp_why_2 %>`,
  },
];

export default template;

const getAppTypes = (values: ApplicationDataType): string => {
  const appTypes: Array<string> = values.app_types as Array<string>;
  let appTypesStr = [];
  if (appTypes.includes('AE')) appTypesStr.push('EXTEND THE ORDER');
  if (appTypes.includes('AV') || appTypes.includes('RV')) appTypesStr.push('VARY THE CONDITIONS ON THE ORDER');
  if (appTypes.includes('AC')) {
    if (_.some(values.children, { child_action: 'add' })) appTypesStr.push('ADD A CHILD/CHILDREN');
    if (_.some(values.children, { child_action: 'remove' })) appTypesStr.push('REMOVE A CHILD/CHILDREN');
  }
  if (appTypes.includes('RC')) appTypesStr.push('REMOVE A CHILD/CHILDREN');
  if (appTypes.includes('AR') || appTypes.includes('RR')) appTypesStr.push('REVOKE THE ORDER');
  return appTypesStr.length === 1 ? appTypesStr[0] : appTypesStr.slice(0, -1).join(', ') + ' and ' + appTypesStr.slice(-1).join(', ');
}

const getChildrenTexts = (values: ApplicationDataType, isAdd?: boolean) => {
  console.log({ values });
  if (!Array.isArray(values.children)) {
    return [];
  }
  // return an array of values for each child
  return (isAdd === undefined ? values.children : values.children.filter((c: any) => c.child_action === (isAdd ? "add" : "remove")))
    .sort((a, b) => {
      if (
        typeof b.prp_dob === "string" &&
        typeof a.prp_dob === "string"
      ) {
        return getAge(b.prp_dob) - getAge(a.prp_dob);
      }
      return 0;
    })
    .map((child, index, arr) => {
      let age = 0;
      if (typeof child.prp_dob === "string") {
        age = getAge(child.prp_dob);
      }

      // picking address keys: value
      // remove any null or ""
      // get values
      // sort
      // reday to comapre
      const pickedChildAddress = _(child)
        .pick([
          "prp_addr_flat",
          "prp_addr_postcode",
          "prp_addr_state",
          "prp_addr_strname",
          "prp_addr_strnr",
          "prp_addr_strtype",
          "prp_addr_sub",
        ])
        .pickBy(_.identity)
        .sortBy();

      const pickedRespondentAddress = _(values)
        .pick([
          "resp_det_addr_flat",
          "resp_det_addr_postcode",
          "resp_det_addr_state",
          "resp_det_addr_strname",
          "resp_det_addr_strnr",
          "resp_det_addr_strtype",
          "resp_det_addr_sub",
        ])
        .pickBy(_.identity)
        .sortBy();

      const isRespondent = values.seeking === 'resp';
      const isProtectedPerson = values.seeking === 'appl' && protectedPersonRoles.includes(String(values.seeking_appl_role));
      let returnValue = `${child.prp_giv_nam}, ${age}, `;
      if (child.prp_live === "yes") {
        returnValue += isProtectedPerson || isRespondent ? `lives with me` : `lives with the adult protected person`;
      } else if (_.isEqual(pickedChildAddress, pickedRespondentAddress)) {
        returnValue += isRespondent ? `lives with the adult protected person` : `lives with the respondent`;
      } else {
        returnValue += isProtectedPerson ? `lives elsewhere, i.e. not with me or the respondent` : isRespondent ? 'does not live with me' : `lives elsewhere, i.e. not with the adult protected person or the respondent`;
      }

      // if we are here there must be orders on the child
      return `${returnValue}.${arr.length - 1 !== index ? '\n' : ''}`;
    });
}