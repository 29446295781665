import { useFormikContext } from "formik";
import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { DeclarationOfTruth } from "csv-package";

import spacing from "../../../theme/spacing";

import { ApplicationContext } from "../../../reducers/applicationFrame";
import ConfirmSection from "../../../components/ConfirmSection";

import LongFootnote from "../../../components/LongFootnote";
import PageTitle from "../../../components/PageTitle";
import QuestionsFrame from "../../../components/QuestionsFrame";
import SharingAndExtend from "../../../components/ShareAndExtend";

import template from "../narrativeTemplate";
import narrativeGenerator from "../../../helpers/narrativeGenerator";
import { FlatApplciationType } from "../../../types";
import RadioGroup from "../../../components/RadioGroup";
import CheckboxGroup from "../../../components/CheckboxGroup";
import colors from "../../../theme/colors";

const Review = () => {
  const {
    state: { schema, narrativeLimit, narrativeKeys },
    goToStep,
  } = React.useContext(ApplicationContext);
  const { values, setFieldValue } = useFormikContext<FlatApplciationType>();

  let displayNarrative = narrativeGenerator(values, template, schema, true);
  try {
    if (values.Status !== "initiated") {
      displayNarrative = values.Narrative.split("\n")
        .join("<br />")
        .split("\r")
        .join("<br />");
    }
  } catch (e) {
    // do nothing
  }

  return (
    <>
      <PageTitle
        heading="Review your final statement"
        description={
          <>
            The statement below has been automatically prepared from the
            information you have provided, Please review this statement and add
            any final information you believe is important in the area provided
            below it.
          </>
        }
      />

      <Narrative
        dangerouslySetInnerHTML={{
          __html: displayNarrative,
        }}
      />

      {!_.isEmpty(narrativeKeys) &&
        typeof values.NarrativeCharsLeft === "number" &&
        values.NarrativeCharsLeft < 0 && (
          <NarrativeCharsOverWarning
            style={{ color: colors.error }}
            name="NarrativeCharsLeft"
          >
            Incident details when combined are over {narrativeLimit} characters.
            Please review and reduce the size of these descriptions.{" "}
            <span onClick={() => goToStep(3)}>Go to Incident details</span>
          </NarrativeCharsOverWarning>
        )}

      <QuestionsFrame>
        <LongFootnote name="sub_info_oth" charLimit={150} short />
      </QuestionsFrame>
      <ConfirmSection
        heading="Confirm safe contact details"
        goToStepIndex={0}
        config={[
          {
            names: [
              "appl_safe_cont",
              "appl_cont_phone",
              "appl_cont_phone_2",
              "appl_cont_phone_3",
              "appl_cont_phone_vm",
              "appl_cont_safe_times",
              "appl_cont_email",
              "appl_cont_email_2",
              "appl_cont_trst_per",
              "appl_cont_trst_per_rel",
              "appl_cont_trst_per_rel_other",
              "appl_cont_trst_per_phone",
              "appl_cont_trst_per_email",
            ],
          },
        ]}
      />
      <QuestionsFrame>
        <SharingAndExtend />
        <RadioGroup
          name="appl_info_share"
          optionChildren={{
            limited: <CheckboxGroup name="appl_info_share_limited" />,
          }}
        />
        <DeclarationOfTruth
          applicantName={`${values.appl_det_giv_nam} ${values.appl_det_fam_nam}`}
          applicantDOB={values.appl_det_dob + ""}
          onCheckboxChange={() =>
            setFieldValue(
              "declaration_of_truth",
              !Boolean(values.declaration_of_truth)
            )
          }
          checked={Boolean(values.declaration_of_truth)}
        />
        {/* <ExtendOrderReminder jurisdiction="FVIO" /> */}
      </QuestionsFrame>
    </>
  );
};

export default Review;

const Narrative = styled.div`
  padding: 0 ${spacing.gutters}px 0;

  h3: {
    font-size: 1.375rem;
    line-height: 1.36;
    margin: 2em 0 1em;
  }

  h3:first-child {
    margin-top: 0;
  }
`;

const NarrativeCharsOverWarning = styled.fieldset`
  background: #f8d9dd;
  color: ${colors.error};
  padding: 15px;
  border: none;
  margin-bottom: 2rem;

  span {
    text-decoration: underline;
    cursor: pointer;
    :hover {
      text-decoration: none;
    }
  }
`;
