import { useFormikContext } from "formik";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";
import { ReDeclareType } from "csv-package";

import colors from "../theme/colors";
import media from "../theme/media";
import { FlatApplciationType } from "../types";

const ReDeclareStatusBar: React.FunctionComponent<{}> = () => {
  const { values } = useFormikContext<FlatApplciationType>();
  // Don't show if there are no re-declares
  if (!values.ReDeclarations || values.ReDeclarations.length === 0) {
    return null;
  }

  const orderedReDecalres = values.ReDeclarations.sort(
    (a: ReDeclareType, b: ReDeclareType): number => {
      const aValueOf = new Date(a.Created).valueOf();
      const bValueOf = new Date(b.Created).valueOf();
      return bValueOf - aValueOf;
    }
  );

  const latestReDeclare = orderedReDecalres.find(
    (r: ReDeclareType) => r.Status === "accepted"
  );

  if (latestReDeclare === undefined) {
    return null;
  }

  if (latestReDeclare.Status !== "accepted") {
    return null;
  }

  return (
    <Container>
      <Inner>
        <p>
          This application was re-declared on{" "}
          {new Date(latestReDeclare.Accepted).toLocaleDateString("en-AU")}.{" "}
        </p>
      </Inner>
    </Container>
  );
};

export default ReDeclareStatusBar;

const Container = styled.div`
  background: ${colors.blue};
  height: ${rem(36)};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 1280px;
  color: white;
  font-size: 0.9rem;
`;

const Inner = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1310px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;

  ${media.greaterThan("tablet")`
    justify-content: flex-start;
  `}
`;
