import _ from "lodash";
import { ApplicationDataType } from "csv-package";

import { MapType } from ".";

export const fetchValueFromMap = (
  map: MapType,
  names: string[],
  values: ApplicationDataType
): string => {
  let returnValue: string | MapType | undefined = undefined;
  const calculatedValues = names.map((name) => values[name]);

  // return blank string if any of the values are undefined
  if (calculatedValues.some((v) => v === undefined)) {
    return "";
  }

  // this will try and grab a value at the deepest level, if nothing then remove one level and try again
  while (returnValue === undefined) {
    const findS = calculatedValues.join(".");
    returnValue = _.get(map, findS, undefined) as string | undefined;
    calculatedValues.pop();
  }
  if (returnValue === undefined) {
    throw new Error("Unable to value in map");
  }

  return returnValue;
};

export default fetchValueFromMap;
