import React from "react";
import { Route, Redirect, useHistory, RouteProps } from "react-router-dom";

import { isAuthenticated, hasAnonymousIdentity } from "../helpers/auth";

type Props = RouteProps;

const ProtectedRoute: React.FC<Props> = (props) => {
  const { push } = useHistory();
  const [isAuth, setIsAuth] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    const checkIsAuth = async () => {
      try {
        const isAuth = await isAuthenticated();
        setIsAuth(isAuth);
      } catch (error) {
        push("/login");
      }
    };
    checkIsAuth();
  }, [push]);

  // wait for auth check first
  if (isAuth === null) {
    return null;
  }

  // if both not authenticated and no anon identity send user to signup
  if (!isAuth && !hasAnonymousIdentity()) {
    return <Redirect to="/signup" />;
  }

  // if they get here they either have anon identity or auth
  return <Route {...props} />;
};

export default ProtectedRoute;
