import React from "react";
import styled from "styled-components/macro";
import { darken, lighten } from "polished";
import quickExit from "quick-exit";

import { ReactComponent as CloseIcon } from "../assets/icons/Close.svg";

const QuickExitButton = () => {
  return (
    <Wrapper
      onClick={() => {
        const safeUrl = process.env.REACT_APP_QUICK_EXIT_URL;
        const newTab = window.open(safeUrl, "_blank");
        if (newTab) {
          newTab.focus();
        }

        quickExit({
          safeUrl,
        });
      }}
    >
      <CloseIcon />
      Quick exit
    </Wrapper>
  );
};

export default QuickExitButton;

const Wrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 0 20px;
  height: 58px;
  background: #f9b080;
  font-weight: bold;
  color: #3c3c3c;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
  border: none;
  cursor: pointer;
  > svg {
    height: 1.2em;
    width: 1.2em;
    fill: currentColor;
    margin-right: 0.56em;
    transform: translateY(-1px);
  }
  &:hover {
    background: ${darken(0.1, `#f9b080`)};
  }
  &:active {
    background: ${lighten(0.1, `#f9b080`)};
  }
`;
