import React from "react";
import { useFormikContext } from "formik";
import { DeclarationOfTruth } from "csv-package";
import _ from "lodash";

import Button from "../../../components/Button";
import ButtonContainer from "../../../components/ButtonContainer";
import PageTitle from "../../../components/PageTitle";
import QuestionsFrame from "../../../components/QuestionsFrame";
import ReDeclareSection from "../../../components/ReDeclareSection";
import ReDeclareNarrative from "../../../components/ReDeclareNarrative";

import useReDeclare, { ReDeclareModals } from "../../../hooks/useReDeclare";
import { FlatApplciationType } from "../../../types";
import ReDeclareSectionChild from "../../../components/ReDeclareSectionChild";

const ReDeclare: React.FC = () => {
  const { values } = useFormikContext<FlatApplciationType>();
  const {
    handleAcceptReDeclare,
    handleCallBack,
    reDeclareObject,
    reDeclareState,
    handleDeclarationOfTruth,
    handleErrorModalClose,
  } = useReDeclare();

  if (reDeclareObject.Status === "canceled") {
    return null;
  }

  const reDeclareCreateDate = new Date(reDeclareObject.Created);

  return (
    <>
      <PageTitle
        heading="Application amendments"
        description={`The following sections have been amended by the Magistrates’ Court based on your telephone conversation that occurred on the ${reDeclareCreateDate.toLocaleDateString(
          "en-AU"
        )}. Please review these amendments to ensure they’re accurate before re-submitting.`}
      />

      <ReDeclareNarrative
        title="Your statement"
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <ReDeclareSection
        title="Applicant"
        names={[
          "appl_det_giv_nam",
          "appl_det_giv_nam2",
          "appl_det_giv_nam3",
          "appl_det_fam_nam",
          "appl_other_names",
          "appl_det_dob",
          "appl_det_sex",
          "appl_det_sex_other",
          "appl_pregnant_baby",
          "appl_abor_code",
          "appl_lgbt",
          "appl_disabl",
          "appl_disabl_req",
          "appl_disabl_req_other",
          "appl_det_inter",
          "appl_det_lang",
          "appl_det_lang_other",
          "appl_det_addr_flat",
          "appl_det_addr_strnr",
          "appl_det_addr_strname",
          "appl_det_addr_strtype",
          "appl_det_addr_sub",
          "appl_det_addr_state",
          "appl_det_addr_postcode",
          "appl_det_addr_secret",
          "appl_safe_cont",
          "appl_cont_phone",
          "appl_cont_phone_2",
          "appl_cont_phone_3",
          "appl_cont_phone_vm",
          "appl_cont_safe_times",
          "appl_cont_email",
          "appl_cont_email_2",
          "appl_cont_trst_per",
          "appl_cont_trst_per_rel",
          "appl_cont_trst_per_rel_other",
          "appl_cont_trst_per_phone",
          "appl_cont_trst_per_email",
        ]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <ReDeclareSection
        title="Children"
        names={["prp_witness", "prp_add_order"]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      {Array.isArray(reDeclareObject.Data.children) &&
        reDeclareObject.Data.children
          .sort((a, b) => {
            if (typeof a.index === "number" && typeof b.index === "number") {
              return a.index - b.index;
            }
            return 0;
          })
          .map((data, index) => (
            <ReDeclareSectionChild
              key={index}
              index={index}
              values={values}
              titlePrefix="Child "
              data={data}
              names={[
                "prp_giv_nam",
                "prp_giv_nam2",
                "prp_giv_nam3",
                "prp_fam_nam",
                "prp_sex1",
                "prp_sex_other",
                "prp_dob",
                "prp_abor_code",
                "prp_disabl",
                "prp_live",
                "prp_addr_flat",
                "prp_addr_strnr",
                "prp_addr_strname",
                "prp_addr_strtype",
                "prp_addr_sub",
                "prp_addr_state",
                "prp_addr_postcode",
                "prp_rel_to_appl",
                "prp_rel_to_resp",
                "prp_ord_exist",
                "prp_ord_exist_det",
                "prp_ord_cpo_dlo",
                "prp_ord_cpo_ncd",
                "prp_ord_io_dlo",
                "prp_ord_io_ncd",
                "prp_ord_oo_dlo",
                "prp_ord_oo_ncd",
                "prp_ord_fc",
                "prp_flc_hear",
                "prp_flc_other_po",
                "prp_flc_other",
              ]}
            />
          ))}

      <ReDeclareSection
        title="Respondent"
        names={[
          "resp_giv_nam",
          "resp_giv_nam2",
          "resp_giv_nam3",
          "resp_fam_nam",
          "resp_other_nam",
          "appl_rel_to_resp",
          "resp_known_lbl",
          "resp_sep",
          "resp_sep_inf",
          "resp_sep_long_lbl",
          "resp_sex",
          "resp_sex_other",
          "resp_dob_known",
          "resp_dob",
          "resp_age",
          "resp_abor_code",
          "resp_det_inter",
          "resp_det_lang",
          "resp_det_lang_other",
          "resp_addr_known",
          "resp_det_addr_flat",
          "resp_det_addr_strnr",
          "resp_det_addr_strname",
          "resp_det_addr_strtype",
          "resp_det_addr_sub",
          "resp_det_addr_state",
          "resp_det_addr_postcode",
          "resp_addr_type",
          "resp_addr_type_other",
          "resp_contact_type",
          "resp_contact_ph_3",
          "resp_contact_ph",
          "resp_contact_ph_2",
          "resp_contact_email",
          "resp_contact_email_2",
          "resp_contact_other",
          "resp_imm_pro",
          "resp_armed",
          "resp_armed_detail",
          "resp_armed_loc",
          "resp_armed_loc_add_flat",
          "resp_armed_loc_add_strnr",
          "resp_armed_loc_add_strname",
          "resp_armed_loc_add_strtype",
          "resp_armed_loc_add_sub",
          "resp_armed_loc_add_state",
          "resp_armed_loc_add_postcode",
          "resp_addiction",
          "resp_suicidal",
        ]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <ReDeclareSection
        title="Incident details"
        names={[
          "incident_phy_recent",
          "incident_phy_prev",
          "incident_phy_detail",
          "incident_pys_recent",
          "incident_pys_prev",
          "incident_pys_detail",
          "incident_aep_recent",
          "incident_aep_prev",
          "incident_aep_detail",
          "incident_cym_recent",
          "incident_cym_prev",
          "incident_cym_detail",
          "incident_thy_recent",
          "incident_thy_prev",
          "incident_thy_detail",
          "incident_cbp_recent",
          "incident_cbp_prev",
          "incident_cbp_detail",
          "incident_fsw_recent",
          "incident_fsw_prev",
          "incident_fsw_detail",
          "incident_dyp_recent",
          "incident_dyp_prev",
          "incident_dyp_detail",
          "incident_ryf_recent",
          "incident_ryf_prev",
          "incident_ryf_detail",
          "incident_htc_recent",
          "incident_htc_prev",
          "incident_htc_detail",
          "incident_kha_recent",
          "incident_kha_prev",
          "incident_kha_detail",
          "incident_sty_recent",
          "incident_sty_prev",
          "incident_sty_detail",
          "incident_fmc_recent",
          "incident_fmc_prev",
          "incident_fmc_detail",
          "incident_cdw_recent",
          "incident_cdw_prev",
          "incident_cdw_detail",

          "incident_rec_date",
          "incident_rec_desc",
          "incident_rec_pc",
          "incident_rec_pc_ofc",
          "incident_rec_pc_stn",
          "incident_prev_fv",
          "incident_prev_fv_det",
          "incident_prev_fv_time",
          "incident_prev_pc",
          "incident_prev_pc_ofc",
          "incident_prev_pc_stn",
          "incident_prev_freq",
        ]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <ReDeclareSection
        title="Previous orders"
        names={[
          "pio_fio",
          "pio_gio",
          "pio_bio",
          "pio_crt",
          "pio_crt_oth",
          "pio_rec_time",
          "pio_ref_nr",
          "pio_sub_io",
          "pio_sub_io_given",
        ]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <ReDeclareSection
        title="Conditions on the order"
        names={[
          "coo_con_old_a",
          "coo_con_old_b",
          "coo_con_old_c",
          "coo_con_old_d",
          "coo_con_old_e",
          "coo_con_old_f",
          "coo_con_old_g",
          "coo_con_old_h",
          "coo_exc_old_a",
          "coo_exc_old_b",
          "coo_exc_old_c",
          "coo_exc_old_d",
          "coo_exc_old_e",
          "coo_con_old_i",
          "coo_con_old_j",
          "coo_con_old_k",
          "coo_con_old_l",
          "coo_con_old_m",
          "coo_con_old_n",
          "coo_con_old_o",
          "coo_con_old_p",
          "coo_con_old_q",
          "coo_con_old_r",
          "coo_con_other",
        ]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <ReDeclareSection
        title="Other information"
        names={["sub_info_oth"]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <ReDeclareSection
        title="Sharing your information"
        names={["appl_info_share", "appl_info_share_limited"]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <QuestionsFrame>
        <DeclarationOfTruth
          applicantName={`${_.get(
            reDeclareObject,
            "Data.appl_det_giv_nam",
            values.appl_det_giv_nam
          )}  ${_.get(
            reDeclareObject,
            "Data.appl_det_fam_nam",
            values.appl_det_fam_nam
          )}`}
          applicantDOB={
            _.get(reDeclareObject, "Data.appl_det_dob", values.appl_det_dob) +
            ""
          }
          onCheckboxChange={handleDeclarationOfTruth}
          checked={reDeclareState.declaration_of_truth}
        />
      </QuestionsFrame>
      <ButtonContainer>
        <Button
          disabled={["loading", "successful"].includes(
            reDeclareState.submit_status
          )}
          variant="secondary"
          onClick={handleCallBack}
        >
          {
            {
              uninitialised: "Reject and request a call",
              loading: "Requesting...",
              successful: "Request successful",
              failed: "Reject and request a call",
            }[reDeclareState.call_back_status]
          }
        </Button>
        <Button onClick={handleAcceptReDeclare}>
          {
            {
              uninitialised: "Accept changes and redeclare",
              loading: "Submitting...",
              successful: "Request successful",
              failed: "Accept changes and redeclare",
            }[reDeclareState.submit_status]
          }
        </Button>
      </ButtonContainer>

      <ReDeclareModals
        reDeclareState={reDeclareState}
        handleErrorModalClose={handleErrorModalClose}
      />
    </>
  );
};

export default ReDeclare;
