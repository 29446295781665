import _ from "lodash";
import { ApplicationDataType } from "csv-package";

import relationshipsMap from "../../data/relationshipMap.json";
import getAge from "../../helpers/getAge";
import { NarrativeTemplateType } from "../../helpers/narrativeGenerator";
import fetchValueFromMap from "../../helpers/narrativeGenerator/fetchValuesFromMap";

const template: NarrativeTemplateType[] = [
  {
    variables: {
      relationship: _.curry(fetchValueFromMap)(relationshipsMap, [
        "appl_rel_to_resp",
        "resp_sex",
      ]),
    },
    title: "Background",
    template:
      "The respondent is <%= _.toLower(relationship) %> and I have known the respondent for <%= _.toLower(resp_known_lbl) %>.",
  },
  {
    conditions: {
      resp_sep: "yes",
    },
    template:
      "I am separated from the respondent. I have been separated for <%= _.toLower(resp_sep_long_lbl) %>",
  },
  {
    conditions: {
      resp_sep_inf: "yes",
    },
    template: "I have told the respondent of my plan to separate.",
  },
  {
    conditions: {
      resp_sep_inf: "no",
    },
    template: "I am not seeking to separate from the respondent.",
  },
  {
    conditions: {
      prp_add_order: "yes",
    },
    variables: {
      child_children: (values: ApplicationDataType) =>
        values.children && values.children.length > 1 ? "children" : "child",
      children_number: (values: ApplicationDataType) =>
        values.children && values.children.length.toString(),
      child_list: (values) => {
        if (!Array.isArray(values.children)) {
          return [];
        }
        // return an array of values for each child
        return values.children
          .sort((a, b) => {
            if (
              typeof b.prp_dob === "string" &&
              typeof a.prp_dob === "string"
            ) {
              return getAge(b.prp_dob) - getAge(a.prp_dob);
            }
            return 0;
          })
          .map((child) => {
            let age = 0;
            if (typeof child.prp_dob === "string") {
              age = getAge(child.prp_dob);
            }

            // picking address keys: value
            // remove any null or ""
            // get values
            // sort
            // reday to comapre
            const pickedChildAddress = _(child)
              .pick([
                "prp_addr_flat",
                "prp_addr_postcode",
                "prp_addr_state",
                "prp_addr_strname",
                "prp_addr_strnr",
                "prp_addr_strtype",
                "prp_addr_sub",
              ])
              .pickBy(_.identity)
              .sortBy();

            const pickedRespondentAddress = _(values)
              .pick([
                "resp_det_addr_flat",
                "resp_det_addr_postcode",
                "resp_det_addr_state",
                "resp_det_addr_strname",
                "resp_det_addr_strnr",
                "resp_det_addr_strtype",
                "resp_det_addr_sub",
              ])
              .pickBy(_.identity)
              .sortBy();

            let returnValue = `${child.prp_giv_nam}, ${age}, `;

            if (child.prp_live === "yes") {
              returnValue += `lives with me`;
            } else if (_.isEqual(pickedChildAddress, pickedRespondentAddress)) {
              returnValue += `lives with the respondent`;
            } else {
              returnValue += `lives elsewhere, i.e. not with me or the respondent`;
            }

            if (child.prp_ord_exist === "yes") {
              returnValue += `, there are child protection orders in place`;
            }
            if (child.prp_ord_fc === "yes") {
              returnValue += `, there are family law orders in place`;
            }

            // if we are here there must be orders on the child
            return returnValue + ".\n";
          });
      },
    },
    title: "Children",
    template:
      "We have <%= children.length %> <%= child_children %>. \n\n <% _.forEach(child_list, (c) => { %><%- c %>\n <% }) %>",
  },
  {
    title: "Most recent family violence",
    template:
      "<%= incident_rec_date %>\n<%= _.lowerFirst(incident_rec_desc) %>",
  },
  {
    title: "Previous family violence",
    conditions: {
      incident_prev_fv: "yes",
    },
    template: "<%= _.lowerFirst(incident_prev_fv_time) %>",
  },
  {
    conditions: {
      incident_prev_fv: "yes",
    },
    template: "<%= _.lowerFirst(incident_prev_fv_det) %>",
  },
  {
    conditions: {
      pio_fio: "no",
      pio_gio: "yes",
      pio_bio: "yes",
    },

    template: "The respondent has breached previous intervention orders.",
  },
  {
    conditions: {
      show: (values) =>
        _(values)
          .pick(["incident_phy_recent", "incident_phy_prev"])
          .values()
          .some((v) => v === "yes"),
    },
    template: "<%= incident_phy_detail %>",
  },
  {
    conditions: {
      show: (values) =>
        _(values)
          .pick(["incident_pys_recent", "incident_pys_prev"])
          .values()
          .some((v) => v === "yes"),
    },
    template: "<%= incident_pys_detail %>",
  },
  {
    conditions: {
      show: (values) =>
        _(values)
          .pick(["incident_aep_recent", "incident_aep_prev"])
          .values()
          .some((v) => v === "yes"),
    },
    template: "<%= incident_aep_detail %>",
  },
  {
    conditions: {
      show: (values) =>
        _(values)
          .pick(["incident_cym_recent", "incident_cym_prev"])
          .values()
          .some((v) => v === "yes"),
    },
    template: "<%= incident_cym_detail %>",
  },
  {
    conditions: {
      show: (values) =>
        _(values)
          .pick(["incident_thy_recent", "incident_thy_prev"])
          .values()
          .some((v) => v === "yes"),
    },
    template: "<%= incident_thy_detail %>",
  },
  {
    conditions: {
      show: (values) =>
        _(values)
          .pick(["incident_cbp_recent", "incident_cbp_prev"])
          .values()
          .some((v) => v === "yes"),
    },
    template: "<%= incident_cbp_detail %>",
  },
  {
    conditions: {
      show: (values) =>
        _(values)
          .pick(["incident_fsw_recent", "incident_fsw_prev"])
          .values()
          .some((v) => v === "yes"),
    },
    template: "<%= incident_fsw_detail %>",
  },
  {
    conditions: {
      show: (values) =>
        _(values)
          .pick(["incident_dyp_recent", "incident_dyp_prev"])
          .values()
          .some((v) => v === "yes"),
    },
    template: "<%= incident_dyp_detail %>",
  },
  {
    conditions: {
      show: (values) =>
        _(values)
          .pick(["incident_ryf_recent", "incident_ryf_prev"])
          .values()
          .some((v) => v === "yes"),
    },
    template: "<%= incident_ryf_detail %>",
  },
  {
    conditions: {
      show: (values) =>
        _(values)
          .pick(["incident_htc_recent", "incident_htc_prev"])
          .values()
          .some((v) => v === "yes"),
    },
    template: "<%= incident_htc_detail %>",
  },
  {
    conditions: {
      show: (values) =>
        _(values)
          .pick(["incident_kha_recent", "incident_kha_prev"])
          .values()
          .some((v) => v === "yes"),
    },
    template: "<%= incident_kha_detail %>",
  },
  {
    conditions: {
      show: (values) =>
        _(values)
          .pick(["incident_sty_recent", "incident_sty_prev"])
          .values()
          .some((v) => v === "yes"),
    },
    template: "<%= incident_sty_detail %>",
  },
  {
    conditions: {
      show: (values) =>
        _(values)
          .pick(["incident_fmc_recent", "incident_fmc_prev"])
          .values()
          .some((v) => v === "yes"),
    },
    template: "<%= incident_fmc_detail %>",
  },
  {
    conditions: {
      show: (values) =>
        _(values)
          .pick(["incident_cdw_recent", "incident_cdw_prev"])
          .values()
          .some((v) => v === "yes"),
    },
    template: "<%= incident_cdw_detail %>",
  },
  {
    // if police were contacted but no police station
    conditions: {
      show: (values) =>
        Boolean(
          (values.incident_rec_pc === "yes" ||
            values.incident_prev_pc === "yes") &&
            !values.incident_rec_pc_stn &&
            !values.incident_prev_pc_stn
        ),
    },
    template: "I have reported the family violence to police.",
  },
  {
    // if police were contacted and police station only on recent
    conditions: {
      show: (values) =>
        Boolean(values.incident_rec_pc_stn && !values.incident_prev_pc_stn),
    },
    template:
      "I have reported the family violence to police at <%= incident_rec_pc_stn %> station",
  },
  {
    // if police were contacted and police station only on previous
    conditions: {
      show: (values) =>
        Boolean(!values.incident_rec_pc_stn && values.incident_prev_pc_stn),
    },
    template:
      "I have reported the family violence to police at <%= incident_prev_pc_stn %> station",
  },
  {
    // if police were contacted and police station entered both prev and rec
    // and both police stations are different
    conditions: {
      show: (values) =>
        Boolean(
          values.incident_rec_pc_stn &&
            values.incident_prev_pc_stn &&
            typeof values.incident_rec_pc_stn === "string" &&
            typeof values.incident_prev_pc_stn === "string" &&
            values.incident_rec_pc_stn.toLowerCase() !==
              values.incident_prev_pc_stn.toLowerCase()
        ),
    },
    template:
      "I have reported the family violence to police at <%= incident_rec_pc_stn %> station and <%= incident_prev_pc_stn %> station.",
  },
  {
    // if police were contacted and police station entered both prev and rec
    // and both police stations are the same
    conditions: {
      show: (values) =>
        Boolean(
          values.incident_rec_pc_stn &&
            values.incident_prev_pc_stn &&
            typeof values.incident_rec_pc_stn === "string" &&
            typeof values.incident_prev_pc_stn === "string" &&
            values.incident_rec_pc_stn.toLowerCase() ===
              values.incident_prev_pc_stn.toLowerCase()
        ),
    },
    template:
      "I have reported the family violence to police at <%= incident_rec_pc_stn %> station.",
  },
  {
    conditions: {
      prp_witness: "yes",
    },
    template:
      "A child/children has seen, heard, been exposed to or been present at incidents of family violence.",
  },
  {
    conditions: {
      prp_add_order: "yes",
      prp_witness: "no",
    },
    template:
      "A child/children has not seen, heard, been exposed to or been present at incidents of family violence.",
  },
  {
    conditions: {
      resp_armed: "yes",
    },

    template: "The respondent has access to guns/firearms/weapons",
  },
  {
    conditions: {
      resp_addiction: "yes",
    },

    template: "The respondent has a problem with drugs and/or alcohol.",
  },
  {
    conditions: {
      resp_suicidal: "yes",
    },
    template:
      "The respondent has attempted suicide, hurt or tried to hurt themselves on purpose.",
  },

  {
    conditions: { incident_prev_freq: "yes" },
    template: "Incidents of family violence have increased.",
  },
  {
    conditions: { resp_imm_pro: "yes" },
    template: "I am seeking an interim order for immediate protection.",
  },
];

export default template;
