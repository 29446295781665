import React from "react";
import styled from "styled-components/macro";
import { useFormikContext } from "formik";

import ErrorMessage from "./ErrorMessage";

import getCalculatedCourt from "../helpers/getCalculatedCourt";
import { FlatApplciationType } from "../types";

type Props = {
  namePrefix: string;
};

const QuestionAddressValidator: React.FC<Props> = ({ namePrefix }) => {
  const { values, setFieldValue } = useFormikContext<FlatApplciationType>();

  const addressIsValid = React.useMemo(() => {
    const court = getCalculatedCourt(values, namePrefix);
    if (court === null) {
      return false;
    }

    if (court.enabled === false) {
      return false;
    }
    return true;
  }, [values, namePrefix]);

  // when values are changed update court_address_is_valid
  React.useEffect(() => {
    const val = addressIsValid ? "yes" : "no";
    setFieldValue("court_address_is_valid", val);
    const c = getCalculatedCourt(values, namePrefix);
    setFieldValue("CourtID", c ? c.court_id : null);
  }, [addressIsValid, setFieldValue, values, namePrefix]);

  const applicantAddressComplete =
    `${namePrefix}_sub` in values && `${namePrefix}_postcode` in values;

  return React.useMemo(() => {
    if (!addressIsValid && applicantAddressComplete) {
      return (
        <Wrap>
          <HiddenInput
            name="court_address_is_valid"
            id="court_address_is_valid"
          />
          <ErrorMessage>
            The online form is not currently supported in your area. Please
            check that the postcode is a valid postcode for Victoria.
            <br />
            <br />
            If you are in immediate danger Call 000, or if it's safe for you, go
            to your nearest police station or contact your local court during
            business hours.
            <br />
            <br />
            You can download the paper application from the MCV website{" "}
            <a
              href="https://mcv.vic.gov.au/form-finder/application-family-violence-intervention-order"
              title="MCV Find Court"
            >
              https://mcv.vic.gov.au/form-finder/application-family-violence-intervention-order
            </a>
          </ErrorMessage>
        </Wrap>
      );
    }
    return null;
  }, [addressIsValid, applicantAddressComplete]);
};

export default QuestionAddressValidator;

const Wrap = styled.div`
  margin-bottom: 32px;
`;

const HiddenInput = styled.input`
  height: 1px;
  margin: 0;
  padding: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;
