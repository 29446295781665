import React from "react";

import LongFootnote from "../../../components/LongFootnote";
import PageTitle from "../../../components/PageTitle";
import QuestionsFrame from "../../../components/QuestionsFrame";
import RadioGroup from "../../../components/RadioGroup";

const RelatedOrders: React.FC = () => {
  return (
    <>
      <PageTitle heading="Other court matters" />
      <QuestionsFrame>
        <RadioGroup 
          name="order_exist_cm"
          optionChildren={{
            yes:<LongFootnote name="order_exist_cm_yes" charLimit={150} />
          }}
        />
        <RadioGroup name="order_exist_changed_other_terr"/>
        <RadioGroup name="order_exist_made_other_terr"/>
        <RadioGroup name="order_exist_curr_app_chng_other_terr"/>
      </QuestionsFrame>
    </>
  );
};

export default RelatedOrders;
