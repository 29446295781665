import React from "react";
import styled, { css } from "styled-components/macro";
import { rem } from "polished";

import media from "../theme/media";
import { ReactComponent as PrivacyIcon } from "../assets/icons/Privacy.svg";
import Info from "./Info";
import processHelpText, { ProcessedHelpText } from "../helpers/processHelpText";
import { FlatApplciationType } from "../types";
import { useFormikContext } from "formik";

type Props =
  | {
      privateFrame?: undefined;
      lessPadding?: boolean;
      mbless?: boolean;
      privateHeading?: never;
    }
  | {
      privateFrame: true;
      lessPadding?: boolean;
      mbless?: boolean;
      privateHeading?: string;
    };

const QuestionsFrame: React.FC<Props> = ({
  privateFrame,
  children,
  lessPadding,
  mbless = false,
  privateHeading,
}) => {

  const { values } = useFormikContext<FlatApplciationType>();
  
  // assume single question
  let copy =
          values.seeking === "resp"?  "Private question - the other parties on the intervention order will not see these answers. ||":
          "Private question - the ||respondent|Respondent|A person who has an application for an intervention order made against them.|| will not see these answers.";
  // if multiple children
  if (Array.isArray(children)) {
    copy =
    values.seeking === "resp"? 
      "Private area - the other parties on the intervention order will not see these answers. ||":
      "Private area - the ||respondent|Respondent|A person who has an application for an intervention order made against them.|| will not see these answers.";
  }

  if (privateHeading) {
    copy = privateHeading;
  }

  const processedText = React.useMemo<ProcessedHelpText | false>(
    () => processHelpText(copy),
    [copy]
  );

  if (processedText) {
    let { after, before } = processedText;

    return (
      <Wrapper
        privateFrame={privateFrame}
        lessPadding={lessPadding}
        mbless={mbless}
      >
        {privateFrame && (
          <PrivateHeader>
            <PrivacyIcon />
            <div>
              {before && before}
              <Info inherit string="" {...processedText} />
              {after && after}
            </div>
          </PrivateHeader>
        )}
        {children}
      </Wrapper>
    );
  }

  return (
    <Wrapper
      privateFrame={privateFrame}
      lessPadding={lessPadding}
      mbless={mbless}
    >
      {children}
    </Wrapper>
  );
};

export default QuestionsFrame;

const Wrapper = styled.fieldset<{
  mbless?: boolean;
  privateFrame?: boolean;
  lessPadding?: boolean;
}>`
  width: 100%;
  max-width: 680px;
  padding: 0 15px 0;
  margin-bottom: ${({ mbless }) => (mbless ? "0px" : rem(32))};
  border: none;
  position: relative;
  margin-inline-start: 0;
  margin-inline-end: 0;
  ${media.greaterThan("tablet")`
    padding: 0 17px 0;
  `}
  ${({ privateFrame }) =>
    privateFrame &&
    css<{ lessPadding?: boolean }>`
      border: 1px dashed rgba(27, 164, 178, 0.3);
      padding-top: ${({ lessPadding }) =>
        lessPadding ? `${rem(67)} !important` : `${rem(78)} !important`}};
    `}
`;

const PrivateHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 48px;
  background-color: rgba(199, 239, 243, 0.35);
  padding: 5px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  line-height: 1;
  > svg {
    height: 1.07em;
    width: 1.07em;
    margin-right: 1em;
  }

  > svg + * {
    width: calc(100% - 2.07em);
  }
`;
