import { ApplicationDataType, ChildDataType, ConditionFunction } from "csv-package";
import _ from "lodash";


  export const hasOnlyAddChildren: ConditionFunction = (values: ChildDataType | ApplicationDataType) => {
    const children  = values.children as ChildDataType[];
    return _.some(children, {child_action: 'add'}) && !_.some(children, {child_action: 'remove'});
  }

  export const hasOnlyRemoveChildren: ConditionFunction = (values: ChildDataType | ApplicationDataType) => {
    const children  = values.children as ChildDataType[];
    return _.some(children, {child_action: 'remove'}) && !_.some(children, {child_action: 'add'});
  }

  export const hasAddAndRemoveChildren: ConditionFunction = (values: ChildDataType | ApplicationDataType) => {
    const children  = values.children as ChildDataType[];
    return _.some(children, {child_action: 'add'}) && _.some(children, {child_action: 'remove'});
  }