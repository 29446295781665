import React from "react";
import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";

import media from "../theme/media";
import colors from "../theme/colors";
import Hamburger from "./Hamburger";

import SavingIndicator from "./SavingIndicator";

import { hasAnonymousIdentity } from "../helpers/auth";
import { CrumbType } from "../types";

type Props = {
  saving?: boolean;
  crumbs: CrumbType[];
  hideSaveIndicator?: boolean;
};

const MenuBar: React.FC<Props> = ({ saving, crumbs, hideSaveIndicator }) => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const toggleMenu = React.useCallback((e) => setMenuOpen((bool) => !bool), []);

  React.useEffect(() => {
    return () => setMenuOpen(false);
  }, [setMenuOpen]);

  return (
    <Wrapper>
      <Inner>
        <Item>
          <Hamburger onClick={toggleMenu} menuOpen={menuOpen} />
          Menu
        </Item>
        <PopOutMenu open={menuOpen}>
          {hasAnonymousIdentity() ? (
            <Col>
              <Link to="/convert">Create Account</Link>
              <a
                href="https://www.mcv.vic.gov.au/going-court/find-court"
                target="_blank"
                rel="noopener noreferrer"
                title="Find a court"
              >
                Find a court
              </a>
              <a
                href="https://www.dvrcv.org.au/support-services/victorian-services"
                target="_blank"
                rel="noopener noreferrer"
                title="Find a support agency"
              >
                Find a support agency
              </a>
            </Col>
          ) : (
            <Col>
              {/* <Link to="/account">Manage account</Link>
                <br /> */}

              <Link to="/applications">Applications</Link>
              <Link to="/profile">Edit profile</Link>
              <a
                href="https://www.mcv.vic.gov.au/going-court/find-court"
                target="_blank"
                rel="noopener noreferrer"
                title="Find a court"
              >
                Find a court
              </a>
              <a
                href="https://www.dvrcv.org.au/support-services/victorian-services"
                target="_blank"
                rel="noopener noreferrer"
                title="Find a support agency"
              >
                Find a support agency
              </a>
              <Link to="/logout">Log out</Link>
            </Col>
          )}
        </PopOutMenu>
        <Breadcrumbs aria-live="assertive">
          {hasAnonymousIdentity() && (
            <Crumb>
              <Link to="/convert">Create Profile</Link>
            </Crumb>
          )}
          {crumbs.map((crumb) => (
            <Crumb key={crumb.text}>
              {crumb.url ? (
                <Link to={crumb.url}>{crumb.text}</Link>
              ) : (
                <span>{crumb.text}</span>
              )}
            </Crumb>
          ))}
        </Breadcrumbs>
        {!hideSaveIndicator && (
          <SavingIndicator savingInProgress={saving || false} />
        )}
      </Inner>
    </Wrapper>
  );
};
export default MenuBar;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.header`
  width: 100%;
  height: 50px;
  background: ${colors.blue};
  padding: 0 15px;
  position: fixed;
  top: 58px;
  left: 0;
  z-index: 101;
`;

const Inner = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1310px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;

  ${media.greaterThan("tablet")`
    justify-content: flex-start;
  `}
`;

const Item = styled.a`
  color: white;
  font-weight: bold;
  font-size: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  > svg {
    margin-right: 9px;
  }
  & + & {
    margin-left: 1.5em;
  }
`;

const Breadcrumbs = styled.ul`
  padding: 0;
  margin: 0;
  color: white;
  display: flex;
  flex-direction: row;
  ${media.greaterThan("tablet")`
    margin-left: 1.5em;
  `}
`;

const Crumb = styled.li`
  display: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 15px;
  &:after {
    content: "";
    display: inline-block;
    height: 12px;
    width: 8px;
    background: url('data:image/svg+xml;utf8,<svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.19583 6.23715L0 1.47431L1.35145 0L8.15562 6.23715L1.35145 12.4743L0 11L5.19583 6.23715Z" fill="white"/></svg>');
    vertical-align: middle;
    margin: 0 12px 0;
  }

  > a {
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }

  &:last-child {
    font-weight: normal;
  }

  &:last-child:after {
    display: none;
  }

  &:last-child {
    display: block;
  }

  ${media.greaterThan("tablet")`
    display: block;
  `}
`;

const PopOutMenu = styled.div<{ open: boolean }>`
  width: 100%;
  max-width: 300px;
  padding: 26px 24px;
  background: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 101;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all linear 0.2s;
  transform: translateX(-110%);
  font-weight: 700;
  font-size: 14px;
  line-height: 2;

  ${({ open }) =>
    open &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      transform: translateX(0);
    `};

  a:hover {
    text-decoration: none;
  }
`;
