import { useField } from "formik";
import React from "react";
import styled from "styled-components";
import useQuestionConfig from "../hooks/useQuestionConfig";
import inputStyles from "../theme/inputStyles";
import { Wrapper } from "./Question";
import FormLabel from "./FormLabel";
import FormNote from "./FormNote";
import ErrorMessage from "./ErrorMessage";
import IconButton from "./IconButton";
import { reach, object, StringSchema } from "yup";

type Props = {
  validation: StringSchema;
  lockedName: string;
  label?: string;
  note?: string;
};

const LockedQuestion: React.FC<Props> = ({
  lockedName,
  validation,
  ...rest
}) => {
  const [field, meta, helpers] = useField<string>(lockedName);
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null | undefined>(null);
  const [questionState, setQuestionState] = React.useState<string>(
    field.value || meta.value || ""
  );
  const { label, note } = useQuestionConfig(lockedName, rest);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const schema = object().shape({ [lockedName]: validation });
    const reachedSchema = reach(schema, lockedName);

    try {
      await reachedSchema.validate(value);
      setError(null);
    } catch (error: any) {
      setError(error.errors[0]);
    }
    setQuestionState(value);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // if edit more is true save to formik
    if (editMode) {
      helpers.setValue(questionState);
    }
    setEditMode((m) => !m);
  };

  return (
    <Wrapper>
      {label && <FormLabel name={lockedName} text={label} />}
      {note && <FormNote text={note} />}
      {Boolean(error) && <StyledErrorMessage>{error}</StyledErrorMessage>}
      <Holder>
        <Input
          name="locked_field"
          value={questionState}
          onChange={handleChange}
          disabled={!editMode}
        />
        <IconButton
          icon={editMode ? "tick" : "edit"}
          label={editMode ? "Save" : "Edit"}
          onClick={handleClick}
          disabled={Boolean(error)}
        />
      </Holder>
    </Wrapper>
  );
};

export default LockedQuestion;

const Input = styled.input`
  ${inputStyles}
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-bottom: 13px;
`;

const Holder = styled.div`
  position: relative;
  > button {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }
`;
