import React from "react";
import styled from "styled-components/macro";
import { useField } from "formik";

import { ReactComponent as TickIcon } from "../assets/icons/Tick.svg";
import { Check } from "./Checkbox";
import { Note } from "./FormNote";
import { Label } from "./FormLabel";
import useQuestionConfig from "../hooks/useQuestionConfig";

type Props = {
  name: string;
  label?: string;
  definition?: string;
  value?: string;
  note?: string;
};

const CheckboxAdvanced: React.FC<Props> = ({
  name,
  definition,
  value = "yes",
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const { label, note } = useQuestionConfig(name, rest);

  return (
    <Wrapper>
      <CheckboxContainer>
        <input
          type="checkbox"
          name={name}
          id={name}
          value={value}
          onChange={() => {
            helpers.setValue(value === meta.value ? "" : value);
          }}
          checked={value === field.value}
        />
        <Check htmlFor={name} alternative={Boolean(definition)}>
          <TickIcon />
        </Check>
      </CheckboxContainer>
      <LablesContainer>
        <Label htmlFor={name}>{label}</Label>
        <Note>{note}</Note>
      </LablesContainer>
    </Wrapper>
  );
};

export default CheckboxAdvanced;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
  input {
    opacity: 0;
    position: absolute;
  }
  :last-child {
    width: calc(100% - 80px);
  }
`;

const CheckboxContainer = styled.div`
  width: 36px;
`;

const LablesContainer = styled.div`
  width: calc(100% - 36px);
`;
