import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import ReactGA from "react-ga";
import Amplify from "aws-amplify";

import GlobalStyles from "./theme/globalStyles";
import TopBar from "./components/TopBar";
import QuickExit from "./components/QuickExit";

import Login from "./pages/Login";
import { signOut } from "./helpers/auth";
import ProtectedRoute from "./components/ProtectedRoute";
import ApplicationCreate from "./pages/ApplicationCreate";
import ApplicationCreateAnonymous from "./pages/ApplicationCreateAnonymous";
import Footer from "./components/Footer";
import BackToTop from "./components/BackToTop";
import Signup from "./pages/Signup";
import Applications from "./pages/Applications";
import Profile from "./pages/Profile";
import ReEntry from "./pages/ReEntry";
import { config } from "./config/systemConfig";

// Setup
const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

// GA
ReactGA.initialize(process.env.REACT_APP_GA_UA || "");

// setup auth
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_APPLICANT_IDENTITY_POOL_ID,
    region: "ap-southeast-2",
    userPoolId: process.env.REACT_APP_APPLICANT_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_APPLICANT_USER_POOL_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "csv",
        endpoint: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_STAGE}`,
        region: "ap-southeast-2",
      },
    ],
  },
});

const App: React.FunctionComponent<{}> = () => (
  <>
    <GlobalStyles />
    <Router>
      <Route path="/quick" exact component={QuickExit} />
      <TopBar />
      <Route component={ScrollToTop} />
      <Switch>
        {/* Override routes from config */}
        {config.overrideRoutes &&
          config.overrideRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          ))}

        {/* Universal */}
        <Route path="/login" exact component={Login} />
        <Route
          path="/logout"
          exact
          render={() => {
            signOut();
            return <Redirect to="/" />;
          }}
        />
        <ProtectedRoute
          path="/application/create"
          exact
          component={ApplicationCreate}
        />
        <Route
          path="/application/create/anonymous"
          exact
          component={ApplicationCreateAnonymous}
        />
        <Route path="/signup" exact component={Signup} />
        <Route path="/convert" exact component={Signup} />
        <ProtectedRoute path="/applications" exact component={Applications} />
        <Route
          path="/application"
          exact
          render={() => {
            return <Redirect to="/applications" />;
          }}
        />
        <ProtectedRoute path="/profile" exact component={Profile} />

        {/* Re Declare stuff */}
        <Route path="/r/:base64key" exact component={ReEntry} />

        {/* Variable */}
        <Route path="/" exact component={config.homePage} />
        <ProtectedRoute
          path="/application/:id"
          exact
          component={config.application}
        />

        {/* Custom routes defined in application type config */}
        {config.customRoutes &&
          config.customRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          ))}
      </Switch>
      <Footer />
    </Router>
    <BackToTop />
  </>
);

export default App;
