import React from "react";
import _ from "lodash";
import styled from "styled-components";

import FormLabel from "../../../components/FormLabel";
import LongFootnote from "../../../components/LongFootnote";
import PageTitle from "../../../components/PageTitle";
import Question from "../../../components/Question";
import QuestionAccordion from "../../../components/QuestionAccordion";
import QuestionsFrame from "../../../components/QuestionsFrame";
import QuestionSpacer from "../../../components/QuestionSpacer";
import RadioGroup from "../../../components/RadioGroup";
import SubHeading from "../../../components/SubHeading";
import ConditionalFieldsWrapper from "../../../components/ConditionalFieldsWrapper";
import ApplicationContext from "../../../reducers/applicationFrame";
import NarrativeCharsLeftIndicator from "../../../components/NarrativeCharsLeftIndicator";
import { useFormikContext } from "formik";
import { FlatApplciationType } from "../../../types";
import colors from "../../../theme/colors";

const IncidentDetails = () => {
  const {
    state: { narrativeLimit, narrativeKeys },
  } = React.useContext(ApplicationContext);
  const { values } = useFormikContext<FlatApplciationType>();

  return (
    <>
      <PageTitle
        heading="Incident details"
        description={`In the following section, please describe what happened using no more than ${narrativeLimit} characters.`}
      />

      <QuestionsFrame>
        <FormLabel text="Has the respondent..." />
        <QuestionAccordion namePrefix="incident_phy" startOpen />
        <QuestionAccordion namePrefix="incident_pys" />
        <QuestionAccordion namePrefix="incident_aep" />
        <QuestionAccordion namePrefix="incident_cym" />
        <QuestionAccordion namePrefix="incident_thy" />
        <QuestionAccordion namePrefix="incident_cbp" />
        <QuestionAccordion namePrefix="incident_fsw" />
        <QuestionAccordion namePrefix="incident_dyp" />
        <QuestionAccordion namePrefix="incident_ryf" />
        <QuestionAccordion namePrefix="incident_htc" />
        <QuestionAccordion namePrefix="incident_kha" />
        <QuestionAccordion namePrefix="incident_sty" />
        <QuestionAccordion namePrefix="incident_fmc" />
        <QuestionAccordion namePrefix="incident_cdw" />
      </QuestionsFrame>
      <NarrativeCharsLeftIndicator />
      <QuestionSpacer />
      <SubHeading heading="Most recent family violence incident" />
      <QuestionsFrame>
        <Question name="incident_rec_date" />
        <LongFootnote name="incident_rec_desc" />
      </QuestionsFrame>
      <QuestionsFrame privateFrame>
        <RadioGroup name="incident_rec_pc" />

        <ConditionalFieldsWrapper conditions={{ incident_rec_pc: "yes" }}>
          <Question name="incident_rec_pc_stn" />
          <Question name="incident_rec_pc_ofc" />
        </ConditionalFieldsWrapper>
      </QuestionsFrame>
      <SubHeading heading="History of family violence" />
      <QuestionsFrame>
        <RadioGroup name="incident_prev_fv" />

        <ConditionalFieldsWrapper conditions={{ incident_prev_fv: "yes" }}>
          <Question name="incident_prev_fv_time" />
          <LongFootnote name="incident_prev_fv_det" />
          <RadioGroup name="incident_prev_pc" />

          <ConditionalFieldsWrapper conditions={{ incident_prev_pc: "yes" }}>
            <Question name="incident_prev_pc_stn" />
            <Question name="incident_prev_pc_ofc" />
          </ConditionalFieldsWrapper>
          <RadioGroup name="incident_prev_freq" />
        </ConditionalFieldsWrapper>
      </QuestionsFrame>
      {!_.isEmpty(narrativeKeys) &&
        typeof values.NarrativeCharsLeft === "number" &&
        values.NarrativeCharsLeft < 0 && (
          <NarrativeCharsOverWarning
            style={{ color: colors.error }}
            name="NarrativeCharsLeft"
          >
            Incident details when combined are over {narrativeLimit} characters.
            Please review and reduce the size of these descriptions.
          </NarrativeCharsOverWarning>
        )}
    </>
  );
};

export default IncidentDetails;

const NarrativeCharsOverWarning = styled.fieldset`
  background: #f8d9dd;
  color: ${colors.error};
  padding: 15px;
  border: none;
  margin-bottom: 2rem;
  span {
    text-decoration: underline;
    cursor: pointer;
    :hover {
      text-decoration: none;
    }
  }
`;
