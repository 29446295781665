import React from "react";
import CheckboxGroup from "../../../components/CheckboxGroup";
import LongFootnote from "../../../components/LongFootnote";
import PageTitle from "../../../components/PageTitle";
import QuestionsFrame from "../../../components/QuestionsFrame";
import RadioGroup from "../../../components/RadioGroup";
import SubHeading from "../../../components/SubHeading";

const Conditions = () => {
  return (
    <>
      <PageTitle
        heading="Conditions of the order"
        description={
          <div>
            <p>
              Provide details to ensure the right level of protection is
              mandated in your intervention order for your safety.
            </p>
            <p>
              You may choose as many conditions as you like from the list, but
              the magistrate may not include all the conditions you choose on
              the order. If there is something you do not want the respondent to
              do which is not covered in this list, you should discuss this with
              the Court Registrar (and note it in the box provided).
            </p>
          </div>
        }
      />
      <QuestionsFrame>
        <CheckboxGroup name="cond_prevent" />
      </QuestionsFrame>

      <SubHeading heading="Exemptions" />
      <QuestionsFrame>
        <CheckboxGroup name="cond_exempt" compact />
      </QuestionsFrame>

      <SubHeading heading="Firearms and weaponry" />
      <QuestionsFrame>
        <CheckboxGroup name="cond_firearm_weapon" compact />
      </QuestionsFrame>
      <SubHeading heading="Your safety" />
      <QuestionsFrame>
        <RadioGroup name="cond_interim_sort" />
      </QuestionsFrame>
      <SubHeading heading="Additional information" />
      <QuestionsFrame>
        <LongFootnote name="cond_other" />
      </QuestionsFrame>
    </>
  );
};

export default Conditions;
