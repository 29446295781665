import _ from "lodash";
import {
  ConditionsType,
  SchemaType,
  ApplicationDataType,
  conditionsChecker,
} from "csv-package";

import { FlatApplciationType } from "../../types";
import calculateTempateValues from "./calculateTemplateValues";

export type MapType = {
  [key: string]: MapType | string;
};

type NarrativeTempateVariableFunctionType = (
  values: ApplicationDataType
) => string | string[];

type NarrativeTempateVariableType = {
  [key: string]: NarrativeTempateVariableFunctionType;
};

export type NarrativeTemplateType = {
  title?: string;
  template: string;
  conditions?: ConditionsType;
  variables?: NarrativeTempateVariableType;
};

const narrativeGenerator = (
  values: FlatApplciationType,
  template: NarrativeTemplateType[],
  schema: SchemaType,
  html: boolean = false
): string => {
  const newLine = html ? "<br />" : "\n";

  let finalTemplateString = "";

  const templateValues = calculateTempateValues(values, schema);

  template.forEach((templateSection) => {
    // check if this template section should be included
    if (templateSection.conditions !== undefined) {
      const conditionsPassed = conditionsChecker(
        templateSection.conditions,
        values
      );

      if (!conditionsPassed) {
        // exit if conditions don't pass
        return;
      }
    }

    // Check for any variables in the template section add them to templateValues
    try {
      if (templateSection.variables !== undefined) {
        Object.keys(templateSection.variables).forEach((key) => {
          if (templateSection.variables === undefined) {
            return;
          }
          templateValues[key] = templateSection.variables[key](values);
        });
      }
    } catch (e) {
      // do nothing - skip
    }

    // add title for HTML version
    if (html && "title" in templateSection) {
      finalTemplateString += `<h3>${templateSection.title}</h3>`;
    }

    if (templateSection.template === "") {
      return;
    }

    // add single templte to bigger template
    try {
      const complied = _.template(templateSection.template);
      finalTemplateString += complied(templateValues);
    } catch (e) {
      //do nothing
    }

    finalTemplateString += `${newLine}${newLine}`;
  });

  if (html) {
    finalTemplateString = finalTemplateString.split("\n").join("<br />");
  }

  return finalTemplateString;
};

export default narrativeGenerator;
