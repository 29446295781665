import Yup, { generateAddressValidation } from '../yup';

const respondent = {
  resp_giv_nam: Yup.string()
    .csvGivenName()
    .required("Please enter N/A if you're unsure"),
  resp_giv_nam2: Yup.string().csvGivenName(),
  resp_giv_nam3: Yup.string().csvGivenName(),
  resp_fam_nam: Yup.string()
    .trim()
    .required("Please enter N/A if you're unsure"),
  appl_rel_to_resp: Yup.string()
    .trim()
    .required('We require this information'),
  resp_known_lbl: Yup.string()
    .required('Please enter a number and select a time period.')
    .csvDuration(),
  resp_sep: Yup.string().test('', '', function(value) {
    const { path, parent, createError } = this;
    if (
      ['SP', 'DP', 'IPR', 'FS', 'FIP', 'DPF'].includes(parent.appl_rel_to_resp)
    ) {
      return value
        ? true
        : createError({ path, message: 'We require this information' });
    }
    return true;
  }),
  resp_sep_inf: Yup.string().when('resp_sep', {
    is: 'no',
    then: Yup.string().required('We require this information'),
  }),

  resp_sep_long_lbl: Yup.string().when('resp_sep', {
    is: 'yes',
    then: Yup.string()
      .csvDuration()
      .required('We require this information'),
  }),

  resp_sex: Yup.string()
    .trim()
    .required('We require this information'),
  resp_sex_other: Yup.string().when('resp_sex', {
    is: 'U',
    then: Yup.string().required('We require this information'),
  }),
  resp_dob_known: Yup.string()
    .trim()
    .required('We require this information'),
  resp_dob: Yup.string().when('resp_dob_known', {
    is: 'yes',
    then: Yup.string()
      .csvDate()
      .csvDobOver18()
      .required('Please enter a DOB'),
  }),

  resp_age: Yup.string().when('resp_dob_known', {
    is: 'no',
    then: Yup.string()
      .required('Please enter an aproximate age')
      .matches(
        /^[0-9]{2,3}$/,
        "The respondent's aproximate must be a number (18-115)."
      )
      .test({
        name: 'over-18',
        test: value =>
          typeof value === 'string' ? parseInt(value, 10) >= 18 : false,
        message:
          'The respondent must be 18 years of age or older. If they are under 18 please contact the MCV.',
      })
      .test({
        name: 'under-115',
        test: value =>
          typeof value === 'string' ? parseInt(value, 10) < 115 : false,
        message: 'Age must be below 115 years old.',
      }),
  }),
  resp_abor_code: Yup.string()
    .trim()
    .required('We require this information'),
  resp_det_inter: Yup.string()
    .trim()
    .required('We require this information'),
  resp_det_lang: Yup.string().when('resp_det_inter', {
    is: 'yes',
    then: Yup.string().required('We require this information'),
  }),
  resp_det_lang_other: Yup.string().when(['resp_det_inter', 'resp_det_lang'], {
    is: (a: string, b: string) => a === 'yes' && b === 'Other',
    then: Yup.string()
      .trim()
      .required('Please enter a language'),
  }),
  resp_addr_known: Yup.string()
    .trim()
    .required('We require this information'),
  resp_addr_type: Yup.string().when('resp_addr_known', {
    is: 'other',
    then: Yup.string().required('Please enter address type'),
  }),

  resp_addr_type_other: Yup.string().when(
    [`resp_addr_known`, `resp_addr_type`],
    {
      is: (one: string, two: string) => one === 'other' && two === 'other',
      then: Yup.string().required('Please enter address type'),
    }
  ),
  ...generateAddressValidation('resp_det_addr', 'resp_addr_known', 'other'),
  resp_contact_type: Yup.array()
    .ensure()
    .min(1, 'Please select at least on contact method'),

  resp_contact_ph_3: Yup.string().when('resp_contact_type', {
    is: (resp_contact_type: string[]) =>
      resp_contact_type && resp_contact_type.includes('phone'),
    then: Yup.string()
      .required('Mobile phone number is required')
      .ausMobilePhone(),
  }),

  resp_contact_ph: Yup.string().ausPhone(),

  resp_contact_ph_2: Yup.string().ausPhone(),

  resp_contact_email: Yup.string().when('resp_contact_type', {
    is: (a: string) => a === 'email',
    then: Yup.string()
      .required('Not a valid email')
      .email(),
  }),

  resp_contact_email_2: Yup.string().email('Not a valid email'),

  resp_contact_other: Yup.string().when('resp_contact_type', {
    is: (a: string) => a === 'other',
    then: Yup.string().required('Please enter any information you can here'),
  }),
  resp_imm_pro: Yup.string()
    .trim()
    .required('We require this information'),
  resp_armed: Yup.string()
    .trim()
    .required('We require this information'),
  resp_armed_detail: Yup.string().when('resp_armed', {
    is: 'yes',
    then: Yup.string()
      .trim()
      .required('Please enter any information you can here'),
  }),
  resp_armed_loc: Yup.string().when('resp_armed', {
    is: 'yes',
    then: Yup.string()
      .trim()
      .required('Please select a gun location option.'),
  }),
  ...generateAddressValidation('resp_armed_loc_add', 'resp_armed_loc', 'other'),
  resp_addiction: Yup.string()
    .trim()
    .required('We require this information'),
  resp_suicidal: Yup.string()
    .trim()
    .required('We require this information'),
};

export default respondent;
