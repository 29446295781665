import React from "react";
import styled, { css } from "styled-components/macro";
import { rem } from "polished";

import { ReactComponent as Print } from "../assets/icons/Print.svg";
import { ReactComponent as Plus } from "../assets/icons/Plus.svg";
import { ReactComponent as Edit } from "../assets/icons/Edit.svg";
import { ReactComponent as Delete } from "../assets/icons/Delete.svg";
import { ReactComponent as TickFilled } from "../assets/icons/TickFilled.svg";
import { ReactComponent as Chevron } from "../assets/icons/chevron.svg";
import colors from "../theme/colors";

const iconMap = {
  print: <Print />,
  plus: <Plus />,
  edit: <Edit />,
  tick: <TickFilled />,
  delete: <Delete />,
  cross: (
    <div
      className="icon-container"
      style={{ transform: "rotate(45deg)", color: "inherit" }}
    >
      <Plus />
    </div>
  ),
  chevron: <Chevron />,
};

type Props = {
  icon: keyof typeof iconMap;
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  alert?: boolean;
  rotateIcon?: boolean;
};

const IconButton: React.FC<Props> = ({
  icon,
  label,
  onClick,
  disabled,
  alert,
  rotateIcon,
}) => (
  <StyledButton
    onClick={onClick}
    disabled={disabled}
    alert={alert}
    rotateIcon={rotateIcon}
  >
    {iconMap[icon] ? (
      <div className="icon-container">{iconMap[icon]}</div>
    ) : null}

    {label}
  </StyledButton>
);

export default IconButton;

const StyledButton = styled.button<{ alert?: boolean; rotateIcon?: boolean }>`
  padding: 0px;
  font-size: 1rem;
  color: ${({ alert }) => (alert ? colors.error : colors.blue)};
  background-color: transparent;
  display: flex;
  align-items: center;
  border: none;
  text-decoration: underline;

  &:disabled {
    color: #b3b3b3;
    cursor: not-allowed;
  }

  .icon-container {
    display: inline-flex;
    align-items: center;
    margin-right: ${rem(5)};
    ${({ rotateIcon }) =>
      rotateIcon &&
      css`
        transform: rotate(45deg);
      `}
  }
  cursor: pointer;

  svg {
    height: 0.9em;
    width: 0.9em;
    fill: currentColor;
  }
`;
