import { useFormikContext } from "formik";
import React from "react";
import _ from "lodash";

import { FlatApplciationType } from "../types";
import { NarrativeLimitConfigType } from "./ApplicationFrame";

type Props = {
  config: NarrativeLimitConfigType;
};

const NarrativeCharsCalculator: React.FC<Props> = ({ config }) => {
  const { values, setFieldValue } = useFormikContext<FlatApplciationType>();

  const { keys, limit } = config;

  React.useEffect(() => {
    const count = keys.reduce((count, key): number => {
      let currentValue: string | any[] | null = [];

      if(key.substring(0, 9) === "children["){
        const children = _.get(values, 'children', []);
        const option = key.split('.')[1];

        children.forEach(child => {
          const value = child[option];
          if (typeof value === "string") {
            currentValue += value;
          }
        });
      } else {
        currentValue = _.get(values, key, "");
      }

      if (typeof currentValue === "string") {
        return count + currentValue.length;
      }
      return count;
    }, 0);

    const number = limit - count;

    setFieldValue("NarrativeCharsLeft", number);
  }, [setFieldValue, keys, limit, values]);

  return null;
};

export default NarrativeCharsCalculator;
