import { useFormikContext } from "formik";
import React from "react";
import _ from "lodash";

import Child from "./Child";
import { FlatApplciationType } from "../../../types";
import isStrings from "../../../helpers/isStrings";

const Children: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<FlatApplciationType>();

  const addChild = React.useCallback(() => {
    const newChild = {
      prp_giv_name: "",
    };
    if (_.isEmpty(values.children)) {
      setFieldValue("children", [newChild]);
    } else {
      setFieldValue("children", [...values.children, newChild]);
    }
  }, [values.children, setFieldValue]);

  const removeChild = React.useCallback(
    (childIndex) => {
      if (values.children.length <= 1) {
        setFieldValue("children", []);
        setFieldValue("prp_add_order", "no");
      } else {
        setFieldValue("children", [
          ...values.children.slice(0, childIndex),
          ...values.children.slice(childIndex + 1),
        ]);
      }
    },
    [values.children, setFieldValue]
  );

  const removeAllChildren = React.useCallback(() => {
    setFieldValue("children", []);
  }, [setFieldValue]);

  const code: string = values.seeking === "resp" ? "RC" : "AC";

  // look for changes to to app_types
  React.useEffect(() => {
    // add blank child
    if (
      Array.isArray(values.app_types) &&
      isStrings(values.app_types) &&
      values.app_types.includes(code) &&
      _.isEmpty(values.children)
    ) {
      addChild();
    }

    // remove all children when C is deselected
    if (
      Array.isArray(values.app_types) &&
      isStrings(values.app_types) &&
      !values.app_types.includes(code)
    ) {
      removeAllChildren();
    }
  }, [values.app_types, values.children, addChild, removeAllChildren, code]);

  if (!Array.isArray(values.children)) {
    return null;
  }

  return (
    <>
      {values.children.map((_, index) => (
        <Child
          key={index}
          childIndex={index}
          addChild={addChild}
          removeChild={removeChild}
        />
      ))}
    </>
  );
};

export default Children;
