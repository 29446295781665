import React from "react";
import styled from "styled-components/macro";
import _ from "lodash";

import media from "../theme/media";

type Props = {
  label: string;
  name: string;
  value: string;
  onRadioChange: React.ChangeEventHandler<HTMLInputElement>;
  onRadioBlur: React.FocusEventHandler<HTMLInputElement>;
  checked: boolean;
};

const Radio: React.FC<Props> = React.memo(
  ({ label, name, value, onRadioChange, onRadioBlur, checked = false }) => {
    const id = _.kebabCase(`${name}-${value}`);
    return (
      <Wrapper>
        <input
          type="radio"
          name={name}
          id={id}
          value={value}
          onChange={onRadioChange}
          onBlur={onRadioBlur}
          checked={checked}
        />
        <Check htmlFor={id} />
        <Label htmlFor={id}>{label}</Label>
      </Wrapper>
    );
  }
);

export default Radio;

const Wrapper = styled.div`
  width: 100%;
  min-height: 48px;
  padding: 0 15px 0;
  background: rgba(195, 214, 234, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  position: relative;

  input {
    opacity: 0;
    position: absolute;
  }

  & + & {
    margin-top: 4px;
  }
`;

const Label = styled.label`
  flex: 1;
  font-size: 15px;
  color: #3c3c3c;

  ${media.greaterThan("tablet")`
 font-size: 17px;
    `};
`;

const Check = styled.label`
  height: 17px;
  width: 17px;
  border: 1px solid #868686;
  border-radius: 50%;
  background: white;
  margin-right: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  input:checked ~ & {
    border-color: currentColor;
    color: rgba(14, 93, 171, 0.9701);
    &:before {
      content: "";
      display: block;
      height: 9px;
      width: 9px;
      background: currentColor;
      border-radius: 50%;
    }
    @media print {
      &:before {
        content: "●";
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5em;
      }
    }
  }

  input:focus ~ & {
    box-shadow: 0px 0px 5px 0px rgba(14, 93, 171, 0.7);
  }
`;
