export type ProcessedHelpText = {
  displayText: string;
  headingText: string;
  bodyText: string;
  after: string;
  before: string | null;
};

const processHelpText = (str: string): ProcessedHelpText | false => {
  const helpTextReg = /\|(.*)\|/g;
  const helpTextMatch = str.match(helpTextReg);

  if (helpTextReg.test(str)) {
    let arrayStr = str.split(helpTextReg);
    let string = helpTextMatch ? helpTextMatch[0] : "";
    let before;
    let after;
    let helpTextPosition;
    string = string.substr(
      string.indexOf("|") + 1,
      string.lastIndexOf("|") - 1
    );

    helpTextPosition = arrayStr.indexOf(string);

    after = arrayStr.slice(arrayStr.indexOf(string) + 1)[0];
    before = helpTextPosition > 0 ? arrayStr[0] : null;

    const components = string.split("|").filter((str) => str !== "");
    let displayText = components[0];
    let headingText = components[1];
    let bodyText = components[2];

    return {
      displayText,
      headingText,
      bodyText,
      after,
      before,
    };
  }
  return false;
};

export default processHelpText;
