import React from "react";
import { ApplicationType } from "csv-package";

import {
  isAuthenticated,
  hasAnonymousIdentity,
  createAndStoreAnonymousIdentity,
} from "../helpers/auth";
import api from "../helpers/api";

import LoadingIndicator from "../components/LoadingIndicator";

import { useHistory } from "react-router-dom";
import {
  configApplicationType,
  configJurisdiction,
} from "../config/systemConfig";

const ApplicationCreateAnonymous = () => {
  const { replace } = useHistory();

  React.useEffect(() => {
    const createApplication = async () => {
      // if user is logged in send them to applciations
      const isAuth = await isAuthenticated();
      if (isAuth) {
        replace(`/applications`);
      }

      // if user has anon ident send them direct to their application (They should have on already)
      if (hasAnonymousIdentity()) {
        replace(`/applications`);
      }

      // if we are here we can we can create an identity and applciation
      await createAndStoreAnonymousIdentity();

      const Type = configApplicationType;
      const Jurisdiction = configJurisdiction;

      // create applciation
      const result = (await api("post", "/application", {
        Type,
        Jurisdiction,
      })) as ApplicationType;

      if (typeof result.ApplicationID !== "string") {
        throw new Error("Application failed to create");
      }

      // send them to their application
      replace(`/application/${result.ApplicationID}`);
    };
    createApplication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingIndicator />;
};

export default ApplicationCreateAnonymous;
