import React from "react";
import _ from "lodash";
import { useFormikContext } from "formik";

import Child from "./Child";

import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import PageTitle from "../../../components/PageTitle";
import QuestionFrame from "../../../components/QuestionsFrame";
import RadioGroup from "../../../components/RadioGroup";
import { FlatApplciationType } from "../../../types";
import useChildren from "../../../hooks/useChildren";

const Children: React.FC = () => {
  const {
    values: { children, prp_add_order },
    setFieldValue,
  } = useFormikContext<FlatApplciationType>();

  const [confirmModal, setConfirmModal] = React.useState(false);
  const { addChild, removeChild, removeAllChildren } = useChildren();

  // when prp_add_order changes and is yes and children is empty
  React.useEffect(() => {
    if (prp_add_order === "yes" && _.isEmpty(children)) {
      addChild();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prp_add_order]);

  // when prp_add_order yes and children change to []
  React.useEffect(() => {
    if (prp_add_order === "yes" && _.isEmpty(children)) {
      setFieldValue("prp_add_order", "no");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  // when prp_add_order no and there is children data
  React.useEffect(() => {
    if (prp_add_order !== "yes" && !_.isEmpty(children)) {
      setConfirmModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prp_add_order]);

  return (
    <>
      <PageTitle
        heading="Children"
        description="If you want to include a child/children under 18 years of age as
            part of the intervention order, then provide the child’s/children’s
            details here."
      />
      <QuestionFrame>
        <RadioGroup name="prp_witness" />
        <RadioGroup name="prp_add_order" />
      </QuestionFrame>
      {prp_add_order === "yes" &&
        !_.isEmpty(children) &&
        children
          .sort((a, b) => {
            if (typeof a.index === "number" && typeof b.index === "number") {
              return a.index - b.index;
            }
            return 0;
          })
          .map((_, childIndex) => (
            <div key={childIndex}>
              <Child
                showAddButton={
                  childIndex < 7 && children.length - 1 === childIndex
                }
                addChild={addChild}
                childIndex={childIndex}
                removeChild={removeChild}
              />
            </div>
          ))}
      {confirmModal && (
        <Modal
          onClose={(e) => {
            e.preventDefault();
            setConfirmModal(false);
            setFieldValue("prp_add_order", "yes");
          }}
        >
          <p>
            Changing the response to this question will clear all child
            information previously entered.
          </p>
          <div className="buttons">
            <Button
              variant="secondary"
              onClick={(e) => {
                e.preventDefault();
                setConfirmModal(false);
                setFieldValue("prp_add_order", "yes");
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                removeAllChildren();
                setConfirmModal(false);
              }}
            >
              Continue
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Children;
