import React from "react";
import styled from "styled-components/macro";
import { Formik, Form } from "formik";
import { Yup } from "csv-package";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";

import { getUserDetails, updatePassword, updateAttributes } from "../helpers/auth";

import MenuBar from "../components/MenuBar";
import QuestionsFrame from "../components/QuestionsFrame";
import Question from "../components/StandaloneQuestion";
import Button from "../components/Button";

import ErrorMessage from "../components/ErrorMessage";
import PageTitle from "../components/PageTitle";

const Profile: React.FC<{}> = () => {
  const { pathname } = useLocation();

  const [error, setError] = React.useState<boolean>(false);
  const [changingPassword, setChangingPassword] = React.useState<boolean>(false);
  const [details, setDetails] = React.useState<CognitoUserAttribute[] | null>(null);

  React.useEffect(() => {
    const fetchUser = async () => {
      const details = (await getUserDetails()) as CognitoUserAttribute[];
      setDetails(details);
    };
    ReactGA.pageview(pathname);
    fetchUser();
  }, [pathname]);

  const schema = Yup.object({
    given_name: Yup.string().required("Given name is required"),
    email: Yup.string().required("Email is required").email("Must be a vaild email"),
  });

  const passwordsSchema = Yup.object({
    currentPassword: Yup.string().required("Your current password is required to make changes"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Your password must be at least 8 characters long"),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref("password"), undefined], "Passwords must match")
      .required("Passwords must match"),
  });

  if (details === null) {
    return null;
  }

  const foundGivenName = details.find((detail) => detail.Name === "given_name");
  const foundEmail = details.find((detail) => detail.Name === "email");

  if (foundGivenName === undefined || foundEmail === undefined) {
    throw new Error("Could not find given_name or email");
  }

  return (
    <Wrapper>
      <MenuBar crumbs={[{ text: "Profile" }]} hideSaveIndicator />
      <Inner>
        <Formik
          validateOnBlur
          validationSchema={changingPassword ? passwordsSchema : schema}
          isInitialValid={true}
          initialValues={{
            given_name: foundGivenName.Value,
            email: foundEmail.Value,
            currentPassword: "",
            password: "",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setError(false);

            if (changingPassword) {
              try {
                await updatePassword(values.currentPassword, values.password);
                alert("Your password has been updated");
                setSubmitting(false);
              } catch (error: any) {
                alert(error.message);
                setSubmitting(false);
              }
            } else {
              try {
                await updateAttributes(values.email, values.given_name);

                alert("Your details have been updated.");
                setSubmitting(false);
              } catch (e: any) {
                alert(e.message);
                setError(true);
                setSubmitting(false);
              }
            }
          }}
        >
          {(formikProps) => (
            <Form>
              <QuestionsFrame>
                <PageTitle heading="Profile" />
                {!changingPassword && <Question label="Given name" name="given_name" {...formikProps} />}
                {!changingPassword && (
                  <Question
                    label="Email (your username)"
                    name="email"
                    note="Your email address can be a real or a made up email address which will be used as your username. The court will NOT use this email address to contact you about your application."
                    {...formikProps}
                  />
                )}
                {changingPassword && (
                  <Question
                    type="password"
                    label="Current password"
                    name="currentPassword"
                    note="Minimum 8 characters"
                    {...formikProps}
                  />
                )}
                {changingPassword && (
                  <Question
                    type="password"
                    label="New password"
                    name="password"
                    note="Minimum 8 characters"
                    {...formikProps}
                  />
                )}
                {changingPassword && (
                  <Question
                    type="password"
                    label="Re-enter new password"
                    name="passwordConfirmation"
                    {...formikProps}
                  />
                )}
                {changingPassword ? (
                  <Button
                    style={{ display: "block", cursor: "pointer", marginBottom: "1rem" }}
                    variant="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      setChangingPassword(false);
                    }}
                  >
                    Cancel change password
                  </Button>
                ) : (
                  <Button
                    style={{ display: "block", cursor: "pointer", marginBottom: "1rem" }}
                    variant="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      setChangingPassword(true);
                    }}
                  >
                    Change password
                  </Button>
                )}

                {error && (
                  <ErrorMessage>There was an error, please ensure all details are valid</ErrorMessage>
                )}

                <Button disabled={!formikProps.isValid || formikProps.isSubmitting}>Save</Button>
                <br />
                <br />
              </QuestionsFrame>
            </Form>
          )}
        </Formik>
      </Inner>
    </Wrapper>
  );
};
export default Profile;

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const Inner = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 100px 15px 80px 15px;
`;
