import React from "react";

import CheckboxGroup from "../../../components/CheckboxGroup";
import ConditionalFieldsWrapper from "../../../components/ConditionalFieldsWrapper";
import PageTitle from "../../../components/PageTitle";
import Question from "../../../components/Question";
import QuestionAddress from "../../../components/QuestionAddress";
import QuestionDOB from "../../../components/QuestionDOB";
import QuestionsFrame from "../../../components/QuestionsFrame";
import RadioGroup from "../../../components/RadioGroup";
import SubHeading from "../../../components/SubHeading";

const PersonDetails = () => (
  <>
    <ConditionalFieldsWrapper conditions={{ appl_type: "abap" }}>
      <PageTitle
        heading="Personal details"
        description="As the third-party applicant, you will need to fill in your own personal details and also the details of the affected person of the incident. "
      />
      <QuestionsFrame>
        <Question name="appl_det_giv_nam" />
        <Question name="appl_det_giv_nam2" />
        <Question name="appl_det_giv_nam3" />
        <Question name="appl_det_fam_nam" />
        <Question name="appl_other_names" />
        <Question type="select" name="appl_rel_ap" />
        <QuestionDOB dobName="appl_dob" ageName="appl_age" />
        <RadioGroup name="appl_sex" />
        <ConditionalFieldsWrapper conditions={{ appl_sex: "U" }}>
          <Question name="appl_sex_other" />
        </ConditionalFieldsWrapper>
        <RadioGroup name="appl_abor_code" />
      </QuestionsFrame>
      <QuestionsFrame privateFrame>
        <QuestionAddress
          namePrefix="appl_det_addr"
          label="What is your current address?"
          useToDetermineCourt
        />
        <CheckboxGroup
          name="appl_safe_cont"
          optionChildren={{
            phone: (
              <>
                <Question name="appl_cont_phone_3" />
                <Question name="appl_cont_phone" />
                <Question name="appl_cont_phone_2" />
                <RadioGroup name="appl_cont_phone_sms" />
                <RadioGroup name="appl_cont_phone_vm" />
                <CheckboxGroup
                  name="appl_cont_safe_times"
                  disableOthers="any"
                />
              </>
            ),
            email: (
              <>
                <Question name="appl_cont_email" />
                <Question name="appl_cont_email_2" />
              </>
            ),
            trust: (
              <>
                <Question name="appl_cont_trst_per" />
                <Question type="select" name="appl_cont_trst_per_rel" />
                <ConditionalFieldsWrapper
                  conditions={{ appl_cont_trst_per_rel: "other" }}
                >
                  <Question name="appl_cont_trst_per_rel_other" />
                </ConditionalFieldsWrapper>
                <Question name="appl_cont_trst_per_phone" />
                <Question name="appl_cont_trst_per_email" />
              </>
            ),
          }}
        />
      </QuestionsFrame>
    </ConditionalFieldsWrapper>
    <PageTitle
      heading="Affected person’s details"
      description="The affected person is the one who has been affected by threats to their personal safety or acts of violence."
    />
    <QuestionsFrame>
      <Question name="ap_giv_nam" />
      <Question name="ap_giv_nam2" />
      <Question name="ap_giv_nam3" />
      <Question name="ap_fam_nam" />
      <Question name="ap_other_names" />
      <Question name="ap_rel_resp" type="select" />
      <QuestionDOB dobName="ap_dob" ageName="ap_age" />
      <RadioGroup name="ap_sex" />
      <ConditionalFieldsWrapper conditions={{ other: "U" }}>
        <Question name="ap_sex_other" />
      </ConditionalFieldsWrapper>
      <RadioGroup name="ap_lgbt" />
      <RadioGroup name="ap_abor_code" />
    </QuestionsFrame>
    <QuestionsFrame privateFrame>
      <RadioGroup name="ap_pregnant_baby" />
    </QuestionsFrame>
    <QuestionsFrame>
      <RadioGroup name="ap_inter" />
      <ConditionalFieldsWrapper conditions={{ ap_inter: "yes" }}>
        <Question name="ap_lang" type="select" />
        <ConditionalFieldsWrapper conditions={{ ap_lang: "Other" }}>
          <Question name="ap_lang_other" />
        </ConditionalFieldsWrapper>
      </ConditionalFieldsWrapper>
    </QuestionsFrame>
    <SubHeading
      heading="Affected person contact details"
      description="We may need to confirm some things with the affected person, depending on the application. If you are the trusted person, we will contact you instead."
    />
    <QuestionsFrame privateFrame>
      <ConditionalFieldsWrapper conditions={{ appl_type: "ap" }}>
        <QuestionAddress
          namePrefix="ap_addr"
          label="What is your current address?"
          useToDetermineCourt
        />
      </ConditionalFieldsWrapper>
      <CheckboxGroup
        name="ap_safe_cont"
        optionChildren={{
          phone: (
            <>
              <Question name="ap_cont_phone_3" />
              <Question name="ap_cont_phone" />
              <Question name="ap_cont_phone_2" />
              <RadioGroup name="ap_cont_phone_sms" />
              <RadioGroup name="ap_cont_phone_vm" />
              <CheckboxGroup name="ap_cont_safe_times" disableOthers="any" />
            </>
          ),
          email: (
            <>
              <Question name="ap_cont_email" />
              <Question name="ap_cont_email_2" />
            </>
          ),
          trust: (
            <>
              <Question name="ap_cont_trst_per" />
              <Question type="select" name="ap_cont_trst_per_rel" />
              <ConditionalFieldsWrapper
                conditions={{ ap_cont_trst_per_rel: "other" }}
              >
                <Question name="ap_cont_trst_per_rel_other" />
              </ConditionalFieldsWrapper>
              <Question name="ap_cont_trst_per_email" />
              <Question name="ap_cont_trst_per_phone" />
            </>
          ),
        }}
      />
    </QuestionsFrame>
  </>
);

export default PersonDetails;
