import React from "react";

import renderCmsCopy from "../../../helpers/renderCmsCopy";

import CheckboxGroup from "../../../components/CheckboxGroup";
import PageTitle from "../../../components/PageTitle";
import Question from "../../../components/Question";
import QuestionAddress from "../../../components/QuestionAddress";
import QuestionDatepicker from "../../../components/QuestionDatepicker";
import QuestionDuration from "../../../components/QuestionDuration";
import QuestionFrame from "../../../components/QuestionsFrame";
import QuestionsFrame from "../../../components/QuestionsFrame";
import RadioGroup from "../../../components/RadioGroup";
import SubHeading from "../../../components/SubHeading";
import ConditionalFieldsWrapper from "../../../components/ConditionalFieldsWrapper";

const Respondent: React.FC = () => (
  <>
    <PageTitle
      heading="Respondent"
      description={renderCmsCopy(
        `The respondent is the person who used ||family violence|Family violence|Family violence is harmful behaviour that is used to control, threaten, force or dominate a family member through fear. It includes: physically hurting or threatening a person, raping or sexually pressuring a family member, harming a person emotionally or psychologically, intentionally damaging property, controlling what a person can do, controlling how a family member uses money, and harming or threatening to harm a pet.|| against you and/or children.`
      )}
    />
    <QuestionFrame>
      <Question name="resp_giv_nam" />
      <Question name="resp_giv_nam2" />
      <Question name="resp_giv_nam3" />
      <Question name="resp_fam_nam" />
      <Question name="resp_other_nam" />
    </QuestionFrame>

    <SubHeading heading="Relationship with respondent" />

    <QuestionsFrame>
      <Question type="select" name="appl_rel_to_resp" />

      <QuestionDuration name="resp_known_lbl" />

      <ConditionalFieldsWrapper
        conditions={{
          appl_rel_to_resp: ["SP", "DP", "IPR", "FS", "FIP", "DPF"],
        }}
      >
        <RadioGroup name="resp_sep" />

        <ConditionalFieldsWrapper conditions={{ resp_sep: "no" }}>
          <RadioGroup name="resp_sep_inf" />
        </ConditionalFieldsWrapper>

        <ConditionalFieldsWrapper conditions={{ resp_sep: "yes" }}>
          <QuestionDuration name="resp_sep_long_lbl" />
        </ConditionalFieldsWrapper>
      </ConditionalFieldsWrapper>
    </QuestionsFrame>

    <SubHeading heading="Respondent's personal details" />

    <QuestionsFrame>
      <RadioGroup name="resp_sex" />

      <ConditionalFieldsWrapper conditions={{ resp_sex: "U" }}>
        <Question name="resp_sex_other" />
      </ConditionalFieldsWrapper>

      <RadioGroup name="resp_dob_known" />

      <ConditionalFieldsWrapper conditions={{ resp_dob_known: "yes" }}>
        <QuestionDatepicker name="resp_dob" />
      </ConditionalFieldsWrapper>

      <ConditionalFieldsWrapper conditions={{ resp_dob_known: "no" }}>
        <Question name="resp_age" />
      </ConditionalFieldsWrapper>

      <RadioGroup name="resp_abor_code" />

      <RadioGroup name="resp_det_inter" />

      <ConditionalFieldsWrapper conditions={{ resp_det_inter: "yes" }}>
        <Question type="select" name="resp_det_lang" />

        <ConditionalFieldsWrapper conditions={{ resp_det_lang: "Other" }}>
          <Question name="resp_det_lang_other" />
        </ConditionalFieldsWrapper>
      </ConditionalFieldsWrapper>
    </QuestionsFrame>

    <SubHeading
      heading="What is the best way to contact the respondent?"
      description="||Why are we asking this?|Why are we asking this?|Applications and court orders need to be served on the respondent.||"
    />

    <QuestionsFrame>
      <RadioGroup name="resp_addr_known" />

      <ConditionalFieldsWrapper conditions={{ resp_addr_known: "other" }}>
        <QuestionAddress
          label="Enter address (if known)"
          namePrefix="resp_det_addr"
        />

        <RadioGroup name="resp_addr_type" />

        <ConditionalFieldsWrapper conditions={{ resp_addr_type: "other" }}>
          <Question name="resp_addr_type_other" />
        </ConditionalFieldsWrapper>
      </ConditionalFieldsWrapper>

      <CheckboxGroup name="resp_contact_type" />

      <ConditionalFieldsWrapper conditions={{ resp_contact_type: "phone" }}>
        <Question name="resp_contact_ph_3" />
        <Question name="resp_contact_ph" />
        <Question name="resp_contact_ph_2" />
      </ConditionalFieldsWrapper>

      <ConditionalFieldsWrapper conditions={{ resp_contact_type: "email" }}>
        <Question name="resp_contact_email" />
        <Question name="resp_contact_email_2" />
      </ConditionalFieldsWrapper>

      <ConditionalFieldsWrapper conditions={{ resp_contact_type: "other" }}>
        <Question name="resp_contact_other" />
      </ConditionalFieldsWrapper>
    </QuestionsFrame>

    <SubHeading heading="Other information" />

    <QuestionsFrame>
      <RadioGroup name="resp_imm_pro" />

      <RadioGroup name="resp_armed" />

      <ConditionalFieldsWrapper conditions={{ resp_armed: "yes" }}>
        <Question name="resp_armed_detail" />
        <RadioGroup name="resp_armed_loc" />

        <ConditionalFieldsWrapper conditions={{ resp_armed_loc: "other" }}>
          <QuestionAddress
            namePrefix="resp_armed_loc_add"
            label="Please specify"
          />
        </ConditionalFieldsWrapper>
      </ConditionalFieldsWrapper>

      <RadioGroup name="resp_addiction" />
      <RadioGroup name="resp_suicidal" />
    </QuestionsFrame>
  </>
);

export default Respondent;
