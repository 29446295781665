import React from "react";

import PageTitle from "../../../components/PageTitle";
import QuestionFrame from "../../../components/QuestionsFrame";
import RadioGroup from "../../../components/RadioGroup";
import { useFormikContext } from "formik";
import { FlatApplciationType } from "../../../types";

const YourRole: React.FC = () => {
  const {
    values: { seeking },
    setFieldValue,
  } = useFormikContext<FlatApplciationType>();

  // detect changes to seeking => clear children array in values on change
  const prevSeeking = React.useRef<string | null>(null);
  React.useEffect(() => {
    if (
      (seeking === "appl" && prevSeeking.current === "resp") ||
      (seeking === "resp" && prevSeeking.current === "appl")
    ) {
      setFieldValue("children", []);
    }
    if (!Array.isArray(seeking)) {
      prevSeeking.current = seeking;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seeking]);

  return (
    <>
      <PageTitle heading="Who is seeking changes?" />
      <QuestionFrame>
        <RadioGroup
          name="seeking"
          compact
          optionChildren={{
            appl: <RadioGroup
             name="seeking_appl_role"
             label=""
             compact
             optionChildren={{
               pg: <RadioGroup
                name="seeking_appl_sub_role"
                compact
               />
             }}
            />,
          }}
        />
      </QuestionFrame>
    </>
  );
};

export default YourRole;
