import React from "react";
import styled, { css } from "styled-components/macro";
import Cleave from "cleave.js/react";
import ReactMarkdown from "react-markdown";

import inputStyles from "../theme/inputStyles";
import colors from "../theme/colors";
import { ReactComponent as PostcodeOkIcon } from "../assets/icons/PostcodeOk.svg";
import { ReactComponent as PostcodeErrorIcon } from "../assets/icons/PostcodeError.svg";

import ErrorMessage from "./ErrorMessage";
import getValidPostcodes from "../helpers/getValidPostcodes";

type Props = {
  setPostcodeStatus: React.Dispatch<React.SetStateAction<string>>;
};

const PostcodeValidator: React.FunctionComponent<Props> = ({
  setPostcodeStatus,
}) => {
  const [status, setStatus] = React.useState("");

  const postcodes = React.useMemo(() => getValidPostcodes(), []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let status = "";

    const enteredPostcodeIsMatched = postcodes.includes(value);

    if (value.length === 4 && enteredPostcodeIsMatched) {
      status = "ok";
    } else if (value.length === 4 && !enteredPostcodeIsMatched) {
      status = "error";
    }
    setPostcodeStatus(status);

    setStatus(status);
  };

  return (
    <>
      <Wrapper>
        <Input
          type="text"
          status={status}
          onChange={handleChange}
          maxLength={4}
          options={{
            numericOnly: true,
          }}
        />
        {status === "ok" && <PostcodeOkIcon />}
        {status === "error" && <PostcodeErrorIcon />}
      </Wrapper>
      {status === "error" && (
        <ErrorMessage>
          <ReactMarkdown
            source={`The online form is not currently supported in your area. Please check that the postcode is a valid postcode for Victoria.

If you are in immediate danger Call 000, or if it's safe for you, go to your nearest police station or contact your local court during business hours.

You can download the paper application from the MCV website [https://mcv.vic.gov.au/form-finder/application-family-violence-intervention-order](https://mcv.vic.gov.au/form-finder/application-family-violence-intervention-order).`}
          />
        </ErrorMessage>
      )}
    </>
  );
};

export default PostcodeValidator;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > svg {
    height: 32px;
    width: 43px;
  }
`;

const Input = styled(Cleave)<{ status?: string }>`
  ${inputStyles}
  width: 176px;
  margin-right: 4px;
  ${({ status }) =>
    status === "ok" &&
    css`
      border-color: #1ba4b2;
    `};
  ${({ status }) =>
    status === "error" &&
    css`
      border-color: ${colors.error};
    `};
`;
