import { rem } from "polished";
import React from "react";
import styled from "styled-components/macro";

import spacing from "../theme/spacing";
import FormNote from "./FormNote";

type Props = {
  heading: string;
  description?: string;
};

const SubHeading: React.FC<Props> = React.memo(({ heading, description }) => {
  return (
    <SubHeadingContainer>
      <h3>{heading}</h3>
      {description && <FormNote text={description} />}
    </SubHeadingContainer>
  );
});

export default SubHeading;

const SubHeadingContainer = styled.div`
  width: 100%;
  padding: 0 ${spacing.gutters}px 0;
  margin-bottom: ${rem(30)};

  h3 {
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.17;
  }
`;
