import React from "react";
import styled from "styled-components/macro";
import colors from "../theme/colors";
import media from "../theme/media";

import InfoBox, {
  Wrapper as InfoBoxWrapper,
  Overlay as InfoBoxOverlay,
} from "./InfoBox";

type Props = {
  string?: string;
  inherit?: boolean;
  displayText?: string;
  headingText?: string;
  bodyText?: string;
  disabled?: boolean;
};

const Info: React.FunctionComponent<Props> = ({
  string,
  inherit,
  displayText,
  headingText,
  bodyText,
  disabled,
}) => {
  if (disabled) {
    return <span>{displayText}</span>;
  }
  if (displayText && headingText && bodyText) {
    return (
      <Wrapper inherit={inherit}>
        <button disabled={disabled}>{displayText}</button>

        <InfoBox title={headingText} text={bodyText} />
      </Wrapper>
    );
  }

  if (!string) {
    return null;
  }
  const parts = string.split("|");
  const text = parts[0];
  const heading = parts[1];
  const body = parts[2];
  if (parts.length !== 3) {
    return null;
  }
  return (
    <Wrapper inherit={inherit || undefined}>
      <button type="button">{text}</button>
      <InfoBox title={heading} text={body} />
    </Wrapper>
  );
};

export default Info;

const Wrapper = styled.span<{ inherit?: boolean }>`
  position: relative;
  color: ${colors.blue};
  display: inline-block;
  font-size: ${({ inherit }) => (inherit ? `inherit` : `14px`)};
  line-height: 1;
  border-bottom: 1px dashed currentColor;
  vertical-align: middle;
  cursor: pointer;
  > button {
    background: transparent;
    padding: 0;
    border: none;
    color: inherit;
    font-weight: 600;
    cursor: help;
  }

  > button ~ ${InfoBoxWrapper} {
    display: none;
  }

  > button:hover ~ ${InfoBoxWrapper}, > button:active ~ ${InfoBoxWrapper} {
    display: block;
  }

  > button:hover ~ ${InfoBoxOverlay}, > button:active ~ ${InfoBoxOverlay} {
    display: block;
    ${media.greaterThan("phone")`
      display: none;
    `}
  }

  ${InfoBoxWrapper}:hover {
    display: block;
  }

  ${InfoBoxWrapper}:active {
    display: block;
  }
`;
