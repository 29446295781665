import React from "react";
import styled from "styled-components/macro";
import uniq from "lodash/uniq";
import { rem } from "polished";
import { useField } from "formik";
import { SchemaOptionType } from "csv-package";

import inputStyles from "../theme/inputStyles";

import FormLabel from "./FormLabel";
import FormNote from "./FormNote";
import ErrorMessage from "./ErrorMessage";

import useQuestionConfig from "../hooks/useQuestionConfig";

type AutoCompleteOption =
  | "name"
  | "honorific-prefix"
  | "given-name"
  | "additional-name"
  | "family-name"
  | "honorific-suffix"
  | "nickname"
  | "username"
  | "new-password"
  | "current-password"
  | "one-time-code"
  | "organization-title"
  | "organization"
  | "street-address"
  | "address-line1"
  | "address-line2"
  | "address-line3"
  | "address-level4"
  | "address-level3"
  | "address-level2"
  | "address-level1"
  | "country"
  | "country-name"
  | "postal-code"
  | "cc-name"
  | "cc-given-name"
  | "cc-additional-name"
  | "cc-family-name"
  | "cc-number"
  | "cc-exp"
  | "cc-exp-month"
  | "cc-exp-year"
  | "cc-csc"
  | "cc-type"
  | "transaction-currency"
  | "transaction-amount"
  | "language"
  | "bday"
  | "bday-day"
  | "bday-month"
  | "bday-year"
  | "sex"
  | "url"
  | "photo";

type Props = {
  name: string;
  label?: string;
  type?: string;
  note?: string;
  notePosition?: "below" | "right";
  options?: SchemaOptionType[];
  autoComplete?: AutoCompleteOption;
  maxlength?: number;
  prefix?: string;
};

const Question: React.FC<Props> = ({
  notePosition = "below",
  type,
  autoComplete,
  maxlength,
  children,
  name,
  prefix = "",
  label: _label,
  ...rest
}) => {
  const [field, meta] = useField(name);
  const { label, options, note } = useQuestionConfig(name, rest);
  const showError = meta.touched && meta.error;

  return React.useMemo(() => {
    // build select options
    let optionGroups: string[] | null = null;
    if (
      options &&
      typeof options[0] === "object" &&
      options[0].hasOwnProperty("group")
    ) {
      optionGroups = uniq(options.map((option) => option.group)) as string[];
    }

    const getPrefixedValue = () => {
      // empty value
      if (!field.value) {
        return prefix;
      } else if (field.value.substring(0, prefix.length) !== prefix) {
        // check if value start with prefix, if not prepend it
        return `${prefix}`;
      }
      const cleanedValue = field.value.substring(prefix.length);
      return `${prefix}${cleanedValue}`;
    };

    return (
      <Wrapper>
        {label && <FormLabel name={name} text={_label || label} />}
        {notePosition === "below" && note && <FormNote text={note} />}
        {showError && <StyledErrorMessage>{meta.error}</StyledErrorMessage>}
        {type === "select" ? (
          <Select
            name={name}
            type={type}
            onChange={field.onChange}
            onBlur={field.onBlur}
            error={Boolean(showError)}
            value={field.value}
          >
            <option value="">Please select an option</option>
            {optionGroups !== null && options !== null
              ? optionGroups.map((og) => (
                  <optgroup label={og} key={og}>
                    {options &&
                      options
                        .filter((so) => so.group === og)
                        .map((o) => (
                          <option value={o.value} key={o.value}>
                            {o.text}
                          </option>
                        ))}
                  </optgroup>
                ))
              : options!.map((o, i) => (
                  <option value={o.value} key={(o.value, i)}>
                    {o.text}
                  </option>
                ))}
          </Select>
        ) : (
          <Input
            name={name}
            type={type}
            onChange={field.onChange}
            onBlur={field.onBlur}
            error={Boolean(showError)}
            value={prefix ? getPrefixedValue() : field.value || ""}
            autoComplete={autoComplete}
            maxLength={maxlength}
          />
        )}
      </Wrapper>
    );
  }, [
    options,
    label,
    name,
    notePosition,
    note,
    showError,
    meta.error,
    type,
    field.onChange,
    field.onBlur,
    field.value,
    autoComplete,
    maxlength,
    prefix,
    _label,
  ]);
};

export default Question;

export const Wrapper = styled.div`
  margin-bottom: ${rem(35)};
`;
const Input = styled.input`
  ${inputStyles}
`;

export const Select = styled.select<{
  type?: string;
  error?: boolean;
  onBlur?: any;
  onChange?: any;
}>`
  ${inputStyles}
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-bottom: 13px;
`;
