import { Yup } from "csv-package";

const yourRole = {
  appl_type: Yup.string().required("Please select your role."),
  abap_type: Yup.string().when("appl_type", {
    is: "abap",
    then: Yup.string().required("Please select an option"),
  }),
};

export default yourRole;
