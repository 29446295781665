import { ApplicationDataType, ChildDataType, ConditionFunction } from "../../types";
import Yup from "../yup";
import child from "./child";
import _ from "lodash";

const incidentDetailsValid = (value: string) => {
  if (!value) return false;
  return value.trim() !== 'The respondent has';
}

const incidentDetails = [
  "phy",
  "pys",
  "aep",
  "cym",
  "thy",
  "cbp",
  "fsw",
  "dyp",
  "ryf",
  "htc",
  "kha",
  "sty",
  "fmc",
  "cdw",
].reduce(
  (children, incidentKey) => {
    return {
      ...children,

      [`incident_${incidentKey}_date`]: Yup.string().when(
        [`app_types`, `app_type_extend_why`, `incident_${incidentKey}_detail`],
        {
          is: (app_types: string[], app_type_extend_why: string[], incident_detail: string) =>
            Array.isArray(app_types) && app_types.includes("AE") && Array.isArray(app_type_extend_why)
            && app_type_extend_why.includes('further_violence') && incidentDetailsValid(incident_detail),
          then: Yup.string()
            .required('Please provide date or approximate date'),
        }
      ),
    };
  },
  {}
);

const varyOptionDetails = (start:string, end:string, prefix:string, app_type_options:string[] = []) => {
  let options = {}
  for (let i = start.charCodeAt(0); i <= end.charCodeAt(0) ; i++) {
    options = {
      ...options,

      [`coo_${prefix}_old_${String.fromCharCode(i)}_detail`]: Yup.string().when(
        [`app_types`, `coo_${prefix}_old_${String.fromCharCode(i)}`],
        {
          is: (app_types: string[], varyOption: string) =>
            Array.isArray(app_types) && app_type_options.some(op => app_types.includes(op)) && varyOption === 'yes',
          then: Yup.string()
            .required('We require this information'),
        }
      ),
    }
  }
  return options;
}

const applicationType = {
  app_types: Yup.array().ensure().min(1, "You must select at least 1 change"),
  app_type_extend_why: Yup.array()
    .ensure()
    .when("app_types", {
      is: (app_types: string[]) =>
        Array.isArray(app_types) && app_types.includes("AE"),
      then: Yup.array()
        .ensure()
        .min(1, "Please select a reason for extending."),
    }),
  app_type_extend_why_still_fearful: Yup.string()
    .when("app_type_extend_why", {
      is: (app_type_extend_why: string[]) =>
        Array.isArray(app_type_extend_why) &&
        app_type_extend_why.includes("still_fearful"),
      then: Yup.string().required("We require this information"),
    }),
    app_type_extend_further_violence_check: Yup.string()
    .when(["app_type_extend_why", "incident_phy_detail", "incident_pys_detail", "incident_aep_detail", "incident_cym_detail", "incident_thy_detail",
    "incident_cbp_detail", "incident_fsw_detail", "incident_dyp_detail", "incident_ryf_detail", "incident_htc_detail", "incident_kha_detail", "incident_sty_detail", "incident_fmc_detail",
    "incident_cdw_detail"], {
      is: (app_type_extend_why: string[], ...rest: any[]) =>
        Array.isArray(app_type_extend_why) &&
        app_type_extend_why.includes("further_violence") && !rest.some(incidentDetailsValid),
      then: Yup.string().required("Please fill at least one section of allegations of further violence have occurred"),
    }),
  ...incidentDetails,
  app_type_extend_why_breached: Yup.string()
    .when("app_type_extend_why", {
      is: (app_type_extend_why: string[]) =>
        Array.isArray(app_type_extend_why) &&
        app_type_extend_why.includes("breached"),
      then: Yup.string().required("We require this information"),
    }),
  app_type_extend_why_violence_stopped: Yup.string()
    .when("app_type_extend_why", {
      is: (app_type_extend_why: string[]) =>
        Array.isArray(app_type_extend_why) &&
        app_type_extend_why.includes("violence_stopped"),
      then: Yup.string().required("We require this information"),
    }),
  order_codes_sort_check: Yup.string()
    .ensure()
      .when([
          "app_types",
        "coo_con_old_a",
        "coo_con_old_b",
        "coo_con_old_c",
        "coo_con_old_d",
        "coo_con_old_e",
        "coo_con_old_f",
        "coo_con_old_g",
        "coo_con_old_h",
        "coo_con_old_i",
        "coo_con_old_j",
        "coo_con_old_k",
        "coo_con_old_l",
        "coo_con_old_m",
        "coo_con_old_n",
        "coo_con_old_o",
        "coo_con_old_p",
        "coo_con_old_q",
        "coo_con_old_r",
        "coo_exc_old_a",
        "coo_exc_old_b",
        "coo_exc_old_c",
        "coo_exc_old_d",
        "coo_exc_old_e",
      ], {
        is: (app_types: string[], ...rest: any[]) => app_types.some((type: string) => ["AV", "RV"].includes(type)) && !rest.some((item: string) => item === "yes"),
        then: Yup.string()
            .required("You must select at least one section or option on the vary the legal conditions on the order"),
      }),
    ...varyOptionDetails('a', 'm', 'con', ["AV", "RV"]),
    ...varyOptionDetails('n', 'r', 'con', ["AV"]),
    ...varyOptionDetails('a', 'e', 'exc', ["AV", "RV"]),
  child_action_add_why: Yup.string().when(["app_types", "children"], {
    is: (app_types: string[], children: ChildDataType[]) =>
      Array.isArray(app_types) && app_types.includes("AC") &&
      hasOnlyAddChildren({ children }),
    then: Yup.string().required("We require this information"),
  }),
  child_action_remove_why: Yup.string().when(["app_types", "children"], {
    is: (app_types: string[], children: ChildDataType[]) =>
      Array.isArray(app_types) && ((app_types.includes("AC") && hasOnlyRemoveChildren({ children })) || app_types.includes("RC")),
    then: Yup.string().required("We require this information"),
  }),
  child_action_add_remove_why: Yup.string().when(["app_types", "children"], {
    is: (app_types: string[], children: ChildDataType[]) =>
      Array.isArray(app_types) && app_types.includes("AC") &&
      hasAddAndRemoveChildren({ children }),
    then: Yup.string().required("We require this information"),
  }),
  vary_appl_why: Yup.string().when("app_types", {
    is: (app_types: string[]) =>
      Array.isArray(app_types) && app_types.includes("AV"),
    then: Yup.string().required("Please provide a reason"),
  }),
  vary_resp_why: Yup.string().when("app_types", {
    is: (app_types: string[]) =>
      Array.isArray(app_types) && app_types.includes("RV"),
    then: Yup.string().required("Please provide a reason"),
  }),
  vary_resp_why_2: Yup.string().when("app_types", {
    is: (app_types: string[]) =>
      Array.isArray(app_types) && app_types.includes("RV"),
    then: Yup.string().required("Please provide a reason"),
  }),
  children: Yup.array()
    .when("app_types", {
      is: (app_types: string[]) =>
        Array.isArray(app_types) && app_types.includes("AC"),
      then: Yup.array().of(child(true)),
    }).when("app_types", {
      is: (app_types: string[]) =>
        Array.isArray(app_types) && app_types.includes("RC"),
      then: Yup.array().of(child(false)),
    }),
  revoke_appl_why: Yup.string().when("app_types", {
    is: (app_types: string[]) => app_types.includes("AR"),
    then: Yup.string().trim().required("Please provide a reason"),
  }),
  revoke_resp_why: Yup.string().when("app_types", {
    is: (app_types: string[]) => app_types.includes("RR"),
    then: Yup.string().trim().required("Please provide a reason"),
  }),
  revoke_resp_why_2: Yup.string().when("app_types", {
    is: (app_types: string[]) => app_types.includes("RR"),
    then: Yup.string().trim().required("Please provide a reason"),
  }),
};

export default applicationType;

const hasOnlyAddChildren: ConditionFunction = (values: ChildDataType | ApplicationDataType) => {
  const children  = values.children as ChildDataType[];
  return _.some(children, {child_action: 'add'}) && !_.some(children, {child_action: 'remove'});
}

const hasOnlyRemoveChildren: ConditionFunction = (values: ChildDataType | ApplicationDataType) => {
  const children  = values.children as ChildDataType[];
  return _.some(children, {child_action: 'remove'}) && !_.some(children, {child_action: 'add'});
}

const hasAddAndRemoveChildren: ConditionFunction = (values: ChildDataType | ApplicationDataType) => {
  const children  = values.children as ChildDataType[];
  return _.some(children, {child_action: 'add'}) && _.some(children, {child_action: 'remove'});
}
