import _ from 'lodash';
import { ApplicationDataType, ChildDataType, ConditionsType } from '../types';

function isStrings(value: string[] | ChildDataType[]): value is string[] {
  return typeof value[0] === 'string';
}

/*
example conditions
{
  field_name: 'value',
  field_name2: 'other_value',
  placeholder: (values) => values.tt === 'tt'
}
*/

export const conditionsChecker = (
  conditions: ConditionsType,
  values: ChildDataType | ApplicationDataType
): boolean => {
  return Object.keys(conditions)
    .map(conditionKey => {
      const userValue = _.get(values, conditionKey);
      const conditionValue = conditions[conditionKey];

      if (typeof conditionValue === 'function') {
        return conditionValue(values);

        // if value is an array
        // and condtion value string
        // do includes check
      } else if (
        Array.isArray(userValue) &&
        isStrings(userValue) &&
        typeof conditionValue === 'string'
      ) {
        return userValue.includes(conditionValue);

        // if value is a string
        // and condition value is an array
        // OR match
      } else if (
        typeof userValue === 'string' &&
        Array.isArray(conditionValue)
      ) {
        return conditionValue.includes(userValue);

        // if value is an array
        // and condtion value is an array
        // check intersetion
      } else if (
        Array.isArray(userValue) &&
        isStrings(userValue) &&
        Array.isArray(conditionValue)
      ) {
        return _.intersection(userValue, conditionValue).length > 0;

        // if value is a string do a straigh match
      } else if (typeof userValue === 'string') {
        return userValue === conditionValue;
      }
      return false;
    })
    .every(r => r);
};
