import { useFormikContext } from "formik";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { ReDeclareType } from "csv-package";

import Button from "../components/Button";
import Modal from "../components/Modal";
import api from "../helpers/api";
import ApplicationContext from "../reducers/applicationFrame";
import { FlatApplciationType } from "../types";

export type NetworkStatusType =
  | "uninitialised"
  | "loading"
  | "successful"
  | "failed";

export type ReDeclareErrorType = {
  title: string;
  body: string;
};

export type ReDecalreLocalState = {
  call_back_status: NetworkStatusType;
  submit_status: NetworkStatusType;
  declaration_of_truth: boolean;
  errors: ReDeclareErrorType | null;
};

type ReDeclareControls = {
  reDeclareState: ReDecalreLocalState;
  handleCallBack: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleAcceptReDeclare: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleDeclarationOfTruth: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleErrorModalClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  reDeclareObject: ReDeclareType;
};

const useReDeclare = (): ReDeclareControls => {
  const { id: ApplicationID } = useParams<{ id: string }>();
  const { values, setFieldValue } = useFormikContext<FlatApplciationType>();
  const {
    goToStep,
    state: { currentStep },
  } = React.useContext(ApplicationContext);

  const [
    reDeclareState,
    setReDeclareState,
  ] = React.useState<ReDecalreLocalState>({
    declaration_of_truth: values.Status === "re-declare-accepted",
    call_back_status: "uninitialised", // uninitialised | loading | successful | failed
    submit_status: "uninitialised", // uninitialised | loading | successful | failed
    errors: null,
  });

  // get latest re-declare object
  const reDeclareObject = values.ReDeclarations.sort(
    (a: ReDeclareType, b: ReDeclareType) => {
      const aDate = new Date(a.Created);
      const bDate = new Date(b.Created);
      return aDate.valueOf() - bDate.valueOf();
    }
  )[values.ReDeclarations.length - 1];

  // update state on load
  // this will lock the view
  React.useEffect(() => {
    if (values.Flags.call_back) {
      setReDeclareState((s) => ({
        ...s,
        call_back_status: "successful",
      }));
    }
  }, [values.Flags.call_back]);

  const handleCallBack = React.useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      try {
        setReDeclareState((s) => ({
          ...s,
          call_back_status: "loading",
        }));
        await api("post", "/reDeclare/requestCallback", {
          ApplicationID,
        });
        setReDeclareState((s) => ({
          ...s,
          call_back_status: "successful",
        }));
      } catch (e) {
        setReDeclareState((s) => ({
          ...s,
          call_back_status: "failed",
          errors: {
            title: "Re-declare request failed",
            body:
              "Please try again, if this problem persists please contact your registrar.",
          },
        }));
      }
    },
    [ApplicationID]
  );

  const handleAcceptReDeclare = React.useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (reDeclareState.declaration_of_truth === false) {
        setReDeclareState((s) => ({
          ...s,
          errors: {
            title: "Error: missing declaration of truth",
            body: "You must agree with the declaration of truth",
          },
        }));
        return;
      }
      try {
        setReDeclareState((s) => ({
          ...s,
          submit_status: "loading",
        }));
        type R = {
          status: "ok" | "failed";
        };
        const result = (await api("post", "/reDeclare/acceptReDeclare", {
          ApplicationID: values.ApplicationID,
          ReReDeclareKey: reDeclareObject.ReDeclareKey,
        })) as R;

        if (result.status === "ok") {
          setFieldValue("Status", "re-declare-accepted");

          setReDeclareState((s) => ({
            ...s,
            submit_status: "successful",
          }));

          const nextStep = currentStep + 1;

          // send user to complete page.
          goToStep(nextStep);
        }
      } catch (e) {
        setReDeclareState((s) => ({
          ...s,
          submit_status: "failed",
          errors: {
            title: "Re-declare request failed",
            body:
              "Please try again, if this problem persists please contact your registrar.",
          },
        }));
      }
    },
    [
      currentStep,
      goToStep,
      reDeclareObject.ReDeclareKey,
      reDeclareState.declaration_of_truth,
      setFieldValue,
      values.ApplicationID,
    ]
  );

  const handleDeclarationOfTruth = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setReDeclareState((s) => ({
        ...s,
        declaration_of_truth: !s.declaration_of_truth,
      }));
    },
    []
  );

  const handleErrorModalClose = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setReDeclareState((s) => ({
        ...s,
        errors: null,
      }));
    },
    []
  );

  return {
    reDeclareState,
    handleCallBack,
    handleAcceptReDeclare,
    reDeclareObject,
    handleDeclarationOfTruth,
    handleErrorModalClose,
  };
};

export default useReDeclare;

export const ReDeclareModals: React.FC<{
  reDeclareState: ReDecalreLocalState;
  handleErrorModalClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
}> = ({ reDeclareState, handleErrorModalClose }) => {
  const { push } = useHistory();

  const { errors } = reDeclareState;
  if (errors !== null) {
    return (
      <Modal>
        <h2>{errors.title}</h2>
        <p>{errors.body}</p>
        <footer className="buttons">
          <Button variant="alert" onClick={handleErrorModalClose}>
            Continue
          </Button>
        </footer>
      </Modal>
    );
  } else if (reDeclareState.call_back_status === "successful") {
    return (
      <Modal>
        <h2>Call back request successful</h2>
        <p>
          A court registrar will contact you using your safe contact details. If
          your details have changed, please contact your{" "}
          <a
            href="https://mcv.vic.gov.au/going-court/find-court"
            target="_blank"
            rel="noreferrer"
          >
            local court.
          </a>
        </p>
        <footer className="buttons">
          <Button
            variant="alert"
            onClick={(e) => {
              e.preventDefault();
              push("/quick");
            }}
          >
            QuickExit
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              push("/applications");
            }}
          >
            Back to applications
          </Button>
        </footer>
      </Modal>
    );
  }
  return null;
};
