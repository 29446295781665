import React from "react";
import styled from "styled-components/macro";
import { Formik, Form } from "formik";
import { Yup } from "csv-package";
import { Link, useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import ReactGA from "react-ga";

import {
  hasAnonymousIdentity,
  isAuthenticated,
  createAndStoreUser,
  switchToAuthenticated,
  login,
} from "../helpers/auth";

import { drupalUserExists } from "../helpers/drupalAPI";

import QuestionsFrame from "../components/QuestionsFrame";
import Question from "../components/StandaloneQuestion";
import Button from "../components/Button";
import {
  configApplicationType,
  configJurisdiction,
} from "../config/systemConfig";

const Signup: React.FC = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [isAuth, setIsAuth] = React.useState<boolean | null>(null);

  // on mount
  React.useEffect(() => {
    const checkAuth = async () => {
      const result = await isAuthenticated();
      setIsAuth(result);

      // if user is logged send them to their applications
      if (result) {
        push("/applications");
      }
    };
    checkAuth();

    ReactGA.pageview(pathname);
  }, [push, pathname]);

  if (isAuth === null) {
    return null;
  }

  return (
    <Wrapper>
      <Inner>
        <Formik
          initialValues={{
            given_name: "",
            email: "",
            password: "",
            passwordConfirmation: "",
          }}
          validateOnBlur
          validationSchema={Yup.object().shape({
            given_name: Yup.string().required("Given name is required"),
            email: Yup.string()
              .required("Email is required")
              .email("Must be a vaild email"),
            password: Yup.string()
              .required("Password is required")
              .min(8, "Your password must be 8 characters long"),
            passwordConfirmation: Yup.string()
              .oneOf([Yup.ref("password"), undefined], "Passwords must match")
              .required("Passwords must match"),
          })}
          onSubmit={async (values) => {
            if (hasAnonymousIdentity()) {
              try {
                await switchToAuthenticated(
                  values.email,
                  values.password,
                  values.given_name
                );
                push("/applications");
              } catch (e) {
                alert("Failed to convert profile to authenicated");
              }
            } else {
              try {
                if (
                  configApplicationType === "N" &&
                  configJurisdiction === "FVIO"
                ) {
                  // check if user exists in drupal
                  const result = await drupalUserExists(values.email);

                  if (result) {
                    // if yes then send user to login page
                    throw new Error(
                      "This account already exists. Please login."
                    );
                  }
                }

                // if no create and store user + create an applciation
                await createAndStoreUser(values);
                await login(values.email, values.password);

                push(`/application/create`);
              } catch (error) {
                alert(error);
              }
            }
          }}
        >
          {(formikProps) => (
            <Form>
              <QuestionsFrame>
                <h1>
                  {hasAnonymousIdentity()
                    ? `Create Profile`
                    : `Create an applicant profile (recommended)`}
                </h1>
                {hasAnonymousIdentity() && (
                  <p>
                    A previous intervention order application has been started.
                    If you would like to return to your previous application
                    please create a user profile. If you wish to start a new
                    application use{" "}
                    <Link
                      to="/"
                      onClick={(e) => {
                        e.preventDefault();
                        Cookies.remove("anonymousIdenttiyId");
                        push("/");
                      }}
                    >
                      this link
                    </Link>
                    .
                  </p>
                )}

                <p>
                  You will need to create an applicant profile to be able to
                  save your application. This is also the only way that you can
                  return to the application if you need to return to it later as
                  you need your username and password to log back in and
                  complete the application.
                </p>
                {!hasAnonymousIdentity() && (
                  <p>
                    You can{" "}
                    <Link to={`/application/create/anonymous`}>
                      skip this step
                    </Link>{" "}
                    and decide to create a profile later
                  </p>
                )}

                <br />
                <br />
                <Question
                  label="Given name"
                  name="given_name"
                  {...formikProps}
                />
                <Question
                  label="Email (your username)"
                  name="email"
                  note="Your email address can be a real or a made up email address which will be used as your username. The court will NOT use this email address to contact you about your application."
                  {...formikProps}
                />
                <Question
                  type="password"
                  label="Password"
                  name="password"
                  note="Minimum 8 characters"
                  {...formikProps}
                />
                <Question
                  type="password"
                  label="Re-enter password"
                  name="passwordConfirmation"
                  {...formikProps}
                />
                <Button
                  as="button"
                  disabled={!formikProps.isValid || formikProps.isSubmitting}
                >
                  {hasAnonymousIdentity()
                    ? `Create profile`
                    : `Create profile and start form`}
                </Button>
                <br />
                <br />

                {!hasAnonymousIdentity() && (
                  <div>
                    <Link to={`/application/create/anonymous`}>
                      Skip this step
                    </Link>
                  </div>
                )}
              </QuestionsFrame>
            </Form>
          )}
        </Formik>
      </Inner>
    </Wrapper>
  );
};

export default Signup;

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const Inner = styled.div`
  width: 100%;
  max-width: 680px;
`;
