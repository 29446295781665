import { useField } from "formik";
import React from "react";
import styled from "styled-components/macro";

import useQuestionConfig from "../hooks/useQuestionConfig";

import inputStyles from "../theme/inputStyles";
import media from "../theme/media";

import ErrorMessage from "./ErrorMessage";
import FormLabel from "./FormLabel";
import FormNote from "./FormNote";
import { Select } from "./Question";

type Props = {
  name: string;
  label?: string;
  note?: string;
  units?: string[];
};

const QuestionDuration: React.FC<Props> = React.memo(
  ({ name, units = ["Days", "Weeks", "Months", "Years"], ...rest }) => {
    const formikField = useField(name);
    const { label, note } = useQuestionConfig(name, rest);

    const meta = formikField[1];
    const helpers = formikField[2];

    const [questionState, setQuestionState] = React.useState({
      number: meta.value ? meta.value.split(" ")[0] : "",
      unit: meta.value ? meta.value.split(" ")[1] : "",
    });

    const [touched, setTouched] = React.useState({
      number: false,
      unit: false,
    });

    React.useEffect(() => {
      const { number, unit } = questionState;
      const value = `${number} ${unit}`;

      const match = value.match(/^[0-9]{1,9} [A-Za-z]{1,6}$/);

      if (match) {
        helpers.setValue(value);
      }

      if (!match && Object.values(touched).every((t) => t)) {
        // helpers.setTouched();
        helpers.setError("Please enter a number and select a unit");
      }
      // eslint-disable-next-line
    }, [questionState, touched]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      setQuestionState((s) => ({ ...s, [name]: value }));
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      const { name } = e.target;
      setTouched((t) => ({ ...t, [name]: true }));
    };

    const hasError = meta.error && meta.touched;

    return (
      <Wrapper>
        {label && <FormLabel text={label} />}
        {note && <FormNote text={note} />}
        {hasError && <StyledErrorMessage>{meta.error}</StyledErrorMessage>}
        <InputsContainer>
          <Number>
            <input
              name="number"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={questionState.number}
              min={1}
            />
          </Number>
          <Unit>
            <Select
              name="unit"
              value={questionState.unit}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option
                value=""
                disabled={questionState.unit !== "" && meta.touched}
              >
                Please select an option
              </option>
              {units.map((o) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </Select>
          </Unit>
        </InputsContainer>
      </Wrapper>
    );
  }
);

export default QuestionDuration;

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 33px;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  ${media.greaterThan("tablet")`
    justify-content: flex-start;
  `}
`;
const Number = styled.div`
  width: 5em;
  margin-bottom: 15px;
  ${media.greaterThan("tablet")`
      width: 8em;
      min-width: 8em;
      margin-right: 12px;
    `}

  p {
    color: #3c3c3c;
    font-size: 18px;
    line-height: 1.44;
    margin: 0 0 4px;
  }

  input {
    ${inputStyles}
    width: 100%;
  }
`;

const Unit = styled.div`
  width: calc(100% - 5em - 12px);
  ${media.greaterThan("tablet")`
      width: calc(100% - 8em - 12px);
    `}
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-bottom: 13px;
`;
