import React from "react";
import styled from "styled-components/macro";

import colors from "../theme/colors";
import Modal from "./Modal";

const SharingAndExtend: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  const [sharingModalOpen, setSharingModalOpen] = React.useState(false);

  const handleInfoSharingButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    setSharingModalOpen(true);
  };

  const handleModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSharingModalOpen(false);
  };

  return (
    <>
      <h3>Family Violence Information Sharing Scheme</h3>
      <p>
        To ensure your safety, we will share the information you’ve provided
        with authorised protection agencies.{" "}
        <InlineTextButton onClick={handleInfoSharingButtonClick}>
          More information and list of agencies.
        </InlineTextButton>
      </p>
      {/* <Checkbox
        name="appl_cont_sms_consent"
        value="true"
        onCheckboxChange={() =>
          setFieldValue(
            "appl_info_share_consent",
            !get(values, "appl_info_share_consent", false)
          )
        }
        checked={Boolean(get(values, "appl_info_share_consent", false))}
        label={`By checking this box, I consent to sharing the information in this application with relevant protection agencies`}
        disabled={disabled || false}
      /> */}
      <br />

      {sharingModalOpen && (
        <Modal onClose={handleModalClose} large>
          <h2>How we use your information</h2>
          <p>
            Part 5A of the Family Violence Protection Act 2008 provides a
            legislative basis for prescribed Information Sharing Entities to
            request and share information to assess and manage family violence
            risks.
          </p>
          <p>
            Information Sharing is important for relevant services to work
            together to keep perpetrators in view and keep you (and if
            applicable, your children) safe. Information will only be shared
            where it is necessary to assess and manage the risks of family
            violence. Your information will not be shared with the respondent at
            any time.
          </p>
          <p>
            The Court requires your consent to share confidential information
            about you with other prescribed Information Sharing Entities (ISEs)
            for the purpose of assessing your risk, or for the purpose of
            managing your ongoing safety.
          </p>
          <p>
            Please note in cases of a serious threat or where your information
            is linked to assessing and/or managing the family violence risk to a
            child, the law permits information to be shared about any person
            without consent.
          </p>
          The court will endeavour to seek your consent prior to sharing any
          information that it is legally authorised to share. However, in the
          event that the collection, use or disclosure of confidential
          information is necessary to lessen or prevent a serious threat to an
          individual’s life, health, safety or welfare, your consent will not be
          requested.
          <h3>Who we share your information with</h3>
          <p>List of Information Sharing Entities (ISEs):</p>
          <p>
            <ul>
              <li>
                State-funded specialist family violence services including
                family violence counselling, therapeutic programs and
                perpetrator intervention
              </li>
              <li>
                State-funded sexual assault services and sexually abusive
                behaviour treatment service.
              </li>
              <li>The Orange Door (Support and Safety Hubs)</li>
              <li>Risk Assessment and Management Panels (RAMPS)</li>
              <li>Child FIRST</li>
              <li>Perpetrator intervention trials</li>
              <li>Victoria Police</li>
              <li>Corrections Victoria and Corrections funded services</li>
              <li>Adult Parole Board</li>
              <li>Magistrates’ Court officials</li>
              <li>Children’s Court officials</li>
              <li>Court-ordered family violence counselling services</li>
              <li>Justice Health and funded services (for adults)</li>
              <li>Justice Health and funded programs (for children)</li>
              <li>
                Youth Justice and funded programs, including the Youth Parole
                Board (Secretariat)
              </li>
              <li>Child Protection</li>
              <li>Multi-Agency Panels to Prevent Youth Offending</li>
              <li>Commission for Children and Young People</li>
              <li>Maternal and Child Health Services</li>
              <li>Registered community-based child and family services</li>
              <li>Out-of-home care services</li>
              <li>Disability Services Commissioner</li>
              <li>Designated mental health services</li>
              <li>State-funded alcohol and other drugs services</li>
              <li>State-funded financial counselling programs</li>
              <li>Department of Health and Human Services Housing</li>
              <li>
                State-funded homelessness accommodation or homelessness support
                services (providing access point, outreach and accommodation
                services)
              </li>
              <li>Victims of Crime Helpline</li>
              <li>Victims Assistance Program-funded services</li>
              <li>Victims Support Agency</li>
              <li>Tenancy Advice and Advocacy Program</li>
            </ul>
          </p>
        </Modal>
      )}
    </>
  );
};

export default SharingAndExtend;

const InlineTextButton = styled.button`
  display: inline;
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  color: ${colors.blue};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;
