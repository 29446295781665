import { SchemaType, SchemaFieldType, SchemaOptionType } from '../types';

const _getQuestionOptions = (
  schema: SchemaType,
  questionConfig: SchemaFieldType
): SchemaOptionType[] => {
  if (questionConfig.options === undefined) {
    throw new Error(`There are no options for ${questionConfig.name}`);
  }
  let options = questionConfig.options;
  if (typeof options === 'string') {
    options = schema.option_sets[options];
  }

  return options;
};

export const getQuestionConfig = (
  schema: SchemaType,
  name: string
): SchemaFieldType => {
  // make sure we are getting the pure machine name.
  // e.g. children[3].prp_giv_nam => prp_giv_nam
  const splitName = name.split('.');
  const schemaName =
    splitName.length > 1 ? splitName[splitName.length - 1] : name;

  // find the config
  const questionConfig = schema.fields.find(s => s.name === schemaName);

  // there should be question config throw error if not
  if (questionConfig === undefined) {
    throw new Error(`could not find config for key ${schemaName}`);
  }

  // check there are options and we need to map them
  if ('options' in questionConfig) {
    questionConfig.options = _getQuestionOptions(schema, questionConfig);
  }

  return questionConfig;
};

export const getQuestionOptions = (
  schema: SchemaType,
  name: string
): SchemaOptionType[] | null => {
  const config = getQuestionConfig(schema, name);
  if (typeof config.options === 'string') {
    return schema.option_sets[config.options];
  } else if (Array.isArray(config.options)) {
    return config.options;
  }
  return null;
};

export const getMappedOptionValue = (
  schema: SchemaType,
  name: string,
  value: string | string[] | null,
  paramOptions?: any[]
): string | string[] => {
  if (value === '' || value === null) {
    return '';
  }

  const options = paramOptions || getQuestionOptions(schema, name);

  // if options no in config return passed value
  if (options === null) {
    return value;
  }

  if (Array.isArray(value)) {
    const array = value.map(v => {
      const optionObject = options.find(o => o.value === v);
      if (optionObject === undefined) {
        throw new Error(`Unable to find value ${value} on ${name}`);
      }
      return optionObject.text;
    });
    return array;
  }

  const optionsObject = options.find(o => o.value === value);
  if (optionsObject === undefined) {
    throw new Error(
      `could not find option for question ${name} value ${value}`
    );
  }

  return optionsObject.text;
};

export const stripToolTip = (string: string): string => {
  const regex = /(\|\|)([^|]+)\|([^|]+)\|([^|]+)\|\|/gm;
  return string.replace(regex, '$2');
};
