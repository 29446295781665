import { useFormikContext } from "formik";
import styled from "styled-components/macro";
import React from "react";

import CheckboxGroup from "../../../components/CheckboxGroup";
import ClearValues from "../../../components/ClearValues";
import ConditionalFieldsWrapper from "../../../components/ConditionalFieldsWrapper";
import Info from "../../../components/Info";
import PageTitle from "../../../components/PageTitle";
import Question from "../../../components/Question";
import QuestionAddress from "../../../components/QuestionAddress";
import QuestionDatepicker from "../../../components/QuestionDatepicker";
import {
  default as QuestionFrame,
  default as QuestionsFrame,
} from "../../../components/QuestionsFrame";
import RadioGroup from "../../../components/RadioGroup";
import SubHeading from "../../../components/SubHeading";
import UnsafeToContactInfo from "../../../components/UnsafeToContactInfo";
import { FlatApplciationType } from "../../../types";
import { protectedPersonRoles, unProtectedPersonRoles } from "../constants";
import { ApplicationDataType, ChildDataType } from "csv-package";

const ContactDetails: React.FC = () => {
  const { values } = useFormikContext<FlatApplciationType>();

  return (
    <>
      <PageTitle
        heading="Contact details"
        description={
          values.seeking === "resp"
            ? "We collect this information so that the we can send documents to your most up to date address and keep you updated on the progress of your application."
            : null
        }
      />

      <ConditionalFieldsWrapper conditions={{ seeking: "appl", seeking_appl_role: unProtectedPersonRoles }}>
        <SubHeading
          heading="Applicant"
          description="We collect this information from you so that we can communicate with you about the application."
        />
      </ConditionalFieldsWrapper>

      <ConditionalFieldsWrapper conditions={{ seeking: "appl", seeking_appl_role: protectedPersonRoles }}>
        <SubHeading
          heading="Protected person"
          description="We collect this information from you so that we can communicate with you about the application."
        />
      </ConditionalFieldsWrapper>

      <QuestionsFrame>
        <Question name="appl_det_giv_nam" />
        <Question name="appl_det_giv_nam2" />
        <Question name="appl_det_giv_nam3" />
        <Question name="appl_det_fam_nam" />
        <Question name="appl_det_other_nam" />
        <QuestionDatepicker name="appl_det_dob" />
        {(values.seeking === "appl" && unProtectedPersonRoles.includes(String(values.seeking_appl_role))) ||
          values.seeking === "resp" ? (
          <QuestionFrame
            privateFrame
            privateHeading={values.seeking === "appl" ? "Private question - ||respondent|Respondent|A person who has an application for an intervention order made against them.|| will not be advised of your address if you choose to keep your current address secret." : "Private question - as the ||respondent|Respondent|A person who has an application for an intervention order made against them.||, a court registrar will advise if your address is able to be kept secret if you have chosen this option."}
          >
            <QuestionAddress
              label="What is your current address?"
              namePrefix="appl_det_addr"
              useToDetermineCourt
            />
            <RadioGroup name="appl_det_addr_secret" displayRows />
          </QuestionFrame>
        ) : (
          <ClearValues
            names={[
              "appl_det_addr_flat",
              "appl_det_addr_postcode",
              "appl_det_addr_state",
              "appl_det_addr_strname",
              "appl_det_addr_strnr",
              "appl_det_addr_sub",
              "appl_det_addr_strtype",
            ]}
          />
        )}
        <RadioGroup name="appl_sex" />
        <ConditionalFieldsWrapper conditions={{ appl_sex: "U" }}>
          <Question name="appl_det_sex_other" />
        </ConditionalFieldsWrapper>
        <ConditionalFieldsWrapper conditions={{ seeking:   ["appl", "resp"],seeking_appl_role: (values: ApplicationDataType | ChildDataType) => values.seeking === "resp" || protectedPersonRoles.includes(String(values.seeking_appl_role)) }}>
          <QuestionsFrame privateFrame>
            <RadioGroup name="appl_pregnant_baby" />
          </QuestionsFrame>
          <RadioGroup name="appl_abor_code1" />
          <RadioGroup name="appl_lgbt" />
          <RadioGroup name="appl_disabl1" />
          <ConditionalFieldsWrapper conditions={{ appl_disabl1: "yes" }}>
            <CheckboxGroup name="appl_disabl_req" />
            <ConditionalFieldsWrapper conditions={{ appl_disabl_req: "Other" }}>
              <Question name="appl_disabl_req_other" />
            </ConditionalFieldsWrapper>
          </ConditionalFieldsWrapper>
        </ConditionalFieldsWrapper>
        <RadioGroup name="appl_inter" />
        <ConditionalFieldsWrapper conditions={{ appl_inter: "yes" }}>
          <Question name="appl_lang" type="select" />
          <ConditionalFieldsWrapper conditions={{ appl_lang: "Other" }}>
            <Question name="appl_lang_other" />
          </ConditionalFieldsWrapper>
        </ConditionalFieldsWrapper>
        <ConditionalFieldsWrapper conditions={{ seeking: ["appl", "resp"], seeking_appl_role: (values: ApplicationDataType | ChildDataType) => values.seeking === "resp" || unProtectedPersonRoles.includes(String(values.seeking_appl_role)) }}>
          <CheckboxGroup
            name="appl_cont"
            disableOthers="unsafe"
            options={values.seeking==="resp" ? [
              {
                "value": "phone",
                "text": "Phone number"
              },
              {
                "value": "email",
                "text": "Email"
              },
              {
                "value": "trust",
                "text": "Through a trusted person"
              },
              {
                "value": "unsafe",
                "text": "It is not safe to contact me, I will contact the court"
              }
            ] :
            [
              {
                "value": "phone",
                "text": "Phone number"
              },
              {
                "value": "email",
                "text": "Email"
              },
            ]
          }
            optionChildren={{
              phone: (
                <>
                  <Question name="appl_phone_3" />
                  <Question name="appl_phone" />
                  <Question name="appl_phone_2" />
                </>
              ),
              email: (
                <>
                  <Question name="appl_email" />
                  <Question name="appl_email_2" />
                </>
              ),
              trust: (
                <>
                  <Question name="appl_cont_trst_per" />
                  <Question type="select" name="appl_cont_trst_per_rel" />
                  <ConditionalFieldsWrapper
                    conditions={{ appl_cont_trst_per_rel: "other" }}
                  >
                    <Question name="appl_cont_trst_per_rel_other" />
                  </ConditionalFieldsWrapper>
                  <Question name="appl_cont_trst_per_phone" />
                  <Question name="appl_cont_trst_per_email" />
                </>
              ),
              unsafe: <UnsafeToContactInfo />,
            }}
          />
        </ConditionalFieldsWrapper>
      </QuestionsFrame>

      <ConditionalFieldsWrapper conditions={{ seeking: ["appl", "resp"], seeking_appl_role: (values: ApplicationDataType | ChildDataType) => values.seeking === "resp" || unProtectedPersonRoles.includes(String(values.seeking_appl_role)) }}>
        <SubHeading
          heading="Protected Person"
          description={values.seeking !== 'resp' ? "We collect this information from you so that we can ensure the order is active for the most current address and to communicate with the protected person." : undefined}
        />
        <QuestionFrame>
          <Question name="prp_det_giv_nam" />
          <Question name="prp_det_giv_nam2" />
          <Question name="prp_det_giv_nam3" />
          <Question name="prp_det_fam_nam" />
          <ConditionalFieldsWrapper conditions={{ seeking: "appl", seeking_appl_role: unProtectedPersonRoles }}>
            <Question name="prp_other_names" />
          </ConditionalFieldsWrapper>
          <QuestionDatepicker name="prp_det_dob" />
          <ConditionalFieldsWrapper conditions={{ seeking: "appl", seeking_appl_role: unProtectedPersonRoles }}>
            <RadioGroup name="prp_sex" />
            <ConditionalFieldsWrapper conditions={{ prp_sex: "U" }}>
              <Question name="prp_sex_other" />
            </ConditionalFieldsWrapper>
            <QuestionsFrame privateFrame>
              <RadioGroup name="prp_pregnant_baby" />
            </QuestionsFrame>
            <RadioGroup name="prp_abor_code1" />
            <RadioGroup name="prp_lgbt" />
            <RadioGroup name="prp_disabl1" />
            <ConditionalFieldsWrapper conditions={{ prp_disabl1: "yes" }}>
              <CheckboxGroup name="prp_disabl_req" />
              <ConditionalFieldsWrapper conditions={{ prp_disabl_req: "Other" }}>
                <Question name="prp_disabl_req_other" />
              </ConditionalFieldsWrapper>
            </ConditionalFieldsWrapper>
            <RadioGroup name="prp_inter" />
            <ConditionalFieldsWrapper conditions={{ prp_inter: "yes" }}>
              <Question name="prp_lang" type="select" />
              <ConditionalFieldsWrapper conditions={{ prp_lang: "Other" }}>
                <Question name="prp_lang_other" />
              </ConditionalFieldsWrapper>
            </ConditionalFieldsWrapper>
          </ConditionalFieldsWrapper>
        </QuestionFrame>
      </ConditionalFieldsWrapper>

      <ConditionalFieldsWrapper conditions={{ seeking: "appl" }}>
        <QuestionFrame
          privateFrame
          privateHeading="Private question - ||respondent|Respondent|A person who has an application for an intervention order made against them.|| will not be advised of your address if you choose to keep your current address secret."
        >
          <QuestionAddress
            label={
              unProtectedPersonRoles.includes(String(values.seeking_appl_role))
                ? "What is the Protected Person’s current address?"
                : "What is your current address?"
            }
            namePrefix="prp_addr"
            // If applicant is the protected person use court based on address
            useToDetermineCourt={protectedPersonRoles.includes(String(values.seeking_appl_role))}
          />
          <RadioGroup name="prp_addr_secret" displayRows />
        </QuestionFrame>
      </ConditionalFieldsWrapper>

      <ConditionalFieldsWrapper conditions={{ seeking: "appl" }}>
        <QuestionFrame>
          <CheckboxGroup
            name="appl_safe_cont"
            label={unProtectedPersonRoles.includes(String(values.seeking_appl_role)) ? "What is the best way to contact the protected person?"
              : "What is the best way to contact you?"}
            disableOthers="unsafe"
            options={unProtectedPersonRoles.includes(String(values.seeking_appl_role)) ? [
              {
                "value": "phone",
                "text": "Phone number"
              },
              {
                "value": "email",
                "text": "Email"
              },
              {
                "value": "trust",
                "text": "Through a trusted person"
              },
              {
                "value": "unsafe",
                "text": "It is not safe to contact the protected person, she/he will contact the court"
              }
            ] : 
            [
              {
                "value": "phone",
                "text": "Phone number"
              },
              {
                "value": "email",
                "text": "Email"
              },
              {
                "value": "trust",
                "text": "Through a trusted person"
              },
              {
                "value": "unsafe",
                "text": "It is not safe to contact me, I will contact the court"
              }
            ]}
            optionChildren={{
              phone: (
                <>
                  <Question name="appl_cont_phone_3" />
                  <Question name="appl_cont_phone" />
                  <Question name="appl_cont_phone_2" />
                  <RadioGroup name="appl_cont_phone_vm" label={`${unProtectedPersonRoles.includes(String(values.seeking_appl_role)) ? "Is it safe to leave the protected person a" : "Is it safe to leave you a"} ||voicemail message?|Voicemail message|Please only select this option if the respondent cannot listen to your voicemails.||`} />
                  <CheckboxGroup name="appl_cont_safe_times" label={unProtectedPersonRoles.includes(String(values.seeking_appl_role)) ? "When is it safest for us to ring the protected person?" : "When is it safest for us to ring you?"} />
                </>
              ),
              email: (
                <>
                  <Question name="appl_cont_email" />
                  <Question name="appl_cont_email_2" />
                </>
              ),
              trust: (
                <>
                  <Question name="appl_cont_trst_per" />
                  <Question type="select" name="appl_cont_trst_per_rel" label={unProtectedPersonRoles.includes(String(values.seeking_appl_role)) ? "What is the protected person’s relationship with the trusted person?" : "What is your relationship with the trusted person?"} />
                  <ConditionalFieldsWrapper
                    conditions={{ appl_cont_trst_per_rel: "other" }}
                  >
                    <Question name="appl_cont_trst_per_rel_other" />
                  </ConditionalFieldsWrapper>
                  <Question name="appl_cont_trst_per_phone" />
                  <Question name="appl_cont_trst_per_email" />
                </>
              ),
              unsafe: <UnsafeToContactInfo />,
            }}
          />
        </QuestionFrame>
      </ConditionalFieldsWrapper>
      <ConditionalFieldsWrapper conditions={{ seeking: "appl" }}>
        <SubHeading
          heading="Respondent"
        />
        <StyledInfoContainer>
          <Info string="Why are we asking this?|Why are we asking this?|Applications and court orders need to be served on the respondent." />
        </StyledInfoContainer>
        <QuestionFrame>
          <Question name="resp_det_giv_nam" />
          <Question name="resp_det_giv_nam2" />
          <Question name="resp_det_giv_nam3" />
          <Question name="resp_det_fam_nam" />
          <QuestionDatepicker name="resp_det_dob" />
          <RadioGroup
            name="resp_addr_known"
            optionChildren={{
              unknown: (
                <RadioGroup
                  name="resp_addr_known_pc"
                  optionChildren={{
                    yes: (
                      <>
                        <Question name="resp_addr_known_pc_stn" />
                        <Question name="resp_addr_known_pc_ofc" />
                      </>
                    ),
                  }}
                />
              ),
              other: (
                <QuestionAddress
                  label="What is the respondent’s current address?"
                  namePrefix="resp_det_addr"
                />
              ),
            }}
          />
        </QuestionFrame>
      </ConditionalFieldsWrapper>
    </>
  );
};

export default ContactDetails;

const StyledInfoContainer = styled.div`
  margin: -1em 0 1em 1em;
`;