import React from "react";
import _ from "lodash";
import styled from "styled-components";

import Children from "./Children";

import PageTitle from "../../../components/PageTitle";
import QuestionFrame from "../../../components/QuestionsFrame";
import CheckboxGroup from "../../../components/CheckboxGroup";
import LongFootnote from "../../../components/LongFootnote";
import QuestionSpacer from "../../../components/QuestionSpacer";
import QuestionsFrame from "../../../components/QuestionsFrame";
import FormLabel from "../../../components/FormLabel";
import DetailQuestionAccordion from "../../../components/DetailQuestionAccordion";
import ApplicationContext from "../../../reducers/applicationFrame";
import { useFormikContext } from "formik";
import { FlatApplciationType } from "../../../types";
import colors from "../../../theme/colors";
import ConditionalFieldsWrapper from "../../../components/ConditionalFieldsWrapper";
import ConditionsAdjuster from "./ConditionsAdjuster";
import SubHeading from "../../../components/SubHeading";
import CheckboxAdvanced from "../../../components/CheckboxAdvanced";
import { hasAddAndRemoveChildren, hasOnlyAddChildren, hasOnlyRemoveChildren } from "../../../helpers/childHelpers";

const ApplicationType: React.FC = () => {
  const {
    state: { narrativeLimit, narrativeKeys },
  } = React.useContext(ApplicationContext);
  const { values } = useFormikContext<FlatApplciationType>();

  return (
    <>
      <PageTitle
        heading="What changes are you seeking to make?"
        description={`In the following section, please describe using no more than ${narrativeLimit} characters.`}
      />
      <QuestionFrame>
        <CheckboxGroup
          name="app_types"
          disableOthers={["AR", "RR"]}
          confirmationMsg={true}
          optionChildren={{
            AE: (
              <>
                <CheckboxGroup
                  name="app_type_extend_why"
                  optionChildren={{
                    still_fearful: (
                      <LongFootnote prefix="I am still fearful of the respondent because " name="app_type_extend_why_still_fearful" />
                    ),
                    further_violence: (
                      <QuestionsFrame>
                        <HiddenDiv name="app_type_extend_further_violence_check" />
                        <FormLabel text="Has the respondent..." />
                        <DetailQuestionAccordion namePrefix="incident_phy" startOpen />
                        <DetailQuestionAccordion namePrefix="incident_pys" />
                        <DetailQuestionAccordion namePrefix="incident_aep" />
                        <DetailQuestionAccordion namePrefix="incident_cym" />
                        <DetailQuestionAccordion namePrefix="incident_thy" />
                        <DetailQuestionAccordion namePrefix="incident_cbp" />
                        <DetailQuestionAccordion namePrefix="incident_fsw" />
                        <DetailQuestionAccordion namePrefix="incident_dyp" />
                        <DetailQuestionAccordion namePrefix="incident_ryf" />
                        <DetailQuestionAccordion namePrefix="incident_htc" />
                        <DetailQuestionAccordion namePrefix="incident_kha" />
                        <DetailQuestionAccordion namePrefix="incident_sty" />
                        <DetailQuestionAccordion namePrefix="incident_fmc" />
                        <DetailQuestionAccordion namePrefix="incident_cdw" />
                      </QuestionsFrame>

                    ),
                    breached: (
                      <LongFootnote prefix="The respondent has breached the order because " name="app_type_extend_why_breached" />
                    ),
                    violence_stopped: (
                      <LongFootnote
                        prefix="If the order is not in place I think the violence will start again because "
                        name="app_type_extend_why_violence_stopped"
                      />
                    ),
                  }}
                />
                <LongFootnote name="app_type_extend_add" prefix="Other reasons for seeking to extend the order are " />
                <QuestionSpacer />
              </>
            ),
            AV: (
              <>
                <ConditionsAdjuster />
                <PageTitle heading="Conditions requested on the order" />
                <SubHeading
                  heading="I want the respondent to be prevented from:"
                  description="Select one or more conditions that apply to your 
                  intervention order and provide details of the changes you are seeking. 
                  The magistrate may not include all the changes you are seeking on the order. 
                  If there are any special conditions that apply to your order, you can provide 
                  these details and the changes you are seeking in the text box for other information related to the conditions."
                />
                <QuestionsFrame>
                  <HiddenDiv name="order_codes_sort_check" />
                  <CheckboxAdvanced name="coo_con_old_a"/>
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_con_old_a: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_a_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_b" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_con_old_b: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_b_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_c" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_con_old_c: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_c_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_d" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_con_old_d: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_d_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_e" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_con_old_e: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_e_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_f" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_con_old_f: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_f_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_g" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_con_old_g: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_g_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_h" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_con_old_h: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_h_detail"
                    />
                  </ConditionalFieldsWrapper>
                </QuestionsFrame>
                <SubHeading heading="I would like exceptions included in this order. The respondent may:" />
                <QuestionsFrame>
                  <CheckboxAdvanced name="coo_exc_old_a" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_exc_old_a: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_exc_old_a_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_exc_old_b" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_exc_old_b: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_exc_old_b_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_exc_old_c" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_exc_old_c: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_exc_old_c_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_exc_old_d" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_exc_old_d: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_exc_old_d_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_exc_old_e" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_exc_old_e: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_exc_old_e_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <p>
                    BUT ONLY if the respondent does not commit family violence while doing
                    so
                  </p>
                </QuestionsFrame>
                <SubHeading heading="I want the Court to order:" />
                <QuestionsFrame>
                  <CheckboxAdvanced
                  label="The respondent must arrange to return my property within 2 days of the intervention order being served."
                  name="coo_con_old_i" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_con_old_i: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_i_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced
                  label="The respondent must arrange to return property you own together within 2 days of the intervention order being served."
                  name="coo_con_old_j" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_con_old_j: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_j_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_k" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_con_old_k: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_k_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_l" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_con_old_l: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_l_detail"
                    />
                  </ConditionalFieldsWrapper>
                </QuestionsFrame>
                <SubHeading heading="I want the Court to encourage:" />
                <QuestionsFrame>
                  <CheckboxAdvanced name="coo_con_old_m" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_con_old_m: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_m_detail"
                    />
                  </ConditionalFieldsWrapper>
                </QuestionsFrame>
                <SubHeading heading="Children’s arrangements:" />
                <QuestionsFrame>
                  <CheckboxAdvanced name="coo_con_old_n" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_con_old_n: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_n_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_o" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_con_old_o: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_o_detail"
                    />
                  </ConditionalFieldsWrapper>
                </QuestionsFrame>
                <SubHeading heading="I believe that it may jeopardise my safety and/or the safety of my child/ren for my child/ren:" />
                <QuestionsFrame>
                  <CheckboxAdvanced name="coo_con_old_p" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_con_old_p: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_p_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_q" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_con_old_q: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_q_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_r" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'AV',
                    coo_con_old_r: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_r_detail"
                    />
                  </ConditionalFieldsWrapper>
                </QuestionsFrame>
                <QuestionsFrame>
                  <LongFootnote name="coo_con_other" charLimit={1500} />
                </QuestionsFrame>
                <QuestionsFrame>
                  <LongFootnote name="vary_appl_why" prefix="I am seeking to vary the conditions on the order because " />
                </QuestionsFrame>
              </>
            ),
            AC: <>
              <Children />
              <ConditionalFieldsWrapper conditions={{
                app_types: 'AC',
                children: hasOnlyAddChildren
              }}>
                <LongFootnote
                  name="child_action_add_why"
                  prefix="I am seeking to add a child/children on the order because "
                />
              </ConditionalFieldsWrapper>
              <ConditionalFieldsWrapper conditions={{
                app_types: 'AC',
                children: hasOnlyRemoveChildren
              }}>
                <LongFootnote
                  name="child_action_remove_why"
                  prefix="I am seeking to remove a child/children on the order because "
                />
              </ConditionalFieldsWrapper>
              <ConditionalFieldsWrapper conditions={{
                app_types: 'AC',
                children: hasAddAndRemoveChildren
              }}>
                <LongFootnote
                  name="child_action_add_remove_why"
                  prefix="I am seeking to add and remove children on the order because "
                />
              </ConditionalFieldsWrapper>
            </>,
            AR: <LongFootnote name="revoke_appl_why" charLimit={500} prefix="I am seeking to revoke the order because " />,
            RV: (
              <>
                <ConditionsAdjuster />
                <PageTitle heading="Conditions requested on the order" />
                <SubHeading
                  heading="As the respondent, I am seeking changes to be prevented from:"
                  description="Select one or more conditions that apply to the intervention 
                  order and provide details of the changes you are seeking. The magistrate may 
                  not include all the changes you are seeking on the order. If there are any special 
                  conditions that apply to the order, you can provide these details and the changes you 
                  are seeking in the text box for other information related to the conditions."
                />
                <QuestionsFrame>
                  <HiddenDiv name="order_codes_sort" />
                  <CheckboxAdvanced name="coo_con_old_a"/>
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'RV',
                    coo_con_old_a: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_a_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_b" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'RV',
                    coo_con_old_b: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_b_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_c" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'RV',
                    coo_con_old_c: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_c_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_d" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'RV',
                    coo_con_old_d: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_d_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_e" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'RV',
                    coo_con_old_e: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_e_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_f" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'RV',
                    coo_con_old_f: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_f_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_g" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'RV',
                    coo_con_old_g: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_g_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_h" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'RV',
                    coo_con_old_h: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_h_detail"
                    />
                  </ConditionalFieldsWrapper>
                </QuestionsFrame>
                <SubHeading heading="I would like exceptions included in this order. As the respondent I may:" />
                <QuestionsFrame>
                  <CheckboxAdvanced name="coo_exc_old_a" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'RV',
                    coo_exc_old_a: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_exc_old_a_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_exc_old_b" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'RV',
                    coo_exc_old_b: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_exc_old_b_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_exc_old_c" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'RV',
                    coo_exc_old_c: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_exc_old_c_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_exc_old_d" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'RV',
                    coo_exc_old_d: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_exc_old_d_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_exc_old_e" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'RV',
                    coo_exc_old_e: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_exc_old_e_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <p>
                    BUT ONLY if the respondent does not commit family violence while doing
                    so
                  </p>
                </QuestionsFrame>
                <SubHeading heading="As the respondent, I am seeking changes to:" />
                <QuestionsFrame>
                  <CheckboxAdvanced
                    label="The respondent must arrange to return personal property belonging to the protected person(s) 
                    within two days of the intervention order being served."
                    name="coo_con_old_i" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'RV',
                    coo_con_old_i: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_i_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced
                    label="The respondent must arrange to return jointly owned property within two days of the intervention order being served."
                    name="coo_con_old_j" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'RV',
                    coo_con_old_j: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_j_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_k" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'RV',
                    coo_con_old_k: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_k_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_l" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'RV',
                    coo_con_old_l: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_l_detail"
                    />
                  </ConditionalFieldsWrapper>
                  <CheckboxAdvanced name="coo_con_old_m" />
                  <ConditionalFieldsWrapper conditions={{
                    app_types: 'RV',
                    coo_con_old_m: 'yes'
                  }}>
                    <LongFootnote
                      name="coo_con_old_m_detail"
                    />
                  </ConditionalFieldsWrapper>
                </QuestionsFrame>
                <SubHeading heading="Children’s arrangements:" />
                <QuestionsFrame>
                  <LongFootnote name="vary_resp_children_arr"/>
                </QuestionsFrame>
                <QuestionsFrame>
                  <LongFootnote name="vary_resp_other" charLimit={1500} />
                </QuestionsFrame>
                <QuestionsFrame>
                  <LongFootnote name="vary_resp_why" prefix="I am seeking to vary the conditions on the order because " />
                  <LongFootnote name="vary_resp_why_2" prefix="The change in circumstances since the intervention order was made are " />
                </QuestionsFrame>
              </>
            ),
            RC: <>
              <Children />
              <LongFootnote
                name="child_action_remove_why"
                prefix="I am seeking to remove a child/children on the order because "
                label= "Please provide reasons for seeking to remove a child/children in the order."
              />
            </>,
            RR: <>
              <LongFootnote name="revoke_resp_why" charLimit={500} prefix="I am seeking to revoke the order because " />
              <LongFootnote name="revoke_resp_why_2" charLimit={500} prefix="The change in circumstances since the intervention order was made are " />
            </>,
          }}
        />
      </QuestionFrame>
      {!_.isEmpty(narrativeKeys) &&
        typeof values.NarrativeCharsLeft === "number" &&
        values.NarrativeCharsLeft < 0 && (
          <NarrativeCharsOverWarning
            style={{ color: colors.error }}
            name="NarrativeCharsLeft"
          >
            Application types details when combined are over {narrativeLimit} characters.
            Please review and reduce the size of these descriptions.
          </NarrativeCharsOverWarning>
        )}
    </>
  );
};

export default ApplicationType;

const NarrativeCharsOverWarning = styled.fieldset`
  background: #f8d9dd;
  color: ${colors.error};
  padding: 15px;
  border: none;
  margin-bottom: 2rem;
  span {
    text-decoration: underline;
    cursor: pointer;
    :hover {
      text-decoration: none;
    }
  }
`;

const HiddenDiv = styled.div<{ name: string }>`
  display: hidden;
`;