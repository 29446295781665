import React from 'react';

import Checkbox from './Checkbox';

type Props = {
  disabled?: boolean;
  checked: boolean;
  onCheckboxChange: React.ChangeEventHandler<HTMLInputElement>;
  applicantName: string;
  applicantDOB: string;
};

const DeclarationOfTruth: React.FC<Props> = ({
  disabled,
  checked,
  onCheckboxChange,
  applicantName,
  applicantDOB,
}) => {
  return (
    <>
      <h2>Declaration of truth</h2>
      <p>
        By clicking Submit, you are declaring that to the best of your knowledge
        all information provided in this application is true and correct, and no
        relevant details have been left out.
      </p>
      <Checkbox
        name="declaration_of_truth"
        value="true"
        onCheckboxChange={onCheckboxChange}
        checked={checked}
        label={`I, ${applicantName} born on ${applicantDOB} make this declaration of truth and say that the contents of my application are true and correct to the best of my knowledge and belief. <br /><br />
            I understand it is an offence to knowingly make a false statement in a declaration of truth. The offence is punishable by 600 penalty units or 5 years imprisonment or both under section 153A of the <i>Family Violence Protection Act 2008</i>.

          `}
        disabled={disabled || false}
      />
    </>
  );
};

export default DeclarationOfTruth;
