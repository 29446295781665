import { useFormikContext } from "formik";
import React from "react";
import scrollIntoView from "scroll-into-view";
import styled from "styled-components";
import _ from "lodash";

import ErrorMessage from "./ErrorMessage";
import { rem } from "polished";

const ErrorSummary = () => {
  const { errors } = useFormikContext();

  const ScrollTo = (name: string) => {
    scrollIntoView(document.getElementsByName(name)[0]);
  };

  return (
    <ErrorMessage id="errors-list">
      <Title>
        Some questions had the following errors. Please click to resolve before
        continuing.
      </Title>
      <ErrorsList>
        {!_.isEmpty(errors) &&
          Object.keys(errors).map((key, index) => {
            if (key === "children") {
              return (
                <div key={`${key}-${index}`}>
                  {
                    // @ts-ignore
                    errors.children.map((childObject, childIndex) => {
                      if (typeof childObject !== "object") {
                        return null;
                      }
                      return Object.keys(childObject).map(
                        (childObjectKey, innerIndex) => (
                          <li
                            key={`children[${childIndex}].${childObjectKey}-${innerIndex}`}
                            onClick={() =>
                              ScrollTo(
                                `children[${childIndex}].${childObjectKey}`
                              )
                            }
                            data-name={`children[${childIndex}].${childObjectKey}`}
                          >
                            {
                              // @ts-ignore
                              errors.children[childIndex][childObjectKey]
                            }
                          </li>
                        )
                      );
                    })
                  }
                </div>
              );
            } else {
              return (
                <li
                  key={`${key}-${index}`}
                  onClick={() => {
                    ScrollTo(key);
                  }}
                  data-name={key}
                >
                  {
                    // @ts-ignore
                    errors[key]
                  }
                </li>
              );
            }
          })}
      </ErrorsList>
    </ErrorMessage>
  );
};

export default ErrorSummary;

const ErrorsList = styled.ul`
  text-decoration: underline;
  text-decoration-color: black;
  font-weight: 600;

  li:hover {
    cursor: pointer;
  }
`;

const Title = styled.h4`
  margin-top: 0;
  font-size: ${rem(18)};
`;
