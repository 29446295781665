import { useFormikContext } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { ApplicationDataType, ApplicationType } from "csv-package";

import api from "../helpers/api";
import {
  ApplicationContext,
  ReducerActionType,
} from "../reducers/applicationFrame";
import Modal from "./Modal";
import { FlatApplciationType } from "../types";

type Props = {
  dispatch: React.Dispatch<ReducerActionType>;
};
const AutoSaver: React.FC<Props> = ({ dispatch }) => {
  const { id } = useParams<{ id: string }>();

  const {
    state: { savingInProgress },
  } = React.useContext(ApplicationContext);
  const { values, setFieldValue } = useFormikContext<FlatApplciationType>();
  const [lastSavedState, setLastSavedState] = React.useState(values);
  const [failedPatchCount, setFailedPatchCount] = React.useState(0);

  const actionSave = React.useCallback(async () => {
    if (savingInProgress) {
      return null;
    }

    // key/field names that don't need to be synced to the DB
    const keyBlackList = ["court_address_is_valid", "form_is_submitted"];

    const arrayOfKeys = Object.keys(
      _.omit(values, [
        "PK",
        "SK",
        "Created",
        "Identity",
        "Notes",
        "ReDeclarations",
        "RevisionLog",
        "StatusUpdated",
        "User",
        "NarrativeCharsLeft",
      ])
    );

    const fieldsToBeSaved = arrayOfKeys.reduce((acc, key) => {
      if (!_.isEqual(_.get(values, key, ""), _.get(lastSavedState, key, ""))) {
        if (keyBlackList.includes(key)) {
          return acc;
        }

        return {
          ...acc,
          [key]: _.get(values, key, ""),
        };
      } else {
        return acc;
      }
    }, {}) as ApplicationType & ApplicationDataType;

    // omit black list and check if anything is to be sent to drupal
    if (Object.keys(fieldsToBeSaved).length >= 1) {
      dispatch({ type: "startSaving" });
      try {
        const reducerBase = { Data: {} };
        // transform data into required shape
        const payload = Object.keys(fieldsToBeSaved).reduce(
          (accumulator, currentKey) => {
            if (currentKey.charAt(0) === currentKey.charAt(0).toLowerCase()) {
              // remove nulls
              if (fieldsToBeSaved[currentKey] === null) {
                return accumulator;
              }
              return {
                ...accumulator,
                Data: {
                  ...accumulator.Data,
                  [currentKey]: fieldsToBeSaved[currentKey],
                },
              };
            }
            return {
              ...accumulator,
              [currentKey]: fieldsToBeSaved[currentKey],
            };
          },
          reducerBase
        );

        if (!_.isEqual(reducerBase, payload)) {
          // do API call
          const result = (await api(
            "patch",
            `/application/${id}`,
            payload
          )) as ApplicationType;

          // check if app submitted in return payload, this is used to make 100% sure the applciation is submitted
          if (
            result &&
            result.hasOwnProperty("Status") &&
            result.Status === "submitted"
          ) {
            setFieldValue("form_is_submitted", "yes");
          }
        }
      } catch (error) {
        console.log(error);
        setFailedPatchCount((n) => n + 1);
        dispatch({ type: "endSaving" });
        return null;
      }

      dispatch({ type: "endSaving" });
    }
    setLastSavedState(values);
  }, [dispatch, lastSavedState, id, savingInProgress, setFieldValue, values]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      actionSave();
    }, 5000);
    return () => clearInterval(interval);
  }, [actionSave]);

  if (failedPatchCount > 8) {
    return (
      <Modal onClose={() => setFailedPatchCount(0)}>
        <h4>
          Your connection to the online form has been temporarily disrupted or
          lost. Please check your internet connection to ensure it is working
          and that your device is reliably connected to the internet.
        </h4>
        <h4>
          After reconnection to the internet, please refresh your browser and
          review all information in the online form to make sure that all
          required fields have been completed.
        </h4>
      </Modal>
    );
  }
  return null;
};

export default AutoSaver;
