import React from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

import { schemaEVR as schema } from "csv-package";
import ApplicationFrame from "../../../components/ApplicationFrame";

import Complete from "../components/Complete";
import ReDeclare from "../components/ReDeclare";
import ReDeclareComplete from "../components/ReDeclareComplete";

import steps from "../steps";

const ApplicationEVR: React.FC = () => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    ReactGA.pageview(pathname);
  }, [pathname]);

  return (
    <ApplicationFrame
      schema={schema}
      steps={steps}
      complete={<Complete />}
      reDeclare={<ReDeclare />}
      reDeclareComplete={<ReDeclareComplete />}
      compatibleTypes={["E", "V", "R", "EV", "EVR"]}
      narrativeLimitConfig={{
        keys: [
          "app_type_extend_why_still_fearful",
          "incident_phy_detail",
          "incident_phy_date",
          "incident_pys_detail",
          "incident_pys_date",
          "incident_aep_detail",
          "incident_aep_date",
          "incident_cym_detail",
          "incident_cym_date",
          "incident_thy_detail",
          "incident_thy_date",
          "incident_cbp_detail",
          "incident_cbp_date",
          "incident_fsw_detail",
          "incident_fsw_date",
          "incident_dyp_detail",
          "incident_dyp_date",
          "incident_ryf_detail",
          "incident_ryf_date",
          "incident_htc_detail",
          "incident_htc_date",
          "incident_kha_detail",
          "incident_kha_date",
          "incident_sty_detail",
          "incident_sty_date",
          "incident_fmc_detail",
          "incident_fmc_date",
          "incident_cdw_detail",
          "incident_cdw_date",
          "app_type_extend_why_breached",
          "app_type_extend_why_violence_stopped",
          "app_type_extend_add",
          "coo_con_old_a_detail",
          "coo_con_old_b_detail",
          "coo_con_old_c_detail",
          "coo_con_old_d_detail",
          "coo_con_old_e_detail",
          "coo_con_old_f_detail",
          "coo_con_old_g_detail",
          "coo_con_old_h_detail",
          "coo_con_old_i_detail",
          "coo_con_old_j_detail",
          "coo_con_old_k_detail",
          "coo_con_old_l_detail",
          "coo_con_old_m_detail",
          "coo_con_old_n_detail",
          "coo_con_old_o_detail",
          "coo_con_old_p_detail",
          "coo_con_old_q_detail",
          "coo_con_old_r_detail",
          "coo_exc_old_a_detail",
          "coo_exc_old_b_detail",
          "coo_exc_old_c_detail",
          "coo_exc_old_d_detail",
          "coo_exc_old_e_detail",
          "vary_appl_why",
          "children[x].prp_sex_other",
          "children[x].prp_flc_other_po",
          "children[x].prp_flc_other",
          "child_action_add_why",
          "child_action_remove_why",
          "child_action_add_remove_why",
          "revoke_appl_why",
          "vary_resp_why",
          "vary_resp_why_2",
          "vary_resp_children_arr",
          "revoke_resp_why",
          "revoke_resp_why_2",
        ],
        limit: 3500,
        step: 3,
      }}
    />
  );
};

export default ApplicationEVR;
