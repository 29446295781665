import { FormikContextType } from "formik";
import _ from "lodash";
import scrollIntoView from "scroll-into-view";
import { FlatApplciationType } from "../types";
import {
  ReducerActionType,
  ReducerStateType,
} from "../reducers/applicationFrame";

const goToStepCurried = async (
  dispatch: React.Dispatch<ReducerActionType>,
  state: ReducerStateType,
  formik: FormikContextType<FlatApplciationType>,
  step: number
) => {
  if (step < state.currentStep) {
    dispatch({ type: "setStep", step });
  }

  const errors = await formik.validateForm();
  if (_.isEmpty(errors)) {
    dispatch({ type: "setStep", step });
    dispatch({ type: "hideErrors" });
    window.scrollTo(0, 0);
  } else {
    dispatch({ type: "showErrors" });
    const errorListEl = document.getElementById("errors-list");
    if (errorListEl) {
      scrollIntoView(errorListEl);
    }
    // set tocuhed on fields with errors so individual errors messages show up on question itself
    Object.keys(errors).forEach((k) => {
      if (k === "children") {
        // @ts-ignore
        errors.children.forEach((child, childIndex) => {
          if (typeof child === "object") {
            Object.keys(child).forEach((ck) =>
              formik.setFieldTouched(`children[${childIndex}].${ck}`)
            );
          }
        });
      } else {
        formik.setFieldTouched(k);
      }
    });
    // run for a second time for some reason  ¯\_(ツ)_/¯
    formik.validateForm();
  }
};

export default _.curry(goToStepCurried);
