import Yup from "../yup";
import { protectedPersonRoles } from "./constants";

const review = {
  appl_info_share: Yup.string().when(["Status", "seeking", "seeking_appl_role"], {
    is: (status: string, seeking: string, seeking_appl_role: string) => status === 'initiated' && seeking === "appl" && protectedPersonRoles.includes(String(seeking_appl_role)),
    then: Yup.string().required('Please select a information sharing option'),
  }),
  appl_info_share_limited: Yup.array()
    .ensure()
    .when('appl_info_share', {
      is: 'limited',
      then: Yup.array()
        .ensure()
        .min(1, 'Please select at least one Information Sharing Entities'),
    }),
  declaration_of_truth: Yup.boolean()
    .oneOf([true], 'You must agree with the declaration of truth')
    .required('You must agree with the declaration of truth'),
  appl_reminder_cont_phone: Yup.string()
    .when('appl_cont_sms_consent', {
      is: 'yes',
      then: Yup.string().trim().ausPhone().required('Valid Australian mobile number is required to send SMS reminder'),
    }),
};
export default review;
