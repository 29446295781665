import applicationType from './applicationType';
import child from './child';
import contactDetails from './contactDetails';
import exisitingOrder from './exisitingOrder';
import relatedOrders from './relatedOrders';
import review from './review';
import yourRole from './yourRole';

export const fvioEvrValidation = {
  applicationType,
  child,
  contactDetails,
  exisitingOrder,
  relatedOrders,
  review,
  yourRole
};
