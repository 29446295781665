import React from "react";
import styled from "styled-components/macro";
import { rem } from "polished";

import processHelpText, { ProcessedHelpText } from "../helpers/processHelpText";
import Info from "./Info";

type Props = {
  text: string;
};

const FormNote: React.FunctionComponent<Props> = ({ text }) => {
  const processedText = React.useMemo<ProcessedHelpText | false>(
    () => processHelpText(text),
    [text]
  );

  if (!text || text === "") {
    return null;
  }

  if (processedText) {
    let { after, before } = processedText;

    return (
      <Note>
        {before && before}
        <Info inherit string="" {...processedText} />
        {after && after}
      </Note>
    );
  }

  return text ? <Note>{text}</Note> : null;
};

export default FormNote;

export const Note = styled.p`
  color: #585858;
  font-size: 15px;
  line-height: 1.2;
  margin-bottom: ${rem(15)};
  margin-top: ${rem(-4)};
  font-weight: 300;
  z-index: 1001;
`;
