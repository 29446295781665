import { useFormikContext } from "formik";
import React from "react";
import _ from "lodash";

import PageTitle from "../../../components/PageTitle";
import QuestionsFrame from "../../../components/QuestionsFrame";
import RadioGroup from "../../../components/RadioGroup";
import useChildren from "../../../hooks/useChildren";
import { FlatApplciationType } from "../../../types";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import Child from "./Child";

const Children = () => {
  const [confirmModal, setConfirmModal] = React.useState<boolean>(false);
  const {
    values: { children, ap_children_exposed },
    setFieldValue,
  } = useFormikContext<FlatApplciationType>();
  const { addChild, removeChild, removeAllChildren } = useChildren();

  // when ap_children_exposed changes and is yes and children is empty
  React.useEffect(() => {
    if (ap_children_exposed === "yes" && _.isEmpty(children)) {
      addChild();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ap_children_exposed]);

  // when ap_children_exposed yes and children change to []
  React.useEffect(() => {
    if (ap_children_exposed === "yes" && _.isEmpty(children)) {
      setFieldValue("ap_children_exposed", "no");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  // when ap_children_exposed no and there is children data
  React.useEffect(() => {
    if (ap_children_exposed !== "yes" && !_.isEmpty(children)) {
      setConfirmModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ap_children_exposed]);

  return (
    <>
      <PageTitle
        heading="Affected children"
        description="If you want to include a child/children under 18 years of age as part of the intervention order, please provide their details."
      />
      <QuestionsFrame>
        <RadioGroup name="ap_children" />
        <RadioGroup name="ap_children_exposed" />
      </QuestionsFrame>

      {ap_children_exposed === "yes" &&
        !_.isEmpty(children) &&
        children.map((_, childIndex) => (
          <div key={childIndex}>
            <Child
              showAddButton={
                childIndex < 8 && children.length - 1 === childIndex
              }
              addChild={addChild}
              childIndex={childIndex}
              removeChild={removeChild}
            />
          </div>
        ))}

      {confirmModal && (
        <Modal
          onClose={(e) => {
            e.preventDefault();
            setConfirmModal(false);
            setFieldValue("ap_children_exposed", "yes");
          }}
        >
          <h4>
            Changing the response to this question will clear all child
            information previously entered.
          </h4>
          <div className="buttons">
            <Button
              variant="secondary"
              onClick={(e) => {
                e.preventDefault();
                setConfirmModal(false);
                setFieldValue("ap_children_exposed", "yes");
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                removeAllChildren();
                setConfirmModal(false);
              }}
            >
              Continue
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Children;
