import React from "react";
import styled from "styled-components/macro";
import { Redirect, Link, useHistory, useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import { Yup } from "csv-package";
import ReactGA from "react-ga";
import queryString from "query-string";

import {
  isAuthenticated,
  hasAnonymousIdentity,
  login,
  createAndStoreUser,
} from "../helpers/auth";
import { drupalGetUser } from "../helpers/drupalAPI";
import api from "../helpers/api";

import QuestionsFrame from "../components/QuestionsFrame";
import Button from "../components/Button";
import LoadingIndicator from "../components/LoadingIndicator";
import StandaloneQuestion from "../components/StandaloneQuestion";
import Modal from "../components/Modal";
import QuestionSpacer from "../components/QuestionSpacer";
import {
  configApplicationType,
  configJurisdiction,
} from "../config/systemConfig";

type FormValues = {
  email: string;
  password: string;
};

const Login: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const [hasError, setHasError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [redirectPath, setRedirectPath] =
    React.useState<string>("/applications");
  const [authenticated, setAuthenticated] = React.useState<boolean | null>(
    null
  );

  // on mount
  React.useEffect(() => {
    ReactGA.pageview(location.pathname);

    // setup for redirecting re-declare users
    const parsed = queryString.parse(history.location.search);
    if ("redirect" in parsed && typeof parsed.redirect === "string") {
      setRedirectPath(parsed.redirect);
    }
    const checkAuth = async () => {
      const userIsAuthenticated = await isAuthenticated();
      setAuthenticated(userIsAuthenticated);
    };

    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initalValues: FormValues = {
    email: "",
    password: "",
  };

  const handleSubmit = async (values: FormValues): Promise<void> => {
    setLoading(true);

    try {
      // try to login with cognito first
      const cognitoLoginSuccessful = await login(values.email, values.password);

      if (cognitoLoginSuccessful) {
        // If ok send user to their applications
        setLoading(false);
        history.push(redirectPath);
      } else if (
        configJurisdiction === "FVIO" &&
        configApplicationType === "N"
      ) {
        // if new fvio application
        // and if no cognito user try looking in previous system (drupal)

        // get the drupal user so we can get the given_name
        const drupalUserDetails = await drupalGetUser(
          values.email,
          values.password
        );

        // if no drupal user was found throw error / exit
        if (drupalUserDetails === null) {
          throw new Error("No drupal user found");
        }

        // If we get here there was a durpal user
        // lets create a user now that we know their drupal login and password
        try {
          // create user in cognito
          await createAndStoreUser({
            email: values.email,
            password: values.password,
            given_name: drupalUserDetails!.attributes!.user_name_first || "",
          });

          await login(values.email, values.password);

          // use the convert endpoint that will add idenitity to their applications
          await api("post", "/transition/convert", {
            email: values.email,
            password: values.password,
          });

          // Send user to their applciations
          history.push(redirectPath);
        } catch (e) {
          throw new Error("");
        }
      } else {
        // No cognito user and don't need to check durpal show error message
        throw new Error("Username and password fail");
      }
    } catch (error) {
      // Something went wrong show error popup
      setLoading(false);
      setHasError(true);
    }
  };

  if (authenticated === null) {
    return null;
  }

  if (!authenticated && hasAnonymousIdentity()) {
    return <Redirect to="/convert" />;
  }

  if (authenticated) {
    return <Redirect to={redirectPath} />;
  }

  return (
    <>
      <Wrapper>
        <Inner>
          <br />
          <br />
          <br />
          <Formik
            validateOnBlur
            initialValues={initalValues}
            validationSchema={Yup.object().shape({
              email: Yup.string().required("Username is required"),
              password: Yup.string()
                .required("Password is required")
                .min(8, "Passwords is 8 characters or longer"),
            })}
            onSubmit={handleSubmit}
          >
            {(formikProps) => (
              <Form>
                <QuestionsFrame>
                  <h1>Log back into an application</h1>
                  <p>
                    Haven’t started an application yet?{" "}
                    <Link to="/signup">Start an application here</Link>
                  </p>
                  <StandaloneQuestion
                    label="Username"
                    name="email"
                    autoComplete="username"
                    note={`||Forgot your username|Forgot your username|Please contact your <a href="https://www.mcv.vic.gov.au/going-court/find-court" title="_blank" rel="noopener noreferrer"> local court</a> during business hours for assistance.||`}
                    notePosition="right"
                  />
                  <StandaloneQuestion
                    type="password"
                    label="Password"
                    name="password"
                    autoComplete="current-password"
                    note={`||Forgot your password|Forgot your password|Please contact your <a href="https://www.mcv.vic.gov.au/going-court/find-court" title="_blank" rel="noopener noreferrer"> local court</a> during business hours to reset your password.||`}
                    notePosition="right"
                  />
                  {loading ? (
                    <LoadingIndicator />
                  ) : (
                    <Button as="button" disabled={!formikProps.isValid}>
                      Login
                    </Button>
                  )}
                </QuestionsFrame>
              </Form>
            )}
          </Formik>
        </Inner>
      </Wrapper>
      {hasError && (
        <Modal>
          <div style={{ textAlign: "center" }}>
            <p>There has been an error with your account.</p>
            <p>
              Please contact your{" "}
              <a
                href="https://www.mcv.vic.gov.au/going-court/find-court"
                target="_blank"
                rel="noreferrer"
                title="local court"
              >
                local court
              </a>{" "}
              for assistance.
            </p>

            <QuestionSpacer />
            <Button variant="alert" onClick={() => setHasError(false)}>
              Close
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Login;

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const Inner = styled.div`
  width: 100%;
  max-width: 680px;
`;
