import React from "react";

import ConfirmSection from "../../../components/ConfirmSection";
import PageTitle from "../../../components/PageTitle";

const Review = () => {
  return (
    <>
      <PageTitle heading="Confirm the details of your application" />
      <ConfirmSection
        heading="Applicant"
        goToStepIndex={0}
        config={[
          {
            names: [
              "appl_det_giv_nam",
              "appl_det_giv_nam2",
              "appl_det_giv_nam3",
              "appl_det_fam_nam",
              "appl_other_names",
              "appl_det_dob",
              "appl_det_sex",
              "appl_det_sex_other",
              "appl_pregnant_baby",
              "appl_abor_code",
              "appl_lgbt",
              "appl_disabl",
              "appl_disabl_req",
              "appl_disabl_req_other",
              "appl_det_inter",
              "appl_det_lang",
              "appl_det_lang_other",
            ],
          },
        ]}
      />

      <ConfirmSection
        heading="Applicant safe contact details"
        config={[
          {
            names: [
              "appl_safe_cont",
              "appl_cont_phone_3",
              "appl_cont_phone",
              "appl_cont_phone_2",
              "appl_cont_phone_vm",
              "appl_cont_safe_times",
              "appl_cont_email",
              "appl_cont_email_2",
              "appl_cont_trst_per",
              "appl_cont_trst_per_rel",
              "appl_cont_trst_per_rel_other",
              "appl_cont_trst_per_phone",
              "appl_cont_trst_per_email",
            ],
          },
          {
            private:
              "Private area - the Respondent will not see your answers for Contact details.",
            names: ["address__appl_det_addr", "appl_det_addr_secret"],
          },
        ]}
      />

      <ConfirmSection
        heading="Respondent"
        goToStepIndex={2}
        config={[
          {
            names: ["resp_giv_nam", "resp_fam_nam", "resp_other_nam"],
          },
        ]}
      />

      <ConfirmSection
        heading="Relationship with respondent"
        config={[
          {
            names: ["appl_rel_to_resp", "resp_known_lbl"],
          },
        ]}
      />

      <ConfirmSection
        heading="What is the best way to contact the respondent?"
        config={[
          {
            names: [
              "resp_addr_known",
              "address__resp_det_addr",
              "resp_addr_type",
              "resp_addr_type_other",
              "resp_contact_type",
              "resp_contact_ph_3",
              "resp_contact_ph",
              "resp_contact_ph_2",
              "resp_contact_email",
              "resp_contact_email_2",
              "resp_contact_other",
            ],
          },
        ]}
      />

      <ConfirmSection
        heading="Conditions on the order"
        config={[
          {
            names: [
              "coo_con_old_a",
              "coo_con_old_b",
              "coo_con_old_c",
              "coo_con_old_d",
              "coo_con_old_e",
              "coo_con_old_f",
              "coo_con_old_g",
              "coo_con_old_h",
              "coo_exc_old_a",
              "coo_exc_old_b",
              "coo_exc_old_c",
              "coo_exc_old_d",
              "coo_exc_old_e",
              "coo_con_old_i",
              "coo_con_old_j",
              "coo_con_old_k",
              "coo_con_old_l",
              "coo_con_old_m",
              "coo_con_old_n",
              "coo_con_old_o",
              "coo_con_old_p",
              "coo_con_old_q",
              "coo_con_old_r",
              "coo_con_other",
            ],
          },
        ]}
      />
    </>
  );
};

export default Review;
