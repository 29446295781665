import { get } from "lodash";
import { rem } from "polished";
import React from "react";
import styled from "styled-components/macro";
import _ from "lodash";
import { useFormikContext } from "formik";
import {
  SchemaFieldType,
  getMappedOptionValue,
  getQuestionConfig,
  stripToolTip,
} from "csv-package";

import colors from "../theme/colors";

import { ApplicationContext } from "../reducers/applicationFrame";
import { FlatApplciationType } from "../types";
import isStrings from "../helpers/isStrings";

const ConfirmQuestion: React.FC<{ name: string, label?: string}> = ({ name, label=undefined }) => {
  const { values } = useFormikContext<FlatApplciationType>();
  const {
    state: { schema },
  } = React.useContext(ApplicationContext);

  let value = null;
  let questionConfig: SchemaFieldType | null = null;

  const addressQuestion = name.substring(0, 9) === "address__";

  if (!addressQuestion) {
    value = _.get(values, name, "");
    questionConfig = getQuestionConfig(schema, name);
    try {
      if (typeof value === "string" || (value && isStrings(value))) {
        try {
          value = getMappedOptionValue(schema, name, value);
        } catch (error) {
          // do nothing
        }
      }
    } catch (e) {
      // do nothing
    }
  } else if (addressQuestion) {
    const namePrefix = name.replace("address__", "");
    const flat = get(values, `${namePrefix}_flat`, "") || "";
    const postcode = get(values, `${namePrefix}_postcode`, "");
    const state = get(values, `${namePrefix}_state`, "");
    const strname = get(values, `${namePrefix}_strname`, "");
    const strnr = get(values, `${namePrefix}_strnr`, "");
    const strtype = get(values, `${namePrefix}_strtype`, "");
    const sub = get(values, `${namePrefix}_sub`, "");
    if (postcode !== "" && sub !== "") {
      value = `${flat} ${strnr} ${strname} ${strtype}, ${sub} ${state} ${postcode}`;
    } else {
      value = "";
    }
    questionConfig = { name: "", label: "Current address" };
  }

  if (!value || !questionConfig) {
    return null;
  }

  return (
    <ConfirmQuestionWrapper>
      <Label>{stripToolTip(label ? label : questionConfig.label)}</Label>
      <Value>
        {Array.isArray(value) && isStrings(value) ? (
          <ul>
            {value.map((v) => (
              <li key={v}>{v}</li>
            ))}
          </ul>
        ) : (
          value
        )}
      </Value>
    </ConfirmQuestionWrapper>
  );
};

export default ConfirmQuestion;

const ConfirmQuestionWrapper = styled.div`
  border-bottom: 1px solid #d4e1ed;
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Label = styled.label`
  font-size: ${rem(16)};
  color: ${colors.primary};
  width: 100%;
  max-width: 330px;
  padding-right: ${rem(45)};
`;

const Value = styled.div`
  color: ${colors.blue};
  margin: 0;
  max-width: ${rem(320)};
  ul {
    padding: 0;
    margin: 0;
  }
`;
