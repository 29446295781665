import React from "react";
import numeral from "numeral";

import CheckboxGroup from "../../../components/CheckboxGroup";
import LongFootnote from "../../../components/LongFootnote";
import PageTitle from "../../../components/PageTitle";
import Question from "../../../components/Question";
import QuestionDatepicker from "../../../components/QuestionDatepicker";
import QuestionDuration from "../../../components/QuestionDuration";
import QuestionsFrame from "../../../components/QuestionsFrame";
import QuestionSpacer from "../../../components/QuestionSpacer";
import RadioGroup from "../../../components/RadioGroup";
import SubHeading from "../../../components/SubHeading";
import ApplicationContext from "../../../reducers/applicationFrame";

const incidentKeys = ["assult", "threat", "damage", "stalked", "other"];

const IncidentHistory = () => {
  const {
    state: { narrativeLimit },
  } = React.useContext(ApplicationContext);

  const incidentTypesChildren = incidentKeys.reduce((children, incidentKey) => {
    return {
      ...children,
      [incidentKey]: (
        <>
          <CheckboxGroup
            name={`incident_${incidentKey}`}
            indentChildren={false}
            compact
            optionChildren={{
              recent: (
                <>
                  <LongFootnote name={`incident_${incidentKey}_recent`} />
                  <QuestionSpacer />
                </>
              ),
              previous: (
                <>
                  <LongFootnote name={`incident_${incidentKey}_previous`} />
                  <QuestionSpacer />
                </>
              ),
            }}
          />
        </>
      ),
    };
  }, {});

  return (
    <>
      <PageTitle
        heading="Incident history"
        description="The purpose of this section is to gather information about the actions of the respondent who displayed prohibited behaviour towards the affected person. "
      />
      <QuestionsFrame>
        <QuestionDatepicker name="incident_date" />
        <QuestionDuration name="violence_duration" />
      </QuestionsFrame>
      <SubHeading
        heading="Details of incidents"
        description={`In the following section, please describe what happened using no more than ${numeral(
          narrativeLimit
        ).format("0,0")} characters. See an example of what to write.`}
      />
      <QuestionsFrame>
        <CheckboxGroup
          name="incident_types"
          optionChildren={incidentTypesChildren}
        />
        <LongFootnote name="incident_other" />
      </QuestionsFrame>

      <SubHeading heading="Additional information" />
      <QuestionsFrame>
        <RadioGroup
          name="child_affected"
          optionChildren={{
            yes: <LongFootnote name="child_affected_detail" />,
          }}
        />
        <RadioGroup name="resp_charged" />
      </QuestionsFrame>
      <QuestionsFrame privateFrame>
        <RadioGroup
          name="police_contacted"
          optionChildren={{
            yes: (
              <>
                <Question name="police_station" />
                <Question name="police_officer" />
              </>
            ),
          }}
        />
      </QuestionsFrame>
    </>
  );
};

export default IncidentHistory;
