import React from "react";
import _ from "lodash";
import { SchemaOptionType, getQuestionConfig } from "csv-package";

import ApplicationContext from "../reducers/applicationFrame";

type QuestionConfigType = {
  label?: string;
  note?: string;
  options?: SchemaOptionType[];
  default?: string | string[];
};

const useQuestionConfig = (
  name: string,
  config: QuestionConfigType
): QuestionConfigType => {
  const {
    state: { schema },
  } = React.useContext(ApplicationContext);

  return React.useMemo(() => {
    const questionConfig = getQuestionConfig(schema, name);

    const cleanedProps = _(config).omit(["name"]).pickBy(_.identity).value();

    return _.merge(questionConfig, cleanedProps) as QuestionConfigType;
  }, [config, name, schema]);
};

export default useQuestionConfig;
