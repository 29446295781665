import Yup from "../yup";

const previousOrders = {
  pio_fio: Yup.string().trim().required("We require this information"),
  pio_gio: Yup.string().when("pio_fio", {
    is: "no",
    then: Yup.string().required("We require this information"),
  }),
  pio_bio: Yup.string().when("pio_gio", {
    is: "yes",
    then: Yup.string().required("We require this information"),
  }),
  pio_crt_oth: Yup.string().when("pio_crt", {
    is: "other",
    then: Yup.string().required("We require this information"),
  }),
  pio_sub_io: Yup.string().trim().required("We require this information"),
  pio_sub_io_given: Yup.string().when("pio_sub_io", {
    is: "yes",
    then: Yup.string().required("We require this information"),
  }),
  pio_ref_nr: Yup.string().when("pio_fio", {
    is: "no",
    then: Yup.string().matches(/^[a-zA-Z]{1}[0-9]{8}$/, {
      excludeEmptyString: true,
      message: "Must be an alphabetical letter followed by 8 numbers",
    }),
  }),
  pio_crt_matters: Yup.string().when(["Submitted", "$Submitted"], {
    is: (formSubmitted: string, registrarSubmitted: string) => {

      /* We have to use two different methods to access the Submitted value
       * In the registrar we make use of Yup context to access the value
       * however, in the FVIO application form we have direct access
       */
      const submitted = registrarSubmitted ? registrarSubmitted : formSubmitted;

      // Required if submitted after date
      return !submitted || submitted > "2022-06-01";
    },
    then: Yup.string().trim().required("We require this information"),
  }),
  pio_crt_matters_given: Yup.string().when("pio_crt_matters", {
    is: "yes",
    then: Yup.string().required("We require this information"),
  }),
};

export default previousOrders;