import React from "react";
import styled, { css } from "styled-components";
import _ from "lodash";

import { ReactComponent as DownArrowOutlined } from "../assets/icons/DownArrowOutlined.svg";
import Question from "./Question";
import Info from "./Info";
import { useFormikContext } from "formik";
import useQuestionConfig from "../hooks/useQuestionConfig";
import media from "../theme/media";

type Props = {
  namePrefix: string;
  label?: string;
  note?: string;
  startOpen?: boolean;
};

const DetailQuestionAccordionHooks: React.FC<Props> = React.memo(
  ({ namePrefix, startOpen, ...rest }) => {
    const { values } = useFormikContext();
    const [isOpen, setIsOpen] = React.useState<boolean>(startOpen || false);

    const detailName = `${namePrefix}_detail`;
    const detailValue = _.get(values, detailName);
    const dateName = `${namePrefix}_date`;
    const { label, note } = useQuestionConfig(namePrefix, rest);

    return React.useMemo(() => {
      return (
        <Wrapper>
          <QuestionWrapper
            onClick={(e) => {
              e.preventDefault();
              setIsOpen((o) => !o);
            }}
          >
            <span>{label}</span>
            <DownIcon isopen={isOpen ? "yes" : undefined} />
          </QuestionWrapper>
          <Options isOpen={isOpen ? "yes" : undefined} isPopulated={detailValue} >
            <QuestionCont>
              <Question name={dateName} label="Date or approximate date of when it happened" />
            </QuestionCont>
            <QuestionCont>
              <Question
                name={detailName}
                note={`Please provide the details of how the respondent has ${label}`}
                prefix="The respondent has "
                label=" "
              />
            </QuestionCont>
            <InfoWrapper>
              <Info string={`?|${note}`} />
            </InfoWrapper>
          </Options>
        </Wrapper>
      );
      // eslint-disable-next-line
    }, [isOpen, detailValue]);
  }
);

export default DetailQuestionAccordionHooks;

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 7px;
  position: relative;
  padding-right: 22px;
`;

const QuestionWrapper = styled.button`
  width: 100%;
  min-height: 48px;
  padding: 5px 15px 5px;
  height: 100%;
  background: rgba(195, 214, 234, 0.2);
  margin: 0;
  font-size: 18px;
  color: rgba(14, 93, 171, 0.9701);
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  border-radius: 4px;
  text-align: left;
  cursor: pointer;
  &:after {
    content: "";
    min-height: inherit;
    font-size: 0;
  }

  span {
    margin-right: auto;
    max-width: calc(100% - 20px);
  }
`;

const Options = styled.div<{ isOpen?: string; isPopulated?: boolean }>`
  border-left: 47px solid transparent;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s linear;

  &:before {
    content: "";
    display: block;
    height: 7px;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      max-height: calc((48px * 6) + 14px);
    `}

  ${({ isOpen, isPopulated }) =>
    isOpen &&
    isPopulated &&
    css`
      max-height: calc((48px * 6) + 170px);
    `}

  label {
    align-self: center;
  }
  ${media.lessThan("tablet")`
    border-left: 25px solid transparent;
  `}
`;

const InfoWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 0;
  transform: translateY(-50%);
`;

const DownIcon = styled(DownArrowOutlined) <{ isopen?: string }>`
  width: 11px;
  height: 8px;
  transition: all 0.2s linear;
  ${({ isopen }) =>
    isopen === "yes" &&
    css`
      transform: rotate(180deg);
    `}
`;

const QuestionCont = styled.div`
  margin-top: 12px;
  > div {
    margin-bottom: 7px;

    input {
      margin-top: -10px;
    }
  }
`;
