import React from "react";
import styled from "styled-components/macro";
import { rem } from "polished";

import spacing from "../theme/spacing";
import colors from "../theme/colors";

type Props = {
  heading: string;
  description?: string | React.ReactNode;
};

const PageTitle: React.FC<Props> = React.memo(
  ({ heading, description }) => {
    return (
      <PageTitleContainer>
        <Heading aria-live="assertive">{heading}</Heading>
        {description && typeof description === "string" ? (
          <p>{description}</p>
        ) : description && typeof description !== "string" ? (
          description
        ) : null}
      </PageTitleContainer>
    );
  },
  (p, n) => true
);

export default PageTitle;

const PageTitleContainer = styled.div`
  margin-bottom: ${rem(40)};
  padding: 0 ${spacing.gutters}px 0;
`;

const Heading = styled.h1`
  font-size: ${rem(26)};
  line-height: 1.12;
  color: ${colors.black};
  margin-bottom: ${rem(16)};
  &:first-child {
    margin-top: 0;
  }
`;
