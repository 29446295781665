import React from "react";
import styled from "styled-components";
import { ApplicationStatusType } from "csv-package";

import { ApplicationFrameStepType } from "../types";

type Props = {
  status: ApplicationStatusType;
  currentStep: number;
  steps: ApplicationFrameStepType[];
};

const DisableFormFieldset: React.FC<Props> = ({
  children,
  status,
  currentStep,
  steps,
}) => {
  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    if (status === "initiated") {
      setDisabled(false);
      return;
    }
    if (status === "re-declare-requested" && currentStep === steps.length - 1) {
      setDisabled(false);
      return;
    }
    setDisabled(true);
  }, [status, currentStep, steps.length]);

  return <Fieldset disabled={disabled}>{children}</Fieldset>;
};

export default DisableFormFieldset;

const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
`;
