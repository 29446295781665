import React from "react";

import { ApplicationFrameStepType } from "../../types";

import YourRole from "./components/YourRole";
import IncidentHistory from "./components/IncidentHistory";
import PersonDetails from "./components/PersonDetails";
import Children from "./components/Children";
import Respondent from "./components/Respondent";
import Conditions from "./components/Conditions";
import Confirm from "./components/Confirm";
import yourRole from "./validation/yourRole";
import incidentHistory from "./validation/incidentHistory";
import personDetails from "./validation/personDetails";
import children from "./validation/children";
import respondent from "./validation/respondent";
import conditions from "./validation/conditions";
import Review from "./components/Review";

const steps: ApplicationFrameStepType[] = [
  {
    name: "Your role",
    validation: yourRole,
    component: <YourRole />,
  },
  {
    name: "Incident history",
    validation: incidentHistory,
    component: <IncidentHistory />,
  },
  {
    name: "Personal details",
    validation: personDetails,
    component: <PersonDetails />,
  },
  {
    name: "Children",
    validation: children,
    component: <Children />,
  },
  {
    name: "Respondent",
    validation: respondent,
    component: <Respondent />,
  },
  {
    name: "Conditions",
    validation: conditions,
    component: <Conditions />,
  },
  {
    name: "Confirm details",
    validation: {},
    component: <Confirm />,
  },
  {
    name: "Review and submit",
    validation: {},
    component: <Review />,
  },
];

export default steps;
