import { rgba } from "polished";
import React from "react";
import { useParams, withRouter } from "react-router-dom";
import styled from "styled-components/macro";

import { ReactComponent as OkIcon } from "../../../assets/icons/PostcodeOk.svg";

import media from "../../../theme/media";

import ExitContent from "../../../components/ExitContent";
import SupportServices from "../../../components/SupportServices";

const ReDeclareComplete = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <Inner>
        <Main>
          <SuccessBox>
            <OkIcon />
            <div>
              <h2>
                Your application has been re-declared but you do not have an
                intervention order at this stage.
              </h2>
              <p>
                Your reference number is {id}. You will not need to quote this
                number when talking to staff, however it may be useful as a
                reference so keep it somewhere safe.
              </p>
            </div>
          </SuccessBox>{" "}
          <h2>What happens next?</h2>
          <ExitContent />
          <h2>Family violence support services</h2>
          <p>
            For support and advice about family violence, you may wish to
            contact a family violence service. To find local, available support
            in your location, visit the{" "}
            <a
              href="https://www.dvrcv.org.au/"
              target="_blank"
              rel="noreferrer"
            >
              Domestic Violence Resource Centre directory.
            </a>
          </p>
          <SupportServices
            services={[
              "safeSteps",
              "noToViolence",
              "_1800Respect",
              "djirra",
              "inTouch",
              "rainbowDoor",
            ]}
          />
        </Main>{" "}
      </Inner>{" "}
    </>
  );
};

export default withRouter(ReDeclareComplete);

const Inner = styled.div`
  width: 100%;
  max-width: 1310px;
  position: relative;
  z-index: 1;
  padding: 0;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${media.greaterThan("tablet")`
    flex-direction: row;
  `}
`;

const Main = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: 646px;
`;

const SuccessBox = styled.div`
  padding: 20px 15px;
  background: ${rgba(`#1ba4b2`, 0.15)};
  width: 100%;
  font-size: 18px;
  line-height: 1.44;
  position: relative;
  margin-bottom: 40px;

  ${media.greaterThan("tablet")`
    padding: 33px 64px;
  `}

  h2 {
    font-size: 22px;
    margin-top: 0;
  }

  > svg {
    height: 25px;
    width: 25px;
    margin-bottom: 5px;
    ${media.greaterThan("tablet")`
        position: absolute;
        top: 33px;
        left: 32px;
        transform: translateX(-50%);

    `};
  }
`;
