import React from "react";
import styled from "styled-components/macro";
import { lighten, darken } from "polished";

import media from "../theme/media";

import QuickExitButton from "./QuickExitButton";
import { ReactComponent as XIcon } from "../assets/icons/X.svg";

type Props = {
  onCloseClick: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
};

const YourSafetyPopup: React.FunctionComponent<Props> = ({ onCloseClick }) => (
  <Wrapper>
    <Overlay onClick={onCloseClick} />
    <Content>
      <header>
        <CloseButton onClick={onCloseClick}>
          <XIcon />
        </CloseButton>
        <QuickExitButton />
      </header>
      <div>
        <h3>Your safety</h3>
        <p>
          If you believe you (and/or your children) are in immediate danger, do
          not fill out this form but take action:
        </p>
        <h3>Call 000</h3>
        <p>
          Alternatively, contact one of our provided Support Services or, if you
          are safely able to do so, attend your nearest police station or court.
        </p>
        <p>
          <strong>Remember to clear your browser cache and history.</strong>
        </p>
      </div>
    </Content>
  </Wrapper>
);

export default YourSafetyPopup;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1002;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.greaterThan("tablet")`
        display: none;
    `}
`;

const Overlay = styled.div`
  display: block;
  position: fixed;
  z-index: 1002;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100vh;
`;

const Content = styled.div`
  width: 90%;
  display: block;
  position: relative;
  z-index: 1003;
  background-color: #fff;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 20px;
  }

  > div {
    padding: 20px;
  }
`;

const CloseButton = styled.button`
  height: 24px;
  width: 24px;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: #8e8b8e;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: ${darken(0.2, `#8e8b8e`)};
  }

  &:active {
    color: ${lighten(0.2, `#8e8b8e`)};
  }

  > svg {
    height: 100%;
    width: 100%;
  }
`;
