import { courts, CourtType } from "csv-package";
import { FlatApplciationType } from "../types";

const getCalculatedCourt = (
  values: FlatApplciationType,
  namePrefix: string
): CourtType | null => {
  const suburb = values[`${namePrefix}_sub`];
  const postcode = values[`${namePrefix}_postcode`];

  if (typeof suburb !== "string" || typeof postcode !== "string") {
    return null;
  }

  // check if suburb is in an override
  let court = courts.find((court) => {
    return court.court_suburb_overrides
      .map((c) => c.toLowerCase())
      .includes(suburb.toLowerCase());
  });

  // look for a postcode match now
  if (court === undefined) {
    court = courts.find((court) => court.court_postcodes.includes(postcode));
  }
  return court || null;
};

export default getCalculatedCourt;
