import { rem } from "polished";
import styled from "styled-components/macro";

import colors from "../theme/colors";
import spacing from "../theme/spacing";

export const ErrorMessage = styled.div`
  background: #f8d9dd;
  width: 100%;
  max-width: 650px;
  color: ${colors.error};
  line-height: 1.333;
  font-size: 15px;
  padding: ${rem(24)} ${rem(spacing.gutters)};
  margin-top: 17px;
  margin-bottom: 3rem;

  ul {
    margin: 13px 0 0;
    padding: 0 0 0 1.1em;

    li {
      color: #3c3c3c;
      font-size: 15px;
      line-height: 1.33;
      button {
        border: 0;
        margin: 0;
        padding: 0;
        background-color: transparent;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
`;

export default ErrorMessage;
