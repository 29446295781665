import React from "react";
import get from "lodash/get";
import styled, { css } from "styled-components/macro";
import { rem } from "polished";
import { useField } from "formik";
import { SchemaOptionType } from "csv-package";

import Radio from "./Radio";
import FormLabel, { Label } from "./FormLabel";
import FormNote, { Note } from "./FormNote";
import ErrorMessage from "./ErrorMessage";
import ConditionalFieldsWrapper from "./ConditionalFieldsWrapper";
import { Wrapper as QuestionWrapper } from "./Question";
import useQuestionConfig from "../hooks/useQuestionConfig";

type Props = {
  name: string;
  label?: string;
  note?: string;
  options?: SchemaOptionType[];
  optionChildren?: {
    [key: string]: React.ReactNode;
  };
  indentChildren?: boolean;
  compact?: boolean;
  displayRows?: boolean;
};

const RadioGroup: React.FC<Props> = ({
  name,
  optionChildren,
  indentChildren = true,
  compact,
  displayRows,
  ...rest
}) => {
  const [field, meta] = useField(name);
  const { label, note, options } = useQuestionConfig(name, rest);

  const hasError = meta.touched && meta.error;

  if (options === undefined) {
    return null;
  }

  return (
    <Wrapper compact={compact}>
      {label && <FormLabel name={name} text={label} />}
      {note && <FormNote text={note} />}

      {hasError && <StyledErrorMessage>{meta.error}</StyledErrorMessage>}
      <RadioItems displayRows={displayRows}>
        {options.map((o) => {
          const optionChild = get(optionChildren, o.value, null);
          const checked = meta.value === o.value;
          return (
            <RadioWrapper key={o.value} displayRows={displayRows}>
              <Radio
                name={name}
                value={o.value}
                label={o.text}
                onRadioChange={field.onChange}
                onRadioBlur={field.onBlur}
                checked={checked}
              />
              {optionChild && (
                <ConditionalFieldsWrapper
                  conditions={{ [name]: o.value }}
                  indent={indentChildren}
                  embeded
                >
                  {optionChild}
                </ConditionalFieldsWrapper>
              )}
              {checked && o.description && (
                <DescriptionContainer>
                  <FormNote text={o.description} />
                </DescriptionContainer>
              )}
            </RadioWrapper>
          );
        })}
      </RadioItems>
    </Wrapper>
  );
};

export default RadioGroup;

export const Wrapper = styled.div<{ compact?: boolean }>`
  margin-bottom: ${rem(35)};

  ${({ compact }) =>
    compact &&
    css`
      margin-top: -${rem(16)};
      > ${Note} {
        display: none;
      }
      > ${Label} {
        display: none;
      }
    `}
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-bottom: 13px;
`;

const RadioWrapper = styled.div<{ displayRows?: boolean }>`
  margin-top: ${rem(4)};
  > div > ${QuestionWrapper}:first-child {
    margin-top: 1rem;
  }

  ${({ displayRows }) =>
    displayRows &&
    css`
      width: calc(50% - 2px);
    `}
`;

const RadioItems = styled.div<{ displayRows?: boolean }>`
  ${({ displayRows }) =>
    displayRows &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    `}
`;

const DescriptionContainer = styled.div`
  margin-top: 1rem
`;