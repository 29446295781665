import React from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import { schemaFVIO as schema } from "csv-package";

import ApplicationFrame from "../../../components/ApplicationFrame";

import Complete from "../components/Complete";
import ReDeclare from "../components/ReDeclare";
import ReDeclareComplete from "../components/ReDeclareComplete";

import steps from "../steps";
import template from "../narrativeTemplate";

const ApplicationNew: React.FC = () => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    ReactGA.pageview(pathname);
  }, [pathname]);

  return (
    <ApplicationFrame
      schema={schema}
      steps={steps}
      complete={<Complete />}
      reDeclare={<ReDeclare />}
      reDeclareComplete={<ReDeclareComplete />}
      compatibleTypes={["N"]}
      narrativeTemplate={template}
      narrativeLimitConfig={{
        keys: [
          "incident_phy_detail",
          "incident_pys_detail",
          "incident_aep_detail",
          "incident_cym_detail",
          "incident_thy_detail",
          "incident_cbp_detail",
          "incident_fsw_detail",
          "incident_dyp_detail",
          "incident_ryf_detail",
          "incident_htc_detail",
          "incident_kha_detail",
          "incident_sty_detail",
          "incident_fmc_detail",
          "incident_cdw_detail",
          "incident_rec_date",
          "incident_rec_desc",
          "incident_prev_fv_time",
          "incident_prev_fv_det",
        ],
        limit: 3500,
        step: 3,
      }}
    />
  );
};

export default ApplicationNew;
