import applicant from './applicant';
import child from './child';
import children from './children';
import conditions from './conditions';
import incidentDetails from './incidentDetails';
import previousOrders from './previousOrders';
import respondent from './respondent';
import review from './review';

export const fvioValidation = {
  applicant,
  child,
  children,
  conditions,
  incidentDetails,
  previousOrders,
  respondent,
  review,
};
