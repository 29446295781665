import React from "react";
import { useFormikContext } from "formik";
import { DeclarationOfTruth } from "csv-package";

import Button from "../../../components/Button";
import ButtonContainer from "../../../components/ButtonContainer";
import PageTitle from "../../../components/PageTitle";
import QuestionsFrame from "../../../components/QuestionsFrame";
import ReDeclareSection from "../../../components/ReDeclareSection";
import ReDeclareNarrative from "../../../components/ReDeclareNarrative";

import { FlatApplciationType } from "../../../types";
import useReDeclare, { ReDeclareModals } from "../../../hooks/useReDeclare";

const ReDeclare: React.FC = () => {
  const { values } = useFormikContext<FlatApplciationType>();
  const {
    handleAcceptReDeclare,
    handleCallBack,
    reDeclareObject,
    reDeclareState,
    handleDeclarationOfTruth,
    handleErrorModalClose,
  } = useReDeclare();

  if (reDeclareObject.Status === "canceled") {
    return null;
  }

  const reDeclareCreateDate = new Date(reDeclareObject.Created);
  const applicantGivNam = values.appl_det_giv_nam || values.ap_giv_name;
  const applicantFamNam = values.appl_det_fam_nam || values.ap_fam_name;
  const applicantName = `${applicantGivNam} ${applicantFamNam}`;
  const applicantDOB = `${values.appl_dob || values.ap_dob}`;

  return (
    <>
      <PageTitle
        heading="Application amendments"
        description={`The following sections have been amended by the Magistrates’ Court based on your telephone conversation that occurred on the ${reDeclareCreateDate.toLocaleDateString(
          "en-AU"
        )}. Please review these amendments to ensure they’re accurate before re-submitting.`}
      />

      <ReDeclareNarrative
        title="Your statement"
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <ReDeclareSection
        title="Your role"
        names={["appl_type", "abap_type"]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <ReDeclareSection
        title="Incident history"
        names={[
          "incident_date",
          "violence_duration",
          "incident_types",
          "incident_assult",
          "incident_assult_recent",
          "incident_assult_previous",
          "incident_threat",
          "incident_threat_recent",
          "incident_threat_previous",
          "incident_damage",
          "incident_damage_recent",
          "incident_damage_previous",
          "incident_stalked",
          "incident_stalked_recent",
          "incident_stalked_previous",
          "incident_other",
          "incident_other_recent",
          "incident_other_previous",
          "incident_other",
          "child_affected",
          "child_affected_detail",
          "resp_charged",
          "police_contacted",
          "police_station",
          "police_officer",
        ]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <ReDeclareSection
        title="Personal details"
        names={[
          "appl_det_giv_nam",
          "appl_det_giv_nam2",
          "appl_det_giv_nam3",
          "appl_det_fam_nam",
          "appl_other_names",
          "appl_rel_ap",
          "appl_dob",
          "appl_age",
          "appl_sex",
          "appl_sex_other",
          "appl_abor_code",
          "appl_det_addr_flat",
          "appl_det_addr_strnr",
          "appl_det_addr_strname",
          "appl_det_addr_strtype",
          "appl_det_addr_sub",
          "appl_det_addr_state",
          "appl_det_addr_postcode",
          "appl_safe_cont",
          "appl_cont_phone_3",
          "appl_cont_phone",
          "appl_cont_phone_2",
          "appl_cont_phone_sms",
          "appl_cont_phone_vm",
          "appl_cont_safe_times",
          "appl_cont_email",
          "appl_cont_email_2",
          "appl_cont_trst_per",
          "appl_cont_trst_per_rel",
          "appl_cont_trst_per_rel_other",
          "appl_cont_trst_per_phone",
          "appl_cont_trst_per_email",
          "ap_giv_nam",
          "ap_giv_nam2",
          "ap_giv_nam3",
          "ap_fam_nam",
          "ap_other_names",
          "ap_rel_resp",
          "ap_dob",
          "ap_age",
          "ap_sex",
          "ap_sex_other",
          "ap_lgbt",
          "ap_abor_code",
          "ap_pregnant_baby",
          "ap_inter",
          "ap_lang",
          "ap_lang_other",
          "ap_addr_flat",
          "ap_addr_strnr",
          "ap_addr_strname",
          "ap_addr_strtype",
          "ap_addr_sub",
          "ap_addr_state",
          "ap_addr_postcode",
          "ap_safe_cont",
          "ap_cont_phone_3",
          "ap_cont_phone",
          "ap_cont_phone_2",
          "ap_cont_phone_sms",
          "ap_cont_phone_vm",
          "xap_cont_safe_timesxx",
          "ap_cont_email",
          "ap_cont_trst_per",
          "ap_cont_trst_per_rel",
          "ap_cont_trst_per_rel_other",
          "ap_cont_trst_per_email",
          "ap_cont_trst_per_phone",
        ]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <ReDeclareSection
        title="Children"
        names={["ap_children", "ap_children_exposed"]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <ReDeclareSection
        title="Respondent"
        names={[
          "resp_giv_nam",
          "resp_giv_nam2",
          "resp_giv_nam3",
          "resp_fam_nam",
          "resp_other_nam",
          "resp_addr_flat",
          "resp_addr_strnr",
          "resp_addr_strname",
          "resp_addr_strtype",
          "resp_addr_sub",
          "resp_addr_state",
          "resp_addr_postcode",
          "resp_known_lbl",
          "resp_sex",
          "resp_sex_other",
          "resp_inter",
          "resp_lang",
          "resp_lang_other",
          "resp_contact_type",
          "resp_contact_ph_mobile",
          "resp_contact_ph",
          "resp_contact_ph_2",
          "resp_contact_email",
          "resp_contact_email_2",
          "resp_contact_post_flat",
          "resp_contact_post_strnr",
          "resp_contact_post_strname",
          "resp_contact_post_strtype",
          "resp_contact_post_sub",
          "resp_contact_post_state",
          "resp_contact_post_postcode",
          "resp_contact_other",
          "resp_addiction",
          "resp_armed",
          "resp_armed_detail",
          "resp_armed_loc",
          "resp_armed_loc_add_flat",
          "resp_armed_loc_add_strnr",
          "resp_armed_loc_add_strname",
          "resp_armed_loc_add_strtype",
          "resp_armed_loc_add_sub",
          "resp_armed_loc_add_state",
          "resp_armed_loc_add_postcode",
          "resp_weapon",
          "resp_weapon_detail",
        ]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <ReDeclareSection
        title="Conditions"
        names={[
          "cond_prevent",
          "cond_exempt",
          "cond_firearm_weapon",
          "cond_interim_sort",
          "cond_other",
        ]}
        reDeclareObject={reDeclareObject}
        values={values}
      />

      <QuestionsFrame>
        <DeclarationOfTruth
          applicantName={applicantName}
          applicantDOB={applicantDOB}
          checked={reDeclareState.declaration_of_truth}
          onCheckboxChange={handleDeclarationOfTruth}
        />
      </QuestionsFrame>
      <ButtonContainer>
        <Button
          disabled={["loading", "successful"].includes(
            reDeclareState.submit_status
          )}
          variant="secondary"
          onClick={handleCallBack}
        >
          {
            {
              uninitialised: "Reject and request a call",
              loading: "Requesting...",
              successful: "Request successful",
              failed: "Request failed",
            }[reDeclareState.call_back_status]
          }
        </Button>
        <Button onClick={handleAcceptReDeclare}>
          {
            {
              uninitialised: "Accept changes and redeclare",
              loading: "Submitting...",
              successful: "Request successful",
              failed: "Request failed",
            }[reDeclareState.submit_status]
          }
        </Button>
      </ButtonContainer>

      <ReDeclareModals
        reDeclareState={reDeclareState}
        handleErrorModalClose={handleErrorModalClose}
      />
    </>
  );
};

export default ReDeclare;
