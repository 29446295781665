import Yup from "../yup";

const relatedOrders = {
    order_exist_cm: Yup.string().required("We require this information"),
    order_exist_cm_yes: Yup.string().when("order_exist_cm", {
        is: "yes",
        then: Yup.string().required("We require this information"),
    }),
    order_exist_changed_other_terr: Yup.string().required("We require this information"),
    order_exist_made_other_terr: Yup.string().required("We require this information"),
    order_exist_curr_app_chng_other_terr: Yup.string().required("We require this information"),
};

export default relatedOrders;