import React from 'react';
import styled, { css } from 'styled-components/macro';
import _ from 'lodash';

import media from '../theme/media';

import { ReactComponent as TickIcon } from '../assets/icons/Tick.svg';
import Info from './Info';

type Props = {
  label: string;
  name: string;
  definition?: string;
  alternative?: boolean;
  value: string;
  onCheckboxChange: React.ChangeEventHandler<HTMLInputElement>;
  onCheckboxBlur?: React.FocusEventHandler<HTMLInputElement>;
  checked: boolean;
  disabled?: boolean;
  description?: string;
};

const Checkbox: React.FunctionComponent<Props> = React.memo(
  ({
    label,
    name,
    definition,
    alternative,
    value,
    onCheckboxChange,
    onCheckboxBlur,
    checked,
    disabled,
    description,
  }) => {
    const id = _.kebabCase(`${name}-${value}`);
    const containsBr = label.indexOf('<br') > 0;
    return (
      <Outer hasDescription={Boolean(description)}>
        <Wrapper alternative={alternative} disabled={disabled}>
          <input
            type="checkbox"
            name={name}
            id={id}
            value={value}
            onChange={onCheckboxChange}
            onBlur={onCheckboxBlur}
            checked={checked}
            disabled={disabled}
          />
          <Check htmlFor={id} alternative={Boolean(definition)}>
            <TickIcon />
          </Check>
          {containsBr ? (
            <Label htmlFor={id} dangerouslySetInnerHTML={{ __html: label }} />
          ) : (
            <Label htmlFor={id}>{label}</Label>
          )}

          {definition ? <Info>?</Info> : <span />}
        </Wrapper>
        {description && (
          <InfoWrapper>
            <Info string={`?|${label}|${description}`} />
          </InfoWrapper>
        )}
      </Outer>
    );
  }
);

export default Checkbox;

type WrapperProps = {
  hasDescription?: boolean;
  alternative?: boolean;
  disabled?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  min-height: 48px;
  padding: 0 15px 0;
  background: rgba(195, 214, 234, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  padding: 15px;

  input {
    opacity: 0;
    position: absolute;
  }

  > :last-child {
    margin-left: auto;
  }

  ${({ alternative }) =>
    alternative &&
    css`
      background: transparent;
      padding-left: 0;
      max-width: 600px;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
    `}
`;

const Outer = styled.div<{ hasDescription?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${({ hasDescription }) =>
    hasDescription &&
    css`
      ${Wrapper} {
        width: calc(100% - 16px);
      }
    `}
  & + & {
    margin-top: 4px;
  }
`;

export const Label = styled.label`
  font-size: 15px;
  color: #3c3c3c;
  line-height: 1.3;
  padding-right: 30px;
  align-self: flex-start;
  max-width: calc(100% - 18px - 17px - 15px);
  text-align: left;
  ${media.greaterThan('tablet')`
    font-size: 17px;
  `}
`;

export const Check = styled.label<{ alternative?: boolean }>`
  height: 18px;
  width: 18px;
  border: 1px solid #868686;
  border-radius: 2px;
  background: white;
  margin-right: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  align-self: flex-start;

  > svg {
    width: 10px;
    height: 9px;
  }

  input:checked ~ & {
    color: white;
    background: rgba(14, 93, 171, 0.9701);
    border-color: rgba(14, 93, 171, 0.9701);

    ${({ alternative }) =>
      alternative &&
      css`
        background: white;
        border-color: currentColor;
        color: rgba(14, 93, 171, 0.9701);
      `};
  }

  input:checked ~ &:before {
    @media print {
      content: '✔';
      display: block;
      font-size: 1.3em;
    }
  }

  input:focus ~ & {
    box-shadow: 0px 0px 5px 0px rgba(14, 93, 171, 0.75);
  }
`;

const InfoWrapper = styled.div`
  button {
    font-size: 1rem;
  }
`;
