import { ApplicationJurisdictionType, ApplicationTypeType } from "csv-package";

// Application Types
import ApplicationFvio from "./fvio-new/pages/Application";
import ApplicationPsio from "./psio-new/pages/Application";
import ApplicationFvioEvr from "./fvio-evr/pages/Application";

// Home pages
import HomePageFvio from "./fvio-new/pages/Home";
import HomePagePsio from "./psio-new/pages/Home";
import HomePageEvr from './fvio-evr/pages/Home';

// Routes
import Gatekeeper from "./psio-new/pages/Gatekeeper";

export type PickedApplicationTypeType = Extract<
  ApplicationTypeType,
  "N" | "EVR"
>;

type ConfigRouteType = {
  path: string;
  exact: boolean;
  component: React.FunctionComponent;
};

export type ConfigType = {
  homePage: React.FunctionComponent;
  application: React.FunctionComponent;
  customRoutes?: ConfigRouteType[];
  overrideRoutes?: ConfigRouteType[];
  validApplicationTypes: ApplicationTypeType[];
};

export type SystemConfigType = {
  [K in ApplicationJurisdictionType]: {
    [K in PickedApplicationTypeType]: ConfigType;
  };
};

// this is to setup application froms.
// eg. fvio.mcv.vic.gov.au
export const systemConfig: SystemConfigType = {
  FVIO: {
    N: {
      homePage: HomePageFvio,
      application: ApplicationFvio,
      validApplicationTypes: ["N"],
    },
    EVR: {
      homePage: HomePageEvr,
      application: ApplicationFvioEvr,
      validApplicationTypes: ["E", "V", "R", "EV", "EVR"],
    },
  },
  PSIO: {
    N: {
      homePage: HomePagePsio,
      application: ApplicationPsio,
      validApplicationTypes: ["N"],
      overrideRoutes: [
        // these overrides are there to pop the gatekeeper mini form in before the user can create a form
        {
          path: "/application/create",
          exact: true,
          component: Gatekeeper,
        },
        {
          path: "/application/create/anonymous",
          exact: true,
          component: Gatekeeper,
        },
      ],
    },
    EVR: {
      homePage: HomePageEvr,
      application: ApplicationFvioEvr,
      validApplicationTypes: ["E", "V", "R", "EV", "EVR"],
    },
  },
};

export const configJurisdiction = (process.env.REACT_APP_JURISDICTION ||
  "FVIO") as ApplicationJurisdictionType;

export const configApplicationType = (process.env.REACT_APP_APPLICATION_TYPE ||
  "N") as PickedApplicationTypeType;

export const config = systemConfig[configJurisdiction][configApplicationType];
