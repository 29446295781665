import { Formik } from "formik";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { Yup } from "csv-package";
import ReactGA from "react-ga";

import Checkbox from "../../../components/Checkbox";

import PageTitle from "../../../components/PageTitle";
import QuestionsFrame from "../../../components/QuestionsFrame";
import SubHeading from "../../../components/SubHeading";
import colors from "../../../theme/colors";
import ButtonContainer from "../../../components/ButtonContainer";
import Button from "../../../components/Button";
import StandaloneRadioGroup from "../../../components/StandaloneRadioGroup";
import ErrorSummary from "../../../components/ErrorSummary";
import { useLocation } from "react-router-dom";
import IconButton from "../../../components/IconButton";
import { isAuthenticated } from "../../../helpers/auth";
import ApplicationCreate from "../../../pages/ApplicationCreate";
import ApplicationCreateAnonymous from "../../../pages/ApplicationCreateAnonymous";

const validation = {
  step: Yup.number().required(),
  read_definitions: Yup.boolean().when("step", {
    is: 1,
    then: Yup.boolean()
      .oneOf([true], "xxxx - copy for prohibited behaviours error")
      .required(),
  }),
  legal_fees: Yup.boolean()
    .when("step", {
      is: 1,
      then: Yup.boolean().oneOf([true], "xxxx - Copy for legal feels error"),
    })
    .required(),
  address_check: Yup.string().when("step", {
    is: 2,
    then: Yup.string().required("xxxx - copy for unselected address question"),
  }),
  police_order_check: Yup.string().when("step", {
    is: 2,
    then: Yup.string().required("xxxx - error for un selected police order"),
  }),
  fvio_check: Yup.string().when("step", {
    is: 2,
    then: Yup.string().required("xxxx - error for un selected fvio"),
  }),
};

const Gatekeeper = () => {
  const { pathname } = useLocation();
  const [showErrors, setShowErrors] = React.useState<boolean>(false);
  const [authenticated, setAuthenticated] =
    React.useState<boolean | null>(null);
  const [progress, setProgress] = React.useState<boolean>(false);

  React.useEffect(() => {
    ReactGA.pageview(pathname);

    const checkAuth = async () => {
      const userIsAuthenticated = await isAuthenticated();
      setAuthenticated(userIsAuthenticated);
    };

    checkAuth();
  }, [pathname]);

  // create application if everything passes
  if (progress && authenticated) {
    return <ApplicationCreate />;
  }
  if (progress && !authenticated) {
    return <ApplicationCreateAnonymous />;
  }

  return (
    <>
      <Outter>
        <Wrapper>
          <Formik
            isInitialValid={false}
            initialValues={{
              step: 1,
              read_definitions: false,
              legal_fees: false,
              address_check: "",
              police_order_check: "",
              fvio_check: "",
            }}
            onSubmit={(values) => console.log(values)}
            validationSchema={Yup.object().shape(validation)}
          >
            {({ values, setFieldValue, isValid, validateForm, errors }) => (
              <>
                {values.step === 1 && (
                  <>
                    <PageTitle
                      heading="Important information before you begin"
                      description={
                        <>
                          <p>
                            Personal Safety Intervention Orders are granted
                            based on evidence that specific prohibited
                            behaviours have occured. It’s important to consider
                            the details of your incidents and decide whether or
                            not submitting an application is the right way
                            forward to resolving your problem.
                          </p>
                          <p>
                            If your incident does not constitute a prohibited
                            behaviour, you should visit dispute resolution
                            services to get advice.
                          </p>
                        </>
                      }
                    />
                    <SubHeading
                      heading="Understanding prohibited behaviours vs. harassment"
                      description="The court will not approve applications that are solely based on incidents of harrassment. In some cases, the court will consider your application if you have taken other initiatives, such as professional dispute mediation, to resolve your dispute with no success."
                    />
                    <QuestionsFrame>
                      <ProhibitedBehaviours>
                        <div>
                          <div>
                            <h4>Prohibited behaviours</h4>
                            <p>
                              <a
                                href="http://google.com"
                                target="_blank"
                                rel="noreferrer"
                                title="Assault and sexual assault"
                              >
                                Assault and sexual assault
                              </a>
                            </p>
                            <p>
                              Aenean eu leo quam. Pellentesque ornare sem
                              lacinia quam venenatis vestibulum. Nullam quis
                              risus eget urna mollis ornare vel eu leo. Sed
                              posuere consectetur est at lobortis. Nulla vitae
                              elit libero, a pharetra augue.
                            </p>
                            <p>
                              Aenean eu leo quam. Pellentesque ornare sem
                              lacinia quam venenatis vestibulum. Nullam quis
                              risus eget urna mollis ornare vel eu leo. Sed
                              posuere consectetur est at lobortis. Nulla vitae
                              elit libero, a pharetra augue.
                            </p>
                            <p>
                              Aenean eu leo quam. Pellentesque ornare sem
                              lacinia quam venenatis vestibulum. Nullam quis
                              risus eget urna mollis ornare vel eu leo. Sed
                              posuere consectetur est at lobortis. Nulla vitae
                              elit libero, a pharetra augue.
                            </p>
                            <p>
                              Aenean eu leo quam. Pellentesque ornare sem
                              lacinia quam venenatis vestibulum. Nullam quis
                              risus eget urna mollis ornare vel eu leo. Sed
                              posuere consectetur est at lobortis. Nulla vitae
                              elit libero, a pharetra augue.
                            </p>
                            <p>
                              Aenean eu leo quam. Pellentesque ornare sem
                              lacinia quam venenatis vestibulum. Nullam quis
                              risus eget urna mollis ornare vel eu leo. Sed
                              posuere consectetur est at lobortis. Nulla vitae
                              elit libero, a pharetra augue.
                            </p>
                            <p>
                              Aenean eu leo quam. Pellentesque ornare sem
                              lacinia quam venenatis vestibulum. Nullam quis
                              risus eget urna mollis ornare vel eu leo. Sed
                              posuere consectetur est at lobortis. Nulla vitae
                              elit libero, a pharetra augue.
                            </p>
                            <p>
                              Aenean eu leo quam. Pellentesque ornare sem
                              lacinia quam venenatis vestibulum. Nullam quis
                              risus eget urna mollis ornare vel eu leo. Sed
                              posuere consectetur est at lobortis. Nulla vitae
                              elit libero, a pharetra augue.
                            </p>
                            <p>
                              Aenean eu leo quam. Pellentesque ornare sem
                              lacinia quam venenatis vestibulum. Nullam quis
                              risus eget urna mollis ornare vel eu leo. Sed
                              posuere consectetur est at lobortis. Nulla vitae
                              elit libero, a pharetra augue.
                            </p>
                          </div>
                        </div>
                      </ProhibitedBehaviours>

                      <Checkbox
                        label="I have read the definitions of the prohibited behaviours and my situation fits the description"
                        name="read_definitions"
                        value="yes"
                        onCheckboxChange={(e) => {
                          if (_.get(values, "read_definitions", false)) {
                            setFieldValue("read_definitions", false);
                          } else {
                            setFieldValue("read_definitions", true);
                          }
                        }}
                        checked={_.get(values, "read_definitions", false)}
                      />

                      <h3>Legal fees</h3>

                      <p>
                        If your application does not get approved as an order,
                        and the respondent had obtained legal representation
                        during the process, you are likely to be responsible for
                        legal fees. Find out more.
                      </p>

                      <Checkbox
                        label="I understand that I may be liable to pay legal fees incurred during the court process"
                        name="legal_fees"
                        value="yes"
                        onCheckboxChange={(e) => {
                          if (_.get(values, "legal_fees", false)) {
                            setFieldValue("legal_fees", false);
                          } else {
                            setFieldValue("legal_fees", true);
                          }
                        }}
                        checked={_.get(values, "legal_fees", false)}
                      />
                      {showErrors && !_.isEmpty(errors) && <ErrorSummary />}
                    </QuestionsFrame>
                    <ButtonContainer>
                      <Button
                        onClick={() => {
                          validateForm();
                          if (isValid) {
                            setShowErrors(false);
                            setFieldValue("step", 2);
                          } else {
                            setShowErrors(true);
                          }
                        }}
                      >
                        Next
                      </Button>
                    </ButtonContainer>
                  </>
                )}

                {values.step === 2 && (
                  <>
                    <PageTitle heading="Additional information" />
                    <QuestionsFrame>
                      <StandaloneRadioGroup
                        name="fvio_check"
                        label="Are you applying against a family member, partner or ex-partner?"
                        options={[
                          { text: "Yes", value: "yes" },
                          { text: "No", value: "no" },
                        ]}
                      />
                      <StandaloneRadioGroup
                        name="police_order_check"
                        label="Did the police file an order as a result of any incident?"
                        options={[
                          { text: "Yes", value: "yes" },
                          { text: "No", value: "no" },
                        ]}
                      />
                      <StandaloneRadioGroup
                        name="address_check"
                        label="Do you know the name and address of the offender?"
                        options={[
                          { text: "Yes", value: "yes" },
                          { text: "No", value: "no" },
                        ]}
                      />
                      {showErrors && !_.isEmpty(errors) && <ErrorSummary />}
                    </QuestionsFrame>
                    <ButtonContainer>
                      <Button
                        onClick={() => {
                          validateForm();

                          const pickedValues = _(values)
                            .pick([
                              "police_order_check",
                              "fvio_check",
                              "address_check",
                            ])
                            .values();

                          if (
                            isValid &&
                            _.isEqual(pickedValues, ["no", "no", "yes"])
                          ) {
                            setProgress(true);
                          } else if (
                            isValid &&
                            !_.isEqual(pickedValues, ["no", "no", "yes"])
                          ) {
                            setProgress(false);
                            setFieldValue("step", 999);
                          } else {
                            setProgress(false);
                            setShowErrors(true);
                          }
                        }}
                      >
                        Next:{" "}
                        {authenticated ? "Begin application" : "Create profile"}
                      </Button>
                    </ButtonContainer>
                  </>
                )}
                {values.step === 999 && (
                  <>
                    <IconButton
                      onClick={() => setFieldValue("step", 2)}
                      icon="chevron"
                      label="Back"
                    />
                    <ErrorBox>
                      <p>
                        <strong>
                          Based on your details there are other dispute
                          resolutions that are more relevent to your situation
                          than the PSIO
                        </strong>
                      </p>
                      <p>
                        Please follow the recommended steps below to proceed
                        with the best alternative
                      </p>
                    </ErrorBox>
                    {values.fvio_check === "yes" && (
                      <OtherResolution>
                        <h5>
                          I’m applying against a family member or intimate
                          partner
                        </h5>
                        <p>
                          There is a seperate form called the Family Violence
                          Intervention Order (FVIO). Please follow{" "}
                          <a href="https://fvio.mcv.vic.gov.au/" title="FVIO">
                            this link
                          </a>{" "}
                          to begin that application.
                        </p>
                      </OtherResolution>
                    )}
                    {values.police_order_check === "yes" && (
                      <OtherResolution>
                        <h5>The police filed an order on the incident</h5>
                        <p>
                          Contact the police station of the officer that
                          attended the incident. If you know the officer’s name
                          and the case number they will be able to give you more
                          information about the steps they are taking to protect
                          you.
                        </p>
                        <p>
                          If you are unsure please contact the court registrar.
                        </p>
                      </OtherResolution>
                    )}
                    {values.address_check === "no" && (
                      <OtherResolution>
                        <h5>
                          I don’t know the full name or address of the offender
                        </h5>

                        <p>
                          Contact your court registrar if you are experiencing a
                          dispute with an unfamiliar person. To serve an
                          intervention order the court needs to know more
                          details to proceed.
                        </p>

                        <p>
                          If you are in immediate danger, take action by calling
                          000.
                        </p>
                      </OtherResolution>
                    )}
                  </>
                )}
              </>
            )}
          </Formik>
        </Wrapper>
      </Outter>
    </>
  );
};

export default Gatekeeper;
const Outter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: ${rem(52)} 0;
  min-height: calc(100vh - 196px);
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 680px;
`;

const ProhibitedBehaviours = styled.div`
  width: 100%;
  border: 1px solid black;
  height: ${rem(423)};
  padding: 2rem;
  margin-bottom: 2rem;

  border-radius: 4px;
  > div {
    height: 100%;
    overflow: scroll;
  }

  > div > div > :first-child {
    margin-top: 0;
  }

  a {
    color: ${colors.blue};
  }
`;

const ErrorBox = styled.div`
  margin-top: 2rem;
  background-color: #f2f2f3;
  padding: 1rem;
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
`;

const OtherResolution = styled.div`
  margin-top: 2rem;
  h5 {
    color: ${colors.blue};
    font-size: ${rem(18)};
    margin-bottom: 0.6em;
  }

  a {
    color: ${colors.blue};
  }
`;
