import React from "react";
import styled from "styled-components/macro";
import { withRouter } from "react-router-dom";
import get from "lodash/get";

import media from "../theme/media";

import mcvLogoSrc from "../assets/images/mcv-lgoo.svg";
import aboriginalFlagSrc from "../assets/images/Australian_Aboriginal_Flag.svg";
import torresStraitIslandersFlagSrc from "../assets/images/Flag_of_the_Torres_Strait_Islanders.svg";
import colors from "../theme/colors";

const footerLinks = [
  {
    url: "https://www.mcv.vic.gov.au/privacy",
    title: "Privacy",
  },
  {
    url: "https://www.mcv.vic.gov.au/copyright",
    title: "Copyright",
  },
  {
    url: "https://www.mcv.vic.gov.au/disclaimer",
    title: "Disclaimer",
  },
  {
    url: "https://www.mcv.vic.gov.au/accessibility-statement",
    title: "Accessibility statement",
  },
  {
    url: "https://www.mcv.vic.gov.au/online-safety",
    title: "Online safety",
  },
  {
    url: "https://www.mcv.vic.gov.au/sitemap",
    title: "Sitemap",
  },
];

const Footer: React.FC = (props) => {
  if (get(props, "location.pathname") === "/quick") {
    return null;
  }
  return (
    <Wrapper>
      <Inner>
        <div>
          <Logo src={mcvLogoSrc} alt="MCV Logo" />
          <p>© Magistrates’ Court of Victoria</p>
        </div>
        <div>
          <Flags>
            <Flag src={aboriginalFlagSrc} alt="Australian Aboriginal Flag" />
            <Flag
              src={torresStraitIslandersFlagSrc}
              alt="Torres Strait Islanders Flag"
            />
            <p>
              The Magistrates’ Court of Victoria acknowledges the traditional
              custodians of the land and pays respect to Elders both past and
              present.
            </p>
          </Flags>
          <Links>
            {footerLinks.map((l) => (
              <li key={l.url}>
                <a href={l.url} title={l.title}>
                  {l.title}
                </a>
              </li>
            ))}
          </Links>
        </div>
      </Inner>
    </Wrapper>
  );
};

export default withRouter(Footer);

const Wrapper = styled.footer`
  width: 100%;
  background: #f4f4f4;
  background-image: url(https://www.mcv.vic.gov.au/themes/custom/mcv/images/footer-pattern.png);
  background-position: left top;
  background-repeat: no-repeat;
  padding: 24px 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const Inner = styled.div`
  width: 100%;
  max-width: 1310px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    margin-top: 10px;
    ${media.greaterThan("tablet")`
      text-align: left;
      align-items: flex-start;
      margin-right: 50px;
    `}
  }
  ${media.greaterThan("tablet")`
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;    
    margin-top: 0;
  `}
`;

const Logo = styled.img`
  width: 90px;
  height: 38px;
  display: block;
`;

const Flags = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  > p {
    max-width: 35em;
    margin: -0.1em 0 0 13px;
    line-height: 1.45;
  }
`;

const Flag = styled.img`
  display: block;
  width: 39px;
  height: 27px;
  margin-right: 9px;
  margin-bottom: 18px;
  ${media.greaterThan("tablet")`
    margin-bottom: 0;
  `}
`;

const Links = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 60px;
  margin: 30px 0 0 0;

  ${media.greaterThan("tablet")`
    justify-content: flex-start;
    padding: 0;
  `}

  li {
    position: relative;
    margin: 0 10px 15px;
    list-style-type: none;
    padding: 0;
    ${media.greaterThan("tablet")`
      margin: 0 0 0 36px;
    `}
  }

  li:before {
    content: "";
    position: absolute;
    top: 3px;
    height: 12px;
    width: 1px;
    background: ${colors.blue};
    left: -20px;
    display: none;
    ${media.greaterThan("tablet")`
      display: block;
    `}
  }

  li:first-child {
    margin-left: 0;
  }

  li:first-child:before {
    display: none;
  }

  a {
    color: ${colors.blue};
    display: inline-block;
    vertical-align: top;
    position: relative;
    text-decoration: none;
    line-height: 1.5;
  }

  a:after {
    content: "";
    height: 2px;
    background: ${colors.blue};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0;
    transition: width 0.3s ease-in;
  }

  a:hover:after {
    width: 100%;
  }
`;
