import axios from "axios";

type DrupalGetUserResponse = {
  attributes: {
    user_name_first: string;
  };
};

export const drupalGetUser = async (
  email: string,
  password: string
): Promise<DrupalGetUserResponse | null> => {
  const apiStage = process.env.REACT_APP_API_STAGE || "";

  let apiURL = process.env.REACT_APP_API_URL;
  const stage = apiStage.replace("/", "");
  const url = `${apiURL}${stage}/public/transition/drupalGetUser`;

  try {
    const result = await axios.post(url, { email, password });
    return result.data;
  } catch (error) {
    return null;
  }
};

export const drupalUserExists = async (email: string): Promise<boolean> => {
  const apiStage = process.env.REACT_APP_API_STAGE || "";
  let apiURL = process.env.REACT_APP_API_URL;
  const stage = apiStage.replace("/", "");
  const url = `${apiURL}${stage}/public/transition/drupalUserExists`;

  try {
    const result = await axios.post(url, { email });
    return Boolean(result.data);
  } catch (error) {
    return false;
  }
};
