import React from "react";
import ConfirmSection from "../../../components/ConfirmSection";
import PageTitle from "../../../components/PageTitle";

const Confirm = () => {
  return (
    <>
      <PageTitle
        heading="Confirm the details of your application"
        description="Please review the details you’ve entered to make sure we have the correct information. You’ll be able to see a summary of what the registrar will see on the next page."
      />
      <ConfirmSection
        heading="Person details"
        goToStepIndex={0}
        config={[
          {
            heading: "Applicant",
            names: [
              "appl_det_giv_nam",
              "appl_det_giv_nam2",
              "appl_det_giv_nam3",
              "appl_det_fam_nam",
              "appl_other_names",
              "appl_rel_ap",
              "appl_dob",
              "appl_age",
              "appl_sex",
              "appl_sex_other",
              "appl_abor_code",
              "address__appl_det_addr",
              "appl_safe_cont",
              "appl_cont_phone_3",
              "appl_cont_phone",
              "appl_cont_phone_2",
              "appl_cont_phone_sms",
              "appl_cont_phone_vm",
              "appl_cont_safe_times",
              "appl_cont_email",
              "appl_cont_email_2",
              "appl_cont_trst_per",
              "appl_cont_trst_per_rel",
              "appl_cont_trst_per_rel_other",
              "appl_cont_trst_per_email",
              "appl_cont_trst_per_phone",
            ],
          },
          {
            heading: "Affected person",
            names: [
              "ap_giv_nam",
              "ap_giv_nam2",
              "ap_giv_nam3",
              "ap_fam_nam",
              "ap_other_names",
              "ap_rel_resp",
              "ap_dob",
              "ap_sex",
              "ap_sex_other",
              "ap_lgbt",
              "ap_abor_code",
              "ap_inter",
              "ap_lang",
              "ap_lang_other",
            ],
          },
          {
            private:
              "Private question - the Respondent will not see this answer.",
            names: [
              "ap_pregnant_baby",
              "ap_safe_cont",
              "ap_cont_phone_3",
              "ap_cont_phone",
              "ap_cont_phone_2",
              "ap_cont_phone_sms",
              "ap_cont_phone_vm",
              "ap_cont_safe_times",
              "ap_cont_email",
              "ap_cont_email_2",
              "ap_cont_trst_per",
              "ap_cont_trst_per_rel",
              "ap_cont_trst_per_rel_other",
              "ap_cont_trst_per_email",
              "ap_cont_trst_per_phone",
            ],
          },
        ]}
      />
    </>
  );
};

export default Confirm;
