import React from "react";
import { useFormikContext } from "formik";
import getCourtById from "../helpers/getCourtById";
import { FlatApplciationType } from "../types";

const UnsafeToContactInfo = () => {
  const { values } = useFormikContext<FlatApplciationType>();
  if (!values.CourtID) {
    return null;
  }

  const court = getCourtById(values.CourtID);

  if (court === null) {
    return null;
  }

  return (
    <>
      <h2>After you submit the application, you will need to contact:</h2>
      <div>
        {court.name}
        <br />
        {court.address_line1}
        <br />
        {court.locality}
        <p>
          To find more details about this court, please click the link below.
          <br />
          <a
            href="https://www.mcv.vic.gov.au/going-court/find-court"
            title="Find a court"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.mcv.vic.gov.au/going-court/find-court
          </a>
        </p>
      </div>
    </>
  );
};

export default UnsafeToContactInfo;
