import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { lighten, darken, rem } from "polished";
import ReactGA from "react-ga";
import { ApplicationType } from "csv-package";

import { ReactComponent as DeleteIcon } from "../assets/icons/Delete.svg";

import { hasAnonymousIdentity } from "../helpers/auth";
import api from "../helpers/api";

import MenuBar from "../components/MenuBar";
import Button from "../components/Button";
import LoadingIndicator from "../components/LoadingIndicator";
import getCourtById from "../helpers/getCourtById";
import colors from "../theme/colors";
import media from "../theme/media";
import { config, configJurisdiction } from "../config/systemConfig";

const Applications: React.FC = () => {
  const { push, replace } = useHistory();
  const { pathname } = useLocation();

  const [applications, setApplications] = React.useState<ApplicationType[]>([]);
  const [loading, setLoading] = React.useState(false);

  // on mount fetch applciations
  React.useEffect(() => {
    ReactGA.pageview(pathname);

    setLoading(true);
    const fetchApplications = async () => {
      try {
        // get applications
        const result = (await api("get", "/application")) as ApplicationType[];

        // check if anon user
        // anon can only have 1 application
        // therefore send anon to first application
        if (hasAnonymousIdentity() && result.length === 0) {
          throw new Error("Your application could not be found");
        } else if (hasAnonymousIdentity()) {
          replace(`/application/${result[0].ApplicationID}`);
        } else {
          setLoading(false);

          // filter result to valid types only
          const filteredResults = result.filter(
            (application) =>
              application.Jurisdiction === configJurisdiction &&
              config.validApplicationTypes.includes(application.Type)
          );
          setApplications(filteredResults);
        }
      } catch (err: any) {
        alert(err.message);
      }
    };

    fetchApplications();
  }, [push, pathname, replace]);

  const deleteApplication = React.useCallback(
    async (ApplicationID) => {
      if (
        !window.confirm("Are you sure you want to delete this application?")
      ) {
        return null;
      }

      // stored application for optomisitic update.
      const storedObject = applications.filter(
        (application) => application.ApplicationID === ApplicationID
      ) as ApplicationType[];
      try {
        // filter out application
        setApplications(
          applications.filter(
            (application) => application.ApplicationID !== ApplicationID
          )
        );

        await api("del", `/application/${ApplicationID}`);
      } catch (error: any) {
        setApplications([...applications, ...storedObject]);
        alert(error.message);
      }
    },
    [applications]
  );

  if (hasAnonymousIdentity()) {
    return (
      <LoadingWrap>
        <LoadingIndicator />
      </LoadingWrap>
    );
  }

  return (
    <Wrapper>
      <MenuBar crumbs={[{ text: "Applications" }]} hideSaveIndicator />
      <Inner>
        <h2>My Applications</h2>

        <Buttons>
          <Button onClick={() => push(`/application/create`)}>
            Start new application
          </Button>
        </Buttons>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <ul>
            <>
              {applications.length === 0 && (
                <li>
                  <h2>You have no applications</h2>
                </li>
              )}
              {applications
                .sort((a, b) => (a.Created > b.Created ? -1 : 1))
                .map((application) => {
                  const court = getCourtById(application.CourtID);

                  return (
                    <li key={application.ApplicationID}>
                      <div>
                        <Number>
                          {application.ApplicationID} ({application.Type})
                        </Number>
                        <p>
                          Started:{" "}
                          {new Date(application.Created).toDateString()}
                          <br />
                          {court ? court.name : ""}
                        </p>
                      </div>
                      <Right>
                        {application.Status &&
                        [
                          "dnp",
                          "archived",
                          "submitted",
                          "verified",
                          "re-declare-accepted",
                        ].includes(application.Status) ? (
                          <>
                            <SubmittedTag />
                            <br />
                            <EditButton
                              to={`/application/${application.ApplicationID}`}
                            >
                              {application.Status === "re-declare-accepted"
                                ? "View re-redeclared application"
                                : "View"}
                            </EditButton>
                          </>
                        ) : (
                          <>
                            <EditButton
                              to={`/application/${application.ApplicationID}`}
                            >
                              {application.Status === "re-declare-requested"
                                ? "RE-DECLARE"
                                : "EDIT"}
                            </EditButton>

                            {application.Status === "initiated" && (
                              <DeleteButton
                                as="button"
                                onClick={() =>
                                  deleteApplication(application.ApplicationID)
                                }
                              >
                                <DeleteIcon /> Delete
                              </DeleteButton>
                            )}
                          </>
                        )}
                      </Right>
                    </li>
                  );
                })}
            </>
          </ul>
        )}
      </Inner>
    </Wrapper>
  );
};

export default Applications;

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Inner = styled.div`
  width: 100%;
  max-width: 600px;
  min-height: calc(100vh - 200px);
  padding: 100px 15px 80px 15px;
  ul {
    padding: 0;
    border-top: 1px solid black;
  }
  li {
    padding: 20px 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    border-bottom: 1px solid black;
    ${media.lessThan("tablet")`
      flex-direction: column;
    `}
  }
`;

const Number = styled.p`
  font-size: 18px;
  font-weight: 700;
  margin-top: 0;
`;

const EditButton = styled(Link)`
  text-transform: uppercase;
  display: inline-block;
  height: 1.6em;
  line-height: 1.4em;
  border-radius: 0.8em;
  background: ${colors.blue};
  text-align: center;
  min-width: 5em;
  text-decoration: none;
  color: white;
  padding: 0.2em 0.6em 0;

  &:hover {
    background: ${darken(0.2, colors.blue)};
  }

  &:active {
    background: ${lighten(0.2, colors.blue)};
  }
`;

const DeleteButton = styled.button`
  background: transparent;
  color: #000000;
  padding: 0;
  margin: 0;
  border: 0;
  display: flex;
  align-items: center;
  color: #4a4a4a;
  cursor: pointer;
  > svg {
    height: 1.5em;
    width: 1.5em;
    transform: translateY(-0.1em);
  }

  &:hover {
    color: ${lighten(0.2, `#4a4a4a`)};
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  ${media.lessThan("tablet")`
    align-items: flex-start;
    a, button {
      margin-bottom: 0.5em;
    }
  `}
`;

const SubmittedTag = styled.div`
  background: #d5d5d5;
  color: black;
  display: flex;
  align-items: center;
  height: 1.5em;
  border-radius: 0.8em;
  padding: 0.1em 1.1em 0;
  &:after {
    content: "SUBMITTED";
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  button + button {
    margin-top: ${rem(8)};
  }

  ${media.lessThan("tablet")`
    align-items: flex-start;
    margin-bottom: 1.5em;
  `}
`;

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 246px);
`;
