import styled from "styled-components/macro";
import spacing from "../theme/spacing";

const ButtonContainer = styled.fieldset`
  padding: 0;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding: 0 ${spacing.gutters}px;
  button {
    margin: 0 10px;
  }

  button:last-child {
    margin-right: 0;
  }

  button:first-child {
    margin-left: 0;
  }
`;

export default ButtonContainer;
