import React from "react";
import styled, { css } from "styled-components/macro";
import useWindowScroll from "@react-hook/window-scroll";

import { ReactComponent as UpArrow } from "../assets/icons/UpArrow.svg";
import { ReactComponent as Printer } from "../assets/icons/Printer.svg";

import media from "../theme/media";
import colors from "../theme/colors";

const BackToTop = () => {
  const scrollY = useWindowScroll();

  return (
    <Wrapper show={scrollY > 50}>
      <ul>
        <li>
          <button onClick={() => window.print()}>
            <div>
              <Printer />
            </div>
            <span>Print</span>
          </button>
        </li>
        <li>
          <button onClick={() => window.scrollTo(0, 0)}>
            <div>
              <UpArrow />
            </div>
            <span>Back to top</span>
          </button>
        </li>
      </ul>
    </Wrapper>
  );
};

export default BackToTop;

const Wrapper = styled.div<{ show: boolean }>`
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 74px;
  z-index: 1002;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  ${media.greaterThan("phone")`
    right: 20px;
    bottom: 20px;
  `}

  ${media.greaterThan("tablet")`
    right: 40px;
    bottom: 40px;
  `}

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    `}

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    width: 100%;
    font-size: 14px;
    li {
      list-style-type: none;
      width: 100%;
      margin-top: 14px;
      font-weight: 700;
      button {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 0;
        background-color: transparent;
        display: block;
        width: 100%;
        color: ${colors.black};
        cursor: pointer;
        &:hover {
          color: ${colors.blue};
        }

        div {
          position: relative;
          margin: 0 auto 7px;
          width: 36px;
          height: 36px;
          background-color: currentColor;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            fill: white;
            display: block;
            height: 1.5em;
            width: 1.5em;
          }
        }

        span {
          font-weight: bold;
        }
      }
    }
  }
`;
