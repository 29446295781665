import React from "react";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import uniq from "lodash/uniq";
import { useFormikContext } from "formik";
import { FlatApplciationType } from "../../../types";

const ConditionsAdjuster: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<FlatApplciationType>();

  const prevValues = React.useRef();

  React.useEffect(() => {
    // // courlink conditions
    const codeMap = {
      coo_con_old_a: ["ASH"],
      coo_con_old_b: ["DAM"],
      coo_con_old_c: ["SUR"],
      coo_con_old_d: ["COM"],
      coo_con_old_e: ["ATC1"],
      coo_con_old_f: ["BPR1"],
      coo_con_old_g: ["EXC1"],
      coo_con_old_h: ["ENG"],
      coo_exc_old_a: ["92"],
      coo_exc_old_b: ["92"],
      coo_exc_old_c: ["92"],
      coo_exc_old_d: ["92"],
      coo_exc_old_e: ["92"],
      coo_con_old_i: ["PPR1"],
      coo_con_old_j: ["PPR2"],
      coo_con_old_k: ["FIR1", "FIR2"],
      coo_con_old_l: ["WEA1", "WEA2"],
      coo_con_old_m: ["REF"],
      coo_con_old_n: ["68R1", "68R2"],
      coo_con_old_o: ["68R1", "68R2"],
      coo_con_old_p: ["93"],
      coo_con_old_q: ["93"],
      coo_con_old_r: ["93"],
    };

    const resp_imm_pro = get(values, `resp_imm_pro`);

    // modify if an interim sort is being sort do below mods
    if (resp_imm_pro === "yes") {
      codeMap["coo_con_old_k"] = ["FIR1"];
      codeMap["coo_con_old_l"] = ["WEA1"];
    } else if (resp_imm_pro === "no") {
      codeMap["coo_con_old_k"] = ["FIR2"];
      codeMap["coo_con_old_l"] = ["WEA2"];
    }

    //modify is children included in the order
    if (get(values, `prp_add_order`) === "no") {
      codeMap["coo_con_old_n"] = [];
      codeMap["coo_con_old_o"] = [];
      codeMap["coo_con_old_p"] = [];
      codeMap["coo_con_old_q"] = [];
      codeMap["coo_con_old_r"] = [];
    }

    // get a custom object made of prevProps conditions and Interim Order questions - used to compare
    const prevConditions = Object.keys(codeMap).reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: get(prevValues.current, cur),
      }),
      {
        resp_imm_pro: get(prevValues.current, `resp_imm_pro`),
        prp_add_order: get(prevValues.current, `prp_add_order`),
      }
    );

    // get a custom object made of current props conditions and Interim Order questions - used to compare
    const currConditions = Object.keys(codeMap).reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: get(values, cur),
      }),
      {
        resp_imm_pro: get(values, `resp_imm_pro`),
        prp_add_order: get(values, `prp_add_order`),
      }
    );

    if (!isEqual(prevConditions, currConditions)) {
      const courtLinkCodes = Object.keys(currConditions)
        // @ts-ignore
        .filter((key) => currConditions[key] === "yes")
        // @ts-ignore
        .reduce((acc, cur) => {
          if (cur === "resp_imm_pro" || cur === "prp_add_order") return acc;
          // @ts-ignore
          return [...acc, ...codeMap[cur]];
        }, []);

      setFieldValue("order_codes_sort", uniq(courtLinkCodes));
    }
    // @ts-ignore
    prevValues.current = values;
  }, [setFieldValue, values]);

  return null;
};

export default ConditionsAdjuster;
