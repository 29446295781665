import { rem } from "polished";
import React from "react";
import styled from "styled-components/macro";
import spacing from "../theme/spacing";

import IconButton from "./IconButton";

type Props = {
  heading: string;
  description?: string;
  onRemoveClick?: () => void;
};

const QuestionsSection: React.FC<Props> = ({
  heading,
  description,
  onRemoveClick,
  children,
}) => {
  return (
    <div>
      <div>
        <Heading>
          {heading}
          {onRemoveClick && (
            <IconButton
              label="Remove"
              icon="plus"
              rotateIcon
              alert
              onClick={(e) => {
                e.preventDefault();
                if (
                  window.confirm("Are you sure you want to remove this child")
                ) {
                  onRemoveClick();
                }
              }}
            />
          )}
        </Heading>

        {description && typeof description === "string" ? (
          <p>{description}</p>
        ) : description && typeof description !== "string" ? (
          description
        ) : null}
      </div>
      {children}
    </div>
  );
};

export default QuestionsSection;

const Heading = styled.h3`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${spacing.gutters}px;
  margin: 0 0 1em;
  font-size: ${rem(26)};
`;
