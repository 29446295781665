import React from "react";
import styled from "styled-components/macro";

import media from "../theme/media";

import { ReactComponent as RightArrowIcon } from "../assets/icons/RightArrow.svg";
import colors from "../theme/colors";

const SideBar: React.FC = () => {
  return (
    <Aside>
      <Header>Need more information?</Header>
      <Content>
        <Link
          href="https://mcv.vic.gov.au/family-matters/family-violence-intervention-orders-fvio "
          target="__blank"
          title="What is a Family Violence Intervention Order?"
        >
          <span>What is a Family Violence Intervention Order?</span>
          <RightArrowIcon />
        </Link>
        <Link
          href="https://mcv.vic.gov.au/family-matters/family-violence-intervention-orders-fvio"
          target="__blank"
          title="What is Family Violence?"
        >
          <span>What is Family Violence?</span>
          <RightArrowIcon />
        </Link>
        <Link
          href="https://mcv.vic.gov.au/node/2461645"
          target="__blank"
          title="Extend, vary or revoke an existing FVIO"
        >
          <span>Extend, vary or revoke an existing FVIO</span>
          <RightArrowIcon />
        </Link>
        <Link
          href="https://mcv.vic.gov.au/intervention-orders/family-violence-intervention-orders/applying-intervention-order-fvio"
          target="__blank"
          title="What is the FVIO process?"
        >
          <span>What is the FVIO process?</span>
          <RightArrowIcon />
        </Link>
        <Link
          href="https://mcv.vic.gov.au/privacy"
          target="__blank"
          title="How is my information used?"
        >
          <span>How is my information used?</span>
          <RightArrowIcon />
        </Link>
      </Content>
    </Aside>
  );
};

export default SideBar;

const Aside = styled.aside`
  border-radius: 5px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.13);
  overflow: hidden;
  width: 100%;
  ${media.greaterThan("tablet")`
    max-width: 315px;
  `}
  font-weight: 700;
`;

const Header = styled.header`
  width: 100%;
  height: 56px;
  background: ${colors.lightBlue};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: ${colors.black};
  font-size: 20px;
  font-weight: bold;
  padding: 0 16px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  ${media.greaterThan("tablet")`
    background: ${colors.blue};
    color: white;
  `}
`;

const Content = styled.nav`
  background-color: #f2f2f3;
  ${media.greaterThan("tablet")`
    background: #f2f2f3;
    color: white;
  `}
`;

const Link = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 1.375;
  color: ${colors.blue};
  padding: 17px 20px 17px 16px;

  position: relative;
  text-decoration: none;

  &:first-child {
    padding: 20px 20px 13px 16px;
  }

  span {
    flex-basis: 238px;
  }

  > svg {
    flex-basis: 26px;
  }

  &:after {
    content: "";
    width: calc(100% - 36px);
    height: 2px;
    background: #d8d8d8;
    position: absolute;
    bottom: 0;
    left: 16px;
  }

  &:last-child:after {
    display: none;
  }

  &:hover {
    background: ${colors.blue};
    color: white;
  }
  &:hover:after {
    display: none;
  }
`;
