import { useField } from "formik";
import React from "react";
import styled from "styled-components";
import numeral from "numeral";
import colors from "../theme/colors";

const NarrativeCharsLeftIndicator: React.FC<{}> = () => {
  const [field] = useField("NarrativeCharsLeft");

  const isNegative: boolean = React.useMemo(() => {
    if (typeof field.value === "number") {
      return field.value < 0;
    }
    return false;
  }, [field.value]);

  return (
    <Wrapper isNegative={isNegative}>
      {numeral(Math.abs(field.value)).format("0,0")} characters{" "}
      {isNegative ? "over" : "remaining"}
    </Wrapper>
  );
};

export default NarrativeCharsLeftIndicator;

const Wrapper = styled.p<{ isNegative: boolean }>`
  text-align: right;
  padding-left: 15px;
  padding-right: 15px;
  color: ${({ isNegative }) => (isNegative ? colors.error : "#6d6d6d")};
`;
