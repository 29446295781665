import { css } from "styled-components/macro";
import media from "./media";
import colors from "./colors";

const inputStyles = css<{ error?: boolean }>`
  font-size: 15px;

  width: 100%;
  max-width: 650px;
  height: 48px;
  border: 1px solid #a4a4a4;
  border-radius: 2px;
  padding-left: 0.6em;
  color: #000000;
  background: white;
  ${({ error }) =>
    error &&
    css`
      border-color: ${colors.error};
    `}

  ${media.greaterThan("tablet")`
    font-size: 17px;
    padding-left: 0.8em;
  `};
`;

export default inputStyles;
