import * as Yup from 'yup';

const getAge = (string: string): number => {
  // convert dd/mm/yyyy to js date
  const dobDate = new Date();
  const dobParts = string.split('/');
  dobDate.setDate(parseInt(dobParts[0], 10));
  dobDate.setMonth(parseInt(dobParts[1], 10) - 1);
  dobDate.setFullYear(parseInt(dobParts[2], 10));
  dobDate.setHours(0);
  dobDate.setMinutes(0);
  dobDate.setSeconds(0);
  dobDate.setMilliseconds(0);

  const nowDate = new Date();

  // @ts-ignore
  const diff = nowDate - dobDate;

  return new Date(diff).getUTCFullYear() - 1970;
};

const getMonths = (string: string): number => {
  // convert dd/mm/yyyy to js date
  const pastDate = new Date();
  const dateParts = string.split('/');
  pastDate.setDate(parseInt(dateParts[0], 10));
  pastDate.setMonth(parseInt(dateParts[1], 10) - 1);
  pastDate.setFullYear(parseInt(dateParts[2], 10));
  pastDate.setHours(0);
  pastDate.setMinutes(0);
  pastDate.setSeconds(0);
  pastDate.setMilliseconds(0);

  const nowDate = new Date();

  // @ts-ignore
  const diff = nowDate - pastDate;
  const diffDate = new Date(diff);
  const fullMonths = diffDate.getMonth() + 12 * (diffDate.getUTCFullYear() - 1970);
  return fullMonths;
};

Yup.addMethod<Yup.StringSchema>(Yup.string, 'csvGivenName', function() {
  return this.test(
    'given-name',
    'Only one given name can be entered.',
    function(value) {
      if (value) {
        return value.trim().indexOf(' ') === -1;
      }
      return true;
    }
  );
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'csvDate', function() {
  return this.matches(
    /^[0-3]{1}[0-9]{1}\/[0-1]{1}[0-9]{1}\/[1-2]{1}[0|9]{1}[0-9]{2}$/m,
    'Date format must be DD/MM/YYYY'
  ).test('date-is-valid', 'Invalid date', function(value) {
    if (typeof value === 'string') {
      const match = value.match(/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/);
      if (match && match.length === 4) {
        const yearInt = parseInt(match[3], 10);
        const monthInt = parseInt(match[2], 10);
        const dateInt = parseInt(match[1]);
        const dateToCheck = new Date(yearInt, monthInt - 1, dateInt);
        if (!dateToCheck || dateToCheck.getMonth() + 1 !== monthInt) {
          return false;
        }

        // check age
        if (yearInt < 1905) {
          return false;
        }
        if (yearInt > 2050) {
          return false;
        }
      }
    }
    return true;
  });
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'birthdate', function(
    _,
    overMessage
) {
    return this.test(
        'dob-over-0',
        'Date/Age cannot be a future date.',
        function(value) {
            if (value && value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/m)) {
                const age = getAge(value);
                return age >= 0;
            } else if (value && value.match(/^[0-9]{1,3}$/m)) {
                const age = parseInt(value, 10);
                return age >= 0;
            }
            return true;
        }
    ).test(
        'dob-below-115',
        overMessage ? overMessage : 'Age must be below 115 years old',
        function(value) {
            if (value && value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/m)) {
                const age = getAge(value);
                return age < 115;
            } else if (value && value.match(/^[0-9]{1,3}$/m)) {
                const age = parseInt(value, 10);
                return age < 115;
            }
            return true;
        }
    );
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'csvDobOver18', function(
  underMessage,
  overMessage
) {
  return this.test(
    'dob-over-0',
    'Date/Age cannot be a future date.',
    function(value) {
      if (value && value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/m)) {
        const age = getAge(value);
        return age >= 0;
      } else if (value && value.match(/^[0-9]{1,3}$/m)) {
        const age = parseInt(value, 10);
        return age >= 0;
      }
      return true;
    }
  ).test(
    'dob-over-18',
    underMessage
      ? underMessage
      : 'For the role you have selected, you must be 18 years of age or older. If you are unsure what role applies to you please contact your nearest court (https://www.mcv.vic.gov.au/going-court/find-court).',
    function(value) {
      if (value && value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/m)) {
        const age = getAge(value);
        return age >= 18;
      } else if (value && value.match(/^[0-9]{1,3}$/m)) {
        const age = parseInt(value, 10);
        return age >= 18;
      }
      return true;
    }
  ).test(
    'dob-below-115',
    overMessage ? overMessage : 'Age must be below 115 years old',
    function(value) {
      if (value && value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/m)) {
        const age = getAge(value);
        return age < 115;
      } else if (value && value.match(/^[0-9]{1,3}$/m)) {
        const age = parseInt(value, 10);
        return age < 115;
      }
      return true;
    }
  );
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'csvDobUnder18', function(
  overMessage,
  underMessage
) {
  return this.test(
    'dob-under-18',
    overMessage
      ? overMessage
      : 'Children in an application must be under 18 years of age.',
    function(value) {
      if (value && value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/m)) {
        const age = getAge(value);
        return age < 18;
      } else if (value && value.match(/^[0-9]{1,3}$/m)) {
        const age = parseInt(value, 10);
        return age < 18;
      }
      return true;
    }
  ).test(
    'dob-over-0',
    underMessage ? underMessage : 'Date/Age cannot be a future date.',
    function(value) {
      if (value && value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/m)) {
        const age = getAge(value);
        return age >= 0;
      } else if (value && value.match(/^[0-9]{1,3}$/m)) {
        const age = parseInt(value, 10);
        return age >= 0;
      }
      return true;
    }
  );
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'ausPhone', function() {
  return this.test(
    '',
    'Must be a valid Australian phone number, please include any area codes for landlines.',
    value => {
      if (!value) return true;
      return /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/.test(
        value
      );
    }
  );
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'ausMobilePhone', function() {
  return this.test('', 'Must be a valid Australian mobile number.', value => {
    if (!value) return true;
    return /^04[0-9]{8}$/.test(value);
  });
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'csvDuration', function() {
  return this.test(
    '',
    'Please enter a number and select a time period (Days|Weeks|Months|Years).',
    value => {
      if (!value) return true;
      if (typeof value === 'string' && value.split(' ')[0] === '0')
        return false;
      return /^\d*\.?\d* (Days|Weeks|Months|Years)$/.test(value);
    }
  );
});

type WhenIsFunc = (value: string, value2?: string) => boolean;

export const generateAddressValidation = (
  namePrefix: string,
  whenKey: string | string[] | null = null,
  whenIs: string | WhenIsFunc | null = null
) => {
  if (whenKey === null || whenIs === null) {
    return {
      [`${namePrefix}_strtype`]: Yup.string()
        .trim()
        .nullable()
        .required('Please enter a street type'),
      [`${namePrefix}_strname`]: Yup.string()
        .trim()
        .nullable()
        .required('Please enter a street name'),
      [`${namePrefix}_sub`]: Yup.string().required('Please enter a suburb'),
      [`${namePrefix}_postcode`]: Yup.string()
        .trim()
        .required('Please enter a postcode')
        .length(4, 'Postcode must be 4 digits long')
        .nullable(),
    };
  } else {
    return {
      [`${namePrefix}_strtype`]: Yup.string().when(whenKey, {
        is: whenIs,
        then: Yup.string()
          .trim()
          .required('Please enter a street type')
          .nullable(),
      }),
      [`${namePrefix}_strname`]: Yup.string().when(whenKey, {
        is: whenIs,
        then: Yup.string()
          .trim()
          .required('Please enter a street name')
          .nullable(),
      }),
      [`${namePrefix}_sub`]: Yup.string().when(whenKey, {
        is: whenIs,
        then: Yup.string()
          .required('Please enter a suburb')
          .nullable(),
      }),
      [`${namePrefix}_postcode`]: Yup.string().when(whenKey, {
        is: whenIs,
        then: Yup.string()
          .trim()
          .required('Please enter a postcode')
          .length(4, 'Postcode must be 4 digits long')
          .nullable(),
      }),
    };
  }
};

Yup.addMethod<Yup.StringSchema>(Yup.string, 'csvIncidentDetails', function() {
  return this.test('', 'Please enter incident details', value => {
    if (!value) return true;
    return value.trim() !== 'The respondent has';
  });
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'csvDateUnder12Months', function(
  overMessage,
  underMessage
) {
  return this.test(
    'date-under-12-months',
    overMessage
      ? overMessage
      : 'Invalid date, the order date is normally within 12 months of the current date.',
    function(value) {
      if (value && value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/m)) {
        const months = getMonths(value);
        return months < 12;
      }
      return true;
    }
  ).test(
    'date-over-0-months',
    underMessage ? underMessage : 'Date/Age cannot be a future date.',
    function(value) {
      if (value && value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/m)) {
        const months = getMonths(value);
        return months >= 0;
      }
      return true;
    }
  );
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'csvDobOver14', function(
  underMessage
) {
  return this.test(
    'dob-over-0',
    'Date/Age cannot be a future date.',
    function(value) {
      if (value && value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/m)) {
        const age = getAge(value);
        return age >= 0;
      } else if (value && value.match(/^[0-9]{1,3}$/m)) {
        const age = parseInt(value, 10);
        return age >= 0;
      }
      return true;
    }
  ).test(
    'dob-over-14',
    underMessage ? underMessage : 'For the role you have selected, you must be over 14 years of age and under 18 years of age. If you are unsure what role applies to you please contact your nearest court (https://www.mcv.vic.gov.au/going-court/find-court).',
    function(value) {
      if (value && value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/m)) {
        const age = getAge(value);
        return age >= 14;
      } else if (value && value.match(/^[0-9]{1,3}$/m)) {
        const age = parseInt(value, 10);
        return age >= 14;
      }
      return true;
    }
  );
});

declare module 'yup' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface StringSchema {
    csvGivenName(): StringSchema;
    csvDate(): StringSchema;
    csvDobOver18(): StringSchema;
    ausMobilePhone(): StringSchema;
    ausPhone(): StringSchema;
    csvDobUnder18(): StringSchema;
    birthdate(): StringSchema;
    csvDuration(): StringSchema;
    csvIncidentDetails(): StringSchema;
    csvDateUnder12Months(overMessage?: string, underMessage?: string): StringSchema;
    csvDobOver14(): StringSchema;
  }
}

export default Yup;
