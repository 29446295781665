import { courts as courtsJson } from 'csv-data-package';
import { schemaFvio as fvio_schema} from 'csv-data-package';
import { schemaEvr as evr_schema } from 'csv-data-package';
import { CourtType, SchemaType } from './types';
import YupImport from './validation/yup';

// Validation
export const Yup = YupImport;
export { generateAddressValidation } from './validation/yup';

// Data
export const courts: CourtType[] = courtsJson;
export const schemaFVIO: SchemaType = fvio_schema;
export const schemaEVR: SchemaType = evr_schema;
export { fvioValidation } from './validation/fvio';
export { fvioEvrValidation } from './validation/fvio-evr';

// Types
export {
  CourtType,
  SchemaType,
  SchemaOptionSetsType,
  SchemaFieldType,
  SchemaOptionType,
  ConditionsType,
  ConditionFunction,
  ApplicationStatusType,
  ApplicationTypeType,
  ApplicationJurisdictionType,
  RevisionType,
  NoteType,
  FlagType,
  SendConfigMethodType,
  SendConfigType,
  ApplicationDataType,
  ChildDataType,
  ReDeclareRequestedType,
  ReDeclareAcceptedType,
  ReDeclareCanceledType,
  ReDeclareType,
  ApplicationType,
} from './types';

// Components
export { DeclarationOfTruth } from './components';

// Helpers
export {
  getQuestionConfig,
  getQuestionOptions,
  getMappedOptionValue,
  stripToolTip,
} from './helpers/schema';
export { conditionsChecker } from './helpers/conditionsChecker';
