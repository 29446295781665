import React from "react";
import styled, { css } from "styled-components/macro";
import { rem } from "polished";
import { useField } from "formik";
import { SchemaOptionType } from "csv-package";

import Radio from "./Radio";
import FormLabel, { Label } from "./FormLabel";
import FormNote, { Note } from "./FormNote";
import ErrorMessage from "./ErrorMessage";
import { Wrapper as QuestionWrapper } from "./Question";

type Props = {
  name: string;
  label: string;
  note?: string;
  options: SchemaOptionType[];
};

const StandaloneRadioGroup: React.FC<Props> = ({
  name,
  label,
  note,
  options,
}) => {
  const [field, meta] = useField(name);
  const hasError = meta.touched && meta.error;

  if (options === undefined) {
    return null;
  }

  return (
    <Wrapper>
      {label && <FormLabel name={name} text={label} />}
      {note && <FormNote text={note} />}

      {hasError && <StyledErrorMessage>{meta.error}</StyledErrorMessage>}
      <RadioItems>
        {options.map((o) => {
          const checked = meta.value === o.value;
          return (
            <RadioWrapper key={o.value}>
              <Radio
                name={name}
                value={o.value}
                label={o.text}
                onRadioChange={field.onChange}
                onRadioBlur={field.onBlur}
                checked={checked}
              />
            </RadioWrapper>
          );
        })}
      </RadioItems>
    </Wrapper>
  );
};

export default StandaloneRadioGroup;

export const Wrapper = styled.div<{ compact?: boolean }>`
  margin-bottom: ${rem(35)};

  ${({ compact }) =>
    compact &&
    css`
      margin-top: -${rem(16)};
      > ${Note} {
        display: none;
      }
      > ${Label} {
        display: none;
      }
    `}
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-bottom: 13px;
`;

const RadioWrapper = styled.div<{ displayRows?: boolean }>`
  margin-top: ${rem(4)};
  > div > ${QuestionWrapper}:first-child {
    margin-top: 1rem;
  }

  ${({ displayRows }) =>
    displayRows &&
    css`
      width: calc(50% - 2px);
    `}
`;

const RadioItems = styled.div<{ displayRows?: boolean }>`
  ${({ displayRows }) =>
    displayRows &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    `}
`;
