import React from "react";
import ReactMarkdown from "react-markdown";

import processHelpText from "./processHelpText";

import Info from "../components/Info";

const renderCmsCopy = (text: string, markdown = false) => {
  const helpText = processHelpText(text);

  if (helpText) {
    let { after, before } = helpText;

    return (
      <>
        {before && markdown ? <ReactMarkdown source={before} /> : before}
        <Info inherit string="" {...helpText} />
        {after && markdown ? <ReactMarkdown source={after} /> : after}
      </>
    );
  }
  return markdown ? <ReactMarkdown source={text} /> : text;
};

export default renderCmsCopy;
