import React from "react";
import styled from "styled-components/macro";

type Props = {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  menuOpen: boolean;
};

const Hamburger: React.FC<Props> = ({ onClick, menuOpen }) => (
  <Container onClick={onClick}>
    <Line menuOpen={menuOpen} />
    <Line menuOpen={menuOpen} />
    <Line menuOpen={menuOpen} />
  </Container>
);

export default Hamburger;

const Container = styled.div`
  width: 16px;
  height: 12px;
  position: relative;
  cursor: pointer;
  color: white;
  margin-right: 9px;
`;

const Line = styled.div<{ menuOpen: boolean }>`
  height: 1px;
  width: 100%;
  background-color: currentColor;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -1px;

  &:nth-child(1) {
    top: 0;
    transition: top 0.2s linear 0.2s, transform 0.2s linear 0s;
    ${(props) =>
      props.menuOpen &&
      `
      top: 50%;
      transform: rotate(45deg);
      transition: top 0.2s linear, transform 0.2s linear 0.2s;
    `};
  }

  &:nth-child(2) {
    top: 50%;
    transition: transform 0.2s linear 0s;
    ${(props) =>
      props.menuOpen &&
      `
      transform: rotate(-45deg);
      transition: transform 0.2s linear 0.2s;
    `};
  }

  &:nth-child(3) {
    top: 100%;
    transition: top 0.2s linear 0.2s, transform 0.2s linear 0s;
    ${(props) =>
      props.menuOpen &&
      `
      top: 50%;
      transform: rotate(-45deg);
      transition: top 0.2s linear, transform 0.2s linear 0.2s;
    `};
  }
`;
