import React from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

import ApplicationFrame from "../../../components/ApplicationFrame";

import schema from "../schema.json";
import Complete from "../components/Complete";
import ReDeclare from "../components/ReDeclare";

import steps from "../steps";
import ReDeclareComplete from "../components/ReDeclareComplete";

const ApplicationPSIO: React.FC = () => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    ReactGA.pageview(pathname);
  }, [pathname]);

  return (
    <ApplicationFrame
      schema={schema}
      steps={steps}
      complete={<Complete />}
      reDeclare={<ReDeclare />}
      reDeclareComplete={<ReDeclareComplete />}
      compatibleTypes={["N"]}
      narrativeLimitConfig={{
        keys: [
          "incident_assult_recent",
          "incident_threat_recent",
          "incident_damage_recent",
          "incident_stalked_recent",
          "incident_other_recent",
          "incident_assult_previous",
          "incident_threat_previous",
          "incident_damage_previous",
          "incident_stalked_previous",
          "incident_other_previous",
          "incident_other",
          "child_affected_detail",
        ],
        step: 1,
        limit: 4560,
      }}
    />
  );
};

export default ApplicationPSIO;
