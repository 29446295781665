import React from "react";
import styled from "styled-components/macro";
import ReactGA from "react-ga";
import { rem } from "polished";
import { Link, useHistory, useLocation } from "react-router-dom";

import Info from "../../../components/Info";
import McvBar from "../../../components/McvBar";
import InfoTip from "../../../components/InfoTip";
import ErrorMessage from "../../../components/ErrorMessage";
import SupportServices from "../../../components/SupportServices";
import SideBar from "../../../components/SideBar";
import FormLabel from "../../../components/FormLabel";
import PostcodeValidator from "../../../components/PostcodeValidator";

import { ReactComponent as ImmediateDangerIcon } from "../../../assets/icons/ImmediateDanger.svg";
import Button from "../../../components/Button";
import media from "../../../theme/media";
import colors from "../../../theme/colors";
import QuestionSpacer from "../../../components/QuestionSpacer";

const HomePage = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();

  const [error, setError] = React.useState<boolean>(false);
  const [postcodeStatus, setPostcodeStatus] = React.useState<string>("");

  React.useEffect(() => {
    ReactGA.pageview(pathname);
  }, [pathname]);

  React.useEffect(() => {
    setError(false);
  },[postcodeStatus])

  return (
    <Wrapper>
      <McvBar />
      <Intro>
        <IntroInner>
          <div>
            <h1>Apply to Extend, Vary or Revoke a Family Violence Intervention Order (FVIO)</h1>
            <IntroInfoWrapper>
              <p>
                <ImmediateDangerIcon />
                &nbsp;{" "}
                <strong>
                  If you are in immediate danger, take action by calling 000.
                </strong>
              </p>
              <div>
                Or if you are safely able to do so, contact your nearest police
                station or{" "}
                <Info
                  displayText="court location"
                  headingText="nearest court"
                  bodyText='<a href="https://www.mcv.vic.gov.au/going-court/find-court" target="_blank" rel="noopener noreferrer" >You can find your nearest court location here.</a>'
                  inherit
                />{" "}
                . Please note that the courts are open 9am-4.30pm Mon-Fri,
                excluding public holidays.
              </div>
            </IntroInfoWrapper>
            Fill out this online form to apply to extend, vary or revoke an existing family violence intervention order if:
            <ul>
              <li>
                your intervention order expiry date is two or more weeks away. If your intervention order is due to expire 
                within the next 2 weeks, contact your{" "}
                <Info
                  displayText="local court."
                  headingText="your local court"
                  bodyText='<a href="https://www.mcv.vic.gov.au/going-court/find-court" target="_blank" rel="noopener noreferrer" >You can find your nearest court location here.</a>'
                  inherit
                />
              </li>
              <li>
                you are 18 years of age or older and are either:
                <ul>
                  <li>the applicant, protected person or respondent on the intervention order</li>
                  <li>the parent or guardian other than the respondent, of a protected person who is a child under 18 years of age</li>
                  <li>any other person with the written consent of a parent other than the respondent, of a protected person who is a child under 18 years of age</li>
                </ul>
              </li>
            </ul>
            <div>
              <p>
                This form cannot be a filled out by someone else on your behalf.
              </p>
              <p>
                If you are under 18 years of age and are a protected person on an intervention order from the Magistrates Court of Victoria contact your{" "}
                <Info
                  displayText="local court."
                  headingText="your local court"
                  bodyText='<a href="https://www.mcv.vic.gov.au/going-court/find-court" target="_blank" rel="noopener noreferrer" >You can find your nearest court location here.</a>'
                  inherit
                />
              </p>
              <p>
                If you are under 18 years of age and not a protected person on the intervention order,
                 you will need to contact the{" "}
                <a
                  href="https://www.childrenscourt.vic.gov.au/contact-us"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Children's Court of Victoria
                </a>{" "}
                to complete an application
              </p>
              <div>
                If your family violence intervention order has expired or you are seeking a new family violence
                 intervention order you can{" "}
                 <a
                  href="https://fvio.mcv.vic.gov.au/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  apply online
                </a>{" "}
                or contact your{" "}
                <Info
                  displayText="local court."
                  headingText="your local court"
                  bodyText='<a href="https://www.mcv.vic.gov.au/going-court/find-court" target="_blank" rel="noopener noreferrer" >You can find your nearest court location here.</a>'
                  inherit
                />
              </div>
              <QuestionSpacer size={120} />
            </div>
          </div>
          <SideBar />
        </IntroInner>
      </Intro>
      <Start>
        <StartInner>
          <div style={{ maxWidth: "647px" }}>
            <h2>Start your extend, vary or revoke online application here</h2>

            <div>
              <InfoTip>
                Have an account? Log in to start or continue an
                application.&nbsp;{" "}
                <div>
                  <Link to="/login">Log in</Link>
                </div>
              </InfoTip>
              <QuestionSpacer size={10} />
            </div>
            <FormLabel text="Enter the postcode of your current residential address" />
            <StyledInfo string="Why are we asking this?|Why are we asking this?|We need your current residential address to identify which court will process your application. A residential address can be the address of your house, refuge, crisis accommodation or friend’s house." />
            <br />
            <br />

            <div>
              <PostcodeValidator setPostcodeStatus={setPostcodeStatus} />
            </div>
          </div>
          {error && (
            <div>
              <ErrorMessage>
                Please ensure you have entered a valid postcode
              </ErrorMessage>
            </div>
          )}
          <QuestionSpacer size={25} />
          <Button
            onClick={() => {
              if(postcodeStatus === "ok") {
                push("/application");
              } else {
                setError(true);
              }
            }}
          >
            Begin application
          </Button>
          <br />
          <LowerInfo>
            <SideBar />
          </LowerInfo>
        </StartInner>
      </Start>
      <Support>
        <SupportInner>
          <h2>Family violence support services</h2>
          <p>
            If you need help completing this application, contact your{" "}
            <Info
              displayText="local court"
              headingText="nearest court"
              bodyText='<a href="https://www.mcv.vic.gov.au/going-court/find-court" title="_blank" rel="noopener noreferrer" >You can find your nearest court location here.</a>'
              inherit
            />{" "}
            or{" "}
            <Info
              displayText="family violence support service"
              headingText="family violence support service"
              bodyText='<a href="https://www.dvrcv.org.au/support-services/victorian-services" title="_blank" rel="noopener noreferrer" >Family violence support services</a>'
              inherit
            />{" "}
            or one of the following state-wide services:
          </p>

          <br />
          <SupportServices
            services={[
              "safeSteps",
              "noToViolence",
              "_1800Respect",
              "djirra",
              "inTouch",
              "rainbowDoor",
            ]}
          />

          <h2>Legal advice</h2>
          <SupportServices
            services={[
              "victoriaLegalAid",
              "federationOfCommunityLegalCentres",
              "victoriaAboriginalLegalService",
            ]}
          />
        </SupportInner>
      </Support>
    </Wrapper>
  );
};

export default HomePage;

const leftPaddingDesktop = "120px";
const leftPaddingTablet = "15px";

const Wrapper = styled.section`
  position: relative;
`;

const Support = styled.div`
  padding: 0 15px 70px;
  padding-top: 0px;
  width: 100%;
  max-width: 1310px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;

  ${media.greaterThan("desktop")`
      justify-content: flex-start;
    `}
`;

const SupportInner = styled.div`
  background: white;
  position: relative;
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
  ${media.greaterThan("tablet")`
      margin-top: 0px;
      padding-left: ${leftPaddingTablet};
  `};
  ${media.greaterThan("desktop")`
padding-left: 105px;
transform: translateY(-40px);
  `};
  p {
    font-size: 18px;
  }
`;

const Intro = styled.section`
  background: #d4e1ed;
  color: #3c3c3c;
  padding: 60px 15px 30px;
`;

const IntroInner = styled.div`
  width: 100%;
  max-width: 1310px;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  font-size: 18px;
  line-height: 1.44;
  strong {
    font-weight: bold;
  }

  a {
    color: ${colors.blue};
  }

  small {
    font-size: 90%;
  }

  > :first-child {
    width: 680px;
    ${media.greaterThan("tablet")`
      padding-right: 20px;
    `}
  }

  > :last-child {
    display: none;
    ${media.greaterThan("tablet")`
      display: block;
    `}
  }

  ${media.greaterThan("tablet")`
    padding-left: ${leftPaddingTablet};
  `}
  ${media.greaterThan("desktop")`
    padding-left: ${leftPaddingDesktop};
  `}

  ul {
    margin: 18px 0 36px;
  }

  li {
    margin-bottom: 0.75em;
  }
`;

const Start = styled.div`
  padding: 0 15px 70px;
  padding-bottom: 0px;
  width: 100%;
  max-width: 1310px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;

  ${media.greaterThan("desktop")`
     padding-left: ${leftPaddingTablet};
     justify-content: flex-start;
    `}
`;

const StartInner = styled.div`
  background: white;
  position: relative;
  width: 100%;
  max-width: 1310px;
  padding: 60px 0;
  min-height: ${rem(350)};
  z-index: 99;
  ${media.greaterThan("tablet")`
    padding-left: ${leftPaddingTablet};
  transform: translateY(-93px);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13);

  h2 {
    margin-bottom: 0.75em;
  }


  `};
  ${media.greaterThan("desktop")`
    padding-left: 105px;
  
  `};
`;

const StyledInfo = styled(Info)`
  margin-top: -1em;
`;

const LowerInfo = styled.div`
  margin-top: 60px;
  display: block;
  ${media.greaterThan("tablet")`
    display: none;
  `}
`;

const IntroInfoWrapper = styled.div`
  background: #fff;
  width: 100%;
  padding: 15px 20px 15px 37px;
  position: relative;
  margin-bottom: 21px;

  ${media.greaterThan("tablet")`
    padding: 16px 18px 16px;
  `}

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  line-height: 1.44;

  svg {
    width: 17px;
    height: 16px;
    display: inline-block;
    margin-right: 2px;
    position: absolute;
    left: 10px;
    top: 16px;
    ${media.greaterThan("tablet")`
      position: static;
    `}
  }

  strong {
    font-size: 18px;
    font-weight: bold;
    br {
      line-height: 0.8em;
    }
  }

  p:first-child {
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0.3em;
  }
`;
