import React from "react";
import styled, { keyframes, css } from "styled-components";

import media from "../theme/media";

import { ReactComponent as SavingIcon } from "../assets/icons/Saving.svg";

const SavingIndicator: React.FC<{ savingInProgress: boolean }> = ({
  savingInProgress,
}) => (
  <Saving savingInProgress={savingInProgress}>
    <Spinner animate={savingInProgress}>
      <SavingIcon />
    </Spinner>
    {savingInProgress ? "Saving" : "Saved"}
  </Saving>
);
export default SavingIndicator;

const Saving = styled.div<{ savingInProgress: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-size: 15px;
  min-width: 5em;

  circle.path {
    display: none;
  }

  ${media.greaterThan("tablet")`
    margin-left: auto;
  `}
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div<{ animate: boolean }>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: relative;
  box-sizing: border-box;
  margin-right: 0.66em;
  > svg {
    height: 14px;
    width: 14px;
    position: absolute;
    top: 0;
    left: 0;
  }
  ${({ animate }) =>
    animate &&
    css`
      border-top: 1px solid white;
      animation: ${rotate} 0.5s linear infinite;
      > svg {
        display: none;
      }
    `}
`;
