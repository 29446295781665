import { SchemaOptionType, conditionsChecker } from "csv-package";

import { FlatApplciationType } from "../types";

const optionsCalculator = (
  optionsArray: SchemaOptionType[],
  values: FlatApplciationType
) => {
  return optionsArray.filter((option) => {
    if (option.conditions !== undefined) {
      const { conditions } = option;
      return conditionsChecker(conditions, values);
    }
    return true;
  });
};

export default optionsCalculator;
