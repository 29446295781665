import { generateAddressValidation, Yup } from "csv-package";

const personDetails = {
  appl_det_giv_nam: Yup.string().when("appl_type", {
    is: "abap",
    then: Yup.string().csvGivenName().required("Please enter a given name"),
  }),
  appl_det_giv_nam2: Yup.string().csvGivenName(),
  appl_det_giv_nam3: Yup.string().csvGivenName(),
  appl_det_fam_nam: Yup.string().when("appl_type", {
    is: "abap",
    then: Yup.string().required("Please enter a family name"),
  }),
  appl_rel_ap: Yup.string().when("appl_type", {
    is: "abap",
    then: Yup.string().required("We require this information"),
  }),
  appl_dob: Yup.string().when(["appl_type", "appl_age"], {
    is: (appl_type: string, appl_age: string) =>
      appl_type === "abap" && !appl_age,
    then: Yup.string().csvDobOver18().required("You must enter a DOB or age"),
  }),
  appl_age: Yup.string().csvDobOver18(),
  appl_sex: Yup.string().when("appl_type", {
    is: "abap",
    then: Yup.string().required("We require this information"),
  }),
  appl_sex_other: Yup.string().when(["appl_type", "appl_sex"], {
    is: (appl_type: string, appl_sex: string) =>
      appl_type === "abap" && appl_sex === "U",
    then: Yup.string().required("We require this information"),
  }),
  appl_abor_code: Yup.string().when("appl_type", {
    is: "abap",
    then: Yup.string().required("We require this information"),
  }),
  ...generateAddressValidation("appl_det_addr", "appl_type", "abap"),
  appl_safe_cont: Yup.array()
    .ensure()
    .when("appl_type", {
      is: "abap",
      then: Yup.array().min(1, "Please select a contact option"),
    }),
  appl_cont_phone_3: Yup.string().when(["appl_type", "appl_safe_cont"], {
    is: (appl_type: string, appl_safe_cont: string[]) =>
      appl_type === "abap" &&
      appl_safe_cont &&
      appl_safe_cont.includes("phone"),
    then: Yup.string().ausMobilePhone().required("We require this information"),
  }),
  appl_cont_phone: Yup.string().ausPhone(),
  appl_cont_phone_2: Yup.string().ausPhone(),
  appl_cont_phone_sms: Yup.string().when(["appl_type", "appl_safe_cont"], {
    is: (appl_type: string, appl_safe_cont: string[]) =>
      appl_type === "abap" &&
      appl_safe_cont &&
      appl_safe_cont.includes("phone"),
    then: Yup.string().required("We require this information"),
  }),
  appl_cont_phone_vm: Yup.string().when(["appl_type", "appl_safe_cont"], {
    is: (appl_type: string, appl_safe_cont: string[]) =>
      appl_type === "abap" &&
      appl_safe_cont &&
      appl_safe_cont.includes("phone"),
    then: Yup.string().required("We require this information"),
  }),
  appl_cont_safe_times: Yup.array()
    .ensure()
    .when(["appl_type", "appl_safe_cont"], {
      is: (appl_type: string, appl_safe_cont: string[]) =>
        appl_type === "abap" &&
        appl_safe_cont &&
        appl_safe_cont.includes("phone"),
      then: Yup.array().ensure().min(1, "Please select at least 1 option"),
    }),
  appl_cont_email: Yup.string().when(["appl_type", "appl_safe_cont"], {
    is: (appl_type: string, appl_safe_cont: string[]) =>
      appl_type === "abap" &&
      appl_safe_cont &&
      appl_safe_cont.includes("email"),
    then: Yup.string().email().required("We require this information"),
  }),
  appl_cont_email_2: Yup.string().email(),
  appl_cont_trst_per: Yup.string().when(["appl_type", "appl_safe_cont"], {
    is: (appl_type: string, appl_safe_cont: string[]) =>
      appl_type === "abap" &&
      appl_safe_cont &&
      appl_safe_cont.includes("trust"),
    then: Yup.string().trim().required("We require this information"),
  }),
  appl_cont_trst_per_rel: Yup.string().when(["appl_type", "appl_safe_cont"], {
    is: (appl_type: string, appl_safe_cont: string[]) =>
      appl_type === "abap" &&
      appl_safe_cont &&
      appl_safe_cont.includes("trust"),
    then: Yup.string().trim().required("We require this information"),
  }),
  appl_cont_trst_per_rel_other: Yup.string().when(
    ["appl_type", "appl_safe_cont", "appl_cont_trst_per_rel"],
    {
      is: (
        appl_type: string,
        appl_safe_cont: string[],
        appl_cont_trst_per_rel: string
      ) =>
        appl_type === "abap" &&
        appl_safe_cont &&
        appl_safe_cont.includes("trust") &&
        appl_cont_trst_per_rel === "other",
      then: Yup.string().trim().required("We require this information"),
    }
  ),
  appl_cont_trst_per_phone: Yup.string().when(
    ["appl_type", "appl_safe_cont", "appl_cont_trst_per_email"],
    {
      is: (
        appl_type: string,
        appl_safe_cont: string[],
        appl_cont_trst_per_email: string
      ) =>
        appl_type === "abap" &&
        appl_safe_cont &&
        appl_safe_cont.includes("trust") &&
        !appl_cont_trst_per_email,
      then: Yup.string().required(
        "Please enter a email address or phone number"
      ),
    }
  ),
  appl_cont_trst_per_email: Yup.string().email(),

  ap_giv_nam: Yup.string()
    .csvGivenName()
    .required("Please provide n given name"),
  ap_giv_nam2: Yup.string().csvGivenName(),
  ap_giv_nam3: Yup.string().csvGivenName(),
  ap_fam_nam: Yup.string().trim().required("Please provide a family name"),
  ap_rel_resp: Yup.string().required("Please select a relationship type"),
  ap_dob: Yup.string().when("ap_age", {
    is: (ap_age: string) => !ap_age,
    then: Yup.string().csvDate().required("Please enter a DOB or age"),
  }),
  ap_sex: Yup.string().required("We require this information"),
  ap_sex_other: Yup.string().when("ap_sex", {
    is: "U",
    then: Yup.string().trim().required("We require this information"),
  }),
  ap_lgbt: Yup.string().required("We require this information"),
  ap_abor_code: Yup.string().required("We require this information"),
  ap_pregnant_baby: Yup.string().required("We require this information"),
  ap_inter: Yup.string().required("We require this information"),
  ap_lang: Yup.string().when("ap_inter", {
    is: "yes",
    then: Yup.string().required("We require this information"),
  }),
  ap_lang_other: Yup.string().when("ap_lang", {
    is: "Other",
    then: Yup.string().trim().required("We require this information"),
  }),
  ...generateAddressValidation("ap_addr", "appl_type", "ap"),
  ap_safe_cont: Yup.array()
    .ensure()
    .min(1, "Please select at least 1 contact option"),
  ap_cont_phone_3: Yup.string().when("ap_safe_cont", {
    is: (ap_safe_cont: string[]) =>
      ap_safe_cont && ap_safe_cont.includes("phone"),
    then: Yup.string()
      .ausMobilePhone()
      .required("Please enter a mobile number"),
  }),
  ap_cont_phone: Yup.string().ausPhone(),
  ap_cont_phone_2: Yup.string().ausPhone(),
  ap_cont_phone_sms: Yup.string().when("ap_safe_cont", {
    is: (ap_safe_cont: string[]) =>
      ap_safe_cont && ap_safe_cont.includes("phone"),
    then: Yup.string().required("We require this information"),
  }),
  ap_cont_phone_vm: Yup.string().when("ap_safe_cont", {
    is: (ap_safe_cont: string[]) =>
      ap_safe_cont && ap_safe_cont.includes("phone"),
    then: Yup.string().required("We require this information"),
  }),
  ap_cont_safe_times: Yup.array()
    .ensure()
    .when("ap_safe_cont", {
      is: (ap_safe_cont: string[]) =>
        ap_safe_cont && ap_safe_cont.includes("phone"),
      then: Yup.array().ensure().min(1, "Please select at least 1 option"),
    }),
  ap_cont_email: Yup.string().when("ap_safe_cont", {
    is: (ap_safe_cont: string[]) =>
      ap_safe_cont && ap_safe_cont.includes("email"),
    then: Yup.string().email().required("We require this information"),
  }),
  ap_cont_email_2: Yup.string().email(),
  ap_cont_trst_per: Yup.string().when("ap_safe_cont", {
    is: (ap_safe_cont: string[]) =>
      ap_safe_cont && ap_safe_cont.includes("trust"),
    then: Yup.string().trim().required("We require this information"),
  }),
  ap_cont_trst_per_rel: Yup.string().when("ap_safe_cont", {
    is: (ap_safe_cont: string[]) =>
      ap_safe_cont && ap_safe_cont.includes("trust"),
    then: Yup.string().trim().required("We require this information"),
  }),
  ap_cont_trst_per_rel_other: Yup.string().when(
    ["ap_safe_cont", "ap_cont_trst_per_rel"],
    {
      is: (ap_safe_cont: string[], ap_cont_trst_per_rel: string) =>
        ap_safe_cont &&
        ap_safe_cont.includes("trust") &&
        ap_cont_trst_per_rel === "other",
      then: Yup.string().trim().required("We require this information"),
    }
  ),
  ap_cont_trst_per_email: Yup.string().when(
    ["ap_safe_cont", "ap_cont_trst_per_phone"],
    {
      is: (ap_safe_cont: string[], ap_cont_trst_per_phone: string) =>
        ap_safe_cont &&
        ap_safe_cont.includes("trust") &&
        !ap_cont_trst_per_phone,
      then: Yup.string().required(
        "Please enter a email address or phone number"
      ),
    }
  ),
  ap_cont_trst_per_phone: Yup.string().email(),
};

export default personDetails;
