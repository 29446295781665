import React from "react";
import _ from "lodash";
import { FlatApplciationType } from "../types";
import { useFormikContext } from "formik";

type ChildrenControls = {
  removeChild: (childIndex: number) => void;
  addChild: () => void;
  removeAllChildren: () => void;
};

const useChildren = (): ChildrenControls => {
  const {
    values: { children },
    setFieldValue,
  } = useFormikContext<FlatApplciationType>();

  const removeChild = React.useCallback(
    (childIndex) => {
      if (children.length <= 1) {
        setFieldValue("children", []);
      } else {
        setFieldValue("children", [
          ...children.slice(0, childIndex),
          ...children.slice(childIndex + 1),
        ]);
      }
    },
    [children, setFieldValue]
  );

  const addChild = React.useCallback(() => {
    const newChild = {
      index: "",
    };
    if (_.isEmpty(children)) {
      setFieldValue("children", [newChild]);
    } else {
      setFieldValue("children", [...children, newChild]);
    }
  }, [children, setFieldValue]);

  const removeAllChildren = () => {
    setFieldValue("children", []);
  };

  return {
    addChild,
    removeChild,
    removeAllChildren,
  };
};

export default useChildren;
