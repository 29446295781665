import React from "react";
import styled from "styled-components/macro";
import ReactGA from "react-ga";
import { rem } from "polished";
import { Link, useHistory, useLocation } from "react-router-dom";

import Info from "../../../components/Info";
import McvBar from "../../../components/McvBar";
import InfoTip from "../../../components/InfoTip";
import ErrorMessage from "../../../components/ErrorMessage";
import SideBar from "../../../components/SideBar";
import FormLabel from "../../../components/FormLabel";
import PostcodeValidator from "../../../components/PostcodeValidator";
import Checkbox from "../../../components/Checkbox";

import { ReactComponent as ImmediateDangerIcon } from "../../../assets/icons/ImmediateDanger.svg";
import Button from "../../../components/Button";
import media from "../../../theme/media";
import colors from "../../../theme/colors";
import QuestionSpacer from "../../../components/QuestionSpacer";

const HomePage = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();

  const [error, setError] = React.useState<boolean>(false);
  const [postcodeStatus, setPostcodeStatus] = React.useState<string>("");
  const [userHasAgreed, setUserHasAgreed] = React.useState(false);
  const [userApplyingAgainstFamilyMember, setUserApplyingAgainstFamilyMember] =
    React.useState(false);

  React.useEffect(() => {
    ReactGA.pageview(pathname);
  }, [pathname]);

  return (
    <Wrapper>
      <McvBar />
      <Intro>
        <IntroInner>
          <div>
            <h1>Personal Safety Intervention Order (PSIO)</h1>
            <IntroInfoWrapper>
              <p>
                <ImmediateDangerIcon />
                &nbsp;{" "}
                <strong>
                  If you are in immediate danger, take action by calling 000.
                </strong>
              </p>
              <div>
                Or if you are safely able to do so, contact your nearest police
                station or{" "}
                <Info
                  displayText="court location"
                  headingText="nearest court"
                  bodyText='<a href="https://www.mcv.vic.gov.au/going-court/find-court" title="_blank" rel="noopener noreferrer" >You can find your nearest court location here.</a>'
                  inherit
                />{" "}
                . Please note that the courts are open 9am-4.30pm Mon-Fri,
                excluding public holidays.
              </div>
            </IntroInfoWrapper>
            Before starting your application, please consider:
            <ul>
              <li>
                you must be over 18 years old (if you’re under 18, contact us)
              </li>
              <li>
                you must be alleging that a prohibited behaviour such as a
                threat, act to cause serious bodily harm has occured by a
                non-family member.
              </li>
              <li>
                applications for an intervention can take up to 3-5 days to
                reach initial processing and legal protection is not immediate
              </li>
              <li>
                if the police filed the initial order you will need to contact
                them directly about all matters regarding your intervention
                status.
              </li>
            </ul>
            <QuestionSpacer size={120} />
          </div>
          <SideBar />
        </IntroInner>
      </Intro>
      <Start>
        <StartInner>
          <div style={{ maxWidth: "647px" }}>
            <h2>Start your online PSIO application here</h2>

            <div>
              <InfoTip>
                Have an account? Log in to start or continue an
                application.&nbsp;{" "}
                <div>
                  <Link to="/login">Log in</Link>
                </div>
              </InfoTip>
              <QuestionSpacer size={10} />
            </div>
            <FormLabel text="Enter the postcode of your current residential address" />
            <StyledInfo string="Why are we asking this?|Why are we asking this?|We need your current residential address to identify which court will process your application. A residential address can be the address of your house, refuge, crisis accommodation or friend’s house." />
            <br />
            <br />

            <div>
              <PostcodeValidator setPostcodeStatus={setPostcodeStatus} />
            </div>
          </div>
          <div style={{ maxWidth: "647px" }}>
            {postcodeStatus === "ok" && (
              <>
                <QuestionSpacer size={25} />
                <Checkbox
                  label="I am at least 18 years of age"
                  name="agree"
                  value="yes"
                  onCheckboxChange={(e) => setUserHasAgreed(e.target.checked)}
                  checked={userHasAgreed}
                />
                <Checkbox
                  label={
                    "I am applying against a family member, partner or ex-partner"
                  }
                  name="against family member"
                  value="yes"
                  onCheckboxChange={(e) =>
                    setUserApplyingAgainstFamilyMember(e.target.checked)
                  }
                  checked={userApplyingAgainstFamilyMember}
                />
              </>
            )}
          </div>
          {error && (
            <div>
              <ErrorMessage>
                Please ensure you have entered a valid postcode and agreed to
                the terms
              </ErrorMessage>
            </div>
          )}
          <QuestionSpacer size={25} />
          <Button
            onClick={() => {
              if (!userHasAgreed || !userApplyingAgainstFamilyMember) {
                setError(true);
              } else {
                push("/application");
              }
            }}
          >
            Begin application
          </Button>
          <br />
          <LowerInfo>
            <SideBar />
          </LowerInfo>
        </StartInner>
      </Start>
      <Support>
        <SupportInner>
          <h2>Dispute resolution</h2>
          <p>
            The courts will not hear matters relating to the following types of
            harrassments:
          </p>
          <ul>
            <li>
              Derogatory taunts including racial taunts or about sexual
              orientation or gender identity
            </li>
            <li>Encouragement of a third person to make derogatory taunts</li>
            <li>
              Sexual harrassment that does not meet the severiity of a sexual
              assault
            </li>
          </ul>
          <p>
            These types of incidents can be resolved using alternative dispute
            resolution:
          </p>
        </SupportInner>
      </Support>
    </Wrapper>
  );
};

export default HomePage;

const leftPaddingDesktop = "120px";
const leftPaddingTablet = "15px";

const Wrapper = styled.section`
  position: relative;
`;

const Support = styled.div`
  padding: 0 15px 70px;
  padding-top: 0px;
  width: 100%;
  max-width: 1310px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;

  ${media.greaterThan("desktop")`
      justify-content: flex-start;
    `}
`;

const SupportInner = styled.div`
  background: white;
  position: relative;
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
  ${media.greaterThan("tablet")`
      margin-top: 0px;
      padding-left: ${leftPaddingTablet};
  `};
  ${media.greaterThan("desktop")`
padding-left: 105px;
transform: translateY(-40px);
  `};
  p {
    font-size: 18px;
  }
`;

const Intro = styled.section`
  background: #d4e1ed;
  color: #3c3c3c;
  padding: 60px 15px 30px;
`;

const IntroInner = styled.div`
  width: 100%;
  max-width: 1310px;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  font-size: 18px;
  line-height: 1.44;
  strong {
    font-weight: bold;
  }

  a {
    color: ${colors.blue};
  }

  small {
    font-size: 90%;
  }

  > :first-child {
    width: 680px;
    ${media.greaterThan("tablet")`
      padding-right: 20px;
    `}
  }

  > :last-child {
    display: none;
    ${media.greaterThan("tablet")`
      display: block;
    `}
  }

  ${media.greaterThan("tablet")`
    padding-left: ${leftPaddingTablet};
  `}
  ${media.greaterThan("desktop")`
    padding-left: ${leftPaddingDesktop};
  `}

  ul {
    margin: 18px 0 36px;
  }

  li {
    margin-bottom: 0.75em;
  }
`;

const Start = styled.div`
  padding: 0 15px 70px;
  padding-bottom: 0px;
  width: 100%;
  max-width: 1310px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;

  ${media.greaterThan("desktop")`
     padding-left: ${leftPaddingTablet};
     justify-content: flex-start;
    `}
`;

const StartInner = styled.div`
  background: white;
  position: relative;
  width: 100%;
  max-width: 1310px;
  padding: 60px 0;
  min-height: ${rem(350)};
  z-index: 99;
  ${media.greaterThan("tablet")`
    padding-left: ${leftPaddingTablet};
  transform: translateY(-93px);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13);

  h2 {
    margin-bottom: 0.75em;
  }


  `};
  ${media.greaterThan("desktop")`
    padding-left: 105px;
  
  `};
`;

const StyledInfo = styled(Info)`
  margin-top: -1em;
`;

const LowerInfo = styled.div`
  margin-top: 60px;
  display: block;
  ${media.greaterThan("tablet")`
    display: none;
  `}
`;

const IntroInfoWrapper = styled.div`
  background: #fff;
  width: 100%;
  padding: 15px 20px 15px 37px;
  position: relative;
  margin-bottom: 21px;

  ${media.greaterThan("tablet")`
    padding: 16px 18px 16px;
  `}

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  line-height: 1.44;

  svg {
    width: 17px;
    height: 16px;
    display: inline-block;
    margin-right: 2px;
    position: absolute;
    left: 10px;
    top: 16px;
    ${media.greaterThan("tablet")`
      position: static;
    `}
  }

  strong {
    font-size: 18px;
    font-weight: bold;
    br {
      line-height: 0.8em;
    }
  }

  p:first-child {
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0.3em;
  }
`;
