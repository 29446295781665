import { API, Auth } from "aws-amplify";

export type ApiBodyType = {
  [key: string]: string | object | string[];
};

const api = async (
  method: "get" | "post" | "patch" | "del",
  path: string,
  body: ApiBodyType | null = null
): Promise<object> => {
  const myInit: {
    body: ApiBodyType | null;
    headers?: {
      [key: string]: string;
    };
  } = {
    body,
  };

  // added for local dev
  if (process.env.REACT_APP_API_URL === "http://localhost:4444") {
    const creds = await Auth.currentCredentials();
    myInit.headers = {
      "X-Identity-Id": creds.identityId,
    };
  }
  // @ts-ignore
  const result = await API[method]("csv", `/public${path}`, myInit);

  return result;
};

export default api;
