import React from "react";
import styled from "styled-components";
import { ReDeclareType } from "csv-package";

import { ReactComponent as Eye } from "../assets/icons/Eye.svg";
import { FlatApplciationType } from "../types";
import { Container, Heading, ViewPrevButton } from "./ReDeclareSection";

type Props = {
  values: FlatApplciationType;
  reDeclareObject: ReDeclareType;
  title: string;
};

const ReDeclareSection: React.FC<Props> = ({
  values,
  reDeclareObject,
  title,
}) => {
  const [viewPrevious, setViewPrevious] = React.useState(false);

  if (values.Narrative === reDeclareObject.Narrative) {
    return null;
  }

  const narrative = viewPrevious ? values.Narrative : reDeclareObject.Narrative;

  return (
    <Container>
      <Heading>
        {title}
        <ViewPrevButton
          onClick={(e) => {
            e.preventDefault();
            setViewPrevious((bool) => !bool);
          }}
        >
          <Eye />
          {viewPrevious ? `View admended response` : `View previous response`}
        </ViewPrevButton>
      </Heading>
      <Pre>{narrative}</Pre>
    </Container>
  );
};

export default ReDeclareSection;

const Pre = styled.pre`
  white-space: pre-line;
  font-family: inherit;
`;
