import { useFormikContext } from "formik";
import React from "react";
import ConfirmSection from "../../../components/ConfirmSection";
import PageTitle from "../../../components/PageTitle";
import { hasAddAndRemoveChildren, hasOnlyAddChildren, hasOnlyRemoveChildren } from "../../../helpers/childHelpers";
import { FlatApplciationType } from "../../../types";
import { protectedPersonRoles, unProtectedPersonRoles } from "../constants";

const Confirm: React.FC = () => {
  const { values } = useFormikContext<FlatApplciationType>();

  return (
    <>
      <PageTitle
        heading="Confirm your details"
        description="Please review the details of your application to ensure that the information you’ve provided is true and correct."
      />

      <ConfirmSection
        heading="Who’s making the application today?"
        goToStepIndex={1}
        config={[
          {
            names: ["seeking", "seeking_appl_role"],
          },
          {
            conditions: {
              seeking: "appl",
              seeking_appl_role: "pg",
            },
            names: [
              "seeking_appl_sub_role",
            ],
          },
        ]}
      />

      {values.seeking === "resp" && (
        <ConfirmSection
          heading="Applicant contact details"
          goToStepIndex={2}
          config={[
            {
              names: [
                "appl_det_giv_nam",
                "appl_det_fam_nam",
                "appl_det_dob",
                "appl_safe_cont",
                "appl_cont_phone_3",
                "appl_cont_phone",
                "appl_cont_phone_2",
                "appl_cont_email",
                "appl_cont_email_2",
              ],
            },
            {
              private:
                "Private question - as the respondent, a court registrar will advise if your address is able to be kept secret if you have chosen this option.",
              names: ["address__appl_det_addr", "appl_det_addr_secret"],
            },
            {
              heading: "Protected person",
              names: [
                "prp_det_giv_nam",
                "prp_det_giv_nam2",
                "prp_det_giv_nam3",
                "prp_det_fam_nam",
                "prp_det_dob"
              ],
            },
          ]}
        />
      )}

      {values.seeking === "appl" && unProtectedPersonRoles.includes(String(values.seeking_appl_role)) && (
        <ConfirmSection
          heading="Applicant contact details"
          goToStepIndex={2}
          config={[
            {
              names: [
                "appl_det_giv_nam",
                "appl_det_fam_nam",
                "appl_det_dob",
                "appl_cont",
                "appl_phone_3",
                "appl_phone",
                "appl_phone_2",
                "appl_email",
                "appl_email_2",
              ],
            },
            {
              private:
                "Private question - respondent will not be advised of your address if you choose to keep your current address secret.",
              names: ["address__appl_det_addr", "appl_det_addr_secret"],
            },
            {
              heading: "Protected person",
              private:
                "Private question - respondent will not be advised of your address if you choose to keep your current address secret.",
              names: ["address__prp_addr", "prp_addr_secret"],
            },
            {
              names: [
                "prp_det_giv_nam",
                "prp_det_giv_nam2",
                "prp_det_giv_nam3",
                "prp_det_fam_nam",
                "prp_det_dob",
                {name: "appl_safe_cont", label: "What is the best way to contact the protected person?"},
                "appl_cont_phone_3",
                "appl_cont_phone",
                "appl_cont_phone_2",
                "appl_cont_email",
                "appl_cont_email_2",
                "appl_cont_trst_per",
                {name: "appl_cont_trst_per_rel", label: "What is the protected person’s relationship with the trusted person?"},                
                "appl_cont_trst_per_rel_other",
                "appl_cont_trst_per_phone",
                "appl_cont_trst_per_email",
              ],
            },
            {
              heading: "Respondent",
              names: [
                "resp_det_giv_nam",
                "resp_det_giv_nam2",
                "resp_det_giv_nam3",
                "resp_det_fam_nam",
                "resp_det_dob",
                "resp_addr_known",
                "resp_addr_known_pc",
                "resp_addr_known_pc_stn",
                "resp_addr_known_pc_ofc",
                "address__resp_det_addr",
              ],
            },
          ]}
        />
      )}
      {values.seeking === "appl" && protectedPersonRoles.includes(String(values.seeking_appl_role)) && (
        <ConfirmSection
          heading="Applicant contact details"
          goToStepIndex={2}
          config={[
            {
              names: [
                "appl_det_giv_nam",
                "appl_det_fam_nam",
                "appl_det_dob"
              ],
            },
            {
              private:
                "Private question - respondent will not be advised of your address if you choose to keep your current address secret.",
              names: ["address__prp_addr", "prp_addr_secret"],
            },
            {
              heading: "Respondent",
              names: [
                "resp_det_giv_nam",
                "resp_det_giv_nam2",
                "resp_det_giv_nam3",
                "resp_det_fam_nam",
                "resp_det_dob",
                "resp_addr_known",
                "resp_addr_known_pc",
                "resp_addr_known_pc_stn",
                "resp_addr_known_pc_ofc",
                "address__resp_det_addr",
              ],
            },
          ]}
        />)}

      <ConfirmSection
        heading="Application type"
        goToStepIndex={3}
        config={[
          {
            names: ["app_types"],
          },
          {
            conditions: {
              app_types: "AE",
            },
            heading: "Extend the order",
            names: [
              "app_type_extend_why",
            ],
          },
          {
            conditions: {
              app_types: "AE",
              app_type_extend_why: "still_fearful",
            },
            names: [
              "app_type_extend_why_still_fearful",
            ],
          },
          {
            conditions: {
              app_types: "AE",
              app_type_extend_why: "breached",
            },
            names: [
              "app_type_extend_why_breached",
            ],
          },
          {
            conditions: {
              app_types: "AE",
              app_type_extend_why: "violence_stopped",
            },
            names: [
              "app_type_extend_why_violence_stopped",
            ],
          },
          {
            conditions: {
              app_types: "AE",
            },
            names: [
              "app_type_extend_further_violence",
              "app_type_extend_add",
            ],
          },
          {
            conditions: {
              app_types: "AV",
            },
            heading: "Vary the legal conditions on the order",
            names: [
              "coo_con_old_a",
              "coo_con_old_a_detail",
              "coo_con_old_b",
              "coo_con_old_b_detail",
              "coo_con_old_c",
              "coo_con_old_c_detail",
              "coo_con_old_d",
              "coo_con_old_d_detail",
              "coo_con_old_e",
              "coo_con_old_e_detail",
              "coo_con_old_f",
              "coo_con_old_f_detail",
              "coo_con_old_g",
              "coo_con_old_g_detail",
              "coo_con_old_h",
              "coo_con_old_h_detail",
              "coo_exc_old_a",
              "coo_exc_old_a_detail",
              "coo_exc_old_b",
              "coo_exc_old_b_detail",
              "coo_exc_old_c",
              "coo_exc_old_c_detail",
              "coo_exc_old_d",
              "coo_exc_old_d_detail",
              "coo_exc_old_e",
              "coo_exc_old_e_detail",
              {name: "coo_con_old_i", label: "The respondent must arrange to return my property within 2 days of the intervention order being served."},
              "coo_con_old_i_detail",
              {name: "coo_con_old_j", label: "The respondent must arrange to return property you own together within 2 days of the intervention order being served."},
              "coo_con_old_j_detail",
              "coo_con_old_k",
              "coo_con_old_k_detail",
              "coo_con_old_l",
              "coo_con_old_l_detail",
              "coo_con_old_m",
              "coo_con_old_m_detail",
              "coo_con_old_n",
              "coo_con_old_n_detail",
              "coo_con_old_o",
              "coo_con_old_o_detail",
              "coo_con_old_p",
              "coo_con_old_p_detail",
              "coo_con_old_q",
              "coo_con_old_q_detail",
              "coo_con_old_r",
              "coo_con_old_r_detail",
              "coo_con_other",
              "vary_appl_why",
            ],
          },
          {
            conditions: {
              app_types: "AC",
              children: hasOnlyAddChildren
            },
            heading: "Add or remove a child/children",
            names: ["child_action_add_why"],
          },
          {
            conditions: {
              app_types: "AC",
              children: hasOnlyRemoveChildren
            },
            heading: "Add or remove a child/children",
            names: ["child_action_remove_why"],
          },
          {
            conditions: {
              app_types: "AC",
              children: hasAddAndRemoveChildren
            },
            heading: "Add or remove a child/children",
            names: ["child_action_add_remove_why"],
          },
          {
            conditions: {
              app_types: "RV",
            },
            heading: "Vary the legal conditions on the order",
            names: [
              "coo_con_old_a",
              "coo_con_old_a_detail",
              "coo_con_old_b",
              "coo_con_old_b_detail",
              "coo_con_old_c",
              "coo_con_old_c_detail",
              "coo_con_old_d",
              "coo_con_old_d_detail",
              "coo_con_old_e",
              "coo_con_old_e_detail",
              "coo_con_old_f",
              "coo_con_old_f_detail",
              "coo_con_old_g",
              "coo_con_old_g_detail",
              "coo_con_old_h",
              "coo_con_old_h_detail",
              "coo_exc_old_a",
              "coo_exc_old_a_detail",
              "coo_exc_old_b",
              "coo_exc_old_b_detail",
              "coo_exc_old_c",
              "coo_exc_old_c_detail",
              "coo_exc_old_d",
              "coo_exc_old_d_detail",
              "coo_exc_old_e",
              "coo_exc_old_e_detail",
              {name: "coo_con_old_i", label: "The respondent must arrange to return personal property belonging to the protected person(s) within two days of the intervention order being served."},
              "coo_con_old_i_detail",
              {name: "coo_con_old_j", label: "The respondent must arrange to return jointly owned property within two days of the intervention order being served."},
              "coo_con_old_j_detail",
              "coo_con_old_k",
              "coo_con_old_k_detail",
              "coo_con_old_l",
              "coo_con_old_l_detail",
              "coo_con_old_m",
              "coo_con_old_m_detail",
              "vary_resp_children_arr",
              "vary_resp_other",
              "vary_resp_why",
              "vary_resp_why_2",
            ],
          },
          {
            conditions: {
              app_types: "RC",
            },
            heading: "Remove a child/children from the order",
            names: ["child_action_remove_why"],
          },
          {
            conditions: {
              app_types: "AR",
            },
            heading: "Revoke the order so that it’s no longer in effect",
            names: ["revoke_appl_why"],
          },
          {
            conditions: {
              app_types: "RR",
            },
            heading: "Revoke the order so that it’s no longer in effect",
            names: ["revoke_resp_why", "revoke_resp_why_2"],
          },
        ]}
      />

      {((values.app_types as string[])?.includes('AC') || (values.app_types as string[])?.includes('RC')) &&
        <ConfirmSection
          heading="Children"
          config={
            values.children.map((_, i) => (
              {
                heading: `Child ${i + 1}`,
                names: [
                  `children[${i}].child_action`,
                  `children[${i}].prp_giv_nam`,
                  `children[${i}].prp_fam_nam`,
                  `children[${i}].prp_dob`,
                  `children[${i}].prp_sex1`,
                  `children[${i}].prp_sex_other`
                ],
              }))
          }
        />
      }

      <ConfirmSection
        heading="Other orders"
        goToStepIndex={4}
        config={[
          {
            names: [
              "order_exist_cm",
            ],
          },
          {
            conditions: {
              order_exist_cm: "yes",
            },
            names: ["order_exist_cm_yes"]
          },
          {
            names: [
              "order_exist_changed_other_terr",
              "order_exist_made_other_terr",
              "order_exist_curr_app_chng_other_terr"
            ]
          }
        ]}
      />
    </>
  );
};

export default Confirm;
