import React from "react";

import CheckboxGroup from "../../../components/CheckboxGroup";
import Info from "../../../components/Info";
import PageTitle from "../../../components/PageTitle";
import Question from "../../../components/Question";
import QuestionAddress from "../../../components/QuestionAddress";
import QuestionDatepicker from "../../../components/QuestionDatepicker";
import QuestionFrame from "../../../components/QuestionsFrame";
import RadioGroup from "../../../components/RadioGroup";
import SubHeading from "../../../components/SubHeading";
import ConditionalFieldsWrapper from "../../../components/ConditionalFieldsWrapper";
import UnsafeToContactInfo from "../../../components/UnsafeToContactInfo";

const Applicant = () => (
  <>
    <PageTitle
      heading="Applicant"
      description={
        <>
          The applicant is the person who is or has been affected by{" "}
          <Info
            inherit
            string="family violence|Family violence|Family violence is harmful behaviour that is used to control, threaten, force or dominate a family member through fear. It includes: physically hurting or threatening a person, raping or sexually pressuring a family member, harming a person emotionally or psychologically, intentionally damaging property, controlling what a person can do, controlling how a family member uses money, and harming or threatening to harm a pet."
          />
        </>
      }
    />
    <SubHeading heading="Your details" />

    <QuestionFrame>
      <Question name="appl_det_giv_nam" />
      <Question name="appl_det_giv_nam2" />
      <Question name="appl_det_giv_nam3" />
      <Question name="appl_det_fam_nam" />
      <Question name="appl_other_names" />
      <QuestionDatepicker name="appl_det_dob" />
      <RadioGroup name="appl_det_sex" />

      <ConditionalFieldsWrapper conditions={{ appl_det_sex: "U" }}>
        <Question name="appl_det_sex_other" />
      </ConditionalFieldsWrapper>
    </QuestionFrame>
    <QuestionFrame privateFrame>
      <RadioGroup name="appl_pregnant_baby" />
    </QuestionFrame>
    <QuestionFrame>
      <RadioGroup name="appl_abor_code" />
      <RadioGroup name="appl_lgbt" />
      <RadioGroup name="appl_disabl" />

      <ConditionalFieldsWrapper conditions={{ appl_disabl: "yes" }}>
        <CheckboxGroup name="appl_disabl_req" />

        <ConditionalFieldsWrapper conditions={{ appl_disabl_req: "Other" }}>
          <Question name="appl_disabl_req_other" />
        </ConditionalFieldsWrapper>
      </ConditionalFieldsWrapper>

      <RadioGroup name="appl_det_inter" />

      <ConditionalFieldsWrapper conditions={{ appl_det_inter: "yes" }}>
        <Question type="select" name="appl_det_lang" />
        <ConditionalFieldsWrapper conditions={{ appl_det_lang: "Other" }}>
          <Question name="appl_det_lang_other" />
        </ConditionalFieldsWrapper>
      </ConditionalFieldsWrapper>
    </QuestionFrame>

    <SubHeading heading="Your details" />

    <QuestionFrame
      privateFrame
      privateHeading="Private question - ||respondent|Respondent|The person who used family violence against you and/or your children in this application.|| will not be advised of your address if you choose to keep your current address secret."
    >
      <QuestionAddress
        label="Current address"
        namePrefix="appl_det_addr"
        useToDetermineCourt
      />
      <RadioGroup name="appl_det_addr_secret" />
    </QuestionFrame>
    <QuestionFrame mbless>
      <CheckboxGroup name="appl_safe_cont" disableOthers="unsafe" />
      <ConditionalFieldsWrapper conditions={{ appl_safe_cont: "phone" }}>
        <Question name="appl_cont_phone_3" />
        <Question name="appl_cont_phone" />
        <Question name="appl_cont_phone_2" />
        <RadioGroup name="appl_cont_phone_vm" />
        <CheckboxGroup name="appl_cont_safe_times" />
      </ConditionalFieldsWrapper>
      <ConditionalFieldsWrapper conditions={{ appl_safe_cont: "email" }}>
        <Question name="appl_cont_email" />
        <Question name="appl_cont_email_2" />
      </ConditionalFieldsWrapper>
      <ConditionalFieldsWrapper conditions={{ appl_safe_cont: "trust" }}>
        <Question name="appl_cont_trst_per" />
        <Question type="select" name="appl_cont_trst_per_rel" />
        <ConditionalFieldsWrapper
          conditions={{ appl_cont_trst_per_rel: "other" }}
        >
          <Question name="appl_cont_trst_per_rel_other" />
        </ConditionalFieldsWrapper>
        <Question name="appl_cont_trst_per_phone" />
        <Question name="appl_cont_trst_per_email" />
      </ConditionalFieldsWrapper>
      <ConditionalFieldsWrapper conditions={{ appl_safe_cont: "unsafe" }}>
        <UnsafeToContactInfo />
      </ConditionalFieldsWrapper>
    </QuestionFrame>
  </>
);

export default Applicant;
