import React from "react";
import styled from "styled-components/macro";

import media from "../theme/media";
import colors from "../theme/colors";

type Props = {};

const ProgressBarComplete: React.FC<Props> = () => {
  const steps = [
    {
      name: "Application submitted",
      current: false,
      future: false,
    },
    {
      name: "A registrar will verify your application",
      current: true,
      future: false,
    },
    {
      name: "Magistrate assesses application",
      current: false,
      future: true,
    },
    {
      name: "Application is granted or refused",
      current: false,
      future: true,
    },
  ];
  return (
    <Wrapper>
      <Inner>
        {steps.map((s, i, a) => {
          return (
            <Step key={s.name} current={s.current} future={s.future}>
              <CircleNumber disabled={true}>
                <StepNumber>{i + 1}</StepNumber>
                <StepLabel>{s.name}</StepLabel>
              </CircleNumber>
            </Step>
          );
        })}
      </Inner>
    </Wrapper>
  );
};

export default ProgressBarComplete;

const Wrapper = styled.div`
  width: 100%;
  height: 100px;
  background: ${colors.blue};
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1310px;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CircleNumber = styled.button`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${colors.blue};
  color: ${colors.blue};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  position: relative;
  border: none;
  &:disabled {
    cursor: not-allowed;
  }

  ${media.greaterThan("tablet")`
    width: 26px;
    height: 26px;
    color: white;
  `};
`;
const StepLabel = styled.span`
  display: none;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  color: ${colors.blue};
  /* white-space: nowrap; */
  text-align: center;
  font-size: 12px;
  border: none;
  background: transparent;
  width: 9.5em;
  ${media.greaterThan("tablet")`
    display:block;
    font-size: 12px;
  `}
  ${media.greaterThan("desktop")`
    font-size: 15px;
  `}
`;

const StepNumber = styled.span`
  display: none;
  transform: translateY(0.05em);
  font-weight: 300;
  ${media.greaterThan("tablet")`display:block;`};
`;

const Step = styled.div<{ future?: boolean; current?: boolean }>`
  border: none;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 15px;
  &:before {
    content: "";
    display: block;
    height: 0;
    width: 20px;
    border: 1px ${({ future }) => (future ? `dashed` : `solid`)} #d4e1ed;
    ${media.greaterThan("phone")`width: 60px;`}
    ${media.greaterThan("tablet")`width: 90px;`}
    ${media.greaterThan("desktop")`width: 150px;`}
  }

  &:first-child:before {
    display: none;
  }

  ${({ future }) =>
    future &&
    `
        ${CircleNumber} {
            background: #D4E1ED;
            color: #6D6D6D;
        }

        ${StepLabel} {
          color: #6D6D6D;
        }
  `}

  ${({ current }) =>
    current &&
    `
        ${CircleNumber} {
            background: #3C3C3C;
            color: white;
        }

        ${StepLabel} {
          color: #3C3C3C;
        }
  `}
`;
