import { useFormikContext } from "formik";
import { rgba } from "polished";
import React from "react";
import { useParams, withRouter } from "react-router-dom";
import styled from "styled-components/macro";

import { ReactComponent as OkIcon } from "../../../assets/icons/PostcodeOk.svg";

import media from "../../../theme/media";

import ExitContent from "../../../components/ExitContent";
import LoadingIndicator from "../../../components/LoadingIndicator";
import SupportServices from "../../../components/SupportServices";

import template from "../narrativeTemplate";
import narrativeGenerator from "../../../helpers/narrativeGenerator";
import ApplicationContext from "../../../reducers/applicationFrame";
import { FlatApplciationType } from "../../../types";

const Complete = () => {
  const {
    state: { schema },
  } = React.useContext(ApplicationContext);
  const { values, setFieldValue } = useFormikContext<FlatApplciationType>();
  const { id } = useParams<{ id: string }>();
  const [saving, setSaving] = React.useState(false);

  // on mount
  React.useEffect(() => {
    setFieldValue("Status", "submitted");

    if (schema !== null) {
      let generatedNarative = narrativeGenerator(
        values,
        template,
        schema,
        false
      );
      generatedNarative = generatedNarative.toUpperCase();
      setFieldValue("Narrative", generatedNarative);
    }

    // eslint-disable-next-line
  }, [schema]);

  // turn off loading screen when <AutoSaver is a reported a confirmed submit
  const form_is_submitted = values.form_is_submitted;
  React.useEffect(() => {
    if (form_is_submitted === "yes") {
      setSaving(false);
    }
  }, [form_is_submitted]);

  if (saving) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Inner>
        <Main>
          <SuccessBox>
            <OkIcon />
            <div>
              <h2>Your application {id} has been submitted for processing.</h2>
              <p>
                Please note that getting an order granted is not immediate nor
                ensured. Applications for an intervention can take up to 3-5
                days to reach initial processing. Depending on the nature of
                prohibited behaviours and communication with the respondent the
                application may be delayed or rejected.
              </p>
            </div>
          </SuccessBox>{" "}
          <ExitContent />
          <h2>What happens next?</h2>
          <p>
            A registrar will contact you as soon as possible to finalise your
            application and explain what happens next. This can take up to 3-5
            days. If you have concerns or questions, get in touch.
          </p>
          <h2>What to expect when the registrar calls you</h2>
          <h2>Legal coverage, protection and your safety</h2>
          <h2>Personal safety support services</h2>
          <p>
            For support and advice about family violence, you may wish to
            contact a family violence service. If you wish to seek legal advice,
            please contact the Community Legal Centers.
          </p>
          <SupportServices services={["safeSteps"]} />
        </Main>{" "}
      </Inner>{" "}
    </>
  );
};

export default withRouter(Complete);

const Inner = styled.div`
  width: 100%;
  max-width: 1310px;
  position: relative;
  z-index: 1;
  padding: 0;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${media.greaterThan("tablet")`
    flex-direction: row;
  `}
`;

const Main = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: 646px;
`;

const SuccessBox = styled.div`
  padding: 20px 15px;
  background: ${rgba(`#1ba4b2`, 0.15)};
  width: 100%;
  font-size: 18px;
  line-height: 1.44;
  position: relative;
  margin-bottom: 40px;

  ${media.greaterThan("tablet")`
    padding: 33px 64px;
  `}

  h2 {
    font-size: 22px;
    margin-top: 0;
  }

  > svg {
    height: 25px;
    width: 25px;
    margin-bottom: 5px;
    ${media.greaterThan("tablet")`
        position: absolute;
        top: 33px;
        left: 32px;
        transform: translateX(-50%);

    `};
  }
`;
