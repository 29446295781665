import React from "react";
import CheckboxGroup from "../../../components/CheckboxGroup";
import ConditionalFieldsWrapper from "../../../components/ConditionalFieldsWrapper";
import PageTitle from "../../../components/PageTitle";
import Question from "../../../components/Question";
import QuestionAddress from "../../../components/QuestionAddress";
import QuestionDuration from "../../../components/QuestionDuration";
import QuestionsFrame from "../../../components/QuestionsFrame";
import RadioGroup from "../../../components/RadioGroup";
import SubHeading from "../../../components/SubHeading";

const Respondent = () => {
  return (
    <>
      <PageTitle
        heading="Respondent details"
        description="The respondent is the person who displayed unsafe or violent behavious towards the affected person."
      />

      <QuestionsFrame>
        <Question name="resp_giv_nam" />
        <Question name="resp_giv_nam2" />
        <Question name="resp_giv_nam3" />
        <Question name="resp_fam_nam" />
        <Question name="resp_other_nam" />
        <QuestionAddress
          namePrefix="resp_addr"
          label="What is the respondent’s last known address?"
        />
        <QuestionDuration name="resp_known_lbl" />

        <RadioGroup name="resp_sex" />

        <ConditionalFieldsWrapper conditions={{ resp_sex: "U" }}>
          <Question name="resp_sex_other" />
        </ConditionalFieldsWrapper>
        <RadioGroup name="resp_inter" />
        <ConditionalFieldsWrapper conditions={{ resp_inter: "yes" }}>
          <Question type="select" name="resp_lang" />
          <ConditionalFieldsWrapper conditions={{ resp_lang: "Other" }}>
            <Question name="resp_lang_other" />
          </ConditionalFieldsWrapper>
        </ConditionalFieldsWrapper>
      </QuestionsFrame>
      <SubHeading
        heading="Respondent contact details"
        description="We collect this information from you so that we can serve the intervention order on the respondent and summon them to court if the order is approved."
      />
      <QuestionsFrame>
        <CheckboxGroup
          name="resp_contact_type"
          optionChildren={{
            phone: (
              <>
                <Question name="resp_contact_ph_mobile" />
                <Question name="resp_contact_ph" />
                <Question name="resp_contact_ph_2" />
              </>
            ),
            email: (
              <>
                <Question name="resp_contact_email" />
                <Question name="resp_contact_email_2" />
              </>
            ),
            post: (
              <QuestionAddress
                namePrefix="resp_contact_post"
                label="Postal address"
              />
            ),
            other: <Question name="resp_contact_other" />,
          }}
        />
      </QuestionsFrame>
      <SubHeading heading="Other information" />
      <QuestionsFrame>
        <RadioGroup name="resp_addiction" />
        <RadioGroup name="resp_armed" />
        <ConditionalFieldsWrapper conditions={{ resp_armed: "yes" }}>
          <Question name="resp_armed_detail" />
          <RadioGroup name="resp_armed_loc" />
          <ConditionalFieldsWrapper conditions={{ resp_armed_loc: "other" }}>
            <QuestionAddress
              namePrefix="resp_armed_loc_add"
              label="Please specify"
            />
          </ConditionalFieldsWrapper>
        </ConditionalFieldsWrapper>
        <RadioGroup name="resp_weapon" />
        <ConditionalFieldsWrapper conditions={{ resp_weapon: "yes" }}>
          <Question name="resp_weapon_detail" />
        </ConditionalFieldsWrapper>
      </QuestionsFrame>
    </>
  );
};

export default Respondent;
