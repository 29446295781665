import { generateAddressValidation, Yup } from "csv-package";

const respondent = {
  resp_giv_nam: Yup.string()
    .csvGivenName()
    .required("Please enter a frist name."),
  resp_giv_nam2: Yup.string().csvGivenName(),
  resp_giv_nam3: Yup.string().csvGivenName(),
  resp_fam_nam: Yup.string().trim().required("Please enter a family name"),
  ...generateAddressValidation("resp_addr"),
  resp_known_lbl: Yup.string().required("We require this information"),
  resp_sex: Yup.string().required("We require this information"),
  resp_sex_other: Yup.string().when("resp_sex", {
    is: "U",
    then: Yup.string().trim().required("We require this information"),
  }),
  resp_inter: Yup.string().required("We require this information"),
  resp_lang: Yup.string().when("resp_inter", {
    is: "yes",
    then: Yup.string().required("We require this information"),
  }),
  resp_lang_other: Yup.string().when("resp_lang", {
    is: "Other",
    then: Yup.string().trim().required("We require this information"),
  }),
  resp_contact_type: Yup.array()
    .ensure()
    .min(1, "Please select at least 1 option"),
  resp_contact_ph_mobile: Yup.string().when("resp_contact_type", {
    is: (resp_contact_type: string[]) =>
      resp_contact_type && resp_contact_type.includes("phone"),
    then: Yup.string()
      .ausMobilePhone()
      .required("Please enter a mobile phone number"),
  }),
  resp_contact_ph: Yup.string().ausPhone(),
  resp_contact_ph_2: Yup.string().ausPhone(),
  resp_contact_email: Yup.string().when("resp_contact_type", {
    is: (resp_contact_type: string[]) =>
      resp_contact_type && resp_contact_type.includes("email"),
    then: Yup.string().email().required("Please enter a email address"),
  }),
  resp_contact_email_2: Yup.string().email(),
  ...generateAddressValidation(
    "resp_contact_post",
    "resp_contact_type",
    // @ts-ignore
    (resp_contact_type: string[]): boolean =>
      Boolean(resp_contact_type && resp_contact_type.includes("post"))
  ),
  resp_addiction: Yup.string().required("We require this information"),
  resp_armed: Yup.string().required("We require this information"),
  resp_armed_detail: Yup.string().when("resp_armed", {
    is: "yes",
    then: Yup.string().trim().required("We require this information"),
  }),
  resp_armed_loc: Yup.string().when("resp_armed", {
    is: "yes",
    then: Yup.string().trim().required("We require this information"),
  }),
  ...generateAddressValidation("resp_armed_loc_add", "resp_armed_loc", "other"),
  resp_weapon: Yup.string().required("We require this information"),
  resp_weapon_detail: Yup.string().when("resp_weapon", {
    is: "yes",
    then: Yup.string().trim().required("We require this information"),
  }),
};

export default respondent;
