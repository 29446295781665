import React from 'react';
import styled from 'styled-components/macro';
import { lighten, darken } from 'polished';

import media from '../theme/media';
import { ReactComponent as XIcon } from '../assets/icons/X.svg';

type Props = {
  title: string;
  text: string;
  onXClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const InfoBox: React.FunctionComponent<Props> = ({ title, text, onXClick }) => {
  return (
    <>
      <Overlay onClick={onXClick} />
      <Wrapper>
        <Header>
          <span>{title}</span>
          {onXClick !== undefined && (
            <CloseButton onClick={onXClick}>
              <XIcon />
            </CloseButton>
          )}
        </Header>
        <Body dangerouslySetInnerHTML={{ __html: text }} />
        <Arrow />
      </Wrapper>
    </>
  );
};

export default InfoBox;

export const Overlay = styled.span`
  display: block;
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100vh;
  display: none;
`;

export const Wrapper = styled.span`
  display: block;
  width: 80%;
  padding: 0 0 12px;
  background: white;
  position: relative;
  border: 1px solid #8e8b8e;
  color: #000000;
  font-size: 11px;
  line-height: 1.444;
  position: fixed;
  z-index: 1002;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  :after {
    content: '';
    display: block;
    width: 100%;
    height: 13px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: transparent;
  }
  ${media.greaterThan('tablet')`
    width: 300px;
    font-size: 13px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    margin-top: -0.5em;
    margin-left: -0.3em;
  `};
`;

const Header = styled.span`
  display: block;
  min-height: 32px;
  width: 100%;
  background: #3c3c3c;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 1.2em;
  line-height: 1;
  padding: 0.5em 10px;
`;

const CloseButton = styled.button`
  height: 12px;
  width: 12px;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: #8e8b8e;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: ${darken(0.2, `#8e8b8e`)};
  }

  &:active {
    color: ${lighten(0.2, `#8e8b8e`)};
  }

  > svg {
    height: 100%;
    width: 100%;
  }
`;

const Arrow = styled.span`
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 6px 0 6px;
  border-color: #8e8b8e transparent transparent transparent;
  position: absolute;
  top: 100%;
  left: 50%;
  display: none;
  ${media.greaterThan('tablet')`
    display: block;
  `}

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 6px 0 6px;
    border-color: white transparent transparent transparent;
    position: absolute;
    top: -9px;
    left: -6px;
  }
`;

const Body = styled.span`
  display: block;
  font-size: 1em;
  padding: 10px;
`;
