import { useFormikContext } from "formik";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import IconButton from "../../../components/IconButton";
import ConditionalFieldsWrapper from "../../../components/ConditionalFieldsWrapper";
import Question from "../../../components/Question";
import QuestionDatepicker from "../../../components/QuestionDatepicker";
import RadioGroup from "../../../components/RadioGroup";
import QuestionAddress from "../../../components/QuestionAddress";
import SubHeading from "../../../components/SubHeading";
import { FlatApplciationType } from "../../../types";
import QuestionsSection from "../../../components/QuestionsSection";
import QuestionsFrame from "../../../components/QuestionsFrame";
import CheckboxGroup from "../../../components/CheckboxGroup";
import QuestionSpacer from "../../../components/QuestionSpacer";
import {unProtectedPersonRoles} from "../constants";

type Props = {
  childIndex: number;
  addChild: () => void;
  removeChild: (childIndex: number) => void;
};

const Child: React.FC<Props> = ({ childIndex, addChild, removeChild }) => {
  const { errors, values } = useFormikContext<FlatApplciationType>();
  const numberAsWord = [
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
  ];

  return (
    <Indent>
      <QuestionsSection
        heading={`Child ${numberAsWord[childIndex]}'s personal details`}
        onRemoveClick={() => removeChild(childIndex)}
      >
        <QuestionsFrame>

          <ConditionalFieldsWrapper conditions={{ seeking: "appl" }}>
            <RadioGroup name={`children[${childIndex}].child_action`} displayRows />
          </ConditionalFieldsWrapper>
          <Question name={`children[${childIndex}].prp_giv_nam`} />
          <Question name={`children[${childIndex}].prp_giv_nam2`} />
          <Question name={`children[${childIndex}].prp_giv_nam3`} />
          <Question name={`children[${childIndex}].prp_fam_nam`} />
          <QuestionDatepicker name={`children[${childIndex}].prp_dob`} />
          <RadioGroup name={`children[${childIndex}].prp_sex1`} />
          <ConditionalFieldsWrapper
            conditions={{ [`children[${childIndex}].prp_sex1`]: "U" }}
          >
            <Question name={`children[${childIndex}].prp_sex_other`} />
          </ConditionalFieldsWrapper>
          <RadioGroup name={`children[${childIndex}].prp_abor_code`} />
          <RadioGroup name={`children[${childIndex}].prp_disabl`} />
          <RadioGroup name={`children[${childIndex}].prp_live`} />
          <ConditionalFieldsWrapper
            conditions={{ [`children[${childIndex}].prp_live`]: "no" }}
          >
            <QuestionAddress
              namePrefix={`children[${childIndex}].prp_addr`}
              label="What address is the child currently living at?"
            />
          </ConditionalFieldsWrapper>
          <QuestionsSection heading={unProtectedPersonRoles.some((r: string) =>
              r === values.seeking_appl_role) && values.seeking === "appl" && childIndex === 0
              ? "Child’s relationship to the adult protected person and the respondent"
              : values.seeking === "resp"
                  ? "Child’s relationship to you and the adult protected person"
                  : "Child’s relationship to you and the respondent"}>
            <QuestionsFrame>
              <Question
                type="select"
                label={unProtectedPersonRoles.some((r: string) => r === values.seeking_appl_role)
                && values.seeking === "appl" && childIndex === 0
                    ? "Relationship of child to the adult protected person"
                    : "Who is the child to you?"}
                name={`children[${childIndex}].prp_rel_to_appl`}
              />
              <Question
                type="select"
                name={`children[${childIndex}].prp_rel_to_resp`}
                label={values.seeking === "appl"
                    ? "Relationship of child to respondent?"
                    : "Relationship of child to the adult protected person?"}
              />
            </QuestionsFrame>
            <SubHeading heading="Existing court applications" />
            <QuestionsFrame>
              <RadioGroup name={`children[${childIndex}].prp_ord_exist`} />
              <ConditionalFieldsWrapper
                conditions={{
                  [`children[${childIndex}].prp_ord_exist`]: "yes",
                }}
              >
                <CheckboxGroup
                  name={`children[${childIndex}].prp_ord_exist_det`}
                  optionChildren={{
                    cpo: (
                      <>
                        <QuestionSpacer />
                        <DatesContainer>
                          <QuestionDatepicker
                            label="Date of last order"
                            name={`children[${childIndex}].prp_ord_cpo_dlo`}
                          />
                          <QuestionDatepicker
                            label="Next court date"
                            name={`children[${childIndex}].prp_ord_cpo_ncd`}
                          />
                        </DatesContainer>
                      </>
                    ),
                    io: (
                      <>
                        <QuestionSpacer />
                        <DatesContainer>
                          <QuestionDatepicker
                            label="Date of last order"
                            name={`children[${childIndex}].prp_ord_io_dlo`}
                          />
                          <QuestionDatepicker
                            label="Next court date"
                            name={`children[${childIndex}].prp_ord_io_ncd`}
                          />
                        </DatesContainer>
                      </>
                    ),
                    oo: (
                      <>
                        <QuestionSpacer />
                        <DatesContainer>
                          <QuestionDatepicker
                            label="Date of last order"
                            name={`children[${childIndex}].prp_ord_oo_dlo`}
                          />
                          <QuestionDatepicker
                            label="Next court date"
                            name={`children[${childIndex}].prp_ord_oo_ncd`}
                          />
                        </DatesContainer>
                      </>
                    ),
                  }}
                />
              </ConditionalFieldsWrapper>
              <RadioGroup name={`children[${childIndex}].prp_ord_fc`} />
              <ConditionalFieldsWrapper
                conditions={{ [`children[${childIndex}].prp_ord_fc`]: "yes" }}
              >
                <CheckboxGroup name={`children[${childIndex}].prp_flc_hear`} />
                <ConditionalFieldsWrapper
                  conditions={{
                    [`children[${childIndex}].prp_flc_hear`]: "po",
                  }}
                >
                  <Question name={`children[${childIndex}].prp_flc_other_po`} />
                </ConditionalFieldsWrapper>
                <ConditionalFieldsWrapper
                  conditions={{
                    [`children[${childIndex}].prp_flc_hear`]: "other",
                  }}
                >
                  <Question name={`children[${childIndex}].prp_flc_other`} />
                </ConditionalFieldsWrapper>
              </ConditionalFieldsWrapper>
            </QuestionsFrame>
          </QuestionsSection>
          {childIndex < 7 && values.children.length - 1 === childIndex && (
            <div>
              <IconButton
                disabled={!!errors.children}
                onClick={(e) => {
                  e.preventDefault();
                  addChild();
                }}
                icon="plus"
                label={values.seeking === "appl" ? "Add/remove another child" : "Remove another child"}
              />
            </div>
          )}
        </QuestionsFrame>
      </QuestionsSection>
    </Indent>
  );
};

export default Child;

const DatesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Indent = styled.div`
  padding-left: ${rem(45)};
  margin-bottom: 1rem;
`;
