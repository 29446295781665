import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { rem } from "polished";
import {
  ReDeclareType,
  getQuestionConfig,
  stripToolTip,
  getMappedOptionValue,
} from "csv-package";

import spacing from "../theme/spacing";
import colors from "../theme/colors";
import { ReactComponent as Eye } from "../assets/icons/Eye.svg";

import ApplicationContext from "../reducers/applicationFrame";
import { FlatApplciationType } from "../types";
import isStrings from "../helpers/isStrings";

type Props = {
  names: Array<string | { name: string; label: string; options?: any[] }>;
  reDeclareObject: ReDeclareType;
  values: FlatApplciationType;
  title: string;
};

const ReDeclareSection: React.FC<Props> = ({
  names,
  reDeclareObject,
  values,
  title
}) => {
  const {
    state: { schema },
  } = React.useContext(ApplicationContext);

  const [viewPrevious, setViewPrevious] = React.useState(false);

  const reDeclareData = reDeclareObject.Data;
  const redeclareDataKeys = Object.keys(reDeclareData);
  const relevantNames = names.filter(item => typeof item === 'string' ? redeclareDataKeys.includes(item)
    : redeclareDataKeys.includes(item.name));

  if (relevantNames.length === 0) {
    return null;
  }

  const dataSet = viewPrevious ? values : reDeclareData;
  return (
    <Container>
      <Heading>
        {title}
        <ViewPrevButton
          onClick={(e) => {
            e.preventDefault();
            setViewPrevious((bool) => !bool);
          }}
        >
          <Eye />
          {viewPrevious ? `View admended response` : `View previous response`}
        </ViewPrevButton>
      </Heading>
      {relevantNames.map((item) => {
        const n = typeof item === 'string' ? item : item.name;
        const label = typeof item === 'string' ? "" : item.label;
        const config = getQuestionConfig(schema, n);
        const rawValue = _.get(dataSet, n);
        let val: string | string[] = "";
        if (
          (rawValue && Array.isArray(rawValue) && isStrings(rawValue)) ||
          typeof rawValue === "string"
        ) {
          try {
            val = getMappedOptionValue(schema, n, rawValue, typeof item !== 'string' ? item.options : undefined);
          } catch (e) {
            // do nothing
          }
        }
        if (Array.isArray(val)) {
          const items = val.map((item) =>
            <li>{item}</li>
          );
          return (
            <Field key={n}>
              <Label htmlFor={n}>{stripToolTip(label ? label : config.label)}</Label>
              <Value>
                <ul>
                  {items}
                </ul>
              </Value>
            </Field>
          );

        }
        return (
          <Field key={n}>
            <Label htmlFor={n}>{stripToolTip(label ? label : config.label)}</Label>
            <Value>{val}</Value>
          </Field>
        );
      })}
    </Container>
  );
};

export default ReDeclareSection;

export const ViewPrevButton = styled.button`
  border: none;
  color: ${colors.blue};
  font-size: ${rem(16)};
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
  svg {
    margin-right: 0.3em;
  }
`;

export const Container = styled.div`
  padding: 0 ${spacing.gutters}px;
`;
export const Heading = styled.h2`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Field = styled.div`
  border-bottom: 1px solid #d4e1ed;
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  &:last-child {
    border-bottom: none;
  }
`;

export const Label = styled.label`
  font-size: ${rem(16)};
  color: ${colors.primary};
  width: 100%;
  max-width: 330px;
  padding-right: ${rem(45)};
`;

export const Value = styled.p`
  color: ${colors.blue};
  margin: 0;
`;


