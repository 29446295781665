import React from "react";
import styled from "styled-components/macro";
import { useLocation } from "react-router-dom";

import colors from "../theme/colors";
import media from "../theme/media";
import { ReactComponent as CrisisLineIcon } from "../assets/icons/CrisisLine.svg";
import { ReactComponent as ImmediateDangerIcon } from "../assets/icons/ImmediateDanger.svg";

import QuickExitButton from "./QuickExitButton";
import YourSafetyPopup from "./YourSafetyPopup";

const TopBar: React.FunctionComponent<{}> = () => {
  const [yourSafetyPopupOpen, setYourSafetyPopupOpen] = React.useState<boolean>(
    false
  );
  const { pathname } = useLocation();

  if (pathname === "/quick") {
    return null;
  }

  return (
    <>
      {yourSafetyPopupOpen && (
        <YourSafetyPopup
          onCloseClick={(e) => {
            e.preventDefault();
            setYourSafetyPopupOpen(false);
          }}
        />
      )}
      <Wrapper>
        <Inner>
          <Widget mobile>
            <ImmediateDangerIcon />
            <TextButton
              onClick={(e) => {
                e.preventDefault();
                setYourSafetyPopupOpen(true);
              }}
            >
              Your safety
            </TextButton>
          </Widget>
          <Widget desktop>
            <ImmediateDangerIcon />
            <span>
              Immediate danger <strong>Call&nbsp;000</strong>
            </span>
          </Widget>
          <Widget desktop>
            <CrisisLineIcon />
            <span>
              24/7 crisis line: <strong>1800&nbsp;015&nbsp;188</strong>
            </span>
          </Widget>
          <Right>
            <BrowserHistory>
              Protect yourself online.{" "}
              <a
                href="https://www.mcv.vic.gov.au/online-safety"
                target="__blank"
                title="Learn how to hide your browsing history."
              >
                Learn how to hide your browsing history.
              </a>
            </BrowserHistory>
            <QuickExitButton />
          </Right>
        </Inner>
      </Wrapper>
    </>
  );
};

export default TopBar;

const Wrapper = styled.header`
  width: 100%;
  height: 58px;
  background: #f5f5f5;
  color: ${colors.primary};
  padding: 0 0 0 15px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
`;

const Inner = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media screen and (min-width: 1310px) {
    /* Sorry - weird alignment needed for this.  */
    width: calc(((100% - 1310px + 15px) / 2) + 1310px);
  }
`;

const TextButton = styled.button`
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  display: inline;
  font-weight: bold;
`;

export const Widget = styled.p<{ mobile?: boolean; desktop?: boolean }>`
  font-size: 16px;
  color: ${colors.primary};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > svg {
    height: 1em;
    width: 1em;
    margin-right: 0.75em;
    transform: translateY(-2px);
  }

  > a {
    color: inherit;
    font-weight: bold;
  }

  & + & + & {
    margin-left: 36px;
  }

  ${({ mobile }) => mobile && media.greaterThan("tablet")`display: none`}
  ${({ desktop }) => desktop && media.greaterThan("tablet")`display: flex`}
  ${({ desktop }) => desktop && `display: none`}
`;

const BrowserHistory = styled.p`
  display: none;
  margin-right: 38px;
  ${media.greaterThan("desktop")`
    display: flex;
    align-items: center;
  `}
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: center;
  margin-left: auto;
  a {
    color: inherit;
    font-weight: bold;
  }
`;
