import ReactGA from "react-ga";
import quickExit from "quick-exit";

const QuickExit = () => {
  ReactGA.pageview("/quick-exit");
  const safeUrl = process.env.REACT_APP_QUICK_EXIT_URL || "https://google.com";

  const newTab = window.open("", "_blank");
  if (newTab) {
    newTab.focus();
  }

  quickExit({
    safeUrl,
  });
  return null;
};

export default QuickExit;
