import { useFormikContext } from "formik";
import React from "react";
import styled from "styled-components";
import { DeclarationOfTruth } from "csv-package";

import ExtendOrderReminder from "../../../components/ExtendOrderReminder";
import LongFootnote from "../../../components/LongFootnote";
import PageTitle from "../../../components/PageTitle";
import QuestionsFrame from "../../../components/QuestionsFrame";
import narrativeGenerator from "../../../helpers/narrativeGenerator";
import ApplicationContext from "../../../reducers/applicationFrame";
import spacing from "../../../theme/spacing";
import { FlatApplciationType } from "../../../types";
import template from "../narrativeTemplate";

const Review = () => {
  const { values, setFieldValue } = useFormikContext<FlatApplciationType>();
  const {
    state: { schema },
  } = React.useContext(ApplicationContext);

  return (
    <>
      <PageTitle
        heading="Review your final statement"
        description="Your legal statement below has been automatically created for you based on the information provided throughout this application process. Please review and add any final information you believe is important for your case."
      />
      <Narrative
        dangerouslySetInnerHTML={{
          __html: narrativeGenerator(values, template, schema, true),
        }}
      />
      <QuestionsFrame>
        <LongFootnote name="narrative_notes" charLimit={150} short />

        <DeclarationOfTruth
          applicantName={`${values.appl_det_giv_nam || values.ap_giv_nam} ${
            values.appl_det_fam_nam || values.ap_fam_nam
          }`}
          applicantDOB={(values.appl_det_dob || values.ap_dob) + ""}
          onCheckboxChange={() =>
            setFieldValue(
              "declaration_of_truth",
              !Boolean(values.declaration_of_truth)
            )
          }
          checked={Boolean(values.declaration_of_truth)}
        />
        <ExtendOrderReminder jurisdiction="PSIO" />
      </QuestionsFrame>
    </>
  );
};

export default Review;

const Narrative = styled.div`
  padding: 0 ${spacing.gutters}px 0;
  margin-bottom: 2rem;

  h3: {
    font-size: 1.375rem;
    line-height: 1.36;
    margin: 2em 0 1em;
  }

  h3:first-child {
    margin-top: 0;
  }
`;
