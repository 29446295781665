import React from "react";
import { ApplicationFrameStepType } from "../../types";
import { fvioEvrValidation } from "csv-package";

import ExistingOrder from "./components/ExistingOrder";
import YourRole from "./components/YourRole";
import ContactDetails from "./components/ContactDetails";
import ApplicationType from "./components/ApplicationType";
import RelatedOrders from "./components/RelatedOrders";
import Review from "./components/Review";
import Confirm from "./components/Confirm";

const steps: ApplicationFrameStepType[] = [
  {
    name: "Existing order",
    validation: fvioEvrValidation.exisitingOrder,
    component: <ExistingOrder />,
  },
  {
    name: "Your role",
    validation: fvioEvrValidation.yourRole,
    component: <YourRole />,
  },
  {
    name: "Contact details",
    validation: fvioEvrValidation.contactDetails,
    component: <ContactDetails />,
  },
  {
    name: "Application type",
    validation: fvioEvrValidation.applicationType,
    component: <ApplicationType />,
  },
  {
    name: "Other orders",
    validation: fvioEvrValidation.relatedOrders,
    component: <RelatedOrders />,
  },
  {
    name: "Confirm your details",
    validation: {},
    component: <Confirm />,
  },
  {
    name: "Review and submit",
    validation: fvioEvrValidation.review,
    component: <Review />,
  },
];

export default steps;
