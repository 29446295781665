import React from "react";

import PageTitle from "../../../components/PageTitle";
import Question from "../../../components/Question";
import QuestionsFrame from "../../../components/QuestionsFrame";
import QuestionSpacer from "../../../components/QuestionSpacer";
import RadioGroup from "../../../components/RadioGroup";
import ConditionalFieldsWrapper from "../../../components/ConditionalFieldsWrapper";
import LongFootnote from "../../../components/LongFootnote";


const PreviousOrders: React.FC = () => (
  <>
    <PageTitle heading="Other intervention order applications" />

    <QuestionsFrame privateFrame mbless>
      <RadioGroup name="pio_fio" />
      <ConditionalFieldsWrapper conditions={{ pio_fio: "no" }}>
        <RadioGroup name="pio_gio" />

        <ConditionalFieldsWrapper conditions={{ pio_gio: "yes" }}>
          <RadioGroup name="pio_bio" />
        </ConditionalFieldsWrapper>

        <Question type="select" name="pio_crt" />

        <ConditionalFieldsWrapper conditions={{ pio_crt: "other" }}>
          <Question name="pio_crt_oth" />
        </ConditionalFieldsWrapper>

        <Question name="pio_rec_time" />

        <Question name="pio_ref_nr" />
      </ConditionalFieldsWrapper>

      <RadioGroup name="pio_sub_io" />

      <ConditionalFieldsWrapper conditions={{ pio_sub_io: "yes" }}>
        <RadioGroup name="pio_sub_io_given" />
      </ConditionalFieldsWrapper>
    </QuestionsFrame>

    <QuestionSpacer size={40} />

    <PageTitle heading="Other court matters" />
    <QuestionsFrame mbless>
      <RadioGroup name="pio_crt_matters" />
      <ConditionalFieldsWrapper conditions={{ pio_crt_matters: "yes" }}>
        <LongFootnote name="pio_crt_matters_given" charLimit={150} short />
      </ConditionalFieldsWrapper>
    </QuestionsFrame>

    <QuestionSpacer size={40} />
  </>
);

export default PreviousOrders;
