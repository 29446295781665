import Yup from '../yup';

const incidentDetails = {
  incident_rec_date: Yup.string()
    .trim()
    .required('We require this information'),
  incident_rec_desc: Yup.string()
    .trim()
    .required('Please enter any information you can here'),
  incident_prev_fv: Yup.string()
    .trim()
    .required('We require this information'),
  incident_prev_fv_time: Yup.string().when('incident_prev_fv', {
    is: 'yes',
    then: Yup.string().required('We require this information'),
  }),
  incident_prev_fv_det: Yup.string().when('incident_prev_fv', {
    is: 'yes',
    then: Yup.string().required('We require this information'),
  }),
  incident_prev_pc: Yup.string().when('incident_prev_fv', {
    is: 'yes',
    then: Yup.string().required('We require this information'),
  }),
  incident_prev_freq: Yup.string().when('incident_prev_fv', {
    is: 'yes',
    then: Yup.string().required('We require this information'),
  }),
  incident_phy_detail: Yup.string().when(
    [`incident_phy_recent`, `incident_phy_prev`],
    {
      is: (one: string, two: string) => one === 'yes' || two === 'yes',
      then: Yup.string()
        .trim()
        .csvIncidentDetails()
        .required('Please supply details'),
    }
  ),
  incident_pys_detail: Yup.string().when(
    [`incident_pys_recent`, `incident_pys_prev`],
    {
      is: (one: string, two: string) => one === 'yes' || two === 'yes',
      then: Yup.string()
        .trim()
        .csvIncidentDetails()
        .required('Please supply details'),
    }
  ),
  incident_aep_detail: Yup.string().when(
    [`incident_aep_recent`, `incident_aep_prev`],
    {
      is: (one: string, two: string) => one === 'yes' || two === 'yes',
      then: Yup.string()
        .trim()
        .csvIncidentDetails()
        .required('Please supply details'),
    }
  ),
  incident_cym_detail: Yup.string().when(
    [`incident_cym_recent`, `incident_cym_prev`],
    {
      is: (one: string, two: string) => one === 'yes' || two === 'yes',
      then: Yup.string()
        .trim()
        .csvIncidentDetails()
        .required('Please supply details'),
    }
  ),
  incident_thy_detail: Yup.string().when(
    [`incident_thy_recent`, `incident_thy_prev`],
    {
      is: (one: string, two: string) => one === 'yes' || two === 'yes',
      then: Yup.string()
        .trim()
        .csvIncidentDetails()
        .required('Please supply details'),
    }
  ),
  incident_cbp_detail: Yup.string().when(
    [`incident_cbp_recent`, `incident_cbp_prev`],
    {
      is: (one: string, two: string) => one === 'yes' || two === 'yes',
      then: Yup.string()
        .trim()
        .csvIncidentDetails()
        .required('Please supply details'),
    }
  ),
  incident_fsw_detail: Yup.string().when(
    [`incident_fsw_recent`, `incident_fsw_prev`],
    {
      is: (one: string, two: string) => one === 'yes' || two === 'yes',
      then: Yup.string()
        .trim()
        .csvIncidentDetails()
        .required('Please supply details'),
    }
  ),
  incident_dyp_detail: Yup.string().when(
    [`incident_dyp_recent`, `incident_dyp_prev`],
    {
      is: (one: string, two: string) => one === 'yes' || two === 'yes',
      then: Yup.string()
        .trim()
        .csvIncidentDetails()
        .required('Please supply details'),
    }
  ),
  incident_ryf_detail: Yup.string().when(
    [`incident_ryf_recent`, `incident_ryf_prev`],
    {
      is: (one: string, two: string) => one === 'yes' || two === 'yes',
      then: Yup.string()
        .trim()
        .csvIncidentDetails()
        .required('Please supply details'),
    }
  ),
  incident_htc_detail: Yup.string().when(
    [`incident_htc_recent`, `incident_htc_prev`],
    {
      is: (one: string, two: string) => one === 'yes' || two === 'yes',
      then: Yup.string()
        .trim()
        .csvIncidentDetails()
        .required('Please supply details'),
    }
  ),
  incident_kha_detail: Yup.string().when(
    [`incident_kha_recent`, `incident_kha_prev`],
    {
      is: (one: string, two: string) => one === 'yes' || two === 'yes',
      then: Yup.string()
        .trim()
        .csvIncidentDetails()
        .required('Please supply details'),
    }
  ),
  incident_sty_detail: Yup.string().when(
    [`incident_sty_recent`, `incident_sty_prev`],
    {
      is: (one: string, two: string) => one === 'yes' || two === 'yes',
      then: Yup.string()
        .trim()
        .csvIncidentDetails()
        .required('Please supply details'),
    }
  ),
  incident_fmc_detail: Yup.string().when(
    [`incident_fmc_recent`, `incident_fmc_prev`],
    {
      is: (one: string, two: string) => one === 'yes' || two === 'yes',
      then: Yup.string()
        .trim()
        .csvIncidentDetails()
        .required('Please supply details'),
    }
  ),
  incident_cdw_detail: Yup.string().when(
    [`incident_cdw_recent`, `incident_cdw_prev`],
    {
      is: (one: string, two: string) => one === 'yes' || two === 'yes',
      then: Yup.string()
        .trim()
        .csvIncidentDetails()
        .required('Please supply details'),
    }
  ),
};

export default incidentDetails;
