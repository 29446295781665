import React from "react";
import _ from "lodash";
import {
  ChildDataType,
  getQuestionConfig,
  stripToolTip,
  getMappedOptionValue,
} from "csv-package";

import { ReactComponent as Eye } from "../assets/icons/Eye.svg";
import {
  ViewPrevButton,
  Value,
  Container,
  Heading,
  Field,
  Label,
} from "./ReDeclareSection";

import ApplicationContext from "../reducers/applicationFrame";
import { FlatApplciationType } from "../types";
import isStrings from "../helpers/isStrings";
import QuestionSpacer from "./QuestionSpacer";

type Props = {
  names: string[];
  data: ChildDataType;
  values: FlatApplciationType;
  titlePrefix: string;
  index: number;
};

const ReDeclareSectionChild: React.FC<Props> = ({
  names,
  data,
  values,
  titlePrefix,
  index,
}) => {
  const {
    state: { schema },
  } = React.useContext(ApplicationContext);

  const [viewPrevious, setViewPrevious] = React.useState(false);

  const childValues = _.get(values, `children[${index}]`, {}) as ChildDataType;

  // compare re-declare child data with
  const reDeclareData = data;
  const relevantNames: string[] = names.reduce(
    (accumulator: string[], key: string) => {
      if (_.isEqual(childValues[key], data[key])) {
        return accumulator;
      }

      return [...accumulator, key];
    },
    []
  );

  if (relevantNames.length === 0) {
    return null;
  }

  const dataSet = viewPrevious ? childValues : reDeclareData;
  return (
    <Container>
      <Heading>
        {titlePrefix} {index + 1}
        <ViewPrevButton
          onClick={(e) => {
            e.preventDefault();
            setViewPrevious((bool) => !bool);
          }}
        >
          <Eye />
          {viewPrevious ? `View admended response` : `View previous response`}
        </ViewPrevButton>
      </Heading>
      {relevantNames.map((n) => {
        const config = getQuestionConfig(schema, n);
        const rawValue = _.get(dataSet, n);

        let val: string | string[] = "";
        if (
          (rawValue && Array.isArray(rawValue) && isStrings(rawValue)) ||
          typeof rawValue === "string"
        ) {
          try {
            val = getMappedOptionValue(schema, n, rawValue);
          } catch (e) {
            // do nothing
          }
        }
        if (Array.isArray(val)) {
          val = val.join(", ");
        }
        return (
          <Field key={n}>
            <Label htmlFor={n}>{stripToolTip(config.label)}</Label>
            <Value>{val}</Value>
          </Field>
        );
      })}
      <QuestionSpacer />
    </Container>
  );
};

export default ReDeclareSectionChild;
