import React from "react";
import styled, { css } from "styled-components";
import _ from "lodash";

import { ReactComponent as TickIcon } from "../assets/icons/Tick.svg";
import { ReactComponent as DownArrowOutlined } from "../assets/icons/DownArrowOutlined.svg";
import { Check, Label } from "./Checkbox";
import Question from "./Question";
import Info from "./Info";
import { useFormikContext } from "formik";
import useQuestionConfig from "../hooks/useQuestionConfig";
import media from "../theme/media";

type Props = {
  namePrefix: string;
  label?: string;
  note?: string;
  startOpen?: boolean;
};

const QuestionAccordionHooks: React.FC<Props> = React.memo(
  ({ namePrefix, startOpen, ...rest }) => {
    const { values, setFieldValue } = useFormikContext();
    const [isOpen, setIsOpen] = React.useState<boolean>(startOpen || false);

    const prevName = `${namePrefix}_prev`;
    const recentName = `${namePrefix}_recent`;
    const detailName = `${namePrefix}_detail`;
    const prevValue = _.get(values, prevName);
    const recentValue = _.get(values, recentName);
    const detailValue = _.get(values, detailName);

    const { label, note } = useQuestionConfig(recentName, rest);

    // on mount check if anything is populated
    // if yes start open
    React.useEffect(() => {
      const results = ["recent", "prev"].map((suffix) =>
        _.get(values, `${namePrefix}_${suffix}`)
      );
      if (results.every((v) => v === "yes")) {
        setIsOpen(true);
      }
      // eslint-disable-next-line
    }, []);

    // Clear detail field if recent and prev are unchecked
    React.useEffect(() => {
      if (prevValue !== "yes" && recentValue !== "yes") {
        setFieldValue(detailName, "");
      }
      // eslint-disable-next-line
    }, [prevValue, recentValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = e.target;
      setFieldValue(name, checked ? "yes" : "no");
    };

    return React.useMemo(() => {
      return (
        <Wrapper>
          <QuestionWrapper
            onClick={(e) => {
              e.preventDefault();
              setIsOpen((o) => !o);
            }}
          >
            <span>{label}</span>
            <DownIcon isopen={isOpen ? "yes" : undefined} />
          </QuestionWrapper>
          <Options
            isOpen={isOpen ? "yes" : undefined}
            isPopulated={recentValue === "yes" || prevValue === "yes"}
          >
            <Option name={recentName}>
              <input
                checked={recentValue === "yes"}
                disabled={!isOpen}
                id={recentName}
                name={recentName}
                onChange={handleChange}
                type="checkbox"
              />
              <Check alternative htmlFor={recentName}>
                <TickIcon />
              </Check>
              <Label htmlFor={recentName}>In the most recent incident</Label>
            </Option>
            <Option>
              <input
                checked={prevValue === "yes"}
                disabled={!isOpen}
                id={prevName}
                name={prevName}
                onChange={handleChange}
                type="checkbox"
              />
              <Check alternative htmlFor={prevName}>
                <TickIcon />
              </Check>
              <Label htmlFor={prevName}>In previous incidents</Label>
            </Option>
            <QuestionCont>
              <Question
                name={detailName}
                note={`Please provide the details of how the respondent has ${label}`}
                label=" "
                prefix="The respondent has "
              />
            </QuestionCont>
          </Options>
          <InfoWrapper>
            <Info string={`?|${note}`} />
          </InfoWrapper>
        </Wrapper>
      );
      // eslint-disable-next-line
    }, [isOpen, detailValue, recentValue, prevValue]);
  }
);

export default QuestionAccordionHooks;

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 7px;
  position: relative;
  padding-right: 22px;
`;

const QuestionWrapper = styled.button`
  width: 100%;
  min-height: 48px;
  padding: 5px 15px 5px;
  height: 100%;
  background: rgba(195, 214, 234, 0.2);
  margin: 0;
  font-size: 18px;
  color: rgba(14, 93, 171, 0.9701);
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  border-radius: 4px;
  text-align: left;
  cursor: pointer;
  &:after {
    content: "";
    min-height: inherit;
    font-size: 0;
  }

  span {
    margin-right: auto;
    max-width: calc(100% - 20px);
  }
`;

const Options = styled.div<{ isOpen?: string; isPopulated?: boolean }>`
  border-left: 47px solid transparent;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s linear;

  &:before {
    content: "";
    display: block;
    height: 7px;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      max-height: calc((48px * 2) + 14px);
    `}

  ${({ isOpen, isPopulated }) =>
    isOpen &&
    isPopulated &&
    css`
      max-height: calc((48px * 2) + 170px);
    `}

  label {
    align-self: center;
  }
  ${media.lessThan("tablet")`
    border-left: 25px solid transparent;
  `}
`;

const Option = styled.div<{ name?: string }>`
  padding: 0 0 0 15px;
  border: none;
  height: 48px;
  width: 100%;
  color: #3c3c3c;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: rgba(195, 214, 234, 0.2);
  border-radius: 4px;
  outline: none;
  & + & {
    margin-top: 7px;
  }

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
`;

const InfoWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 0;
  transform: translateY(-50%);
`;

const DownIcon = styled(DownArrowOutlined)<{ isopen?: string }>`
  width: 11px;
  height: 8px;
  transition: all 0.2s linear;
  ${({ isopen }) =>
    isopen === "yes" &&
    css`
      transform: rotate(180deg);
    `}
`;

const QuestionCont = styled.div`
  margin-top: 12px;
  > div {
    margin-bottom: 7px;

    input {
      margin-top: -10px;
    }
  }
`;
