import React from "react";
import styled from "styled-components/macro";

import { ReactComponent as InfoIcon } from "../assets/icons/Info.svg";
import media from "../theme/media";

const InfoTipCTA: React.FunctionComponent = ({ children }) => {
  return (
    <Outer>
      <InfoIcon />
      {children}
    </Outer>
  );
};

export default InfoTipCTA;

const Outer = styled.div`
  background: rgba(199, 239, 243, 0.35);
  display: flex;
  align-items: center;
  padding: 18px 14px 16px 14px;
  min-height: 53px;
  border-radius: 2px;
  font-size: 18px;
  margin-bottom: 0.8em;

  svg {
    margin-right: 12px;
  }

  ${media.lessThan("tablet")`
    flex-wrap: wrap;
    svg {
      margin-bottom: 0.75em;
    }
    div {
      margin-top: 0.75em;
    }
  `}
`;
