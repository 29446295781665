import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserAttribute,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import Cookies from "js-cookie";
import * as AWS from "aws-sdk/global";
import { Auth } from "aws-amplify";

AWS.config.region = "ap-southeast-2";

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_APPLICANT_USER_POOL_ID || "",
  ClientId: process.env.REACT_APP_APPLICANT_USER_POOL_CLIENT_ID || "",
});

export var cognitoUser = userPool.getCurrentUser();

export const isAuthenticated = async (): Promise<boolean> => {
  try {
    await Auth.currentSession();
    return true;
  } catch (error) {
    return false;
  }
};

export const hasAnonymousIdentity = (): boolean => {
  return Boolean(Cookies.get("anonymousIdenttiyId"));
};

export const createAndStoreAnonymousIdentity = async (): Promise<object> => {
  const anonymousUser = await Auth.currentCredentials();
  Cookies.set("anonymousIdenttiyId", anonymousUser.identityId, {
    expires: 0.08,
  });

  return anonymousUser;
};

type CreateUserPayloadType = {
  email: string;
  password: string;
  given_name: string;
};
export const createAndStoreUser = async (
  values: CreateUserPayloadType
): Promise<boolean> => {
  await Auth.signUp({
    username: values.email,
    password: values.password,
    attributes: {
      email: values.email,
      given_name: values.given_name,
    },
  });
  return true;
};

export const signOut = async (): Promise<void> => {
  try {
    await Auth.signOut({ global: true });
    window.location.reload();
  } catch (error) {
    console.log("error signing out: ", error);
  }
};

export const getUsername = async (): Promise<string> => {
  try {
    const user = await Auth.currentAuthenticatedUser();

    return user.attributes.email;
  } catch (error) {
    console.log({ error });
    return "";
  }
};

export const login = async (
  email: string,
  password: string
): Promise<boolean> => {
  try {
    await Auth.signIn(email, password);
    return true;
  } catch (error) {
    console.log("error signing in", { error });
    return false;
  }
};

export const getUserDetails = async (): Promise<object | null> => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const result = await Auth.userAttributes(user);
    return result;
  } catch (error) {
    console.log("Failed to update password ", error);
    return null;
  }
};

export const updatePassword = async (
  oldPassword: string,
  newPassword: string
): Promise<boolean> => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
    return true;
  } catch (error) {
    alert("Failed to update password " + error);
    return false;
  }
};

export const updateAttributes = async (
  email: string,
  given_name: string
): Promise<boolean> => {
  try {
    let user = await Auth.currentAuthenticatedUser();

    let result = await Auth.updateUserAttributes(user, {
      email,
      given_name,
    });
    return Boolean(result);
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const switchToAuthenticated = async (
  email: string,
  password: string,
  given_name: string
): Promise<string> => {
  await new Promise((resolve, reject) => {
    const attributeList = [];

    const attributeEmail = new CognitoUserAttribute({
      Name: "email",
      Value: email,
    });

    const attributeGivenName = new CognitoUserAttribute({
      Name: "given_name",
      Value: given_name,
    });

    attributeList.push(attributeEmail);
    attributeList.push(attributeGivenName);

    userPool.signUp(email, password, attributeList, [], function (err, result) {
      if (err) {
        reject(err.message || JSON.stringify(err));
      } else {
        const cognitoUser = result && result.user;
        resolve(cognitoUser);
      }
    });
  });

  await new Promise((resolve) => setTimeout(() => resolve("done"), 500));

  const identityId = await new Promise((resolve, reject) => {
    var authenticationData = {
      Username: email,
      Password: password,
    };
    var authenticationDetails = new AuthenticationDetails(authenticationData);

    var userData = {
      Username: email,
      Pool: userPool,
    };
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          // @ts-ignore
          IdentityId: Cookies.get("anonymousIdenttiyId"),
          Logins: {
            // Change the key below according to the specific region your user pool is in.
            [`cognito-idp.ap-southeast-2.amazonaws.com/${process.env.REACT_APP_APPLICANT_USER_POOL_ID}`]:
              result.getIdToken().getJwtToken(),
          },
        });

        // @ts-ignore
        AWS.config.credentials.refresh((error) => {
          if (error) {
            reject(error);
          } else {
            Cookies.remove("anonymousIdenttiyId");
            // @ts-ignore
            resolve(AWS.config.credentials.identityId);
          }
        });
      },

      onFailure: function (err) {
        reject(err.message || JSON.stringify(err));
      },
    });
  });
  // @ts-ignore
  return identityId;
};
