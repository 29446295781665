import Yup, { generateAddressValidation } from '../yup';

const applicant = {
  appl_det_giv_nam: Yup.string()
    .csvGivenName()
    .required('Given name is required'),
  appl_det_giv_nam2: Yup.string().csvGivenName(),
  appl_det_giv_nam3: Yup.string().csvGivenName(),
  appl_det_fam_nam: Yup.string()
    .trim()
    .required('Family name is required'),
  appl_det_dob: Yup.string()
    .csvDate()
    .csvDobOver18()
    .required('Your date of birth is required'),

  appl_det_sex: Yup.string().required('We require your gender'),
  appl_det_sex_other: Yup.string().when('appl_det_sex', {
    is: 'U',
    then: Yup.string()
      .trim()
      .required('We require your gender'),
  }),
  appl_lgbt: Yup.string()
    .trim()
    .required('Sharing this will help us with your application'),
  appl_pregnant_baby: Yup.string()
    .trim()
    .required('Sharing this will help us with your application'),
  appl_abor_code: Yup.string()
    .trim()
    .required('We require this information'),
  appl_disabl: Yup.string()
    .trim()
    .required('Sharing this will help us with your application'),
  appl_disabl_req: Yup.array()
    .ensure()
    .when('appl_disabl', {
      is: 'yes',
      then: Yup.array()
        .ensure()
        .min(1, 'Please select your requirements'),
    }),
  appl_det_inter: Yup.string()
    .trim()
    .required('Sharing this will help us with your application'),
  appl_det_lang: Yup.string().when('appl_det_inter', {
    is: 'yes',
    then: Yup.string().required('Please select a language'),
  }),
  appl_det_lang_other: Yup.string().when(['appl_det_inter', 'appl_det_lang'], {
    is: (appl_det_inter: string, appl_det_lang: string) =>
      appl_det_inter === 'yes' && appl_det_lang === 'Other',
    then: Yup.string()
      .trim()
      .required('Please enter a language'),
  }),
  appl_det_addr_secret: Yup.string()
    .trim()
    .required('We require this information'),
  ...generateAddressValidation('appl_det_addr'),
  appl_safe_cont: Yup.array()
    .ensure()
    .min(1, 'Please selection at least 1 contact method'),

  court_address_is_valid: Yup.mixed().oneOf(['yes'], 'Invalid Address'),

  appl_cont_phone_3: Yup.string().when('appl_safe_cont', {
    is: (appl_safe_cont: string[]) =>
      appl_safe_cont && appl_safe_cont.includes('phone'),
    then: Yup.string()
      .ausMobilePhone()
      .required('Mobile phone number required'),
  }),

  appl_cont_phone: Yup.string().ausPhone(),

  appl_cont_phone_2: Yup.string().ausPhone(),

  appl_cont_phone_vm: Yup.string().when('appl_safe_cont', {
    is: (appl_safe_cont: string[]) =>
      appl_safe_cont && appl_safe_cont.includes('phone'),
    then: Yup.string().required('We require this information'),
  }),

  appl_cont_safe_times: Yup.array()
    .ensure()
    .when('appl_safe_cont', {
      is: (appl_safe_cont: string[]) =>
        appl_safe_cont && appl_safe_cont.includes('phone'),
      then: Yup.array()
        .ensure()
        .min(1, 'We require this information'),
    }),

  appl_cont_email: Yup.string().when('appl_safe_cont', {
    is: (appl_safe_cont: string[]) =>
      appl_safe_cont && appl_safe_cont.includes('email'),
    then: Yup.string()
      .required('We require a valid email')
      .email(),
  }),

  appl_cont_email_2: Yup.string().email('Please enter a valid email address'),

  appl_cont_trst_per: Yup.string().when('appl_safe_cont', {
    is: (appl_safe_cont: string[]) =>
      appl_safe_cont && appl_safe_cont.includes('trust'),
    then: Yup.string().required('We require this information'),
  }),

  appl_cont_trst_per_rel: Yup.string().when('appl_safe_cont', {
    is: (appl_safe_cont: string[]) =>
      appl_safe_cont && appl_safe_cont.includes('trust'),
    then: Yup.string().required('We require this information'),
  }),

  appl_cont_trst_per_rel_other: Yup.string().when(
    ['appl_safe_cont,appl_cont_trst_per_rel'],
    {
      is: (appl_safe_cont: string[], appl_cont_trst_per_rel: string) =>
        appl_safe_cont &&
        appl_safe_cont.includes('trust') &&
        appl_cont_trst_per_rel === 'other',
      then: Yup.string()
        .trim()
        .required('We require this information'),
    }
  ),
  appl_cont_trst_per_phone: Yup.string().when(
    [`appl_safe_cont`, `appl_cont_trst_per_email`],
    {
      is: (appl_safe_cont: string[], appl_cont_trst_per_email: string) =>
        appl_safe_cont &&
        appl_safe_cont.includes('trust') &&
        !appl_cont_trst_per_email,
      then: Yup.string()
        .required('You must enter a trusted person email or phone number')
        .ausPhone(),
    }
  ),
  appl_cont_trst_per_email: Yup.string().email(
    "Trusted person's email must be a valid email address."
  ),
};

export default applicant;
