import React from "react";
import { SchemaType } from "csv-package";

export type ReducerStateType = {
  schema: SchemaType;
  currentStep: number;
  showErrors: boolean;
  savingInProgress: boolean;
  narrativeCharactersLeft: number;
  narrativeKeys: string[];
  narrativeLimit: number;
};

export type ReducerActionType =
  | {
      type: "setSchema";
      schema: SchemaType;
    }
  | {
      type: "setStep";
      step: number;
    }
  | {
      type: "showErrors";
    }
  | {
      type: "hideErrors";
    }
  | {
      type: "startSaving";
    }
  | { type: "endSaving" }
  | {
      type: "setNarrativeCount";
      number: number;
    };

export const reducer = (
  state: ReducerStateType,
  action: ReducerActionType
): ReducerStateType => {
  switch (action.type) {
    case "setSchema":
      return {
        ...state,
        schema: action.schema,
      };
    case "setStep":
      return {
        ...state,
        currentStep: action.step,
      };
    case "showErrors":
      return {
        ...state,
        showErrors: true,
      };
    case "hideErrors":
      return {
        ...state,
        showErrors: false,
      };
    case "startSaving":
      return {
        ...state,
        savingInProgress: true,
      };
    case "endSaving":
      return {
        ...state,
        savingInProgress: false,
      };
    case "setNarrativeCount":
      return {
        ...state,
        narrativeCharactersLeft: action.number,
      };
    default:
      return state;
  }
};

type ApplicationContextState = {
  state: ReducerStateType;
  goToStep: (step: number) => void;
};

export const ApplicationContext =
  // @ts-ignore
  React.createContext<ApplicationContextState>();

export default ApplicationContext;
