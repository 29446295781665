import React from "react";
import styled from "styled-components";
import { Yup, ApplicationJurisdictionType } from "csv-package";

import LockedQuestion from "./LockedQuestion";
import QuestionSpacer from "./QuestionSpacer";
import RadioGroup from "./RadioGroup";

type Props = {
  jurisdiction: ApplicationJurisdictionType;
  lockedName?: string
  text?: string
};

const contentMap: {
  [K in ApplicationJurisdictionType]: {
    linkUrl: string;
    linkTitle: string;
  };
} = {
  PSIO: {
    linkUrl:
      "https://www.mcv.vic.gov.au/intervention-orders/personal-safety-intervention-orders/changing-intervention-order-psio",
    linkTitle: "Changing an intervention order (PSIO)",
  },
  FVIO: {
    linkUrl:
      "https://www.mcv.vic.gov.au/intervention-orders/family-violence-intervention-orders/changing-intervention-order-fvio",
    linkTitle: "Changing an intervention order (FVIO)",
  },
};

const ExtendOrderReminder: React.FC<Props> = ({ jurisdiction, lockedName = "appl_cont_phone_3", text }) => {
  const content = contentMap[jurisdiction];
  return (
    <Wrapper>
      <h3>Reminder to extend your order</h3>
      <p>
        {text ||
          `If your order expires, you’ll have to repeat the application process. We
        can send you an SMS reminder to extend your order before the expiry
        date.`}{" "}
        <a
          href={content.linkUrl}
          title={content.linkTitle}
          target="_blank"
          rel="noopener noreferrer"
        >
          Find out more about extending your order.
        </a>
      </p>
      <QuestionSpacer />
      <RadioGroup
        name="appl_cont_sms_consent"
        optionChildren={{
          yes: (
            <LockedQuestion
              // @ts-ignore
              validation={Yup.string()
                .ausMobilePhone()
                .required("Please provide a safe contact mobile number")}
              lockedName={lockedName}
              label="Please confirm your safe contact mobile number"
            />
          ),
        }}
      />
    </Wrapper>
  );
};

export default ExtendOrderReminder;

const Wrapper = styled.div`
  margin-top: 3rem;
`;
