import Yup, { generateAddressValidation } from "../yup";

const child = (isApplicant: boolean) => Yup.object().shape({
  child_action: Yup.string().when(`seeking`, {
    is: () => isApplicant,
    then: Yup.string().required("Please select add or remove")
  }),
  prp_giv_nam: Yup.string()
    .trim()
    .csvGivenName()
    .required("Given name is required"),
  prp_giv_nam2: Yup.string().csvGivenName(),
  prp_giv_nam3: Yup.string().csvGivenName(),
  prp_fam_nam: Yup.string().trim().required("Family name is required"),
  prp_sex1: Yup.string().trim().required("Gender is required"),
  prp_sex_other: Yup.string().when("prp_sex1", {
    is: "U",
    then: Yup.string().required("We require the gender of the child"),
  }),
  prp_dob: Yup.string()
    .trim()
    .csvDate()
    .csvDobUnder18()
    .required("We require this information"),
  prp_rel_to_resp: Yup.string().required("We require this information"),
  prp_rel_to_appl: Yup.string().required("We require this information"),
  prp_abor_code: Yup.string().required("We require this information"),
  prp_disabl: Yup.string().required("We require this information"),
  prp_live: Yup.string().required("We require this information"),
  ...generateAddressValidation('prp_addr', 'prp_live', 'no'),
  prp_ord_exist: Yup.string().required("We require this information"),
  prp_ord_exist_det: Yup.array()
    .ensure()
    .when('prp_ord_exist', {
      is: 'yes',
      then: Yup.array()
        .ensure()
        .min(1, 'We require details about the existing order'),
    }),
  prp_ord_fc: Yup.string()
    .trim()
    .required('We require this information'),
  prp_flc_hear: Yup.array()
    .ensure()
    .when('prp_ord_fc', {
      is: 'yes',
      then: Yup.array().min(1, 'Please select at least 1 option'),
    }),
  prp_flc_other: Yup.string().when([`prp_ord_fc`, `prp_flc_hear`], {
    is: (prp_ord_fc: string, prp_flc_hear: string[]) =>
      prp_ord_fc === 'yes' &&
      Array.isArray(prp_flc_hear) &&
      prp_flc_hear.includes('other'),
    then: Yup.string().required('We require this information'),
  }),
  prp_flc_other_po: Yup.string().when([`prp_ord_fc`, `prp_flc_hear`], {
    is: (one: string, two: string[]) =>
      one === 'yes' && Array.isArray(two) && two.includes('po'),
    then: Yup.string().required('We require this information'),
  }),
});

export default child;
