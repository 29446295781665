import React from "react";
import styled from "styled-components/macro";
import respectImg from "../assets/images/1800respect.png";
import djirra from "../assets/images/djirra.png";
import intouch from "../assets/images/intouch.png";
import qlife from "../assets/images/qlife.png";
import safesteps from "../assets/images/safesteps.png";
import vals from "../assets/images/vals.png";
import ntv from "../assets/images/ntv.png";
import vla from "../assets/images/vla-logo.png";
import federation from "../assets/images/Fclc_Logo_Sub.png";
import rainbowDoor from "../assets/images/rainbow-door.png";
import { em } from "polished";
import media from "../theme/media";

const servicesList = {
  safeSteps: {
    img: safesteps,
    title: `Safe steps - Family Violence Responce Centre`,
    description: `24/7 family violence support service`,
    contact: `1800 015 188`,
    link: `https://www.safesteps.org.au/`,
  },
  victoriaAboriginalLegalService: {
    img: vals,
    title: `Victorian Aboriginal Legal Service`,
    description: `Providing legal assistance for community`,
    contact: `1800 064 865`,
    link: `https://www.vals.org.au/`,
  },
  noToViolence: {
    img: ntv,
    title: `No to Violence`,
    description: `Working together to end men’s family violence`,
    contact: `1300 766 491`,
    link: `https://ntv.org.au/`,
  },

  _1800Respect: {
    img: respectImg,
    title: `1800 RESPECT`,
    description: `Family violence counselling, information and referral service`,
    contact: `1800 737 732`,
    link: `https://www.1800respect.org.au/`,
  },
  inTouch: {
    img: intouch,
    title: `inTouch`,
    description: `Multicultural centre against family violence`,
    contact: `1800 755 988`,
    link: `https://intouch.org.au/`,
  },
  djirra: {
    img: djirra,
    title: `Djirra`,
    description: `Support for aboriginal women experiencing family violence`,
    contact: `1800 105 303`,
    link: `https://djirra.org.au/`,
  },
  qLife: {
    img: qlife,
    title: `QLife`,
    description: `Anonymous LGBTI peer support and referral`,
    contact: `1800 184 527`,
    link: `https://qlife.org.au/`,
  },
  victoriaLegalAid: {
    img: vla,
    title: `Victoria Legal Aid`,
    description: `Helping Victorians with their legal problems`,
    contact: `1300 792 387`,
    link: `https://www.legalaid.vic.gov.au/`,
  },
  federationOfCommunityLegalCentres: {
    img: federation,
    title: `Federation of Community Legal Centres`,
    description: `Independent community organisations providing legal services`,
    contact: `1300 792 387`,
    link: `https://www.fclc.org.au/`,
  },
  rainbowDoor: {
    img: rainbowDoor,
    title: `Rainbow Door`,
    description: `LGBTIQ peer support and referral`,
    contact: `1800 729 367`,
    link: `https://www.rainbowdoor.org.au/`,
  },
};

type Props = {
  services: (keyof typeof servicesList)[];
};

const SupportServices: React.FC<Props> = ({ services }) => {
  return (
    <div>
      {services.map((serviceKey) => {
        const { link, img, title, description, contact } = servicesList[
          serviceKey
        ];
        return (
          <ServicesBlock href={link} key={link}>
            <div className="img-container">
              <img src={img} alt={title} />
            </div>
            <div className="info">
              <p>
                <span className="bold">{title}</span>
                <br />
                {description}
                <br />
                <span className="bold">{contact}</span>
              </p>
            </div>
          </ServicesBlock>
        );
      })}
    </div>
  );
};

const ServicesBlock = styled.a`
  display: flex;
  text-decoration: none;
  max-width: 600px;
  margin-bottom: ${em(20, 18)};
  align-items: center;
  .img-container {
    width: 110px;
    img {
      display: block;
      max-width: 100%;
    }
  }

  .bold {
    font-weight: 700;
  }
  .info {
    margin-left: 40px;
    line-height: 1.3;
  }

  ${media.lessThan("tablet")`
    .img-container {
      width: 90px;
    }
    .info {
      margin-left: 20px;
      width: calc(100% - 110px);
    }
    .info p {
      font-size: 16px;
    }
  `}
`;

export default SupportServices;
