import React from "react";
import styled from "styled-components/macro";
import numeral from "numeral";

import inputStyles from "../theme/inputStyles";

import FormNote from "./FormNote";
import FormLabel from "./FormLabel";
import ErrorMessage from "./ErrorMessage";
import { useField } from "formik";
import useQuestionConfig from "../hooks/useQuestionConfig";
import ApplicationContext from "../reducers/applicationFrame";
import colors from "../theme/colors";

type Props = {
  name: string;
  note?: string;
  label?: string;
  short?: boolean;
  charLimit?: number;
  prefix?: string;
};

const LongFootnote: React.FC<Props> = ({
  name,
  short,
  charLimit = 4560,
  prefix = "",
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const [narrativeCharsLeftField] = useField("NarrativeCharsLeft");
  const {
    state: { narrativeKeys },
  } = React.useContext(ApplicationContext);

  const { label, note } = useQuestionConfig(name, rest);
  const [charsLeft, setCharsLeft] = React.useState<number>(
    narrativeKeys.includes(name) ? narrativeCharsLeftField.value : charLimit
  );

  const hasError = meta.error && meta.touched;

  // on mount set charsLeft
  // if using regular charlimit
  React.useEffect(() => {
    if (!narrativeKeys.includes(name) && typeof field.value === "string") {
      setCharsLeft(charLimit - field.value.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // used of global narrative limit
  React.useEffect(() => {
    if (narrativeKeys.includes(name)) {
      setCharsLeft(narrativeCharsLeftField.value);
    }
  }, [name, narrativeCharsLeftField.value, narrativeKeys]);

  const handleOnChange = React.useCallback(
    (e) => {
      let value = e.target.value;
      if(prefix && String(value).trim() === prefix.trim()) {
        value = "";
      }
      const charsLeft = charLimit - value.length;

      if (!narrativeKeys.includes(name) && charsLeft <= -1) {
        // if not global narrative and over char limit
        e.preventDefault();
      } else if (!narrativeKeys.includes(name)) {
        // if not global narrative and under char limit
        setCharsLeft(charsLeft);
        helpers.setValue(value);
      } else if (narrativeKeys.includes(name)) {
        // if global narrative
        helpers.setValue(value);
      }
    },
    [charLimit, narrativeKeys, name, helpers, prefix]
  );

  const isNegative: boolean = React.useMemo(() => {
    if (typeof charsLeft === "number") {
      return charsLeft < 0;
    }
    return false;
  }, [charsLeft]);

  const getPrefixedValue = () => {
    // empty value
    if (!field.value) {
      return prefix;
    } else if (field.value.substring(0, prefix.length) !== prefix) {
      // check if value start with prefix, if not prepend it
      return `${prefix}`;
    }
    const cleanedValue = field.value.substring(prefix.length);
    return `${prefix}${cleanedValue}`;
  };

  return (
    <Wrapper>
      {label && <FormLabel text={label} name={name} />}
      {note && <FormNote text={note} />}
      {hasError && <StyledErrorMessage>{meta.error}</StyledErrorMessage>}
      <Textarea
        name={name}
        onChange={handleOnChange}
        value={prefix ? getPrefixedValue() : field.value || ""}
        onBlur={field.onBlur}
        short={short}
      />
      <Remaining isNegative={isNegative}>
        {numeral(Math.abs(charsLeft)).format("0,0")} characters{" "}
        {isNegative ? "over" : "remaining"}
      </Remaining>
    </Wrapper>
  );
};

export default LongFootnote;

const Wrapper = styled.div``;

const Textarea = styled.textarea<{ short?: boolean }>`
  ${inputStyles};
  padding: 0.75em 1em;
  min-height: ${({ short }) => (short ? "70px" : "168px")};
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-bottom: 13px;
`;

const Remaining = styled.p<{ isNegative: boolean }>`
  ${inputStyles}
  font-size: 15px;
  line-height: 1.2;
  color: ${({ isNegative }) => (isNegative ? colors.error : "#6d6d6d")};
  text-align: right;
  border: none;
  height: auto;
  margin: 0;
`;
