import Yup from "../yup";

const yourRole = {
  seeking: Yup.string().required("Please select your role"),
  seeking_appl_role: Yup.string().when("seeking", {
    is: "appl",
    then: Yup.string().required("Please select applicant role"),
  }),
  seeking_appl_sub_role: Yup.string().when(["seeking", "seeking_appl_role"], {
    is:(seeking: string, seeking_appl_role: string) => seeking === "appl" && seeking_appl_role === "pg",
    then: Yup.string().required("Please select applicant sub role"),
  }),
};

export default yourRole;
