import React from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";

import media from "../theme/media";

import mcvLogoLong from "../assets/images/mcv-logo-long.svg";
import Button from "./Button";

import { hasAnonymousIdentity, isAuthenticated } from "../helpers/auth";

const McvBar = () => {
  const { push } = useHistory();

  const [isAuth, setIsAuth] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    const getIsAuth = async () => {
      const isAuth = await isAuthenticated();
      setIsAuth(isAuth);
    };
    getIsAuth();
  }, []);

  return (
    <Wrapper>
      <Inner>
        <Logo src={mcvLogoLong} alt="Magistrates' Court of Victoria Logo" />
        {isAuth && !hasAnonymousIdentity() && (
          <Button onClick={() => push("/logout")}>Log out</Button>
        )}
      </Inner>
    </Wrapper>
  );
};

export default McvBar;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 24px 15px;
`;

const Inner = styled.div`
  width: 100%;
  max-width: 1310px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  width: 180px;
  height: 22px;
  ${media.greaterThan("phone")`
    width: 229px;
    height: 29px;
  `}
  ${media.greaterThan("tablet")`
    width: 300px;
    height: 38px;
  `}
`;
