import { courts, CourtType } from "csv-package";

const getCourtById = (court_id: string): CourtType | null => {
  const court = courts.find((court) => court.court_id === court_id);
  if (court === undefined) {
    return null;
  }
  return court;
};

export default getCourtById;
