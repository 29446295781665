import React from "react";
import styled from "styled-components/macro";
import { rem } from "polished";
import { useFormikContext } from "formik";
import { ConditionsType, conditionsChecker } from "csv-package";

import colors from "../theme/colors";
import spacing from "../theme/spacing";
import { ApplicationContext } from "../reducers/applicationFrame";

import { ReactComponent as EditIcon } from "../assets/icons/Edit.svg";

import ConfirmQuestion from "./ConfirmQuestion";
import ConfirmQuestionFrame from "./ConfirmQuestionFrame";
import QuestionSpacer from "./QuestionSpacer";
import { FlatApplciationType } from "../types";
import _ from "lodash";

type ConfirmConfigType = {
  heading?: string;
  names: Array<string|{name: string, label?: string}>;
  private?: string;
  conditions?: ConditionsType;
};

type Props = {
  goToStepIndex?: number;
  heading: string;
  config: ConfirmConfigType[];
};

const ConfirmSection: React.FC<Props> = ({
  goToStepIndex,
  heading,
  config,
}) => {
  const { values } = useFormikContext<FlatApplciationType>();
  const { goToStep } = React.useContext(ApplicationContext);

  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (typeof goToStepIndex === "number") {
      goToStep(goToStepIndex);
    }
  };

  return (
    <ConfirmSectionWrapper>
      <ConfirmQuestionFrame>
        <header>
          <h2>{heading}</h2>
          {typeof goToStepIndex === "number" && values.Status === "initiated" && (
            <button onClick={handleEditClick}>
              <EditIcon />
              Edit
            </button>
          )}
        </header>
      </ConfirmQuestionFrame>
      {config.map((group, index) => {
        if (
          group.conditions !== undefined &&
          !conditionsChecker(group.conditions, values)
        ) {
          return null;
        }

        return (
          <div key={index}>
            {"heading" in group && index > 0 && <QuestionSpacer size={16} />}
            {"heading" in group && <SubHeading>{group.heading}</SubHeading>}
            <ConfirmQuestionFrame
              privateFrame={Boolean(group.private)}
              text={group.private}
            >
              {group.names.map(el => (
                <ConfirmQuestion 
                  key={_.isObject(el) ? el.name : el} 
                  name={_.isObject(el) ? el.name : el} 
                  label={_.isObject(el) && el.label ? el.label : undefined} 
                />
              ))}
            </ConfirmQuestionFrame>
          </div>
        );
      })}
    </ConfirmSectionWrapper>
  );
};

export default ConfirmSection;

const ConfirmSectionWrapper = styled.div`
  margin-bottom: 40px;
  width: 100%;

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${rem(25)};
    h2 {
      font-size: ${rem(23)};
      line-height: 1.17;
      margin: 0;
    }
    button {
      color: ${colors.blue};
      border: none;
      background: transparent;
      display: block;
      padding: 0 0 0 1.1em;
      font-size: ${rem(16)};
      position: relative;
      text-decoration: underline;
      height: 1em;
      line-height: 1;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }

      svg {
        height: 1em;
        width: 1em;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
`;

const SubHeading = styled.h4`
  font-size: ${rem(18)};
  padding: 0 ${spacing.gutters}px;
`;
